import React, { useState, useRef, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Container } from "react-bootstrap";
import DragableMap from "../../../../UI/DragableMap";
import Input from "../../../../UI/Input";
import Autocomplete from "react-google-autocomplete";
import { GOOGLE_MAPS_API_KEY } from "../../../../../configs/map.key";
import { getAddress } from "../../../../../services/localStorageService";
import { dubaiCoordinates, fixCoordinate } from "../../../../../configs/common";
import AddressField from "../../../../UI/AddressField";

const ChooseAddressFromMapModel = (props) => {
  const [address, setAddress] = useState("");
  const [search, setSearch] = useState("");
  const [position, setPosition] = useState(dubaiCoordinates);

  const onMapAddressChange = (data) => {
    setAddress(data.address);
    props.onMapAddressChange(data);
  };

  const onSearchPosition = async () => {
    var parts = search.split(",");
    if (parts.length >= 2) {
      setPosition({
        lat: parseFloat(parts[0].trim()),
        lng: parseFloat(parts[1].trim()),
      });
      const address = await getAddress(parts[0].trim(), parts[1].trim());
      setAddress(address);
      props.onMapAddressChange({
        position: { lat: parts[0].trim(), lng: parts[1].trim() },
        address: address,
      });
    }
  };

  const handleGoogleSearch = async ({ latitude, longitude, address }) => {
    setPosition({
      lat: parseFloat(`${latitude}`),
      lng: parseFloat(`${longitude}`),
    });
    setAddress(address);
    setSearch(`${fixCoordinate(latitude)},${fixCoordinate(longitude)}`);
    props.onMapAddressChange({
      position: { lat: latitude, lng: longitude },
      address: address,
    });
  };

  useEffect(() => {
    if (props.position && props.show) {
      setPosition(props.position);
      setSearch(
        `${fixCoordinate(props.position.lat)}, ${fixCoordinate(
          props.position.lng
        )}`
      );
    }
    setAddress(props.address || "");
  }, [props.position, props.show]);

  return (
    <>
      <Modal
        show={props.show}
        dialogClassName="modal-30w"
        onHide={props.toggleModal}
        aria-labelledby="FielsStaffModal"
      >
        <Modal.Header closeButton>Choose Address From Map</Modal.Header>
        <Modal.Body className="show-grid pb-4 pt-4">
          <Container>
            <div className="d-flex">
              <Input
                label="Coordinates"
                placeholder="25.2048,55.2708"
                value={search}
                onChange={(value) => setSearch(value)}
              />
              <button
                type="button"
                className="btn btn-search mb-0  mt-3 ml-2"
                onClick={() => onSearchPosition()}
              >
                <i className="fa fa-search font-14"></i>
              </button>
              <button
                type="button"
                className="btn btn-theme mb-0  mt-3 ml-2"
                onClick={() => setSearch("")}
              >
                <i className="fa fa-refresh font-13"></i>
              </button>
            </div>

            <AddressField
              onAddressSelect={handleGoogleSearch}
              name="address"
              label="Address"
              placeholder="Search here..."
              wantFieldset={true}
              value={address}
            />

            <div style={{ height: "340px", width: "100%", marginTop: 70 }}>
              <DragableMap
                onDragChange={onMapAddressChange}
                position={position}
                allowUpdateOnChange={true}
              />
            </div>

            <div className="mt-3">
              <p className="text-dark mb-0"> {address} </p>
            </div>
          </Container>
        </Modal.Body>

        <Modal.Footer className="py-0">
          <button
            onClick={props.toggleModal}
            style={{
              fontSize: "12px",
              backgroundColor: "#007bff",
              color: "#ffffff",
            }}
            className="btn btn-small"
          >
            Choose This Address
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const styles = {
  container: {
    maxHeight: "50vh",
    width: "100%",
    overflowY: "auto",
  },
  driverName: {
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  imageContainer: {
    marginLeft: "30%",
    height: "90px",
    width: "90px",
  },
  image: {
    height: "80px",
    width: "80px",
  },
};

export default ChooseAddressFromMapModel;
