
import { GET_ROUTE, SET_ACTIVE_TRIP, SET_TRIP_ZONE, SET_ACTIVE_INTERVAL, GET_ROUTE_SALIKS, GET_ROUTE_ZEOZONES, GET_TRIPS, SET_GROUPS, TOGGLE_SELECTED_VEHICLE, TOGGLE_SELECTED_VEHICLE_GROUP, TRIP_MAP_LOADER } from '../actions/trip.actions';

const initialState = {
    groups: [],
    selectedVehicles: [],
    trips: [],
    tripRoute: null,
    routeGeozones: [],
    routeSaliks: [],
    activeTrip : false,
    tripZone: false,
    activeInterval: 'Today',
    tripMapLoader : false
}

const TripReducer = (state = initialState, action) => {

    switch (action.type) {

        case SET_GROUPS:
            return { ...state, groups: action.value, checkedVehicles: [] }

        case GET_TRIPS:
            const tripsData = action.value
            var _trips = []
            tripsData.map((trip, i) => {
                _trips.push({...trip, id: i})
            })
            return { ...state, trips: _trips }

        case SET_ACTIVE_TRIP:
            return { ...state, activeTrip: action.value }
        
        case SET_ACTIVE_INTERVAL:
            return { ...state, activeInterval: action.value }
        case GET_ROUTE:
            return { ...state, tripRoute: action.value }

        case GET_ROUTE_ZEOZONES:
            return { ...state, routeGeozones: action.value }

        case GET_ROUTE_SALIKS:
            return { ...state, routeSaliks: action.value }

        case SET_TRIP_ZONE:
            return { ...state, tripZone: action.value}

        case TOGGLE_SELECTED_VEHICLE:
            const cloneData = [...state.selectedVehicles]
            var i = cloneData.findIndex((v) => v.id == action.value.id);
            if (i == -1) {
                cloneData.push(action.value)
            } else {
                cloneData.splice(i, 1)
            }
            return { ...state, selectedVehicles: cloneData }

        case TOGGLE_SELECTED_VEHICLE_GROUP:

            const cloneForVehicleGroup = [...state.groups];
            var v = [...state.selectedVehicles]

            var i = cloneForVehicleGroup.findIndex((g) => g.id == action.value.id);

            if (i !== -1) {
                Object.keys(cloneForVehicleGroup[i].__vehicles__).map((key) => {
                    var exist = v.findIndex((v) => v.id == key);
                    if (exist == -1 && action.value.checked == true) {
                        v.push(cloneForVehicleGroup[i].__vehicles__[key])
                    } else if (exist !== -1 && action.value.checked == true) {

                    } else {
                        v.splice(exist, 1)
                    }
                })
            }

            return {
                ...state,
                selectedVehicles: v
            }

        case TRIP_MAP_LOADER:
            return { ...state, tripMapLoader: action.value }

        default:
            return state
    }


}


export default TripReducer;