import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Container } from "react-bootstrap";
import {
  getStorage,
  logout,
  setStorage,
} from "../../../services/localStorageService";
import Input from "../../UI/Input";
import ImageInput from "../../UI/ImageInput";
import { useDispatch } from "react-redux";
import { updateprofile } from "../../../store/actions/setting.action";
import DropZoneInput from "../../UI/DropZoneInput";
import {
  ASSETS_URL,
  TASK_ASSETS_URL,
} from "../../../environments/environments";
import { useHistory } from "react-router-dom";
import { ShowNotification } from "../../../services/Notifications";

const EditProfileModal = (props) => {
  const history = useHistory();

  const dispatch = useDispatch();
  const user = getStorage("__user__", "object");
  const [image, setImage] = useState(false);
  const [logo, setLogo] = useState(false);

  useEffect(() => {
    if (props.show == true) {
      if (user && user.photo && user.photo !== null)
        setImage(`${TASK_ASSETS_URL}/images/${user.photo}`);
      if (
        user &&
        user.organisation &&
        user.organisation.logo &&
        user.organisation.logo != 0
      )
        setLogo(`${TASK_ASSETS_URL}/images/${user.organisation.logo}`);
    }
  }, [props.show]);

  const handleSubmit = async (event) => {
    const formData = new FormData(event.target);
    var formData1 = new FormData();

    event.preventDefault();

    for (let [key, value] of formData.entries()) {
      formData1.append(key, value);
    }

    if (image && !(typeof image === "string" || image instanceof String))
      formData1.append("file", image);
    formData1.append("name", user ? user.name : "");
    formData1.append("id", user ? user.id : "");
    formData1.append("org_id", getStorage("org_id"));
    formData1.append(
      "organisation",
      user.organisation ? user.organisation.name : ""
    );
    if (logo && !(typeof logo === "string" || logo instanceof String))
      formData1.append("files", logo);

    // API call here
    const response = await dispatch(updateprofile(formData1));
    if (response && response.responseCode == 200) {
      ShowNotification(
        "success",
        "Your Profile Succesfully Update Please Login Again."
      );
      alert("Your Profile Succesfully Update Please Login Again.");
      logout();
      history.push("/login");
    }

    props.toggleModal();
  };

  return (
    <>
      <Modal
        show={props.show}
        dialogClassName="modal-30w"
        onHide={props.toggleModal}
        aria-labelledby="EditProfileModal"
      >
        <Modal.Header closeButton>Profile - {user.name}</Modal.Header>
        <form onSubmit={handleSubmit}>
          <Modal.Body className="show-grid pb-4 pt-4 text-start">
            <Container>
              <Input
                label="Username"
                paceholder=""
                name="email"
                value={user ? user.email : ""}
                className="mb-3"
                readOnly={true}
              />

              <Input
                label="Email"
                paceholder=""
                name="user_email"
                value={user ? user.user_email : ""}
                className="mb-3"
                required={true}
              />

              <p className=" mb-0">Profile Icon</p>

              <DropZoneInput
                selected={image}
                file={image}
                label="Image"
                style={{ minHeight: 80 }}
                onChange={(file) => setImage(file)}
              />

              <p className="mt-3 mb-0">Organization Logo</p>
              <DropZoneInput
                selected={logo}
                file={logo}
                label="Logo"
                style={{ minHeight: 80 }}
                onChange={(file) => setLogo(file)}
              />

              {/* <ImageInput
                                label="Image"
                                image={require('../../../assets/img/settings/profile-image.jpg').default}
                                onChange={(files) => setImage(files[0])}
                            />

                            <ImageInput
                                label="Organization Logo"
                                image={require('../../../assets/img/settings/profile-image.jpg').default}
                                onChange={(files) => setLogo(files[0])}
                            /> */}
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              style={{ fontSize: "12px" }}
              className="btn btn-small"
              onClick={props.toggleModal}
            >
              Cancel
            </button>
            <button
              style={{
                fontSize: "12px",
                backgroundColor: "#007bff",
                color: "#ffffff",
              }}
              className="btn btn-small"
            >
              Save
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default EditProfileModal;
