import { useState, useEffect } from "react";
// import Datetime from "react-datetime";
import moment from "moment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker as TimePickerProvider } from "@mui/x-date-pickers/TimePicker";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";

const TimePicker = (props) => {
  const [value, setValue] = useState(new Date());

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  //   const onChangeDate = (event) => {
  //     setValue(event);
  //     if (props.onChange) props.onChange(event);
  //   };

  return (
    <div className="mui-date-picker">
      <fieldset className="p-1 visible" style={{ height: 40 }}>
        <legend>{props.label}</legend>
        <div className="d-flex">
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <TimePickerProvider
              value={moment(value)}
              onChange={(d) => {
                setValue(d.toDate());
                if (props.onChange) props.onChange(d.toDate());
              }}
              sx={{
                width: "100%",
              }}
              viewRenderers={{
                hours: renderTimeViewClock,
                minutes: renderTimeViewClock,
                seconds: renderTimeViewClock,
              }}
            />
          </LocalizationProvider>
          {/* <Datetime
                    dateFormat={false}
                    timeFormat={props.timeFormat || "HH:mm"}
                    value={value}
                    onChange={onChangeDate}
                    className="flex-1"
                />
                <i className="fa fa-clock-o mt-2 text-secondary"></i> */}
        </div>
      </fieldset>
    </div>
  );
};

export default TimePicker;
