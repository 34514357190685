import GetRequest from "../../services/GetRequest";
import { getStorage } from "../../services/localStorageService";
import PostRequest from "../../services/PostRequest";

export const GET_GROUPS = 'GET_GROUPS';
export const DELETE_GEOZONE = 'DELETE_GEOZONE';
export const DELETE_GROUP = 'DELETE_GROUP';
export const ADD_GROUP = 'ADD_GROUP';
export const GET_SINGLE_GROUP = 'GET_SINGLE_GROUP';
export const EDIT_GROUP = 'EDIT_GROUP'
export const ADD_GEOZONE = 'ADD_GEOZONE'
export const GET_GEOZONE_DETAILS = 'GET_GEOZONE_DETAILS'
export const GET_POINT_DETAILS = 'GET_POINT_DETAILS'
export const EDIT_ZEOZONE = 'EDIT_ZEOZONE'
export const SELECT_GEOZONE = 'SELECT_GEOZONE'
export const SELECT_POINT = 'SELECT_POINT'
export const SELECT_POINT_GROUP = 'SELECT_POINT_GROUP'
export const SELECT_GEOZONE_GROUP = 'SELECT_GEOZONE_GROUP'

export const SET_FLAG_FOR_ADD_ZONE_ON_MAP = 'SET_FLAG_FOR_ADD_ZONE_ON_MAP'
export const SET_EDITABLE_ZONE = 'SET_EDITABLE_ZONE'


export const ACTIVE_ZEOZONE_POINT = 'ACTIVE_ZEOZONE_POINT'

export const getGeoZoneGroups = () => {

    return async dispatch => {

        try {

            const response = await GetRequest('/geozones/getGeozoneGroupsForUser', {})

            dispatch({
                type: GET_GROUPS,
                value: response.geozonesGroupsForUser
            })

            return true;

        } catch (error) {
            return false;
        }

    };

}

export const addGeozoneGroup = (data) => {

    return async dispatch => {

        const user = getStorage('__user__', 'object')
        const id = user && user.organisation ? user.organisation.id : null;

        try {

            const response = await PostRequest(`/geozones/GeoGroupCreate/${id}`, data)

            dispatch({
                type: ADD_GROUP,
                value: response
            })

            return true;

        } catch (error) {
            return false;
        }

    };

}


export const setActiveGeoZonePoint = (geozone) => {

    return async dispatch => {

        try {

            dispatch({
                type: ACTIVE_ZEOZONE_POINT,
                value: geozone
            })

            return true;

        } catch (error) {
            return false;
        }

    };

}

export const getSingleGroup = (id) => {

    return async dispatch => {

        try {

            const response = await GetRequest(`/user/getSingleObjectGroup/${id}`, {})

            dispatch({
                type: GET_SINGLE_GROUP,
                value: response
            })

            return true;

        } catch (error) {
            return false;
        }

    };

}

export const editGeozoneGroup = (data) => {

    return async dispatch => {

        try {

            const response = await PostRequest(`/user/editObjGroup/${data.obj_group_id}`, data)

            dispatch({
                type: EDIT_GROUP,
                value: response
            })

            return true;

        } catch (error) {
            return false;
        }

    };

}

export const deleteGeozoneGroup = (id) => {

    return async dispatch => {

        try {

            const response = await GetRequest(`/user/deleteObjGroup/${id}`, {})

            dispatch({
                type: DELETE_GROUP,
                value: response
            })

            return true;

        } catch (error) {
            return false;
        }

    };

}

export const deleteGeoZone = (id) => {

    return async dispatch => {

        try {

            const response = await GetRequest(`/geozones/deleteGeozone/${id}`, {})

            dispatch({
                type: DELETE_GEOZONE,
                value: response
            })

            return true;

        } catch (error) {
            return false;
        }

    };

}

export const addGeozone = (data) => {

    return async dispatch => {

        try {

            const response = await PostRequest(`/geozones/addGeozone`, data)

            dispatch({
                type: ADD_GEOZONE,
                value: response
            })

            return response;

        } catch (error) {
            return false;
        }

    };

}

export const getGeozoneDetails = (id, isPoint = false) => {

    return async dispatch => {

        try {

            const response = await GetRequest(`/geozones/getGeozoneDetails/${id}`, {})

            if(isPoint) {
                dispatch({
                    type: GET_POINT_DETAILS,
                    value: response.geozone
                })
            } else {
                dispatch({
                    type: GET_GEOZONE_DETAILS,
                    value: response.geozone
                })
            }
            
            //GET_POINT_DETAILS

            return true;

        } catch (error) {
            return false;
        }

    };

}

export const editGeozone = (data) => {

    return async dispatch => {

        try {

            const response = await PostRequest(`/geozones/editGeozone/${data.gz_id}`, data)

            dispatch({
                type: EDIT_ZEOZONE,
                value: response
            })

            return true;

        } catch (error) {
            return false;
        }

    };

}

export const selectGeozone = (id, isPoint = false) => {

    return async dispatch => {

        try {

            const response = await GetRequest(`/geozones/getGeozoneDetails/${id}`, {})

            if (isPoint) {
                dispatch({
                    type: SELECT_POINT,
                    value: response.geozone
                })
            } else {
                dispatch({
                    type: SELECT_GEOZONE,
                    value: response.geozone
                })
            }

            return true;

        } catch (error) {
            return false;
        }

    };

}

export const selectGeozoneGroup = (data, isPoint = false, isChecked = true) => {

    return async dispatch => {

        try {

            const response = await PostRequest(`/geozones/getGroupGeozones`, data)

            if (isPoint) {
                dispatch({
                    type: SELECT_POINT_GROUP,
                    value: {response : response, isChecked: isChecked}
                })
            } else {
                dispatch({
                    type: SELECT_GEOZONE_GROUP,
                    value: {response: response, isChecked: isChecked}
                })
            }

            return true;

        } catch (error) {
            return false;
        }

    };

}

export const enableOrDisableZoneDrawManager = (status) => {

    return dispatch => {

        try {

            dispatch({
                type: SET_FLAG_FOR_ADD_ZONE_ON_MAP,
                value: status
            })

        } catch (error) {
            return false;
        }

    };

}




export const setEditableZone = (zone) => {

    return dispatch => {

        try {

            dispatch({
                type: SET_EDITABLE_ZONE,
                value: zone
            })

        } catch (error) {
            return false;
        }

    };

}


export const setActivePoint = (point) => {

    return  dispatch => {

        try {
            dispatch({
                type: GET_POINT_DETAILS,
                value: point
            })
            return true;
        } catch (error) {
            return false;
        }

    };

}
