import moment from "moment";
import { assetHistory, documentReminders, serviceReminders } from "../../data/responses";
import GetRequest from '../../services/GetRequest'
import PostRequest from '../../services/PostRequest'
import { getStorage } from "../../services/localStorageService";
export const FILTER_ASSETS = 'FILTER_ASSETS'
export const GET_ASSETS_GROUPS = 'GET_ASSETS_GROUPS'
export const GET_IGNITION_NOTIFICATIONS = 'GET_IGNITION_NOTIFICATIONS'
export const GET_ASSET_HISTORY = 'GET_ASSET_HISTORY'
export const GET_SERVICE_REMINDER = 'GET_SERVICE_REMINDER'
export const GET_DOCUMENT_REMINDER = 'GET_DOCUMENT_REMINDER'
export const SET_FROM = 'SET_FROM'
export const SET_TO = 'SET_TO'
export const SET_ACTIVE_ASSET = 'SET_ACTIVE_ASSET'
export const GET_ASSET_DETAILS = 'GET_ASSET_DETAILS'

export const filterAssets = (type) => {

    return async dispatch => {
        dispatch({
            type: FILTER_ASSETS,
            value: type
        })
    };
}

export const getAssetsGroups = () => {

    const groups = getStorage('generators_info', 'object')

    return async dispatch => {
        dispatch({
            type: GET_ASSETS_GROUPS,
            value: groups
        })
    };
}

export const setFrom = (value) => {

    return async dispatch => {
        dispatch({
            type: SET_FROM,
            value: value
        })
    };
}

export const setTo = (value) => {

    return async dispatch => {
        dispatch({
            type: SET_TO,
            value: value
        })
    };
}

export const setActiveAsset = (asset) => {

    return async dispatch => {
        dispatch({
            type: SET_ACTIVE_ASSET,
            value: asset
        })
    };
}

export const getIgnitionNotifications = (data) => {

    return async dispatch => {

        const id = data.id ? data.id : null
        const from = data.from ? moment(data.from).format('yyyy-MM-DD 00:00:00') : moment(new Date()).format('yyyy-MM-DD 00:00:00')
        const to = data.to ? moment(data.to).format('yyyy-MM-DD 00:00:00') : moment(new Date()).format('yyyy-MM-DD 00:00:00')

        const response = await GetRequest(`/asset/getAssetIgnitionNotifications/${id}/${from}/${to}`, {})

        dispatch({
            type: GET_IGNITION_NOTIFICATIONS,
            value: response
        })
    };

}

export const getAssetHistory = (id) => {

    const response = assetHistory;

    return async dispatch => {

        // const response = await GetRequest(`/user/getAssetHistory/${id}`, {})

        dispatch({
            type: GET_ASSET_HISTORY,
            value: response
        })
    };

}

export const getServiceReminders = (id) => {

    const response = serviceReminders;

    return async dispatch => {

        // const response = await GetRequest(`/fleet/getLatestRemainders/${id}`, {})

        dispatch({
            type: GET_SERVICE_REMINDER,
            value: response
        })
    };

}

export const getDocumentReminders = (id) => {

    const response = documentReminders;

    return async dispatch => {

        // const response = await GetRequest(`/fleet/getLatestDocuments/${id}`, {})

        dispatch({
            type: GET_DOCUMENT_REMINDER,
            value: response
        })
    };

}

export const getAssetDetail = (data, formData) => {

    return async dispatch => {

        const id = data.id ? data.id : null
        const from = data.from ? moment(data.from).format('yyyy-MM-DD 00:00:00') : moment(new Date()).format('yyyy-MM-DD 00:00:00')
        const to = data.to ? moment(data.to).format('yyyy-MM-DD 00:00:00') : moment(new Date()).format('yyyy-MM-DD 00:00:00')

        const response = await PostRequest(`/asset/status/${id}/${from}/${to}`, formData)

        dispatch({
            type: GET_ASSET_DETAILS,
            value: response[0]
        })
    };

}

// /Events/1039/29-08-2019%2000:00:00/30-08-2019%2000:00:00
//asset/status/20214/2021-08-18%2000:00:00/2021-08-19%2000:00:00 - post