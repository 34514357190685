import TaskManagerRequest from "../../services/TaskManagerRequest";

export const GET_EXPENSES = "GET_EXPENSES";
export const LOAD_MORE_EXPENSES = "LOAD_MORE_EXPENSES";
export const LOAD_PERMISSIONS = "LOAD_PERMISSIONS";

export const GET_SERVICE_CENTERS = "GET_SERVICE_CENTERS";
export const GET_SERVICE_CENTER = "GET_SERVICE_CENTER";
export const REMOVE_ACTIVE_SERVICE_CENTER = "REMOVE_ACTIVE_SERVICE_CENTER";

export const SET_SERVICE_CENTERS_BALANCE = "SET_SERVICE_CENTERS_BALANCE";

export const getExpenses = (data = {}, isLoadMore = false) => {
  try {
    return async (dispatch) => {
      const response = await TaskManagerRequest(`/master/list/expanses`, data);

      dispatch({
        type: isLoadMore ? LOAD_MORE_EXPENSES : GET_EXPENSES,
        value: response,
      });

      return true;
    };
  } catch (error) {
    console.log("Error : ", error);
    return false;
  }
};

export const getServiceCenters = (data = {}) => {
  try {
    return async (dispatch) => {
      const response = await TaskManagerRequest(`/service-centers`, data);

      dispatch({
        type: GET_SERVICE_CENTERS,
        value: response,
      });
    };
  } catch (error) {
    console.log("Error : ", error);
    return false;
  }
};

export const getServiceCenter = (data = {}) => {
  try {
    return async (dispatch) => {
      const response = await TaskManagerRequest(
        `/service-center/details`,
        data
      );

      dispatch({
        type: GET_SERVICE_CENTER,
        value: response,
      });
    };
  } catch (error) {
    console.log("Error : ", error);
    return false;
  }
};

export const removeActiveServiceCenter = () => {
  try {
    return async (dispatch) => {
      dispatch({
        type: REMOVE_ACTIVE_SERVICE_CENTER,
        value: false,
      });
    };
  } catch (error) {
    console.log("Error : ", error);
    return false;
  }
};

export const setServiceCentersBalance = (data) => {
  try {
    return async (dispatch) => {
      dispatch({
        type: SET_SERVICE_CENTERS_BALANCE,
        value: data,
      });
    };
  } catch (error) {
    console.log("Error : ", error);
    return false;
  }
};

export const getPermissions = (data = {}) => {
  try {
    return async (dispatch) => {
      const response = await TaskManagerRequest(
        `/account/permission/get`,
        data
      );

      dispatch({
        type: LOAD_PERMISSIONS,
        value: response,
      });
    };
  } catch (error) {
    console.log("Error : ", error);
    return false;
  }
};
