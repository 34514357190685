import React from "react";
import { BsFillQuestionCircleFill } from "react-icons/bs";
import TooltipBox from "../UI/tooltips/TooltipBox";

export const HelpIcon = (props) => {
  return (
    <TooltipBox tip={props.text}>
      <BsFillQuestionCircleFill {...props} />
    </TooltipBox>
  );
};
