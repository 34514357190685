import moment from "moment";
import _ from 'underscore';

import {
    GET_ASSETS_GROUPS,
    FILTER_ASSETS,
    GET_IGNITION_NOTIFICATIONS,
    GET_ASSET_HISTORY,
    GET_SERVICE_REMINDER,
    GET_DOCUMENT_REMINDER,
    SET_FROM,
    SET_TO,
    SET_ACTIVE_ASSET,
    GET_ASSET_DETAILS,
} from "../actions/asset.action";

const initialState = {
    assetsGroups: [],
    activeFilter: 'all',
    counts: {
        moving: 0,
        parking: 0,
        idling: 0,
        undefined: 0,
        total: 0
    },
    ignitionNotifications: [],
    assetHistory: [],
    serviceReminders: [],
    documentReminders: [],
    from: moment(new Date()).add(-1, 'days'),
    to: new Date(),
    activeAsset : null,
    assetDetails : false
}

const countVehicleState = (vhls) => {

    var count = _.countBy(vhls, function (e) {
        return e.position?.attributes.state
    });

    count.total = vhls.length;
    return count;

}

const AssetReducer = (state = initialState, action) => {

    switch (action.type) {

        case FILTER_ASSETS:
            return { ...state, activeFilter: action.value }

        case GET_ASSETS_GROUPS:

            var asset = null
            var cnt = [];
            
            if(action.value.length > 0) asset = action.value[0]

            // COUNT
            cnt = cnt.concat(Object.values(action.value));
            var counts = countVehicleState(cnt);

            return { ...state, assetsGroups: action.value, activeAsset: asset, counts: {...state.counts, ...counts} }

        case SET_ACTIVE_ASSET:
            return { ...state, activeAsset: action.value }

        case GET_ASSET_DETAILS:
            return { ...state, assetDetails: action.value }

        case GET_IGNITION_NOTIFICATIONS:
            return { ...state, ignitionNotifications: action.value }

        case GET_ASSET_HISTORY:
            return { ...state, assetHistory: action.value }

        case GET_SERVICE_REMINDER:
            return { ...state, serviceReminders: action.value }

        case GET_DOCUMENT_REMINDER:
            return { ...state, documentReminders: action.value }

        case SET_FROM:
            return { ...state, from: action.value }

        case SET_TO:
            return { ...state, to: action.value }

        default:
            return state

    }

}

export default AssetReducer;