import React from 'react'
import Modal from 'react-bootstrap/Modal'

const DeleteTaskModal = (props) => {

    return (
        <>
            <Modal
                show={props.show}
                dialogClassName="modal-30w"
                onHide={props.toggleModal}
                aria-labelledby="attendanceMap"
            >
                <Modal.Header closeButton >
                    Delete Task
                </Modal.Header>
                <Modal.Body className="show-grid">

                    <p>This task has repeating task, Do you want to remove only this task or remove all repeating task?</p>
                   
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" style={{ fontSize: '12px'}} onClick={() => props.onRemove(true)} className="btn btn-small text-white bg-danger">Remove All </button>
                    <button type="button" style={{ fontSize: '12px', backgroundColor: '#3166EE' }} onClick={() => props.onRemove(false)} className="btn btn-small text-white">Remove One</button>
                </Modal.Footer>
            </Modal>
        </>

    )
}

export default DeleteTaskModal