import axios from "axios";
import { API_URLS } from "../configs/api.urls";
import { getStorage, setStorage } from "./localStorageService";

const API_URL = API_URLS.SERVICE_CENTER_URL;

const ServiceCenterRequest = async (
  path,
  data = {},
  isMediaUpload = false,
  button = false
) => {
  const auth = await getStorage("__serviceCenter__", "object");
  const headers = {
    "Service-Authentication-token": auth ? auth.token : "UnAuthorized",
    "client-type": "serviceCenterWeb",
  };

  if (isMediaUpload) {
    headers["content-type"] = "application/x-www-form-urlencoded";
  }

  var buttonText = "";

  if (button) {
    var elem = document.getElementById(button);
    buttonText = elem.textContent || elem.innerText;
    elem.innerHTML = `<i class="fa fa-spinner fa-spin"> </i> Loading`;
    elem.disabled = true;
  }

  return new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}${path}`, data, { headers: headers })
      .then(function (response) {
        try {
          if (response.data.code == 401) {
            window.location.href = "/login";
            throw new Error("Unauthorized");
          }
          if (response.data.code == 503) {
            setStorage("maintenanceData", response.data.data, "object");
            window.location.href = "/maintenance";
          }
          if (response.data.code !== 200) {
            throw new Error(response.data.data);
          }
          resolve(response.data.data);
        } catch (error) {
          reject(error.message);
        } finally {
          if (button) {
            var elem = document.getElementById(button);
            elem.innerHTML = buttonText;
            elem.disabled = false;
          }
        }
      })
      .catch(function (error) {
        console.log("Error : ", error);
        reject(error.message);
        if (button) {
          var elem = document.getElementById(button);
          if (!elem) return;
          elem.innerHTML = buttonText;
          elem.disabled = false;
        }
      });
  });
};

export default ServiceCenterRequest;
