import React, { useState } from "react";
import Header from "../../components/serviceCenter/Header";
import SingleInput from "../../components/UI/SingleInput";
import IconButton from "../../components/UI/IconButton";
import { useHistory } from "react-router-dom";
import { getStorage } from "../../services/localStorageService";
import { ShowNotification } from "../../services/Notifications";
import ServiceCenterRequest from "../../services/ServiceCenterRequest";
import EditServiceCenterProfile from "../../components/serviceCenter/EditServiceCenterProfile";
import { getImageURL } from "../../configs/common";

const ServiceCenterProfile = () => {
  //
  const history = useHistory();
  const serviceCenterData = getStorage("__serviceCenter__", "object");

  const [editProfileModal, setEditProfileModal] = useState(false);
  const toggleEditProfileModal = () => setEditProfileModal(!editProfileModal);

  const handlePasswordChange = async (event) => {
    try {
      const formData = new FormData(event.target);
      event.preventDefault();

      let data = {};

      for (let [key, value] of formData.entries()) {
        data[key] = value;
      }

      // Validate new password v/s confirm password
      if (data["newPassword"] != data["confirmPassword"]) {
        ShowNotification(
          "error",
          "Oops! New password and confirm password not match"
        );
        return;
      }

      // API call here

      const response = await ServiceCenterRequest(
        "/service-center/change/password",
        data
      );

      ShowNotification("success", response);
    } catch (error) {
      ShowNotification("error", error);
    }
  };

  return (
    <>
      <EditServiceCenterProfile
        show={editProfileModal}
        toggleModal={toggleEditProfileModal}
      />

      <div className="as-main-container">
        <div className="as-header pb-2">
          <Header />
        </div>
        <div className={`as-body-conten as-body-content-no-sidebar`}>
          <div className="as-body-data w-100">
            <p style={styles.header} className="bg-light-gray">
              My Profile
              <i
                className="fa fa-times float-right pointer"
                onClick={() => history.goBack()}
              ></i>
            </p>
            <div className="row m-0">
              <div className="col-md-6">
                <div className="row m-0 ">
                  <div className="col-md-4 p-0 text-center">
                    <div className="profile-image-container">
                      <div className="profile-image mt-3">
                        {serviceCenterData &&
                        serviceCenterData.profileImage &&
                        serviceCenterData.profileImage !== null ? (
                          <img
                            src={getImageURL(
                              serviceCenterData.profileImage,
                              "/"
                            )}
                            height="100%"
                            width="100%"
                          />
                        ) : (
                          <img
                            src={
                              require("../../assets/images/avatar-men.png")
                                .default
                            }
                            height="100%"
                            width="100%"
                          />
                        )}
                        {/* <img
                          src={
                            require("../../assets/images/avatar-men.png")
                              .default
                          }
                          height="100%"
                          width="100%"
                        /> */}
                      </div>
                      <img
                        className="camera"
                        src={
                          require("../../assets/img/settings/camera.svg")
                            .default
                        }
                      />
                    </div>
                    <IconButton
                      label="Edit Profile"
                      icon={
                        require("../../assets/img/settings/edit-icon.svg")
                          .default
                      }
                      buttonstyle={{
                        backgroundColor: "#cbddff",
                        padding: "8px",
                      }}
                      onClick={toggleEditProfileModal}
                    />
                  </div>

                  <div className="col-md-8 pt-3" style={styles.borderRight}>
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            <img
                              src={
                                require("../../assets/img/settings/company.svg")
                                  .default
                              }
                            />
                          </td>
                          <td>
                            <span className="pl-2" style={styles.head}>
                              Center Name
                            </span>
                            <p className="pl-2 font-13" style={styles.content}>
                              {serviceCenterData?.centerName || "--"}
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <img
                              src={
                                require("../../assets/img/settings/user.svg")
                                  .default
                              }
                              className="mb-2"
                            />
                          </td>
                          <td>
                            <span className="pl-2" style={styles.head}>
                              Contact Name
                            </span>
                            <p className="pl-2 font-13" style={styles.content}>
                              {serviceCenterData?.contactName || "--"}
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <img
                              src={
                                require("../../assets/img/settings/mail.svg")
                                  .default
                              }
                              className="mb-2"
                            />
                          </td>
                          <td>
                            <span className="pl-2" style={styles.head}>
                              Email
                            </span>
                            <p className="pl-2 font-13" style={styles.content}>
                              {serviceCenterData?.email || "--"}
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <img
                              src={
                                require("../../assets/img/settings/telephone.png")
                                  .default
                              }
                              height={18}
                            />
                          </td>
                          <td>
                            <span className="pl-2" style={styles.head}>
                              Phone Number
                            </span>
                            <p className="pl-2 font-13" style={styles.content}>
                              {serviceCenterData?.phoneNumber || "--"}
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <form onSubmit={handlePasswordChange}>
                  <p className="mt-3 mb-2">Change Password</p>

                  <div className="mb-2">
                    <SingleInput
                      placeholder="Current Password"
                      type="password"
                      style={{ width: "50%" }}
                      name="oldPassword"
                      required={true}
                    />
                  </div>

                  <div className="mb-2">
                    <SingleInput
                      placeholder="New Password"
                      type="password"
                      style={{ width: "50%" }}
                      name="newPassword"
                      required={true}
                    />
                  </div>

                  <div className="mb-3">
                    <SingleInput
                      placeholder="Re-type Password"
                      type="password"
                      style={{ width: "50%" }}
                      name="confirmPassword"
                      required={true}
                    />
                  </div>

                  <button className="btn btn-theme font-12">
                    Change Password
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const styles = {
  borderRight: {
    borderRight: "1px solid rgb(204,204,204)",
    height: "85vh",
  },
  header: {
    padding: "15px",
    borderBottom: "1px solid #e2e2e2",
    fontSize: "14px",
    marginBottom: "0px",
    backgroundColor: "#f3f7fa",
  },
};

export default ServiceCenterProfile;
