import React from 'react'

const ChatCount = (props) => {
    return (
        <div onClick={props.onClick ? props.onClick : null}>
            <img style={styles.chat} src={require('./messenger-gray.png').default} />
            <div style={styles.countContainer}>
                <p style={{...styles.count, ...props.countStyle}}>{props.count}</p>
            </div>
        </div>
    )
}

export default ChatCount

const styles = {
    countContainer: {
        backgroundColor: '#5983ff',
        height: 16,
        width: 16,
        borderRadius: 50,
        textAlign: 'center',
        position: 'relative',
        bottom: 22,
        marginLeft: 8,

    },
    count: {
        fontSize: 8,
        color: '#fff',
        fontWeight: 'bold',
    },
    chat: {
        height: 15,
        width: 15,
        marginTop: 8,
    },
  
}
