import socketIO from 'socket.io-client';
import { MAIN_DOMAIN_URL } from '../configs/api.urls';
import { getStorage } from '../services/localStorageService';
import { socketService } from '../services/socket.service';
import { makeId } from '../services/TaskManagerRequest';

// const SOCKET_URL = 'http://localhost:9090'
const SOCKET_URL = MAIN_DOMAIN_URL

// Initialize Socket IO:
export const socket = socketIO(SOCKET_URL);




// Emit
export const socketEmit = (name, data) => {

  var token = makeId(100) + '__';
  token = token + data.from;
  data.token = token;
  socket.emit(name, data)
}

// Dispatch
export const SOCKET_ON_USER_LOCATION_UPDATE = 'SOCKET_ON_USER_LOCATION_UPDATE';

// export the function to connect and use socket IO:
export const startSocketIO = (store) => {

  socket.connect();

  socket.on('connect', () => {

    console.log('User COnnected');

    const user = getStorage('__user__', 'object');

    if (user && user.id) {
      socketEmit('IAMAVAILABELASADMIN', {
        key: user.id
      })
    }

  });

  socket.on('disconnect', () => {
    console.log('connection to server lost.');
  });

  socket.on('connect_error', (err) => {
    console.log('SOCKET ERROR...',err)
  });


  socket.on('CHAT_MESSAGE_TO_ADMIN', (data) => {
    socketService.inCommingMessage(data);
  });

  socket.on('TASK_STATUS_UPDATED', (data) => {
    socketService.changeTaskStatus(data);
  });

  socket.on('DRIVER_STATUS_UPDATED', (data) => {
    socketService.changeDriverStatus(data);
  });

};