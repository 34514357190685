export const categoryBasedVehicleSpeed = (category = "Car") => {
  switch (category) {
    case "Car":
    case "SUV":
      return 0.9;

    case "Van":
    case "Small Pickup":
    case "Small Bus":
      return 0.8;

    default:
      return 0.75;
  }
};

export const settingsBasedVehicleSpeed = (
  vehicleID,
  settings,
  moreDriverDetails = {}
) => {
  try {
    console.log(vehicleID, settings[+vehicleID]);
    // throw new Error('')
    if (
      !vehicleID ||
      vehicleID == "" ||
      !settings ||
      !settings[+vehicleID] ||
      !settings[+vehicleID].optimizedAverageSpeed
    )
      throw new Error("");
    return Number(settings[+vehicleID].optimizedAverageSpeed);
  } catch (e) {
    var defaultVehicleSpeed = 0.75;

    var __vehicles__ = window.localStorage.getItem("__vehicles__") || "{}";
    __vehicles__ = JSON.parse(__vehicles__);
    if (__vehicles__[vehicleID]) {
      defaultVehicleSpeed = categoryBasedVehicleSpeed(
        __vehicles__[vehicleID]?.category
      );
    }

    console.log("defaultVehicleSpeed", defaultVehicleSpeed);

    return defaultVehicleSpeed;
  }
};
