import React, { useState, useEffect } from "react";
import CustomerCard from "./CusromerCard";
import { useSelector } from "react-redux";
import SearchInput from "../../SearchInput";

const Locations = (props) => {
  const _customersData = useSelector((state) => state.taskReducer.customerList);
  const [customersData, setCustomersData] = useState(false);
  // const [search, setSearch] = useState('')
  const [isSearchOn, setIsSearchOn] = useState("");

  // const onSearchTextChange = (event) => {
  //     setSearch(event.target.value)
  // }

  useEffect(() => {
    setCustomersData(_customersData);
  }, [_customersData]);

  const handleSearch = (event) => {
    setIsSearchOn(event.target.value);
    if (props.data && props.setData)
      props.setData({ ...props.data, search: event.target.value });
  };

  const clear = () => {
    setIsSearchOn("");
    if (props.data && props.setData)
      props.setData({ ...props.data, search: "" });
  };

  // const onSearch = () => {
  //     if (props.data && props.setData) {
  //         props.setData({ ...props.data, search: search })
  //     }
  // }

  // const onRefresh = () => {
  //     setSearch('')
  //     if (props.data && props.setData) {
  //         props.setData({ ...props.data, search: '' })
  //     }
  // }

  // const onFirstLast = (type) => {
  //     setSearch('')
  //     var last = customersData && customersData.last_page
  //     if (props.data && props.setData) {
  //         props.setData({ ...props.data, page: type == 'first' ? 1 : last })
  //     }
  // }

  // const onNextPrev = (type) => {
  //     setSearch('')
  //     var pageNo;

  //     if (type == 'next') {
  //         pageNo = customersData && customersData.current_page < customersData.last_page ? parseInt(customersData.current_page) + 1 : customersData.current_page
  //     } else {
  //         pageNo = customersData && customersData.current_page > 1 ? parseInt(customersData.current_page) - 1 : customersData.current_page
  //     }

  //     if (props.data && props.setData) {
  //         props.setData({ ...props.data, page: pageNo })
  //     }
  // }

  return (
    <div style={styles.container} className="bg-light-gray">
      <div
        className="row m-0 mb-5 p-3 pr-0 visible-scroll-hover"
        // style={styles.container2}
      >
        {customersData ? (
          customersData.length <= 0 ? (
            <h6 className="text-center my-auto text-secondary font-weight-500">
              No any location found!
            </h6>
          ) : (
            customersData.map((customer, i) => (
              <div key={i} className="col-md-4 pl-0 pr-2">
                <CustomerCard customer={customer} />
              </div>
            ))
          )
        ) : (
          <></>
        )}
      </div>

      <div style={styles.footer}>
        <SearchInput
          onSearch={handleSearch}
          placeholder="Search..."
          value={isSearchOn}
          text={isSearchOn}
          iconStyle={{ right: "5%" }}
          onClear={clear}
        />

        {/* <SingleInput placeholder="Serch Customers..." onChange={onSearchTextChange} value={search} />

                <button className="btn btn-search mb-0 pl-2 pr-2 ml-2" onClick={onSearch}><i className="fa fa-search font-12"></i></button>

                <button className="btn btn-theme mb-0 pl-2 pr-2 ml-2" onClick={onRefresh}><i className="fa fa-refresh font-12"></i></button> */}

        {/* <div className="ml-5 d-flex">
                    <button className="btn-footer" onClick={() => onFirstLast('first')}>First</button>
                    <button className="btn-footer" onClick={() => onNextPrev('prev')}>Previous</button>
                    <span style={styles.page}>1</span>
                    <button className="btn-footer" onClick={() => onNextPrev('next')}>Next</button>
                    <button className="btn-footer" onClick={() => onFirstLast('last')} >Last</button>
                </div> */}
      </div>
    </div>
  );
};

const styles = {
  container: {
    height: "81vh",
    // overflow: "hidden",
    overflow: "auto",
  },
  // container2: {
  //   height: "94%",
  //   overflow: "auto",
  // },
  footer: {
    position: "absolute",
    bottom: 7,
    display: "flex",
    margin: "10px",
  },
  page: {
    fontSize: "12px",
    color: "#fff",
    backgroundColor: "#1360EE",
    padding: "5px 12px",
    borderRadius: "5px",
    margin: "0 10px 0 10pxs",
  },
};

export default Locations;
