// import Geolocation from '@react-native-community/geolocation';
import Geocode, { geocode, RequestType } from "react-geocode";
import { GOOGLE_MAPS_API_KEY } from "./map.key";

// Geocode.setLocationType("ROOFTOP");
// Geocode.enableDebug();

const _getCurrentLocation = (location = false, isObject = false) => {
  return new Promise((resolve, reject) => {
    try {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          var lat = position.coords.latitude;
          var lng = position.coords.longitude;

          if (location) {
            lat = location.latitude;
            lng = location.longitude;
          }

          // fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${lat},${lng}&key=${`AIzaSyDiu91FonB6BiQDxaIUAgbr5usMu9D0Fyk`}`)
          fetch(
            `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${lat}&lon=${lng}&accept-language=en`
          )
            .then((response) => response.json())
            .then((responseJson) => {
              // if (responseJson.results && responseJson.results.length) {

              //     resolve({
              //         address: responseJson.results[0].formatted_address,
              //         location: [lat, lng]
              longitude: lng; //     })
              // }
              if (responseJson) {
                resolve({
                  address: responseJson?.display_name,
                  location: isObject
                    ? { latitude: lat, longitude: lng }
                    : [lat, lng],
                });
              }
            });
        },
        (error) => {
          console.log("error", error);
          reject(error);
        }
      );
    } catch (error) {
      console.log("error in catch", error);
      reject(error);
    }
  });
};

export const addressToPosition = (address) => {
  try {
    Geocode.setApiKey(GOOGLE_MAPS_API_KEY.MAIN_KEY);
    Geocode.setLanguage("en");
    Geocode.setRegion("es");

    Geocode.fromAddress(address).then(
      (response) => {
        if (response.results && response.results.length > 0) {
          const { lat, lng } = response.results[0].geometry.location;
          return { lat, lng };
        }
      },
      (error) => {
        console.log("Error in addressToPosition", error);
        return false;
      }
    );
  } catch (error) {
    console.log("Error in addressToPosition", error);
    return false;
  }
};

export const positionToAddress = (position) => {
  try {
    Geocode.setApiKey(GOOGLE_MAPS_API_KEY.MAIN_KEY);
    Geocode.setLanguage("en");
    Geocode.setRegion("es");

    return Geocode.fromLatLng(position.lat, position.lng).then(
      (response) => {
        if (response.results && response.results.length > 0) {
          return response.results[0].formatted_address;
        }
      },
      (error) => {
        console.log("Error in positionToAddress", error);
        return "";
      }
    );
  } catch (error) {
    console.log("Error in positionToAddress", error);
    return "";
  }
};

// Get latitude & longitude from address.

export default _getCurrentLocation;
