import TaskManagerRequest from "../../services/TaskManagerRequest";

export const GET_INSPECTION_RECORDS = "GET_INSPECTION_RECORDS";
export const SET_ACTIVE_VEHICLE = "SET_ACTIVE_VEHICLE";
export const TOGGLE_LOADING = "TOGGLE_LOADING";
export const SET_VEHICLES = "SET_VEHICLES";
export const GET_LATEST_REPORT = "GET_LATEST_REPORT";
export const SET_RECORD_INDEX = "SET_RECORD_INDEX";
export const TOGGLE_RECORDS_LOADING = "TOGGLE_RECORDS_LOADING";
export const FILTER_VEHICLES = "FILTER_VEHICLES";
export const ADD_INSPECTION_RECORD = "ADD_INSPECTION_RECORD";
export const UPDATE_INSPECTION_DETAILS = "UPDATE_INSPECTION_DETAILS";
export const TOGGLE_SHOW_SORTED_VEHICLES = "TOGGLE_SHOW_SORTED_VEHICLES";
export const SORT_VEHICLES_BY_DAYS_REMAINING =
  "SORT_VEHICLES_BY_DAYS_REMAINING";

export const getVehicleInspectionRecords = (vehicleId) => {
  return async (dispatch) => {
    dispatch({
      type: TOGGLE_RECORDS_LOADING,
      value: true,
    });
    const response = await TaskManagerRequest(
      "/inspection/records/" + vehicleId,
      {
        vehicleId: vehicleId,
      }
    );
    dispatch({
      type: GET_INSPECTION_RECORDS,
      value: response,
      vehicle: vehicleId,
    });
    dispatch({
      type: TOGGLE_RECORDS_LOADING,
      value: false,
    });
  };
};

export const setRecordIndex = (index) => {
  return {
    type: SET_RECORD_INDEX,
    value: index,
  };
};

export const toggleShowSortedVehicles = (value) => {
  return {
    type: TOGGLE_SHOW_SORTED_VEHICLES,
    value: value,
  };
};

export const sortVehiclesByDaysRemaining = () => {
  return {
    type: SORT_VEHICLES_BY_DAYS_REMAINING,
  };
};

export const updateInspectionDetails = (vehicleId, inspectionDetails) => {
  return {
    type: UPDATE_INSPECTION_DETAILS,
    vehicleId,
    inspectionDetails,
  };
};

export const filterVehicles = (type) => {
  return async (dispatch) => {
    dispatch({
      type: FILTER_VEHICLES,
      value: type,
    });
  };
};

export const setActiveVehicle = (vehicleId) => {
  return {
    type: SET_ACTIVE_VEHICLE,
    value: vehicleId,
  };
};

export const toggleLoading = (value) => {
  return {
    type: TOGGLE_LOADING,
    value: value,
  };
};

export const toggleRecordsLoading = (value) => {
  return {
    type: TOGGLE_RECORDS_LOADING,
    value: value,
  };
};

export const addInspectionRecord = (vehicleId, report) => {
  return {
    type: ADD_INSPECTION_RECORD,
    vehicleId,
    report,
  };
};

export const getInspectionVehicles = (groups) => {
  return async (dispatch) => {
    dispatch({
      type: TOGGLE_LOADING,
      value: true,
    });
    try {
      const response = await TaskManagerRequest("/inspection/vehicles", {
        groups,
      });
      dispatch({
        type: SET_VEHICLES,
        value: response,
      });
    } catch (err) {
      console.log(err);
    } finally {
      dispatch({
        type: TOGGLE_LOADING,
        value: false,
      });
    }
  };
};
