import { Subject } from 'rxjs';

const messageListner = new Subject();
const statusListner = new Subject();
const driverStatusListner = new Subject();

export const socketService = {
    inCommingMessage: message => messageListner.next(message),
    getMessage: () => messageListner.asObservable(),
    changeTaskStatus: data => statusListner.next(data),
    getStatus: () => statusListner.asObservable(),
    changeDriverStatus: data => driverStatusListner.next(data),
    getDriverStatus: () => driverStatusListner.asObservable(),
};
