import React, { useState, useEffect, useRef } from "react";
import Input from "../../../UI/Input";
import Select from "../../../UI/Select";
import CheckBox from "../../../UI/CheckBox";

import "./make-attribute.css";

import SignatureCanvas from "react-signature-canvas";
import TaskManagerRequest from "../../../../services/TaskManagerRequest";
import { TASK_ASSETS_URL } from "../../../../environments/environments";
import Loader from "../../../UI/Loader";

const MakeAttribute = (props) => {
  const makeArray = (value) => {
    const cloneValue = value.toString().replaceAll("[", "");
    const parts = cloneValue.toString().split(",");
    return parts;
  };

  const [data, setData] = useState(false);
  const [attributes, setAttributes] = useState({});
  const [signImage, setSignImage] = useState("");
  const [mediaImage, setMediaImage] = useState("");
  const [isChecked, setChecked] = useState("");
  const [uploadingImage, setUploadingImage] = useState(false);

  const signaturePad = useRef(null);

  const renderField = () => {
    switch (props.attribute.type.toLowerCase()) {
      case "selectbox":
        if (props.attribute.value) {
          const data = makeArray(props.attribute.value);
          return (
            <Select
              title={props.attribute.key}
              name={`attr_${props.attribute.key}`}
              value={
                attributes && attributes[props.attribute.key]
                  ? attributes[props.attribute.key]
                  : ""
              }
            >
              <option value=""></option>
              {data.map((value, i) => (
                <option value={value.trim()} key={i}>
                  {value.trim()}
                </option>
              ))}
            </Select>
          );
        }
        break;
      case "radiobutton":
        return (
          <fieldset>
            <legend>{props.attribute.key}</legend>
            <div className="d-flex font-12">
              {makeArray(props.attribute.value).map((value, i) => (
                <React.Fragment key={i}>
                  <input
                    onChange={() => setChecked(value)}
                    type="radio"
                    className="radio w-10 ml-2"
                    name={`attr_${props.attribute.key}`}
                    value={value}
                    checked={value == isChecked}
                  />
                  <span className="mt-1 ml-2">{value}</span>
                </React.Fragment>
              ))}
            </div>
          </fieldset>
        );

      case "signature":
        return (
          <React.Fragment>
            <fieldset>
              <legend>{props.attribute.key}</legend>
              <div className="d-flex">
                <input
                  type="hidden"
                  name={`attr_${props.attribute.key}`}
                  value={signImage}
                />
                <SignatureCanvas
                  ref={signaturePad}
                  onEnd={handleOnSignatureEnd}
                  penColor="green"
                  canvasProps={{
                    width: 500,
                    height: 120,
                    className: "sigCanvas",
                  }}
                />
              </div>
            </fieldset>

            {signImage !== "" && (
              <div>
                <button
                  onClick={handleClearSign}
                  className="btn btn-danger brn-sm clear-sign-btn"
                  type="button"
                >
                  {" "}
                  Clear{" "}
                </button>
              </div>
            )}
          </React.Fragment>
        );

      case "image":
        return (
          <React.Fragment>
            <fieldset>
              <legend>{props.attribute.key}</legend>
              <div className="d-flex">
                <input
                  type="hidden"
                  name={`attr_${props.attribute.key}`}
                  value={mediaImage}
                />
                <input
                  type="file"
                  accept="image/png, image/gif, image/jpeg, image/jpg"
                  onChange={handleFileChange}
                />
              </div>
              {uploadingImage && (
                <div
                  className={`loadWrapper ${props.className}`}
                  style={{ left: "45%" }}
                ></div>
              )}
              {mediaImage == "" && (
                <img
                  src={
                    require("../../../../assets/images/no-image.png").default
                  }
                  height="30"
                  style={styles.image}
                />
              )}
              {mediaImage != "" && (
                <img
                  src={`${TASK_ASSETS_URL}/${mediaImage}`}
                  height="100"
                  style={styles.image}
                />
              )}
            </fieldset>
          </React.Fragment>
        );

      case "checkbox":
        if (props.attribute.value) {
          const data = makeArray(props.attribute.value);
          return (
            <fieldset>
              <legend>{props.attribute.key}</legend>
              <div className="d-flex">
                {data.map((value, i) => {
                  return (
                    <div className="d-flex ml-3" key={i}>
                      <CheckBox
                        id={`attr_${props.attribute.key}_${value.trim()}`}
                        name={`attr_${props.attribute.key}_${value.trim()}`}
                        checked={
                          attributes &&
                          attributes[
                            `${props.attribute.key}_${value.trim()}`
                          ] == "on"
                            ? true
                            : false
                        }
                      />
                      <span className="mt-2 ml-1 font-14">{value.trim()}</span>
                    </div>
                  );
                })}
              </div>
            </fieldset>
          );
        }

      case "textarea":
        return (
          <fieldset>
            <legend>{props.attribute.key}</legend>
            <textarea
              defaultValue={
                attributes && attributes[props.attribute.key]
                  ? attributes[props.attribute.key]
                  : ""
              }
              name={`attr_${props.attribute.key}`}
              className="textarea"
              rows="2"
            ></textarea>
          </fieldset>
        );
      case "email":
        return (
          <Input
            name={`attr_${props.attribute.key}`}
            label={props.attribute.key}
            type="email"
            value={
              attributes && attributes[props.attribute.key]
                ? attributes[props.attribute.key]
                : ""
            }
          />
        );
      default:
        return (
          <Input
            name={`attr_${props.attribute.key}`}
            label={props.attribute.label}
            value={
              attributes && attributes[props.attribute.key]
                ? attributes[props.attribute.key]
                : ""
            }
          />
        );
    }
  };

  useEffect(() => {
    if (!props.attributes) return;

    setAttributes(props.attributes);
    if (
      props.attribute.type == "image" &&
      props.attributes[props.attribute.key]
    ) {
      setMediaImage(props.attributes[props.attribute.key]);
    }
    if (
      props.attribute.type == "signature" &&
      props.attributes[props.attribute.key]
    ) {
      setSignImage(props.attributes[props.attribute.key]);
    }
    if (
      props.attribute.type == "radiobutton" &&
      props.attributes[props.attribute.key]
    ) {
      setChecked(props.attributes[props.attribute.key]);
    }
  }, [props.attributes, props.attribute]);

  const handleOnSignatureEnd = () => {
    if (signaturePad.current.isEmpty()) return false;
    setSignImage(signaturePad.current.toDataURL());
  };

  const handleClearSign = () => {
    setSignImage("");
    signaturePad.current.clear();
  };

  const handleFileChange = async (event) => {
    if (event.target.files.length == 0) return false;
    const file = event.target.files[0];

    try {
      setUploadingImage(true);
      var d = new FormData();
      d.append("image", file);
      const res = await TaskManagerRequest("/task/upload-image", d);
      setMediaImage(res);
    } catch (error) {
      console.log("Error: ", error);
    } finally {
      setUploadingImage(false);
    }

    // var reader = new FileReader();
    // reader.readAsDataURL(file);
    // reader.onload = function () {
    //     setMediaImage(reader.result);
    // };
    // reader.onerror = function (error) {
    //     console.log('Error: ', error);
    // };
  };
  return <React.Fragment>{renderField()}</React.Fragment>;
};

const styles = {
  image: {
    marginLeft: 20,
    marginTop: 15,
    marginBottom: 10,
    borderRadius: 3,
    resizeMode: "contain",
  },
};

export default MakeAttribute;
