import React, { useState } from "react";
import ImageModal from "../../UI/ImageModal";
import { TASK_ASSETS_URL } from "../../../environments/environments";

const TaskAttributeFields = (props) => {
  props.field.type = props.field.type.toLowerCase();
  const [imageModal, setImageModal] = useState(false);
  const toggleImageModal = () => setImageModal(!imageModal);

  switch (props.field.type) {
    case "signature":
      if (
        !props.attributes[props.field.key] ||
        props.attributes[props.field.key] == ""
      )
        return <p className="mb-0 font-12 text-black">-</p>;
      return (
        <img
          className="sign-image-view"
          src={props.attributes[props.field.key]}
          height={100}
          width={"100%"}
        />
      );

    case "image":
      if (
        !props.attributes[props.field.key] ||
        props.attributes[props.field.key] == ""
      )
        return <p className="mb-0 font-12 text-black">-</p>;
      // return <a className='pointer' href={props.attributes[props.field.key]} ><img className="sign-image-view" src={props.attributes[props.field.key]} height={100} width={'100%'} /></a>
      var img = `${TASK_ASSETS_URL}/${props.attributes[props.field.key]}`;
      return (
        <React.Fragment>
          <ImageModal
            show={imageModal}
            toggleModal={toggleImageModal}
            title="Attachments"
            src={img}
          />
          <img
            className="sign-image-view pointer"
            onClick={toggleImageModal}
            src={img}
            height={100}
            width={"100%"}
          />
        </React.Fragment>
      );

    default:
      return (
        <p className="mb-0 font-12 text-black">
          {props.attributes[props.field.key]}
        </p>
      );
  }
};

export default TaskAttributeFields;
