import React, { useState } from "react";
import { useEffect } from "react";
import { ShowNotification } from "../../../services/Notifications";
import TaskManagerRequest from "../../../services/TaskManagerRequest";
import Card from "../../UI/Card";
import Input from "../../UI/Input";
import Loader from "../../UI/Loader";
import "./OtherSettings.css";
import IconButton from "../../UI/IconButton";
import { defaultFuelRates } from "../../../configs/common";

const FuelRates = () => {
  const [data, setData] = useState(defaultFuelRates);
  const [isLoading, setLoading] = useState(false);

  const onChangeData = (key, value) => {
    var clone = { ...data };
    clone[key] = parseFloat(value);
    setData(clone);
  };

  const onEditData = async () => {
    try {
      setLoading(true);
      const res = await TaskManagerRequest("/settings/organization/update", {
        fuelRates: data,
      });
      setData(res.fuelRates);
      ShowNotification("success", "Fuel Rates Updated Successfully");
    } catch (error) {
      console.log("Error : ", error);
    } finally {
      setLoading(false);
    }
  };

  const loadData = async () => {
    try {
      setLoading(true);
      const res = await TaskManagerRequest("/settings/organization/get");
      setData(res.fuelRates);
    } catch (error) {
      console.log("Error : ", error);
    } finally {
      setLoading(false);
    }
  };

  const loadDataFromCentralSystem = async () => {
    try {
      setLoading(true);
      const res = await TaskManagerRequest("/get/central-fuel-rate-setting");
      setData({
        Petrol: res.petrol,
        Diesel: res.diesel,
        Gas: res.gas,
        Other: res.other,
      });
    } catch (error) {
      console.log("Error : ", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      <div className="d-flex p-2">
        <Card style={{ boxShadow: "none" }}>
          <div className="d-flex justify-content-between">
            <p className="font-weight-bold text-dark mb-0">
              Fuel Rate Settings
            </p>
            <IconButton
              label="sync from central system"
              buttonstyle={{
                backgroundColor: "#3160ee",
                color: "#fff",
                paddingInline: 10,
                paddingBlock: 5,
              }}
              onClick={loadDataFromCentralSystem}
            />
          </div>

          {isLoading && <Loader style={{ left: "45%" }} />}

          <table className="w-100 other-setting-table text-center mt-3">
            <tbody>
              <tr>
                <th className="p-1">#</th>
                <th className="p-1">Type</th>
                <th className="p-1">Volume</th>
                <th className="p-1 w-25 text-center">Rate</th>
                <th className="p-1 text-center">Action</th>
              </tr>
              {Object.keys(data).map((key, i) => (
                <tr>
                  <td className="p-2">{i + 1}</td>
                  <td className="p-2">{key}</td>
                  <td className="p-2">{key == "Gas" ? "Kg" : "Ltr"}</td>
                  <td className="p-2 text-center">
                    <Input
                      name={key}
                      value={data[key]}
                      onChange={(val) => onChangeData(key, val)}
                    />
                  </td>
                  <td className="p-2 text-center">
                    <span
                      className="text-primary pointer"
                      onClick={() => onEditData()}
                    >
                      Save
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Card>
      </div>
    </>
  );
};

export default FuelRates;
