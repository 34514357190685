export const lottieDefaultSettings = {
    loop: true,
    autoplay: true,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
};


export const REPORT_PAGE_LOTTIE_JSON = require('../assets/animation/Reports-Module.json')
export const REPORT_PAGE_LOTTIE_JSON_FIRST_BLUE = require('../assets/animation/reports/Reports-first-animation-blue.json')
export const REPORT_PAGE_LOTTIE_JSON_SECOND_BLUE = require('../assets/animation/reports/Reports-second-animation-blue.json')

export const TASK_MANAGER_ACTIVE_TASK_JSON = require('../assets/animation/TaskManager-ActiveTask.json')
export const TASK_MANAGER_ACTIVE_TASK_JSON_FIRST_BLUE = require('../assets/animation/task-manager/main-section/Task Manager blue first.json')
export const TASK_MANAGER_ACTIVE_TASK_JSON_SECOND_BLUE = require('../assets/animation/task-manager/main-section/Task Manager blue second.json')

export const TASK_MANAGER_EMPTY_TASK_JSON = require('../assets/animation/TaskManager-Empty.json')
export const TASK_MANAGER_EMPTY_TASK_JSON_FIRST_BLUE = require('../assets/animation/task-manager/second-section/task manager 2 first blue.json')
export const TASK_MANAGER_EMPTY_TASK_JSON_SECOND_BLUE = require('../assets/animation/task-manager/second-section/task manager 2 second blue.json')

export const ZONE_POI_JSON = require('../assets/animation/Zone-Module.json')
export const ZONE_POI_JSON_FIRST_BLUE = require('../assets/animation/zone/Zone first blue.json')
export const ZONE_POI_JSON_SECOND_BLUE = require('../assets/animation/zone/Zone second blue.json')

export const POI_JSON = require('../assets/animation/POI-Module.json')
export const POI_JSON_FIRST_BLUE = require('../assets/animation/poi/POI first blue.json')
export const POI_JSON_SECOND_BLUE = require('../assets/animation/poi/POI second blue.json')

export const TRIP_FIRST_JSON = require('../assets/animation/trips/trips-first-animation-blue.json')
export const TRIP_SECOND_JSON = require('../assets/animation/trips/trips-second-animation-blue.json')

export const EXPENSE_JSON_FIRST_BLUE = require('../assets/animation/expense-manager/ExpenseFirst.json')
export const EXPENSE_JSON_SECOND_BLUE = require('../assets/animation/expense-manager/ExpenseSecond.json')

export const ATTENDANCE_JSON_FIRST_BLUE = require('../assets/animation/attendance/Attendance1.json')
export const ATTENDANCE_JSON_SECOND_BLUE = require('../assets/animation/attendance/Attendance2.json')


export const MAIN_LOADER = require('../assets/animation/loader/loader.json')
