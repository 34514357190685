import React from "react";
import { ButtonGroup } from "react-bootstrap";
import ReactTooltip from "react-tooltip";
import { getStatusCount } from "../../configs/common";

const TaskStatusCount = (props) => {
  //  if(props.isTitleTooltip) console.log("props.driverTaskStatusCount...", props.driverTaskStatusCount, props.driver);
  return (
    <ButtonGroup aria-label="IntervalTabs">
      <ReactTooltip />

      <button data-tip={`Total Tasks`} style={{ ...styles.button }}>
        {getStatusCount(
          props.driver._id,
          "totalTask",
          props.driverTaskStatusCount
        )}
      </button>

      {props.statusList.map((status, i) => {
        if (status.key == "notAssigned" || status.key == "assignedToGroup")
          return null;

        return (
          <button
            data-tip={status.label}
            key={i}
            className="driverStatusCount"
            style={{
              ...styles.button,
              ...{ color: status.color ? status.color : "#ccc" },
            }}
            title={props.isTitleTooltip ? status.label : ""}
          >
            {getStatusCount(
              props.driver._id,
              status.label,
              props.driverTaskStatusCount
            )}
          </button>
        );
      })}
    </ButtonGroup>
  );
};

const styles = {
  button: {
    border: "1px solid #e4e4e4",
    outline: "none",
    fontSize: "13px",
    fontWeight: "bold",
    marginBottom: "0px",
    boxShadow: "none",
    backgroundColor: "#ffffff",
    padding: "5px 7px",
    marginRight: 3,
    borderRadius: 5,
  },
};

export default TaskStatusCount;
