import React, { useState, useEffect, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import { Container } from "react-bootstrap";
import SingleInput from "../UI/SingleInput";
import { ShowNotification } from "../../services/Notifications";
import DragableMap from "../UI/DragableMap";
import { getStorage, setStorage } from "../../services/localStorageService";
import ServiceCenterRequest from "../../services/ServiceCenterRequest";
import { fileValidation } from "../../guards/guards";
import { getImageURL } from "../../configs/common";
const DRIVER_DEFAULT_PROFILE =
  require("../../assets/images/avatar-men.png").default;

const EditServiceCenterProfile = (props) => {
  //
  const imagePicker = useRef(null);
  const serviceCenterData = getStorage("__serviceCenter__", "object");

  const [location, setLocation] = useState({
    lat: 25.204849,
    lng: 55.270782,
    address: "",
  });
  const [editFormData, setEditFormData] = useState({
    centerName: "",
    contactName: "",
    email: "",
    phoneNumber: "",
    note: "",
  });

  const [profileURL, setProfileURL] = useState(DRIVER_DEFAULT_PROFILE);
  const [file, setFile] = useState(false);

  const onChangeForm = (e, name) => {
    var clone = { ...editFormData };
    clone[name] = e.target.value;
    setEditFormData(clone);
  };

  const onEditCenter = async (event) => {
    const formData = new FormData();
    event.preventDefault();

    Object.keys(editFormData).map((key) => {
      formData.append(key, editFormData[key]);
    });

    formData.append("location", JSON.stringify(location));
    if (file) formData.append("profileImage", file);

    try {
      const response = await ServiceCenterRequest(
        "/service-center/edit/profile",
        formData,
        false,
        "edit-center"
      );
      setStorage(
        "__serviceCenter__",
        { ...response, token: serviceCenterData.token },
        "object"
      );
      event.target.reset();
      setEditFormData({
        centerName: "",
        contactName: "",
        email: "",
        phoneNumber: "",
        note: "",
      });
      setProfileURL(DRIVER_DEFAULT_PROFILE);
      ShowNotification("success", "Service center Edited successfully");
      props.toggleModal();
    } catch (error) {
      ShowNotification("error", error);
    } finally {
    }
  };

  useEffect(() => {
    if (!file) {
      setProfileURL(DRIVER_DEFAULT_PROFILE);
      return false;
    }

    if (fileValidation(file.name) !== true) {
      ShowNotification("error", "Please select image only!");
      return false;
    }

    setProfileURL(window.URL.createObjectURL(file));
  }, [file]);

  useEffect(() => {
    if (props.show && serviceCenterData) {
      setEditFormData({
        centerName: serviceCenterData.centerName,
        contactName: serviceCenterData.contactName,
        email: serviceCenterData.email,
        phoneNumber: serviceCenterData.phoneNumber,
        note: serviceCenterData.note,
      });
      setLocation(serviceCenterData.location);
      if (
        serviceCenterData.profileImage &&
        serviceCenterData.profileImage !== ""
      ) {
        setProfileURL(getImageURL(serviceCenterData.profileImage, "/"));
      } else {
        setProfileURL(DRIVER_DEFAULT_PROFILE);
      }
    }
  }, [props.show]);

  return (
    <>
      <Modal
        show={props.show}
        dialogClassName="modal-40w"
        onHide={props.toggleModal}
        aria-labelledby="attendanceMap"
      >
        <Modal.Header closeButton>
          Profile - {serviceCenterData.centerName}
        </Modal.Header>

        <form onSubmit={onEditCenter}>
          <Modal.Body className="show-grid">
            <Container>
              <div className="row text-start">
                <div className="col-md-12 text-center">
                  <img
                    onClick={() => {
                      imagePicker.current.click();
                    }}
                    className="ad-driver-image"
                    style={{ border: "1px solid #d9e5fb" }}
                    src={profileURL}
                    alt=""
                  />
                  <input
                    accept="image/png, image/gif, image/jpeg, image/jpg"
                    onChange={(e) => setFile(e.target.files[0])}
                    ref={imagePicker}
                    type="file"
                    className="hidden"
                  />
                </div>
                <div className="col-md-6">
                  <p className="label font-12 mb-1 mt-3">Service Center Name</p>
                  <SingleInput
                    onChange={(e) => onChangeForm(e, "centerName")}
                    value={editFormData.centerName}
                    name="centerName"
                    placeholder="Service Center Name"
                  />
                </div>

                <div className="col-md-6">
                  <p className="label font-12 mb-1 mt-3">Contact Name</p>
                  <SingleInput
                    onChange={(e) => onChangeForm(e, "contactName")}
                    value={editFormData.contactName}
                    name="contactName"
                    placeholder="Contact Name"
                  />
                </div>

                <div className="col-md-6">
                  <p className="label font-12 mb-1 mt-3">Phone</p>
                  <SingleInput
                    onChange={(e) => onChangeForm(e, "phoneNumber")}
                    value={editFormData.phoneNumber}
                    name="phoneNumber"
                    type="number"
                    placeholder="Phone"
                  />
                </div>

                <div className="col-md-6">
                  <p className="label font-12 mb-1 mt-3">Email ( username )</p>
                  <SingleInput
                    onChange={(e) => onChangeForm(e, "email")}
                    value={editFormData.email}
                    type="email"
                    name="email"
                    placeholder="Email"
                  />
                </div>

                <div className="col-md-12">
                  <p className="label font-12 mb-1 mt-3">Location</p>
                  <div style={{ height: 230 }}>
                    <DragableMap
                      position={{ lat: location.lat, lng: location.lng }}
                      onDragChange={(l) => {
                        setLocation({
                          lat: l.position.lat,
                          lng: l.position.lng,
                          address: l.address,
                        });
                      }}
                    />
                  </div>
                </div>

                <div className="col-md-12">
                  <p className="label font-12 mb-1 mt-3">Note</p>
                  <SingleInput
                    onChange={(e) => onChangeForm(e, "note")}
                    value={editFormData.note}
                    name="note"
                    placeholder="Note"
                  />
                </div>
              </div>
            </Container>
          </Modal.Body>

          <Modal.Footer>
            <button
              type="button"
              className="btn btn-small font-12"
              onClick={props.toggleModal}
            >
              Close
            </button>
            <button
              id="edit-center"
              style={{ backgroundColor: "#007bff" }}
              className="btn btn-small font-12 text-white"
            >
              Edit
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default EditServiceCenterProfile;
