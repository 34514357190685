import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { ShowNotification } from "../../../services/Notifications";
import TaskManagerRequest from "../../../services/TaskManagerRequest";
import { getCustomerList } from "../../../store/actions/taskManager.action";
import EditCustomerModal from "../../modals/task-manager/EditCustomerModal";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { FiTrash, FiPhone } from "react-icons/fi";
import { IoMailOutline } from "react-icons/io5";

import "./location.css";

const CustomerCard = ({ customer }) => {
  const dispatch = useDispatch();
  const [isModal, setIsModal] = useState(false);
  const toggleModal = () => setIsModal(!isModal);

  const deleteHandler = async () => {
    if (!window.confirm("Are you sure you want to delete this customer?"))
      return;

    try {
      const response = await TaskManagerRequest(`/delete/customer`, {
        customer: customer._id,
      });
      dispatch(getCustomerList({}));
      ShowNotification("success", "Customer deleted successfully");
    } catch (error) {
      ShowNotification("error", error);
    }

    // dispatch(deleteTaskCustomer(customer.customer_id))
    // var data = {
    //     sortBy: 'date',
    //     groupId: 0,
    //     page: 1
    // }
    // dispatch(getTaskCustomerGroups())
    // dispatch(getCustomerList(data))
  };

  return (
    <React.Fragment>
      <EditCustomerModal
        customer={customer}
        show={isModal}
        toggleModal={toggleModal}
      />

      <div className="col-12" style={styles.container}>
        <div className="d-flex justify-content-between">
          <div>
            <span className="font-12 bold"> {customer.name} </span>
            <div>
              <span className="font-10 text-muted">
                {" "}
                {customer.groupName ? customer.groupName?.name : "-"}{" "}
              </span>
            </div>
          </div>

          <div className="d-flex flex-row">
            <span className="mr-2 pointer" onClick={toggleModal}>
              <MdOutlineModeEditOutline size={15} color={"#1c7ef2"} />
            </span>

            <span onClick={deleteHandler} className="pointer">
              <FiTrash size={15} color={"#ea3f3f"} />
            </span>
          </div>
        </div>

        <div style={styles.cardFooter}>
          <div className="d-flex justify-content-between align-items-center mt-2">
            <span>
              <FiPhone size={15} className="footer-icons mr-1" />
              {customer.phoneNumber && customer.phoneNumber !== ""
                ? customer.phoneNumber
                : "--"}
            </span>

            <span>
              <IoMailOutline size={15} className="footer-icons mr-1" />
              {customer.email && customer.email !== "" ? customer.email : "--"}
            </span>
          </div>
        </div>
      </div>

      {/* <div className="row m-0 mb-2 r-5 hidden-toggle" style={styles.container}>
                <div className="col-md-10 p-2">
                    <div className="font-weight-bold">{customer.name}</div>
                    <div className="text-light-secondary">Contact</div>
                    <div className="text-dark-primary">{customer.email}</div>
                    <div className="text-black">{customer.phoneNumber}</div>
                </div>
                <div className="col-md-2 p-0  border-left">
                    <div className="border-bottom">
                        <div className="edit-container pointer m-1 mb-2 ml-2" onClick={toggleModal}></div>
                    </div>
                    <div className="delete-container pointer m-1 ml-2" onClick={deleteHandler}></div>
                </div>
            </div> */}
    </React.Fragment>
  );
};

const styles = {
  container: {
    backgroundColor: "#fff",
    color: "#000",
    fontSize: "10px",
    // boxShadow: '0 0 20px #dbd6d6',
    padding: 10,
    marginBottom: 8,
    borderRadius: 5,
  },
  cardFooter: {
    borderTop: "1px solid #dee2e6",
    marginTop: 8,
  },
};

export default CustomerCard;
