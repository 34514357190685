import moment from "moment";
import GetRequest from "../../services/GetRequest";
import { getStorage, setStorage } from "../../services/localStorageService";
import PostRequest from "../../services/PostRequest";
import TaskManagerRequest from "../../services/TaskManagerRequest";

export const GET_TASK_DRIVER_GROUPS = "GET_TASK_DRIVER_GROUPS";
export const GET_TASKMANAGER_DRIVERS = "GET_TASKMANAGER_DRIVERS";
export const GET_TASK_DRIVER = "GET_TASK_DRIVER";
export const GET_CUSTOMERS = "GET_CUSTOMERS";
export const GET_CUSTOMER_GROUPS = "GET_CUSTOMER_GROUPS";
export const GET_TASKS = "GET_TASKS";
export const GET_TASK = "GET_TASK";
export const GET_TASK_ATTRIBUTES = "GET_TASK_ATTRIBUTES";
export const GET_PENDING_TASKS = "GET_PENDING_TASKS";
export const FILTER_TASKS = "FILTER_TASKS";
export const DELETE_TASK = "DELETE_TASK";
export const EDIT_TASK = "EDIT_TASK";
export const ADD_TASK = "ADD_TASK";
export const GET_TASK_CHECK_SETTINGS = "GET_TASK_CHECK_SETTINGS";
export const GET_TASKMANAGER_MODAL_DRIVERS = "GET_TASKMANAGER_MODAL_DRIVERS";
export const SET_TIME = "SET_TIME";
export const SET_SELECTED_DRIVERS = "SET_SELECTED_DRIVERS";
export const REMOVE_SELECTED_DRIVERS = "REMOVE_SELECTED_DRIVERS";
export const SELECT_ALL_TASK_DRIVER_GROUPS = "SELECT_ALL_TASK_DRIVER_GROUPS";
export const SET_TIMELINE_VIEW = "SET_TIMELINE_VIEW";

//Location
export const GET_POI = "GET_POI";
export const GET_CUSTOMER_LIST = "GET_CUSTOMER_LIST";
export const DELETE_CUSTOMER = "DELETE_CUSTOMER";
export const ADD_CUSTOMER = "ADD_CUSTOMER";
export const GET_CUSTOMER = "GET_CUSTOMER";
export const EDIT_CUSTOMER = "EDIT_CUSTOMER";
export const ASSIGN_TASKS = "ASSIGN_TASKS";

export const GET_DRIVER_TASK_STATUS_COUNT = "GET_DRIVER_TASK_STATUS_COUNT";
export const UPDATE_DRIVER_COUNTS = "UPDATE_DRIVER_COUNTS";

export const SET_DRIVERS_BALANCE = "SET_DRIVERS_BALANCE";
export const SELECTED_TASK_FIELDS = "SELECTED_TASK_FIELDS";
export const FILTER_DRIVERS = "FILTER_DRIVERS";

export const ORDER_POSITION_UPDATE = "ORDER_POSITION_UPDATE";
export const TOGGLE_HEADER_SWITCH = "TOGGLE_HEADER_SWITCH";

export const SORT_TASK_DRIVER_GROUPS = "SORT_TASK_DRIVER_GROUPS";

export const SET_OFFICE_TIME = "SET_OFFICE_TIME";

export const filterDrivers = (type) => {
  return async (dispatch) => {
    dispatch({
      type: FILTER_DRIVERS,
      value: type,
    });
  };
};

export const toggleHeaderSwitch = (value) => {
  return async (dispatch) => {
    dispatch({
      type: TOGGLE_HEADER_SWITCH,
      value: value,
    });
  };
};

export const setTimeLineView = (value) => {
  return async (dispatch) => {
    dispatch({
      type: SET_TIMELINE_VIEW,
      value: value,
    });
  };
};

export const sortTaskDriverGroups = () => {
  return async (dispatch) => {
    const sort = await getStorage("isTaskDriverSorted");
    setStorage("isTaskDriverSorted", !sort || sort == "false" ? true : false);

    dispatch({
      type: SORT_TASK_DRIVER_GROUPS,
      value: !sort || sort == "false" ? "_id" : "name",
    });
  };
};

export const updateDriverCounts = (data) => {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_DRIVER_COUNTS,
      value: data,
    });
  };
};

export const setTimetoGetTask = (value) => {
  try {
    return async (dispatch) => {
      dispatch({
        type: SET_TIME,
        value: value,
      });
    };
  } catch (error) {
    console.log("Error : ", error);
    return false;
  }
};

export const selectAllTaskDriverGroups = (isSelected) => {
  return (dispatch) => {
    dispatch({
      type: SELECT_ALL_TASK_DRIVER_GROUPS,
      value: isSelected,
    });
  };
};

export const setSelectedDrivers = (checked, drivers, groups) => {
  try {
    return async (dispatch) => {
      dispatch({
        type: SET_SELECTED_DRIVERS,
        value: checked,
        drivers: drivers || [],
        groups: groups || [],
      });
    };
  } catch (error) {
    console.log("Error : ", error);
    return false;
  }
};

export const removeSelectedDrivers = () => {
  try {
    return async (dispatch) => {
      dispatch({
        type: REMOVE_SELECTED_DRIVERS,
        value: [],
      });
    };
  } catch (error) {
    console.log("Error : ", error);
    return false;
  }
};

export const getTaskDriverGroups = () => {
  try {
    const user = getStorage("__user__", "object");
    const org_id = getStorage("org_id");

    return async (dispatch) => {
      const sort = await getStorage("isTaskDriverSorted");
      setStorage("isTaskDriverSorted", !sort || sort == "false" ? true : false);
      // let response = await GetRequest(`/tasks/getTaskDriverGroups/${user.id}`, {})
      const response = await TaskManagerRequest(`/get/driver/groups`, {});
      // response.push({driver_group_id : -1, driver_group_name : user.name, driver_group_org_id : org_id })

      dispatch({
        type: GET_TASK_DRIVER_GROUPS,
        value: response,
        sortBy: !sort || sort == "false" ? "_id" : "name",
      });
    };
  } catch (error) {
    console.log("Error : ", error);
    return false;
  }
};

export const getTaskDrivers = (value = "today") => {
  try {
    return async (dispatch) => {
      const user = getStorage("__user__", "object");

      const response = await TaskManagerRequest(`/get/drivers`, {});
      dispatch({
        type: GET_TASKMANAGER_DRIVERS,
        value: response,
      });
    };
  } catch (error) {
    console.log("Error : ", error);
    return false;
  }
};

export const getTaskModalDrivers = (value = "today") => {
  try {
    return async (dispatch) => {
      const user = getStorage("__user__", "object");

      const response = await GetRequest(
        `/tasks/getTaskDrivers/${user.id}/${value}`,
        {}
      );
      dispatch({
        type: GET_TASKMANAGER_MODAL_DRIVERS,
        value: response,
      });
    };
  } catch (error) {
    console.log("Error : ", error);
    return false;
  }
};

export const getCustomers = () => {
  try {
    const user = getStorage("__user__", "object");

    return async (dispatch) => {
      // const response = await GetRequest(`/tasks/getCustomersToAddTask/${user.id}`, {})
      const response = await TaskManagerRequest(`/get/customers`);

      dispatch({
        type: GET_CUSTOMERS,
        value: response,
      });
    };
  } catch (error) {
    console.log("Error : ", error);
    return false;
  }
};

export const getDriver = (id, isUpdateBalance = false) => {
  try {
    return async (dispatch) => {
      // const response = await GetRequest(`/tasks/getSingleTaskDriverDetails/${id}`, {})
      const response = await TaskManagerRequest(`/get/driver`, {
        driver: id,
        needStatastics: true,
      });

      dispatch({
        type: GET_TASK_DRIVER,
        value: response,
        isUpdateBalance: isUpdateBalance,
      });
    };
  } catch (error) {
    console.log("Error : ", error);
    return false;
  }
};

export const removeActiveDriver = () => {
  try {
    return async (dispatch) => {
      dispatch({
        type: GET_TASK_DRIVER,
        value: false,
      });
    };
  } catch (error) {
    console.log("Error : ", error);
    return false;
  }
};

export const getTaskCustomerGroups = () => {
  try {
    const user = getStorage("__user__", "object");

    return async (dispatch) => {
      // const response = await GetRequest(`/tasks/getTaskCustomerGroups/${user.id}`, {})
      const response = await TaskManagerRequest(`/get/customer/groups`, {});

      dispatch({
        type: GET_CUSTOMER_GROUPS,
        value: response,
      });
    };
  } catch (error) {
    console.log("Error : ", error);
    return false;
  }
};

export const getTasks = (data = {}) => {
  try {
    // const user = getStorage('__user__', 'object')
    // const from = data.from ? data.from : '00-00-0000'
    // const to = data.to ? data.to : '00-00-0000'
    // const drivers = data.drivers ? data.drivers : []

    return async (dispatch) => {
      const response = await TaskManagerRequest(`/get/all/tasks`, data);
      // const response = await PostRequest(`/tasks/getTasks/${data.id}/${from}/${to}/${user.id}/0`, {drivers: drivers})

      dispatch({
        type: GET_TASKS,
        value: response,
      });
    };
  } catch (error) {
    console.log("Error : ", error);
    return false;
  }
};

export const getTaskCheckSettings = () => {
  try {
    const user = getStorage("__user__", "object");

    return async (dispatch) => {
      const response = await GetRequest(
        `/tasks/getTaskCheckSettings/${user.id}`,
        {}
      );

      dispatch({
        type: GET_TASK_CHECK_SETTINGS,
        value: response,
      });
    };
  } catch (error) {
    console.log("Error : ", error);
    return false;
  }
};

export const getPendingTasks = (data = {}) => {
  try {
    // const user = getStorage('__user__', 'object')
    // const from = data.from ? data.from : '00-00-0000'
    // const to = data.to ? data.to : '00-00-0000'

    return async (dispatch) => {
      // const response = await GetRequest(`/tasks/getPendingTasks/${from}/${to}/${user.id}/0`, {})
      const response = await TaskManagerRequest(`/get/all/tasks`, data);

      dispatch({
        type: GET_PENDING_TASKS,
        value: response,
      });
    };
  } catch (error) {
    console.log("Error : ", error);
    return false;
  }
};

export const filterTasks = (type) => {
  try {
    return async (dispatch) => {
      dispatch({
        type: FILTER_TASKS,
        value: type,
      });
    };
  } catch (error) {
    console.log("Error : ", error);
    return false;
  }
};

export const getTask = (id) => {
  try {
    return async (dispatch) => {
      const response = await GetRequest(`/tasks/getsingletask/${id}`, {});

      dispatch({
        type: GET_TASK,
        value: response,
      });
    };
  } catch (error) {
    console.log("Error : ", error);
    return false;
  }
};

export const deleteTask = (id) => {
  try {
    return async (dispatch) => {
      const response = await GetRequest(`/tasks/deleteTask/${id}`, {});

      dispatch({
        type: DELETE_TASK,
        value: "",
      });
    };
  } catch (error) {
    console.log("Error : ", error);
    return false;
  }
};

// Location

///tasks/getPaginatedCustomerList/1039/null/date/0?page=1

export const getAllPoi = () => {
  try {
    const user = getStorage("__user__", "object");

    return async (dispatch) => {
      const response = await GetRequest(`/tasks/getAllPoi/${user.id}`, {});

      dispatch({
        type: GET_POI,
        value: response,
      });
    };
  } catch (error) {
    console.log("Error : ", error);
    return false;
  }
};

export const getCustomerList = (data) => {
  try {
    const user = getStorage("__user__", "object");
    const sortBy = data.sortBy ? data.sortBy : "date";
    const groupId = data.groupId ? data.groupId : 0;
    const page = data.page ? data.page : 1;
    const search = data.search ? data.search : "";

    data = { sortBy, groupId, page, search };

    return async (dispatch) => {
      const response = await TaskManagerRequest("/get/customers", data);
      dispatch({
        type: GET_CUSTOMER_LIST,
        value: response,
      });
    };
  } catch (error) {
    console.log("Error : ", error);
    return false;
  }
};

export const deleteTaskCustomer = (id) => {
  try {
    return async (dispatch) => {
      const response = await GetRequest(`/tasks/deleteTaskCustomer/${id}`, {});

      dispatch({
        type: DELETE_CUSTOMER,
        value: response,
      });
    };
  } catch (error) {
    console.log("Error : ", error);
    return false;
  }
};

export const addTaskCustomer = (data) => {
  try {
    return async (dispatch) => {
      const response = await PostRequest(`/tasks/addTaskCustomer`, data);

      dispatch({
        type: ADD_CUSTOMER,
        value: response,
      });
    };
  } catch (error) {
    console.log("Error : ", error);
    return false;
  }
};

export const getSingleCustomer = (id) => {
  try {
    return async (dispatch) => {
      const response = await GetRequest(`/tasks/getSingleCustomer/${id}`, {});

      dispatch({
        type: GET_CUSTOMER,
        value: response,
      });
    };
  } catch (error) {
    console.log("Error : ", error);
    return false;
  }
};

export const editTaskCustomer = (data) => {
  try {
    return async (dispatch) => {
      const response = await PostRequest(
        `/tasks/editTaskCustomer/${data.customer_id}`,
        data
      );

      dispatch({
        type: EDIT_CUSTOMER,
        value: response,
      });
    };
  } catch (error) {
    console.log("Error : ", error);
    return false;
  }
};

export const assignTasksToObject = (data) => {
  try {
    return async (dispatch) => {
      const user = await getStorage("__user__", "object");
      if (user) {
        data.authUserName = user.name;
      }
      // const response = await PostRequest(`/tasks/assignTasksToObject/${driver_id}`, data)
      const response = await TaskManagerRequest(`/tasks/assign`, data);

      dispatch({
        type: ASSIGN_TASKS,
        value: response,
      });
    };
  } catch (error) {
    console.log("Error : ", error);
    return false;
  }
};

///tasks/edittask/3084

export const editTask = (data) => {
  try {
    return async (dispatch) => {
      const response = await PostRequest(
        `/tasks/edittask/${data.task_id}`,
        data
      );

      dispatch({
        type: EDIT_TASK,
        value: response,
      });
    };
  } catch (error) {
    console.log("Error : ", error);
    return false;
  }
};

export const getTaskAttributes = () => {
  try {
    const user = getStorage("__user__", "object");

    return async (dispatch) => {
      const response = await TaskManagerRequest(`/task-attributes`, {});

      dispatch({
        type: GET_TASK_ATTRIBUTES,
        value: response,
      });
    };
  } catch (error) {
    console.log("Error : ", error);
    return false;
  }
};

export const addTask = (data) => {
  try {
    const user = getStorage("__user__", "object");

    return async (dispatch) => {
      const response = await PostRequest(`/tasks/addtask/${user.id}`, data);

      dispatch({
        type: ADD_TASK,
        value: response,
      });
    };
  } catch (error) {
    console.log("Error : ", error);
    return false;
  }
};

export const getDriverTaskStatusCount = (data = {}) => {
  try {
    return async (dispatch) => {
      const response = await TaskManagerRequest(`/driver/task/status`, data);

      dispatch({
        type: GET_DRIVER_TASK_STATUS_COUNT,
        value: response,
      });
    };
  } catch (error) {
    console.log("Error : ", error);
    return false;
  }
};

export const changeSelectedTaskFields = (fields) => {
  return async (dispatch) => {
    dispatch({
      type: SELECTED_TASK_FIELDS,
      value: fields,
    });
  };
};

export const setDriversBalance = (data) => {
  try {
    return async (dispatch) => {
      dispatch({
        type: SET_DRIVERS_BALANCE,
        value: data,
      });
    };
  } catch (error) {
    console.log("Error : ", error);
    return false;
  }
};

export const updateOrderPosition = () => {
  try {
    return (dispatch) => {
      dispatch({
        type: ORDER_POSITION_UPDATE,
        value: moment().unix(),
      });
    };
  } catch (error) {
    console.log("Error : ", error);
  }
};

export const loadOfficeTime = () => {
  try {
    return async (dispatch) => {
      const setting = await TaskManagerRequest("/get/setting");
      dispatch({
        type: SET_OFFICE_TIME,
        value: setting,
      });
    };
  } catch (error) {
    console.log("Error : ", error);
  }
};
