import React, {useState, useEffect} from 'react'

const SingleInput = (props) => {

    var disabledStyle = {}

    if(props.disabled) {
        disabledStyle = {
            backgroundColor: '#eee'
        }
    }
    
    const [value,setValue] = useState(props.value)

   useEffect(() => {
      setValue(props.value)
   }, [props.value])


    const classes = "input-style input-focus " + props.className

    return (
        <input
            required={props.required ? props.required : false}
            type={props.type ? props.type : 'text'}
            disabled={props.disabled ? props.disabled : false}
            readOnly={props.readOnly ? props.readOnly : false}
            value={value}
            placeholder={props.placeholder ? props.placeholder : ''}
            style={{...props.style, ...disabledStyle}}
            onChange={(event) => {
                setValue(event.target.value)
                if(props.onChange) props.onChange(event)
            }}
            className={classes} 
            name={props.name} />
            
    )
}


export default SingleInput;