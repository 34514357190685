import React, { useState, useEffect } from "react";
import Autocomplete, { usePlacesWidget } from "react-google-autocomplete";
import { GOOGLE_MAPS_API_KEY } from "../../configs/map.key";
import StaticMap from "./StaticMap";

const AddressField = (props) => {
  const { wantFieldset = true, wantMap = false } = props;

  const [value, setValue] = useState("");
  const [position, setPosition] = useState({ lat: 25.2048, lng: 55.2708 });

  const handleOnAdressSelected = (p) => {
    setValue(p.formatted_address);

    var _pos = {
      lat: p.geometry.location.lat(),
      lng: p.geometry.location.lng(),
    };

    setPosition(_pos);
    if (props.setPosition) props.setPosition(_pos);

    if (props.onAddressSelect) {
      props.onAddressSelect({
        latitude: p.geometry.location.lat(),
        longitude: p.geometry.location.lng(),
        address: p.formatted_address ? p.formatted_address : p.name,
      });

      if (props.setClearAfterClick) {
        setValue("");
      }
    }
  };

  const renderField = () => {
    return (
      <Autocomplete
        apiKey={GOOGLE_MAPS_API_KEY.MAIN_KEY}
        onPlaceSelected={(place) => handleOnAdressSelected(place)}
        options={{
          // types: ["(regions)"],
          componentRestrictions: { country: "AE" },
          fields: ["address_components", "geometry", "icon", "name"],
          strictBounds: false,
          types: ["establishment"],
        }}
        required={props.required ? props.required : false}
        type="text"
        disabled={props.disabled ? props.disabled : false}
        placeholder={props.placeholder ? props.placeholder : ""}
        style={{ fontSize: "12px", ...props.style }}
        name={props.name}
        defaultValue={value}
        className={props.className ? props.className : "focus-style-none"}
        libraries={["places", "geometry"]}
        onFocus={(event) => {
          event.target.setAttribute("autocomplete", "off");
        }}
      />
    );
  };

  useEffect(() => {
    if (props.value) setValue(props.value);
  }, [props.value]);

  useEffect(() => {
    if (props.position) setPosition(props.position);
  }, [props.position]);

  if (wantFieldset) {
    return (
      <React.Fragment>
        <fieldset className={props.className}>
          <legend>
            <span>{props.label}</span>
          </legend>
          {renderField()}
        </fieldset>

        {wantMap && (
          <StaticMap
            lat={position.lat}
            lng={position.lng}
            style={{ height: 200, borderRadius: 5, top: 10, marginBottom: 10 }}
          />
        )}
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      {renderField()}
      {wantMap && (
        <StaticMap
          lat={position.lat}
          lng={position.lng}
          style={{ height: 200, borderRadius: 5, top: 10, marginBottom: 10 }}
        />
      )}
    </React.Fragment>
  );
};

export default React.memo(AddressField);
