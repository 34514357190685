import React, { useState } from "react";
import IconButton from "../../UI/IconButton";
import AddCustomerModal from "../../modals/task-manager/AddCustomerModal";
import { useSelector } from "react-redux";
import { Dropdown } from "react-bootstrap";
import { FiMoreVertical, FiDownload, FiUpload } from "react-icons/fi";
import UploadLocationCSVModal from "./UploadLocationCSVModal";
import {
  CUSTOMER_CSV_EXAMPLE,
  CUSTOMER_CSV_FIELDS,
} from "../../../configs/csv.config";

const Header = (props) => {
  const groups = useSelector((state) => state.taskReducer.customerGroups);

  const [addCustomerModal, setAddCustomerModal] = useState(false);
  const [uploadCSVModal, setUploadCSVModal] = useState(false);

  const toggleAddCustomerModal = () => setAddCustomerModal(!addCustomerModal);
  const toggleUploadCSVModal = () => setUploadCSVModal(!uploadCSVModal);

  const onSort = (event) => {
    if (props.data && props.setData) {
      props.setData({ ...props.data, sortBy: event.target.value });
    }
  };

  const onSelectGroup = (event) => {
    if (props.data && props.setData) {
      props.setData({ ...props.data, groupId: event.target.value });
    }
  };

  const downloadCustomerCSVTemplate = () => {
    var blob = new Blob(
      [
        [CUSTOMER_CSV_FIELDS.join(","), CUSTOMER_CSV_EXAMPLE.join(",")].join(
          "\n"
        ),
      ],
      { type: "text/csv" }
    );
    saveAs(blob, "customer-template.csv");
  };

  return (
    <React.Fragment>
      <AddCustomerModal
        show={addCustomerModal}
        toggleModal={(res) => toggleAddCustomerModal()}
      />

      <UploadLocationCSVModal
        show={uploadCSVModal}
        toggleModal={toggleUploadCSVModal}
      />

      <div className="row m-0 pt-2 pb-2 border-bottom">
        <div className="col-12 d-flex justify-content-between align-items-center">
          <div>
            <span className="font-14">Locations</span>
          </div>

          <div className="d-flex">
            <select
              onChange={onSort}
              className="font-12 outline-none border-theme r-3 w-50 ml-3 p-1"
            >
              <option value="">Sort By</option>
              <option value="date">Created Date</option>
              <option value="name">Name</option>
            </select>
            <select
              onChange={onSelectGroup}
              className="font-12 outline-none border-theme r-3 w-50 ml-3 p-1"
            >
              <option value="">Select Group</option>
              {groups.map((group, i) => (
                <option key={i} value={group._id}>
                  {group.name}
                </option>
              ))}
            </select>
          </div>

          <div className="d-flex">
            <IconButton
              label="Add New Location"
              buttonstyle={{
                padding: "7px",
                color: "white",
              }}
              className="bg-theme"
              onClick={toggleAddCustomerModal}
            />

            <Dropdown className="ml-2">
              <Dropdown.Toggle
                id="dropdown-basic"
                style={{ height: 34.73 }}
                className="btn-white after-none shadow-none mb-0"
              >
                <FiMoreVertical className="mb-1" />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item style={{ width: 220 }}>
                  <div>
                    <div
                      onClick={downloadCustomerCSVTemplate}
                      className="pointer"
                    >
                      <FiDownload size={16} className="mr-1" />
                      <span className="font-12"> Download CSV Template</span>
                    </div>

                    <div
                      onClick={toggleUploadCSVModal}
                      className="pointer mt-2"
                    >
                      <FiUpload size={16} className="mr-1" />
                      <span className="font-12"> Import Locations</span>
                    </div>
                  </div>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <button
              type="button"
              title="close"
              onClick={props.toggleLocation}
              class="btn btn-dark mb-0 ml-2"
              style={{ height: 34.73 }}
            >
              <span class="fa fa-times"></span>
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Header;
