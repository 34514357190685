const Card = (props) => {
   return (
      <div onClick={props.onClick ? props.onClick : () => {}} style={{...styles.card, ...props.style}} className={props.className}>
         {props.children}
      </div>
   )
}

const styles = {
   card: {
      width: '100%',
      padding: '10px 12px',
      borderRadius: '5px',
      boxShadow: '0 4px 15px 0 rgba(0, 0, 0, 0.1)',
      backgroundColor: '#ffffff',
   }
}

export default Card;