import React, { useCallback, useState, useEffect } from "react";
import Collapsible from "react-collapsible";
import { useDispatch, useSelector } from "react-redux";
import Driver from "./Driver";
import _ from "underscore";
import {
  removeActiveDriver,
  selectAllTaskDriverGroups,
  setSelectedDrivers,
  toggleHeaderSwitch,
} from "../../../store/actions/taskManager.action";
import SearchInput from "../../SearchInput";
import TabContentBody from "../../layouts/TabContentBody/TabContentBody";
import { getStorage } from "../../../services/localStorageService";

const GroupName = ({ name, id, index, drivers }) => {
  const dispatch = useDispatch();
  const selectedDrivers = useSelector(
    (state) => state.taskReducer.selectedDrivers
  );
  const [selectedAllGroups, setSelectedAllGroups] = useState(false);

  const getSelected = () => {
    return drivers?.every((v) => selectedDrivers.includes(v._id));
  };

  const onToggleGroupSelection = async (event) => {
    const _drivers = drivers.map((d) => d._id);
    dispatch(removeActiveDriver());
    dispatch(toggleHeaderSwitch(true));
    dispatch(setSelectedDrivers(event.target.checked, _drivers, [id]));
  };

  const onToggleAllGroupSelection = (event) => {
    event.stopPropagation();
    setSelectedAllGroups(!selectedAllGroups);
    dispatch(selectAllTaskDriverGroups(!selectedAllGroups));
  };

  return (
    <div>
      <span
        onClick={(event) => event.stopPropagation()}
        className="group-checkbox"
      >
        <input
          type="checkbox"
          checked={getSelected()}
          id={"g__" + id}
          className="vh"
          onChange={(event) => onToggleGroupSelection(event)}
        />
        <label className="check-box-lab-ac" htmlFor={"g__" + id}></label>
      </span>

      <span className="group-name">
        {name} [{drivers?.length || "0"}]
      </span>

      {index == 0 && (
        <span
          onClick={onToggleAllGroupSelection}
          className="group-name float-right font-weight-normal"
          style={{
            fontSize: "10px",
            position: "absolute",
            top: "30%",
            right: "10%",
          }}
        >
          {selectedAllGroups ? "Deslect all Groups" : "Select all Groups"}
        </span>
      )}
    </div>
  );
};

const DriverGroup = (props) => {
  const _driverGroups = useSelector(
    (state) => state.taskReducer.taskDriverGroups
  );
  const vehicles = getStorage("__vehicles__", "object");

  const [driverGroups, setDriverGroups] = useState([]);

  const [isSearchOn, setIsSearchOn] = useState("");

  const __filter__ = useCallback(() => {
    var gp = [..._driverGroups];
    var _gp_ = [];

    if (isSearchOn != "") {
      gp.map((group) => {
        const _group = { ...group };
        if (_group.Drivers) {
          _group.Drivers = _.filter(
            _group.Drivers,
            (item) =>
              item.name.match(new RegExp(isSearchOn, "gi")) ||
              (vehicles &&
                vehicles[item.vehicle] &&
                vehicles[item.vehicle].name.match(new RegExp(isSearchOn, "gi")))
          );
        }
        _gp_.push(_group);
      });
    } else {
      _gp_ = [..._driverGroups];
    }

    return _gp_;
  });

  const handleSearch = useCallback((event) => {
    var value = event.target.value;
    setIsSearchOn(value);
  });

  const clear = () => {
    setIsSearchOn("");
  };

  useEffect(() => {
    setDriverGroups(__filter__());
  }, [_driverGroups, isSearchOn]);

  const renderContent = () => {
    return (
      <div className="group-list group-list-scroll max-100">
        <div className="tab-container">
          {driverGroups &&
            driverGroups.map((group, i) => (
              <Collapsible
                open={true}
                key={i}
                trigger={
                  <GroupName
                    name={group.name}
                    defaultChecked={props.defaultChecked}
                    index={i}
                    id={group._id}
                    drivers={group.Drivers}
                  />
                }
                transitionTime={100}
                className="mt-1"
                openedClassName="mt-1"
              >
                <div className="acd-tbl bg-e">
                  {group.Drivers?.filter((t) => t.group == group._id).map(
                    (driver, ind) => (
                      <Driver
                        key={ind}
                        setLoading={props.setLoading}
                        isLoading={props.isLoading}
                        driver={driver}
                        isMapList={props.isMapList}
                        onClick={async () => {}}
                        storageUsed={props.storageUsed}
                      />
                    )
                  )}
                </div>
              </Collapsible>
            ))}
        </div>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div className="footer-search d-flex justify-content-between">
        <div className="flex-1 mr-2">
          <SearchInput
            onSearch={handleSearch}
            placeholder="Search..."
            value={isSearchOn}
            text={isSearchOn}
            onClear={clear}
            style={{ width: "94.7%" }}
            iconStyle={{ right: 55 }}
          />
        </div>
      </div>
    );
  };

  return <TabContentBody content={renderContent()} footer={renderFooter()} />;
};

export default DriverGroup;
