import TaskManagerRequest from "../../services/TaskManagerRequest";

export const GET_ROUTES = 'GET_ROUTES'
export const EDIT_ROUTE = 'EDIT_ROUTE'
export const SET_ACTIVE_ROUTE = 'SET_ACTIVE_ROUTE'


export const getRoutes = (data = {}) => {


    return async dispatch => {

        const response = await TaskManagerRequest('/get/routes', data)

        dispatch({
            type: GET_ROUTES,
            value: response
        })
    };
}

export const editRoute = (data = {}) => {


    return async dispatch => {

        const response = await TaskManagerRequest('/update/routes', data, false, 'editRoute')

        dispatch({
            type: EDIT_ROUTE,
            value: response
        })
    };
}


export const setActiveRoute = (route = false) => {

    return dispatch => {
        dispatch({
            type: SET_ACTIVE_ROUTE,
            value: route
        })
    };
}