import { getStorage } from "../../services/localStorageService";
import PostRequest from "../../services/PostRequest";
import TaskManagerRequest from "../../services/TaskManagerRequest";

export const GET_SALIKS = "GET_SALIKS";
export const GET_DARBS = "GET_DARBS";
export const GET_SALIK_ACCOUNT = "GET_SALIK_ACCOUNT";
export const GET_VEHICLE_TAGS = "GET_VEHICLE_TAGS";
export const GET_TOLL_TRIPS = "GET_TOLL_TRIPS";
export const GET_WEEKLY_SUMMARY = "GET_WEEKLY_SUMMARY";
export const GET_SUMMARY_BY_GATES = "GET_SUMMARY_BY_GATES";
export const SET_SELECTED_VEHICLES = "SET_SELECTED_VEHICLES";

export const getSaliks = () => {
  try {
    return async (dispatch) => {
      const response = await TaskManagerRequest(`/gets/salik`, {
        type: "SALIK",
      });

      dispatch({
        type: GET_SALIKS,
        value: response,
      });
    };
  } catch (error) {
    console.log("Error : ", error);
    return false;
  }
};

export const getDARBs = () => {
  try {
    return async (dispatch) => {
      const response = await TaskManagerRequest(`/gets/salik`, {
        type: "DARB",
      });

      dispatch({
        type: GET_DARBS,
        value: response,
      });
    };
  } catch (error) {
    console.log("Error : ", error);
    return false;
  }
};

export const getVehicleTags = () => {
  try {
    return async (dispatch) => {
      const response = await TaskManagerRequest(`/gets/vehicle/tag`, {});
      const groups = getStorage("__groups__", "object");

      var gp = JSON.parse(JSON.stringify(groups));
      var data = [];

      gp.map((group) => {
        var existingVehicles = Object.values(group.__vehicles__);

        for (let index = 0; index < existingVehicles.length; index++) {
          var exist = response.findIndex(
            (r) => r.vehicleID == existingVehicles[index].id
          );
          if (exist !== -1) {
            if (response[exist].tagNumber !== "") {
              existingVehicles[index]["tag"] = response[exist];
            }

            if (response[exist].platNumber !== "") {
              existingVehicles[index]["plat"] = response[exist];
            }
          }
          data.push(existingVehicles[index]);
        }
      });

      dispatch({
        type: GET_VEHICLE_TAGS,
        value: data,
      });
    };
  } catch (error) {
    console.log("Error : ", error);
    return false;
  }
};

export const getSalikAccount = (data = {}) => {
  try {
    return async (dispatch) => {
      const response = await TaskManagerRequest(`/salik/account`, data);

      dispatch({
        type: GET_SALIK_ACCOUNT,
        value: response,
      });
    };
  } catch (error) {
    console.log("Error : ", error);
    return false;
  }
};

export const getTollTrips = (data) => {
  try {
    return async (dispatch) => {
      const response = await TaskManagerRequest(`/get/vehicle/trips`, data);

      dispatch({
        type: GET_TOLL_TRIPS,
        value: response,
      });
    };
  } catch (error) {
    console.log("Error : ", error);
    return false;
  }
};

export const getWeeklySummary = (data) => {
  try {
    return async (dispatch) => {
      const response = await TaskManagerRequest(
        `/salik-darb/summary/byweek`,
        data
      );

      dispatch({
        type: GET_WEEKLY_SUMMARY,
        value: response,
      });
    };
  } catch (error) {
    console.log("Error : ", error);
    return false;
  }
};

export const getSummaryByGates = (data) => {
  try {
    return async (dispatch) => {
      const response = await TaskManagerRequest(
        `/salik-darb/summary/bygate`,
        data
      );

      dispatch({
        type: GET_SUMMARY_BY_GATES,
        value: response,
      });
    };
  } catch (error) {
    console.log("Error : ", error);
    return false;
  }
};

export const setSelectedVehicles = (checked, vehicles) => {
  try {
    return async (dispatch) => {
      dispatch({
        type: SET_SELECTED_VEHICLES,
        value: checked,
        vehicles: vehicles,
      });
    };
  } catch (error) {
    console.log("Error : ", error);
    return false;
  }
};
