import {
    GET_GROUPS,
    GET_SINGLE_GROUP,
    GET_GEOZONE_DETAILS,
    SELECT_GEOZONE,
    SELECT_POINT,
    SELECT_POINT_GROUP,
    SELECT_GEOZONE_GROUP,
    ACTIVE_ZEOZONE_POINT,
    GET_POINT_DETAILS,
    SET_FLAG_FOR_ADD_ZONE_ON_MAP,
    SET_EDITABLE_ZONE,
} from "../actions/geozone.action";

const initialState = {
    geozoneGroups: [],
    activeFilter: 'all',
    activeGroup: null,
    activeGeozone: null,
    selectedZones: [],
    selectedPoints: [],
    activeGeozonePoints : null,
    activePoint: null,
    isZoneDrawManagerEnabled : false,
    setEditableZone : null
}

const GeoZone = (state = initialState, action) => {

    switch (action.type) {

        case GET_GROUPS:
            return { ...state, geozoneGroups: action.value, selectedZones: [], selectedPoints: [] }

        case GET_SINGLE_GROUP:
            return { ...state, activeGroup: action.value }

        case ACTIVE_ZEOZONE_POINT:
            return { ...state, activeGeozonePoints: action.value }

        case GET_POINT_DETAILS:
            return { ...state, activePoint: action.value }

        case GET_GEOZONE_DETAILS:
            return { ...state, activeGeozone: action.value }

        case SELECT_GEOZONE:

            let cloneData = [...state.selectedZones]
            const id = action.value.gz && action.value.gz.length > 0 ? action.value.gz[0].gz_id : false
            let exist = cloneData.findIndex((item) => item.id == id)

            if (exist !== -1) {
                cloneData.splice(exist, 1)
            } else {
                cloneData.push({ ...action.value, id: id })
            }

            return { ...state, selectedZones: cloneData }

        case SELECT_POINT:

            let cloneSelectedPoints = [...state.selectedPoints]
            const id1 = action.value.gz && action.value.gz.length > 0 ? action.value.gz[0].gz_id : false
            let existPoint = cloneSelectedPoints.findIndex((item) => item.id == id1)

            if (existPoint !== -1) {
                cloneSelectedPoints.splice(existPoint, 1)
            } else {
                cloneSelectedPoints.push({ ...action.value, id: id1 })
            }

            return { ...state, selectedPoints: cloneSelectedPoints,activePoint: action.value }

        case SELECT_POINT_GROUP:
            let _cloneSelectedPoints = [...state.selectedPoints]
            const _newPoints = action.value.response
            _newPoints.map((point) => {
                let existPoint = _cloneSelectedPoints.findIndex((item) => item.id == point.gz_id)

                if (existPoint !== -1) {
                    if(!action.value.isChecked) _cloneSelectedPoints.splice(existPoint, 1)
                } else {
                    if(action.value.isChecked) _cloneSelectedPoints.push({ ...point, id: point.gz_id })
                }
            })
            return { ...state, selectedPoints: _cloneSelectedPoints }

        case SELECT_GEOZONE_GROUP:
            let _cloneData = [...state.selectedZones]
            const _newData = action.value.response
            _newData.map((zone) => {
                let existPoint = _cloneData.findIndex((item) => item.id == zone.gz_id)

                if (existPoint !== -1) {
                    if(!action.value.isChecked) _cloneData.splice(existPoint, 1)
                } else {
                    if(action.value.isChecked) _cloneData.push({ ...zone, id: zone.gz_id })
                }
            })
            return { ...state, selectedZones: _cloneData }

            
        case SET_FLAG_FOR_ADD_ZONE_ON_MAP:
            return { ...state, isZoneDrawManagerEnabled: action.value }

        case SET_EDITABLE_ZONE:
            return { ...state, setEditableZone: action.value }
    

        default:
            return state

    }

}

export default GeoZone;