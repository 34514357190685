export const TASK_DRIVER_FIELDS = [
  "driver_id",
  "driver_name",
  "driver_email",
  "driver_phone",
  "driver_group_name",
];

export const TASK_DRIVER_FIELDS_DATA = [
  "1",
  "John Due",
  "john@mail.com",
  "000-000-0000",
  "TestGroup",
];

export const TASK_CUSTOMER_FIELDS = [
  "location_id",
  "location_name",
  "location_username",
  "location_email",
  "location_phone",
  "location_address",
  "location_address_coordinates",
];

export const TASK_CUSTOMER_FIELDS_DATA = [
  "1",
  "Location Name",
  "location@username",
  "location@example.com",
  "000-000-0000",
  "14th Street Dubai-Supervisor Dubai United Arab Emirates",
  "Latitude - Longitude",
];

export const CUSTOMER_CSV_FIELDS = [
  "name",
  "email",
  "phoneNumber",
  "address",
  "location",
  "customerGroup",
  "description",
];

export const CUSTOMER_CSV_EXAMPLE = [
  "John Doe",
  "john@example.com",
  "0000000000",
  "14th Street Dubai-Supervisor Dubai United Arab Emirates",
  "Latitude - Longitude",
  "ExampleGroupName",
  "Write about this customer",
];

export const FLEET_SERVICE_EXPENDITURE_CSV_FIELDS = [
  "service_master_id",
  "service_user_id",
  "device_id",
  "service_name",
  "service_date",
  "service_odometer_value",
  "service_material_cost",
  "service_cost",
  "service_total_cost",
  "service_invoice",
  "service_comments",
];

export const FLEET_FUEL_EXPENDITURE_CSV_FIELDS = [
  "fuel_user_id",
  "device_id",
  "fuel_date",
  "fuel_type",
  "fuel_driver_id",
  "fuel_quantity",
  "fuel_amount",
  "fuel_odometer",
];
