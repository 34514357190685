import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Container } from "react-bootstrap";
import Input from "../../UI/Input";
import Checkbox from "../../UI/CheckBox";
import SingleInput from "../../UI/SingleInput";
import DragableMap from "../../UI/DragableMap";
import { useDispatch, useSelector } from "react-redux";
import { getStorage } from "../../../services/localStorageService";
import {
  addTaskCustomer,
  getAllPoi,
  getCustomerList,
  getTaskCustomerGroups,
} from "../../../store/actions/taskManager.action";
import { ShowNotification } from "../../../services/Notifications";
import TaskManagerRequest from "../../../services/TaskManagerRequest";
import Autocomplete from "react-google-autocomplete";
import AddressField from "../../UI/AddressField";
import ReverseAddress from "../../../services/ReverseAddress";
import { dubaiCoordinates } from "../../../configs/common";

const AddCustomerModal = (props) => {
  const dispatch = useDispatch();
  const groups = useSelector((state) => state.taskReducer.customerGroups);
  const poiGroups = useSelector((state) => state.taskReducer.poi);
  const [position, setPosition] = useState(dubaiCoordinates);
  const user = getStorage("__user__", "object");
  const [address, setAddress] = useState(
    "Financial Center Street, Dubai Burj Khalifa, Dubai"
  );
  const [search, setSearch] = useState("");

  const [addressByPoi, setAddressByPoi] = useState(false);
  const [activePOI, setActivePOI] = useState(false);

  const checkedChange = (event) => {
    setAddressByPoi(event.target.checked);
  };

  useEffect(() => {
    if (props.show == true) {
      dispatch(getAllPoi());
      dispatch(getTaskCustomerGroups());
    }
  }, [props.show]);

  const onSetPosition = (data) => {
    setAddressByPoi(false);
    setActivePOI(false);
    setPosition(data.position);
    setAddress(data.address);
    setSearch(`${data.position.lat},${data.position.lng}`);
  };

  const onPoiAddressSelect = async (event) => {
    const addresData = poiGroups.find(
      (item) => item.gz_id == event.target.value
    );
    if (addresData) {
      setActivePOI(addresData);
      setAddress(addresData.gz_name);
      setPosition({
        lat: parseFloat(addresData.gz_latitude),
        lng: parseFloat(addresData.gz_longitude),
      });
    } else {
      setActivePOI(false);
    }
  };

  const handleSubmit = async (event) => {
    const formData = new FormData(event.target);
    event.preventDefault();

    let data = {};

    for (let [key, value] of formData.entries()) {
      data[key] = value;
    }

    //Addredd By POI
    if (addressByPoi) {
      data["customer_address"] = data["POIaddress"];
      data["customerByPoi"] = activePOI;
    }

    //manage email
    if (data.email == "") delete data.email;

    //Add Latitude and Longtitude
    data["location"] = { latitude: position.lat, longitude: position.lng };
    data["address"] = address;
    data["user"] = user ? user.id : null;

    delete data.customer_address;
    delete data.POIaddress;

    try {
      const response = await TaskManagerRequest(`/add/customer`, data);
      dispatch(getCustomerList({}));
      ShowNotification("success", "Customer created successfully");
      props.toggleModal(response);
    } catch (error) {
      ShowNotification("error", error);
    }
  };

  const handleOnAddressSelect = ({ latitude, longitude, address }) => {
    setPosition({ lat: latitude, lng: longitude });
    setAddress(address);
    setSearch(`${latitude},${longitude}`);
  };

  const onSearchPosition = async () => {
    var parts = search.split(",");
    if (parts.length >= 2) {
      var p = {
        lat: parseFloat(parts[0].trim()),
        lng: parseFloat(parts[1].trim()),
      };
      setPosition(p);
      var _address = await ReverseAddress(p);
      if (_address) {
        setAddress(_address);
      }
    }
  };

  return (
    <>
      <Modal
        show={props.show}
        dialogClassName="modal-30w"
        onHide={() => props.toggleModal(false)}
        aria-labelledby="AddCustomerModal"
      >
        <Modal.Header closeButton>Add New Customer</Modal.Header>
        <form onSubmit={handleSubmit}>
          <Modal.Body className="show-grid">
            <Container>
              <Input name="name" label="Name" />

              <select
                defaultValue={groups.length > 0 ? groups[0]._id : ""}
                name="groupName"
                className="font-12 outline-none border-theme r-3 w-100 p-2 mt-2"
              >
                <option value="">Select Group</option>
                {groups.map((group, i) => (
                  <option key={i} value={group._id}>
                    {group.name}
                  </option>
                ))}
              </select>

              <div className="d-flex">
                <Checkbox
                  className="chk-bordered"
                  checked={addressByPoi}
                  id={1}
                  onChange={(event) => checkedChange(event)}
                />
                <span className="text-light-secondary font-12 mt-2 ml-2">
                  Address by POI{" "}
                </span>
              </div>

              {
                !addressByPoi && (
                  <AddressField
                    onAddressSelect={handleOnAddressSelect}
                    name="address"
                    label="Address"
                    placeholder="Address"
                    className="input-style input-focus mt-2"
                    wantFieldset={false}
                    value={address}
                  />
                )
                // <SingleInput name="address" placeholder="Address" className="mt-2" value={address} />
              }

              {addressByPoi && (
                <select
                  name="POIaddress"
                  onChange={onPoiAddressSelect}
                  className="font-12 outline-none border-theme r-3 w-100 p-2 mt-2"
                >
                  <option value="">Select POI</option>
                  {poiGroups.map((group, i) => (
                    <option key={i} value={group.gz_id}>
                      {group.gz_name}
                    </option>
                  ))}
                </select>
              )}

              <div className="row m-0 mt-2">
                <div className="col-md-9 p-0">
                  <SingleInput
                    placeholder="25.2048, 55.2708"
                    value={search}
                    onChange={(event) => setSearch(event.target.value)}
                  />
                </div>
                <div className="col-md-3 p-0 text-right ">
                  <button
                    type="button"
                    className="btn btn-search mb-0 pl-2 pr-2 ml-2"
                    onClick={onSearchPosition}
                  >
                    <i className="fa fa-search font-12"></i>
                  </button>
                  <button
                    type="button"
                    className="btn btn-theme mb-0 pl-2 pr-2 ml-2"
                    onClick={() => setSearch("")}
                  >
                    <i className="fa fa-refresh font-12"></i>
                  </button>
                </div>
              </div>

              <div
                style={{ height: "200px", width: "100%" }}
                className="hide-control mt-2"
              >
                <DragableMap
                  onDragChange={onSetPosition}
                  allowUpdateOnChange={true}
                  position={position}
                />
              </div>

              <SingleInput
                name="contactName"
                placeholder="Contact Name"
                className="mt-2"
              />

              <SingleInput
                name="phoneNumber"
                placeholder="Contact Number"
                className="mt-2"
              />
              <SingleInput name="email" placeholder="Email" className="mt-2" />
              <SingleInput
                name="description"
                placeholder="Note"
                className="mt-2"
              />
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              style={{ fontSize: "12px" }}
              className="btn btn-small"
              onClick={() => props.toggleModal(false)}
            >
              Cancel
            </button>
            <button
              style={{
                fontSize: "12px",
                backgroundColor: "#007bff",
                color: "#ffffff",
              }}
              className="btn btn-small"
            >
              Add Location
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default AddCustomerModal;

// =========================================================

// import React, { useState, useEffect } from 'react'
// import Modal from 'react-bootstrap/Modal'
// import { Container } from "react-bootstrap";
// import Input from '../../UI/Input';
// import Checkbox from '../../UI/CheckBox'
// import SingleInput from '../../UI/SingleInput'
// import DragableMap from '../../UI/DragableMap';
// import { useDispatch, useSelector } from 'react-redux';
// import { getStorage } from '../../../services/localStorageService';
// import { addTaskCustomer, getAllPoi, getCustomerList, getTaskCustomerGroups } from '../../../store/actions/taskManager.action';
// import { ShowNotification } from '../../../services/Notifications';

// const AddCustomerModal = (props) => {

//     const dispatch = useDispatch()
//     const groups = useSelector(state => state.taskReducer.customerGroups)
//     const poiGroups = useSelector(state => state.taskReducer.poi)
//     const [position, setPosition] = useState({ lat: 25.204849, lng: 55.270782 })
//     const user = getStorage('__user__', 'object')
//     const [address, setAddress] = useState('')
//     const [search, setSearch] = useState('')

//     const [addressByPoi, setAddressByPoi] = useState(false)
//     const checkedChange = (event) => {
//         setAddressByPoi(event.target.checked)
//     }

//     useEffect(() => {
//         if(props.show == true) {
//             dispatch(getAllPoi())
//             dispatch(getTaskCustomerGroups())
//         }
//     }, [props.show])

//     const onSetPosition = (data) => {
//         setAddressByPoi(false)
//         setPosition(data.position)
//         setAddress(data.address)
//     }

//     const onPoiAddressSelect = async (event) => {
//         const addresData = poiGroups.find((item) => item.gz_id == event.target.value)
//         if(addresData) {
//             setAddress(addresData.gz_name)
//             setPosition({lat: parseFloat(addresData.gz_latitude), lng: parseFloat(addresData.gz_longitude)})
//         }
//     }

//     const handleSubmit = async (event) => {

//         const formData = new FormData(event.target);
//         event.preventDefault();

//         let data = {}

//         for (let [key, value] of formData.entries()) {
//             data[key] = value;
//         }

//         //Addredd By POI
//         if (addressByPoi) data['customer_address'] = data['POIaddress']

//         //Add Latitude and Longtitude
//         data['customer_lattitude'] = position.lat
//         data['customer_longitude'] = position.lng
//         data['customer_address'] = address
//         data['customer_user_id'] = user ? user.id : null

//         // API call here
//         await dispatch(addTaskCustomer(data))
//         ShowNotification('success', 'Customer created successfully')
//         await dispatch(getCustomerList({}))

//         props.toggleModal();

//     }

//     const onSearchPosition = () => {
//         var parts = search.split(',')
//         if(parts.length >= 2) {
//             setPosition({lat: parseFloat(parts[0].trim()), lng: parseFloat(parts[1].trim())})
//         }
//     }

//     return (
//         <>
//             <Modal
//                 show={props.show}
//                 dialogClassName="modal-30w"
//                 onHide={props.toggleModal}
//                 aria-labelledby="AddCustomerModal"
//             >
//                 <Modal.Header closeButton >
//                     Add New Customer
//                 </Modal.Header>
//                 <form onSubmit={handleSubmit} >
//                     <Modal.Body className="show-grid">
//                         <Container>

//                             <Input name="customer_name" label="Name" />

//                             <select name="customer_group_id" className="font-12 outline-none border-theme r-3 w-100 p-2 mt-2">
//                                 <option value="" >Select Group</option>
//                                 {
//                                     groups.map((group, i) => <option key={i} value={group.customer_group_id}>{group.customer_group_name}</option>)
//                                 }
//                             </select>

//                             <div className="d-flex">
//                                 <Checkbox className="chk-bordered" checked={addressByPoi} id={1} onChange={(event) => checkedChange(event)} />
//                                 <span className="text-light-secondary font-12 mt-2 ml-2">Address by POI </span>
//                             </div>

//                             {!addressByPoi &&
//                                 <SingleInput name="customer_address" placeholder="Address" className="mt-2" value={address} />
//                             }

//                             {addressByPoi &&
//                                 <select name="POIaddress" onChange={onPoiAddressSelect} className="font-12 outline-none border-theme r-3 w-100 p-2 mt-2">
//                                     <option value="" >Select POI</option>
//                                     {
//                                         poiGroups.map((group, i) => <option key={i} value={group.gz_id}>{group.gz_name}</option>)
//                                     }
//                                 </select>
//                             }

//                             <div className="row m-0 mt-2">
//                                 <div className="col-md-9 p-0">
//                                     <SingleInput placeholder="25.2048, 55.2708" value={search} onChange={(event) => setSearch(event.target.value)} />
//                                 </div>
//                                 <div className="col-md-3 p-0 text-right ">
//                                     <button type="button" className="btn btn-search mb-0 pl-2 pr-2 ml-2" onClick={onSearchPosition}><i className="fa fa-search font-12"></i></button>
//                                     <button type="button" className="btn btn-theme mb-0 pl-2 pr-2 ml-2" onClick={() => setSearch('')}><i className="fa fa-refresh font-12"></i></button>
//                                 </div>
//                             </div>

//                             <div style={{ height: '200px', width: '100%' }} className="hide-control mt-2">
//                                 {addressByPoi && <DragableMap onDragChange={onSetPosition} position={position} />}
//                                 {!addressByPoi && <DragableMap onDragChange={onSetPosition} position={position} />}
//                             </div>

//                             <SingleInput name="customer_contact" placeholder="Contact" className="mt-2" />
//                             <SingleInput name="customer_email" placeholder="Email" className="mt-2" />
//                             <SingleInput name="customer_description" placeholder="Note" className="mt-2" />

//                         </Container>
//                     </Modal.Body>
//                     <Modal.Footer>
//                         <button type="button" style={{ fontSize: '12px' }} className="btn btn-small" onClick={props.toggleModal}>Cancel</button>
//                         <button style={{ fontSize: '12px', backgroundColor: '#007bff', color: '#ffffff' }} className="btn btn-small" >Add Location</button>
//                     </Modal.Footer>
//                 </form>
//             </Modal>
//         </>
//     )
// }

// export default AddCustomerModal
