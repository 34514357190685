export const ignitionNotifications = [
    {
        id: '1',
        status: 'Turned Off',
        date: '17-08-2021 19:31:25'
    },
    {
        id: '2',
        status: 'Turned Off',
        date: '19-08-2021 19:31:25'
    },
    {
        id: '3',
        status: 'Turned On',
        date: '23-05-2021 19:31:25'
    },
    {
        id: '4',
        status: 'Turned On',
        date: '07-08-2021 19:31:25'
    },
    {
        id: '5',
        status: 'Turned Off',
        date: '17-03-2021 19:31:25'
    },
]

export const assetHistory = [
    {
        id: '1',
        name: 'Dilip',
        date: '17-08-2021 19:31:25',
        hour: 34,
        note : "Buy new asset stocks"
    },
    {
        id: '2',
        name: 'Pankaj',
        date: '19-08-2021 19:31:25',
        hour: 23,
        note : "Cleared the background issue, change oil"
    },
    {
        id: '3',
        name: 'Dijo',
        date: '23-05-2021 19:31:25',
        hour: 25,
        note : "change oil"
    },
    {
        id: '4',
        name: 'Dilip',
        date: '07-08-2021 19:31:25',
        hour: 48,
        note : "oil hostory changed"
    },
    {
        id: '5',
        name: 'David',
        date: '17-03-2021 19:31:25',
        hour: 34,
        note : "Cleared the background issue, change oil"
    },
]

export const serviceReminders = [
    {
        id: '1',
        name: '	Oil/oil filter change',
        name: 'Dilip',
        date: '17-08-2021 19:31:25',
        odometer: 21452.5,
        comments: 'Need to do from Station...'
    },
    {
        id: '2',
        name: 'Change Brake Oil',
        date: '19-08-2021 19:31:25',
        odometer: 31452.5,
        comments: 'Robots can be autonomous or semi-autonomous'
    },
    {
        id: '3',
        name: 'Other service 3',
        date: '23-05-2021 19:31:25',
        odometer: 27552.5,
        comments: 'Coents 3...'
    },
    {
        id: '4',
        name: 'Oil/oil filter change',
        date: '17-03-2021 19:31:25',
        odometer: 21452.5,
        comments: 'Ned to change from Enoc...'
    },
]

export const documentReminders = [
    {
        id: '1',
        name: '	Mulkia [Registration]',
        date: '17-08-2021 19:31:25',
        interval: '1 Month',
        type : "Buy new asset stocks"
    },
    {
        id: '2',
        name: '	Mulkia [Registration]',
        date: '19-08-2021 19:31:25',
        interval: '1 Month',
        type : "Cleared the background issue, change oil"
    },
    {
        id: '3',
        name: '	Mulkia [Registration]',
        date: '23-05-2021 19:31:25',
        interval: '1 Month',
        type : "change oil"
    },
]