/*firebase daniel start*/
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { _pushNotification } from "./configs/common";


const firebaseConfig = {
  apiKey: "AIzaSyC4hYP0JBDD96lhGiEFMp2_U3DfQBPpSv8",
  authDomain: "taskmanager-10f09.firebaseapp.com",
  projectId: "taskmanager-10f09",
  storageBucket: "taskmanager-10f09.appspot.com",
  messagingSenderId: "470827963190",
  appId: "1:470827963190:web:e41749d11b04add5fb1997",
  measurementId: "G-FJEQG5V9RY",
};
const fapp = initializeApp(firebaseConfig);
const messaging = getMessaging(fapp);

getToken(messaging, {
  vapidKey:
    "BGZXxo8u1cvQ9fWwjj6-JldCMnpZddlwfcRZRep090iwVaFe3qb84KR79I78XlwFKT1o9BG_GxudZ1FRD3PD8GE",
})
  .then((currentToken) => {
    if (currentToken) {
      // console.log("Firebase Token", currentToken);
      localStorage.setItem("deviceTKN", currentToken);
    } else {
      // Show permission request UI
      console.log(
        "No registration token available. Request permission to generate one."
      );
      // ...
    }
  })
  .catch((err) => {
    console.log("An error occurred while retrieving token. ", err);
    // ...
  });

onMessage(messaging, (payload) => {
  console.log("Message received. ", payload);
  // addNotification({
  //     title: payload.notification.title,
  //     message: payload.notification.body,
  //     theme: 'darkblue',
  //     native: true // when using native, your OS will handle theming.
  //   });

  _pushNotification(payload.notification.title,payload.notification.body)
  // ...
});
