// analytics.js

import moment from "moment";
import { getStorage, setStorage } from "../services/localStorageService";
import PostRequest from "../services/PostRequest";
import TaskManagerRequest from "../services/TaskManagerRequest";

export const trackLoginTiming = (userId) => {
    const loginTime = new Date();
    return buildEventObject('login_timing', {
        timestamp: loginTime.toISOString()
    });
};

export const trackLoginDetails = (username) => {
    const browserInfo = navigator.userAgent;
    return buildEventObject(username, 'login_details', {
        browser_info: browserInfo
    });
};


export const startSession = () => {
    setStorage('sessionStartTime',moment().format('LLLL'))
};

export const endSession = () => {


    const sessionStartTime = moment(getStorage('sessionStartTime'),'LLLL').toDate();
    const sessionEndTime = new Date();
    const sessionDuration = (sessionEndTime - sessionStartTime) / (1000 * 60); // in minutes
    return buildEventObject('session_duration', {
        duration: sessionDuration
    });
};

export const trackLoginCount = (userId) => {
    return buildEventObject('login_count', {
        date: new Date().toISOString().slice(0, 10) // YYYY-MM-DD format
    });
};

export const trackModuleUsage = (userId, moduleName) => {
    return buildEventObject('module_usage', {
        module_name: moduleName
    });
};

export const trackTaskCreation = (userId, taskId) => {
    return buildEventObject('task_creation', {
        task_id: taskId
    });
};

export const trackExpenseManagerUsage = (userId, action) => {
    return buildEventObject('expense_manager_usage', {
        action: action
    });
};

export const trackFileStorageUsage = (userId, fileId, action) => {
    return buildEventObject('file_storage_usage', {
        file_id: fileId,
        action: action // e.g., 'upload', 'download', 'delete'
    });
};

export const trackDriverAppUsage = (userId, action) => {
    return buildEventObject('driver_app_usage', {
        action: action
    });
};

export const trackLocatorAppUsage = (userId, action) => {
    return buildEventObject('locator_app_usage', {
        action: action
    });
};


const buildEventObject = (event, eventData) => {
    
    const userData = getUserData();
    const user = getStorage('__user__','object')

    console.log('user',user,eventData)

    if(user && user?.id){
        const data =  {
            organization : 'organization',
            user: user?.id,
            eventName: event,
            eventName: eventData,
            userInformation: userData,
            // timestamp: new Date().toISOString()
        };

        console.log('data',data)

    }
   
};

// Function to collect user data (browser, OS, device type)
const getUserData = () => {
    const userAgent = navigator.userAgent;
    const platform = navigator.platform;
    const language = navigator.language;
    const deviceType = /Mobi|Android/i.test(userAgent) ? 'Mobile' : 'Desktop';

    // For country and city, you may need to use a third-party API to get accurate data.
    // This example assumes you have some way to retrieve or infer these values.
    const country = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const city = 'Unknown'; // Placeholder for city; use an IP-to-location API if needed.

    return {
        browser_info: userAgent,
        platform: platform,
        language: language,
        device_type: deviceType,
        country: country,
        city: city
    };
};



export const trackSession = async (module, duration) => {
    
    const userData = getUserData();
    const user = getStorage('__user__','object')

    if(user && user?.id){
        const data =  {
            user: user?.id,
            module: module,
            duration: duration,
            userInformation: userData,
        };


        try {

            TaskManagerRequest('/analytics/track',data);
            
        } catch (error) {
            
        }

    }
   
};
