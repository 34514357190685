import React, { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { getStorage, setStorage } from "../../services/localStorageService";
import moment from "moment";
import { useDispatch } from "react-redux";
import { toggleSidebar } from "../../store/actions/group.action";
import { ASSETS_URL } from "../../environments/environments";

const Header = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const serviceCenter = getStorage("__serviceCenter__", "object");

  useEffect(() => {
    // Check Auth
    if (!serviceCenter) handleLogout();
  }, []);

  // Logout

  const handleLogout = async () => {
    localStorage.removeItem("__serviceCenter__");
    history.push("/service-center/login");
  };

  // Header timer controller

  const [time, setTime] = useState(Date.now());

  useEffect(() => {
    dispatch(toggleSidebar());
    const interval = setInterval(() => setTime(Date.now()), 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <React.Fragment>
      <nav
        className="navbar navbar-main navbar-expand-lg py-0 pl-0"
        id="navbarBlur"
        navbar-scroll="true"
        style={{ height: "6vh" }}
      >
        <div className="sidenav-header mt-2">
          <i
            className="fas fa-times p-3 cursor-pointer text-secondary opacity-5 position-absolute right-0 top-0 d-none d-xl-none"
            aria-hidden="true"
            id="iconSidenav"
          />
          <a className="navbar-brand p-0">
            <span className="ms-1 font-weight-bold text-white logo-image">
              <img
                src={require("../../assets/images/logo.svg").default}
                height="40px"
                width="aut"
              />
            </span>
          </a>
        </div>
        <div className="container-fluid  px-3">
          <div
            className="collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4"
            id="navbar"
          >
            <div className="d-flex mb-2" style={{ alignItems: "center" }}>
              <span className="header-date">
                {moment(time).format("ddd, DD MMMM YYYY HH:mm:ss")}
              </span>
            </div>

            <div className="ms-md-auto pe-md-3 d-flex align-items-center">
              {" "}
            </div>

            <ul className="navbar-nav  justify-content-end mt-3">
              <li className="nav-item d-xl-none ps-3 d-flex align-items-center">
                <a className="nav-link p-0" id="iconNavbarSidenav">
                  <div className="sidenav-toggler-inner">
                    <i className="sidenav-toggler-line bg-dark" />
                    <i className="sidenav-toggler-line bg-dark" />
                    <i className="sidenav-toggler-line bg-dark" />
                  </div>
                </a>
              </li>

              <li className="nav-item px-3 d-flex align-items-center mb-2">
                <Dropdown>
                  <Dropdown.Toggle
                    id="dropdown-basic"
                    style={{
                      backgroundColor: "transparent",
                      boxShadow: "none",
                      textTransform: "none",
                    }}
                  >
                    English
                  </Dropdown.Toggle>

                  <Dropdown.Menu id="tri">
                    <Dropdown.Item href="#/action-1">English</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">Arabic</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </li>

              {serviceCenter && (
                <li className="nav-item d-flex align-items-center mb-2">
                  <Dropdown>
                    <Dropdown.Toggle
                      id="dropdown-setting"
                      style={{
                        backgroundColor: "transparent",
                        boxShadow: "none",
                      }}
                    >
                      <a className="nav-link font-weight-bold px-0 after-none">
                        <div>
                          <span className="d-sm-inline d-none text-normal">
                            {serviceCenter?.centerName}
                          </span>
                          <img
                            className="border-radius-lg shadow"
                            style={{
                              width: 45,
                              height: 45,
                              marginLeft: 5,
                              borderRadius: "50%",
                            }}
                            src={
                              require("../../assets/images/avatar-men.png")
                                .default
                            }
                          ></img>
                        </div>
                      </a>
                    </Dropdown.Toggle>

                    <Dropdown.Menu
                      id="tri-profile"
                      style={{
                        borderRadius: "15px",
                        inset: "26px auto auto 0px",
                      }}
                    >
                      <Dropdown.Item className="dropdown-item-profile">
                        <img
                          className="border-radius-lg shadow"
                          style={{
                            width: 80,
                            height: 80,
                            marginLeft: 5,
                            borderRadius: "50%",
                            border: "2px solid rgba(255, 255, 255, 0.8)",
                          }}
                          src={
                            require("../../assets/images/avatar-men.png")
                              .default
                          }
                        ></img>
                        <br />
                        <p className="text-black mt-3">
                          {serviceCenter?.centerName}
                        </p>
                      </Dropdown.Item>
                      <Dropdown.Item
                        className="profile-link border-vertical"
                        onClick={() => history.push("/service-center/profile")}
                      >
                        My Profile
                      </Dropdown.Item>
                      <Dropdown.Item
                        className="profile-link"
                        onClick={handleLogout}
                      >
                        Logout
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
              )}
            </ul>
          </div>
        </div>
      </nav>
    </React.Fragment>
  );
};

export default Header;
