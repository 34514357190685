import React from "react";
import { getTaskColor } from "../../../configs/common";
import { useSelector } from "react-redux";

const styles = {
  lbl: {
    margin: "10px",
    position: "relative",
    color: "#3348f5",
    fontWeight: "bold",
    fontSize: 11,
    top: -1.5,
  },
};

const StatusButton = ({
  status,
  statusList = [],
  toggleChangeStatusModal = () => {},
}) => {
  return (
    <React.Fragment>
      <span
        style={{
          ...styles.lbl,
          color: getTaskColor(
            statusList,
            status == "" ? "Not Assigned" : status
          ),
        }}
        onClick={toggleChangeStatusModal}
      >
        {status == "" ? "Not Assigned" : status}
      </span>
      {/* {status == 'Not Assigned' && <span style={styles.lbl} onClick={toggleChangeStatusModal}>Notassigned</span>}
            {status == 'Assigned' && <span style={styles.lbl} onClick={toggleChangeStatusModal}>Assigned</span>}
            {status == 'Accepted' && <span style={styles.lbl} onClick={toggleChangeStatusModal}>Accepted</span>}
            {status == 'Partially Completed' && <span style={styles.lbl} onClick={toggleChangeStatusModal}>Partially Completed</span>}
            {status == 'Completed' && <span style={styles.lbl} onClick={toggleChangeStatusModal}>Completed</span>}
            {status == 'Rejected' && <span style={styles.lbl} onClick={toggleChangeStatusModal}>Rejected</span>} */}
    </React.Fragment>

    // <React.Fragment>
    // {status == 'Not Assigned' && <button onClick={toggleChangeStatusModal} className="btn-notAssigned">Notassigned</button>}
    // {status == 'Assigned' && <button onClick={toggleChangeStatusModal} className="btn-assigned">Assigned</button>}
    // {status == 'Accepted' && <button onClick={toggleChangeStatusModal} className="btn-accepted">Accepted</button>}
    // {status == 'Partially Completed' && <button onClick={toggleChangeStatusModal} className="btn-partial">Partially Completed</button>}
    // {status == 'Completed' && <button onClick={toggleChangeStatusModal} className="btn-completed">Completed</button>}
    // {status == 'Rejected' && <button onClick={toggleChangeStatusModal} className="btn-rejected">Rejected</button>}
    // </React.Fragment>
  );
};

export default StatusButton;
