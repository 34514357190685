import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getOrganizationVehicles } from "../../../../store/actions/setting.action";
import Card from "../../../UI/Card";
// import AddEditVehicleTag from "./AddEditVehicleTag";
import "../OtherSettings.css";
import AddEditVehicle from "./AddEditVehicle";
import SearchInput from "../../../SearchInput";
import _ from "underscore";

const SpeedSettings = (props) => {
  const dispatch = useDispatch();

  const _vehicles = useSelector((state) => state.settingReducer.vehicles);

  const [vehicles, setVehicles] = useState([]);
  const [addModal, setAddModal] = useState(false);
  const [active, setActive] = useState(false);
  const [search, setSearch] = useState("");

  const toggleAddModal = () => setAddModal(!addModal);

  const onAddEditTag = (vehicle) => {
    setActive(vehicle);
    toggleAddModal();
  };

  const loadVehicles = async () => {
    await dispatch(getOrganizationVehicles());
  };

  const handleSearch = useCallback((event) => {
    var value = event.target.value;
    setSearch(value);
  });

  const clear = () => {
    setSearch("");
  };

  const __filter__ = useCallback(() => {
    if (_.isArray(_vehicles)) {
      var clone = [..._vehicles];
      try {
        if (search != "") {
          clone = _.filter(clone, (item) =>
            item.name.match(new RegExp(search, "gi"))
          );
        }
        return clone;
      } catch {
        return clone;
      }
    }
  });

  useEffect(() => {
    loadVehicles();
  }, []);

  useEffect(() => {
    setVehicles(__filter__());
  }, [_vehicles, search]);

  return (
    <>
      <AddEditVehicle
        show={addModal}
        type={props.type}
        toggleModal={toggleAddModal}
        vehicle={active}
      />

      <div className="d-flex p-2 mt-5">
        <Card style={{ boxShadow: "none" }}>
          <div className="d-flex justify-content-between">
            <p className="font-weight-bold text-dark my-auto">
              Vehicle Settings
            </p>
            <SearchInput
              iconStyle={{ right: 35 }}
              placeholder="Search Vehicle"
              onSearch={handleSearch}
              value={search}
              text={search}
              onClear={clear}
            />
          </div>

          <table className="w-100 other-setting-table mt-3">
            <tbody>
              <tr>
                <th className="p-1">#</th>
                <th className="p-1">Vehicle Name</th>
                <th className="p-1 text-center">Vehicle Type</th>
                <th className="p-1 text-center"> Max Speed</th>
                <th className="p-1 text-center"> Optimized Average Speed</th>
                <th className="p-1 text-center"> Daily Allowed KM </th>
                <th className="p-1 text-center">Action</th>
              </tr>
              {vehicles?.map((vehicle, i) => (
                <tr key={i}>
                  <td className="p-2">{i + 1}</td>
                  <td className="p-2"> {vehicle.name} </td>
                  <td className="p-2 text-center">
                    {vehicle.category ? vehicle.category : "-"}
                  </td>

                  <td className="p-2 text-center">
                    {vehicle.maxSpeed ? vehicle.maxSpeed : "-"}
                  </td>

                  <td className="p-2 text-center">
                    {vehicle.optimizedAverageSpeed
                      ? vehicle.optimizedAverageSpeed
                      : "-"}
                  </td>

                  <td className="p-2 text-center">
                    {vehicle.dailyAllowedKM ? vehicle.dailyAllowedKM : "-"}
                  </td>

                  <td className="p-2 text-center">
                    <span
                      className="text-primary pointer"
                      onClick={() => onAddEditTag(vehicle)}
                    >
                      Add/Edit
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Card>
      </div>
    </>
  );
};

export default SpeedSettings;
