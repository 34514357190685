// ***************** ACTIONS FOR IMMOBILIZER AND CAM ARE PLACED HERE ********************** //

import moment from "moment";
import TaskManagerRequest from "../../services/TaskManagerRequest";
import { setStorage } from "../../services/localStorageService";

export const SET_ACTIVE_IMMOBILIZE_VEHICLE = "SET_ACTIVE_IMMOBILIZE_VEHICLE";
export const REMOVE_ACTIVE_IMMOBILIZE_VEHICLE = "REMOVE_ACTIVE_IMMOBILIZE_VEHICLE"
export const UPDATE_ACTIVE_IMMOBILIZE_VEHICLE = "UPDATE_ACTIVE_IMMOBILIZE_VEHICLE";
export const SET_LOADING = "SET_LOADING";
export const SET_VSS_TOKEN = "SET_VSS_TOKEN";

export const setLoading = (value) => {
    return {
        type: SET_LOADING,
        value,
    }
}

export const setActiveVehicle = (id = '') => {

    return {
        type: SET_ACTIVE_IMMOBILIZE_VEHICLE,
        value: id
    }
}

export const removeActiveVehicle = () => {
    return {
        type: REMOVE_ACTIVE_IMMOBILIZE_VEHICLE,
    }
}

export const refreshVehicle = (uniqueId) => {
    return async (dispatch, getState) => {
        dispatch({
            type: SET_LOADING,
            value: true
        });

        try {
            const updatedVehicle = await TaskManagerRequest("/immobilizer/latestPosition", { imei: uniqueId });

            if (updatedVehicle && updatedVehicle.data) {
                const { activeVehicle } = getState().immobilizerReducer;

                // updating the active vehicle state according to the latestPosition API
                const { out2, ignition, date, time, speed, address } = updatedVehicle.data;

                const updatedActiveVehicle = {
                    ...activeVehicle,
                    position: {
                        ...activeVehicle?.position,
                        attributes: {
                            ...activeVehicle?.position?.attributes,
                            out2: out2 === "" ? null : out2,
                        },
                        ignition: ignition === "off" ? false : true,
                        deviceTime: moment(`${date} ${time}`, "DD/MM/YYYY hh:mm:ss A"),
                        speed: speed,
                        address: address
                    }
                };

                dispatch({
                    type: UPDATE_ACTIVE_IMMOBILIZE_VEHICLE,
                    value: updatedActiveVehicle
                });
            }
        } catch (error) {
            console.log("Error in refreshing vehicle: ", error);
        } finally {
            dispatch({
                type: SET_LOADING,
                value: false
            });
        }
    }
}

export const setVssToken = (token) => {
    if (token) return { type: SET_VSS_TOKEN, value: token }

    // login user if both local storage and redux vssToken value is empty
    return async (dispatch, getState) => {
        try {
            const loginResponse = await TaskManagerRequest("/cam/get-token", {});
            if (loginResponse && loginResponse?.token) {
                setStorage("vssToken", loginResponse.token);
                dispatch({
                    type: SET_VSS_TOKEN,
                    value: loginResponse.token
                });
            }
        } catch (error) {
            console.log("Error in fetching vss token", error?.message);
        }
    }
}

