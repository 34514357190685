export function calculateDays(date1 = Date.now(), date2 = Date.now()) {
  const oneDay = 24 * 60 * 60 * 1000;
  return Math.round((date1 - date2) / oneDay);
}

export function sortByDaysRemaining(a, b) {
  return calculateDays(
    a.inspectionDetails?.nextInspectionDate != undefined || null
      ? Date.parse(a.inspectionDetails?.nextInspectionDate)
      : Date.now()
  ) >
    calculateDays(
      b.inspectionDetails?.nextInspectionDate != undefined || null
        ? Date.parse(b.inspectionDetails?.nextInspectionDate)
        : Date.now()
    )
    ? 1
    : -1;
}

export const convertImageToFile = async (imageUrl, fileName = "image.jpg") => {
  try {
    const response = await fetch(imageUrl);
    const blob = await response.blob();
    const file = new File([blob], fileName, { type: blob.type });
    return file;
  } catch (error) {
    console.error("Error converting image to file:", error);
    throw error;
  }
};
