import { SET_OPTIMIZE_REQUEST_DATA } from "../actions/optimizer.action"

const initialState = {
    requestData: {},
}

const OptimizationReducer = (state = initialState, action) => {

    switch (action.type) {

        case SET_OPTIMIZE_REQUEST_DATA:
            return { ...state, requestData: action.value }

        default:
            return state

    }

}

export default OptimizationReducer