import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import { getStorage, setStorage } from "../services/localStorageService";

import "react-tabs/style/react-tabs.css";
import "./layout.css";
import useModuleTracker from "../hooks/Track/useModuleTracker";
import moment from "moment";

const Default = (props) => {
  const showSidebar = useSelector((state) => state.groupReducer.showSidebar);

  var date = moment().format("YYYY-MM-DD");
  const [isAlert, setIsAlert] = useState(false);

  // TRACK
  const moduleTimes = useModuleTracker();
  useEffect(() => {}, [moduleTimes]);

  useEffect(() => {
    const isShown = getStorage(`isAlertShown_${date}`);
    if (isShown != "true") {
      // FOR SHO, ENABLE ITS
      // setIsAlert(true);
    } else {
      setIsAlert(false);
    }
  }, []);

  return (
    <>
      {isAlert ? (
        <div
          class="alert alert-danger alert-dismissible fade show text-white"
          role="alert"
          style={{position: "absolute", zIndex: 1, width: "100%"}}
        >
          Lorem ipsum dolor sit amet consectetur adipisicing elit. A nesciunt
          accusamus maiores rerum
          <button
            type="button"
            class="close"
            data-dismiss="alert"
            aria-label="Close"
            onClick={() => {
              setIsAlert(false);
              setStorage(`isAlertShown_${date}`, "true");
            }}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      ) : (
        <></>
      )}

      <div className="as-main-container">
        <div className="as-header">
          <Header />
        </div>

        <div
          className={`as-body-conten ${
            !showSidebar ? "as-body-content-no-sidebar" : ""
          }`}
        >
          {/* SIDEBAR  */}
          {showSidebar && <Sidebar />}

          {/* MAIN  */}
          <div
            className={
              showSidebar
                ? "as-body-data"
                : "as-body-data w-100 no-right-border"
            }
          >
            {props.children}
          </div>
        </div>
      </div>
    </>

    // <section >
    //    <Header />
    //    {showSidebar && <Sidebar />}
    //    <main className={`main-content mt-1 border-radius-lg ${isReportMap ? 'move-to-left' : ''}`}>
    //       {/* move-to-left */}
    //       <div className={props.max100  == false  ? 'container-fluid container-fluid-app' : 'container-fluid container-fluid-app max100' }  >
    //          {props.children}
    //       </div>

    //    </main>
    // </section >
  );
};

export default Default;
