import React, { useState } from "react";
import OfficeTimeSettingsModal from "../../components/attendance/OfficeTimeSettingsModal";
import FuelRates from "../../components/settings/other-settings/FuelRates";
import SpeedSettings from "../../components/settings/other-settings/speedSettings/SpeedSettings";
import IconButton from "../../components/UI/IconButton";
import Settings from "./Settings";

const OtherSettings = () => {
  const [active, setActive] = useState("fuelRate");
  const [isOfficeTimeModal, setOfficeTimeModal] = useState(false);

  const toggleOfficeTimeModal = () => setOfficeTimeModal(!isOfficeTimeModal);

  var activeStyle = {
    backgroundColor: "#3160ee",
    color: "#fff",
    marginRight: "5px",
    paddingInline: 20,
    paddingBlock: 5,
  };

  var inActiveStyle = {
    marginRight: "5px",
    paddingInline: 20,
    paddingBlock: 5,
  };

  return (
    <Settings>
      <OfficeTimeSettingsModal
        show={isOfficeTimeModal}
        toggleModal={toggleOfficeTimeModal}
      />

      <div className="row mb-2">
        <div className="col-md-3">
          <p className="pt-3 text-black mb-2">Other Settings</p>
        </div>
        <div className="col-md-9 pt-3 text-right">
          <IconButton
            label="Fuel Rate"
            buttonstyle={active == "fuelRate" ? activeStyle : inActiveStyle}
            onClick={() => setActive("fuelRate")}
          />
          <IconButton
            label="Office Time Setting"
            buttonstyle={inActiveStyle}
            onClick={toggleOfficeTimeModal}
          />
          <IconButton
            label="Vehicle Settings"
            buttonstyle={
              active == "vehicleSetting" ? activeStyle : inActiveStyle
            }
            onClick={() => setActive("vehicleSetting")}
          />
        </div>
      </div>

      {active == "fuelRate" && <FuelRates />}
      {active == "vehicleSetting" && <SpeedSettings />}
    </Settings>
  );
};

export default OtherSettings;
