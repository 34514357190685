import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Input from "../../UI/Input";
import IconButton from "../../UI/IconButton";
import DragableMap from "../../UI/DragableMap";
import Select from "../../UI/Select";
import { Container } from "react-bootstrap";
import AddCustomerModal from "./AddCustomerModal";
import CalendarField from "../../UI/CalendarField";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  getTasks,
  getTaskAttributes,
  getTaskCheckSettings,
  getTaskDrivers,
  getDriverTaskStatusCount,
} from "../../../store/actions/taskManager.action";
import MakeAttribute from "./MakeAttributes/MakeAttribute";
import { ShowNotification } from "../../../services/Notifications";
import TimePicker from "../../UI/TimePicker";
import Autocomplete from "react-google-autocomplete";
import TaskManagerRequest from "../../../services/TaskManagerRequest";
import AddressField from "../../UI/AddressField";
import { getTaskSettings } from "../../../store/actions/setting.action";
import TaskStatusList from "../../taskManager/FieldsCard/TaskStatusList";
import _getCurrentLocation from "../../../configs/current-location";
import { FaMapMarkerAlt, FaRegKeyboard } from "react-icons/fa";
import { GOOGLE_MAPS_API_KEY } from "../../../configs/map.key";
import ReactTooltip from "react-tooltip";
import ChooseAddressFromMapModel from "../settings/task-manager/FielsStaffModal/ChooseAddressFromMapModel";
import StaticMap from "../../UI/StaticMap";
import { default as ReactSelect } from "react-select";
import { dubaiCoordinates, isValidMomentDate } from "../../../configs/common";
import CustomerSelect from "../../UI/CustomerSelect";
import { getStorage } from "../../../services/localStorageService";

const EditTaskModal = (props) => {
  const dispatch = useDispatch();
  const customers = useSelector((state) => state.taskReducer.customers);

  const activeDriver = useSelector((state) => state.taskReducer.activeDriver);
  const selectedDrivers = useSelector(
    (state) => state.taskReducer.selectedDrivers
  );
  const selectedGroups = useSelector(
    (state) => state.taskReducer.selectedGroups
  );
  const taskAttributes = useSelector(
    (state) => state.taskReducer.taskAttributes
  );
  const time = useSelector((state) => state.taskReducer.time);
  const taskDrivers = useSelector((state) => state.taskReducer.taskDrivers);
  const taskDriverGroups = useSelector(
    (state) => state.taskReducer.taskDriverGroups
  );
  const taskCheckSetting = useSelector(
    (state) => state.taskReducer.taskCheckSetting
  );

  const _taskSettings = useSelector(
    (state) => state.settingReducer.taskSettings
  );
  const taskHeaderSwitchOn = useSelector(
    (state) => state.taskReducer.taskHeaderSwitchOn
  );
  // const task = useSelector(state => state.taskReducer.activeTask)
  const [task, setTask] = useState(false);
  const [jobDescription, setJobDesctiption] = useState("");

  const [isModal, setIsModal] = useState(false);

  const [isAddress, setIsAddress] = useState(false);
  const toggleType = () => setIsAddress((prv) => !prv);

  const [date1, setDate1] = useState(Date.now());
  const [fromTime, setFromTime] = useState(Date.now());
  const [toTime, setToTime] = useState(Date.now());

  const [dropLocationPositionValue, setDropLocationPositionValue] =
    useState("");
  const [pickupLocationPositionValue, setPickupLocationPositionValue] =
    useState("");

  const [dropAddress, setDropAddress] = useState(
    props.task.dropAddress ? props.task.dropAddress?.name : ""
  );
  const [pickupAddress, setPickupAddress] = useState(
    props.task.pickupAddress ? props.task.pickupAddress?.name : ""
  );

  const [position, setPosition] = useState(dubaiCoordinates);
  const [dropPosition, setDropPosition] = useState(dubaiCoordinates);
  const [pickupPosition, setPickupPosition] = useState(dubaiCoordinates);

  const [startAddress, setStartAddress] = useState(
    props.task.startAddress ? props.task.startAddress : ""
  );
  const [endAddress, setEndAddress] = useState(
    props.task.endAddress ? props.task.endAddress : ""
  );

  const [startPosition, setStartPosition] = useState(dubaiCoordinates);
  const [endPosition, setEndPosition] = useState(dubaiCoordinates);

  const [isStartAddressModel, setStartAddressModel] = useState(false);
  const toggleStartAddressModel = () =>
    setStartAddressModel(!isStartAddressModel);

  const [isEndAddressModel, setEndAddressModel] = useState(false);
  const toggleEndAddressModel = () => setEndAddressModel(!isEndAddressModel);

  const [search, setSearch] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState(false);
  const [selectedDriver, setSelectedDriver] = useState(false);
  const [selectedDriverGroup, setSelectedDriverGroup] = useState(false);
  const [staff, setStaff] = useState("Driver");
  const [status, setStatus] = useState(task.taskStatus || "Not Assigned");

  const [allowFields, setAllowFields] = useState(false);
  const [taskSettings, setTaskSettings] = useState(_taskSettings);
  const [isLoading, setLoading] = useState(false);

  const toggleModal = (res) => {
    if (res) {
      setSelectedCustomer(res);
    }
    setIsModal(!isModal);
  };

  const tomorrow = new Date(new Date());
  tomorrow.setDate(tomorrow.getDate() + 1);

  const onSetPosition = (data) => {
    setPosition(data.position);
    setSearch(`${data.position.lat},${data.position.lng}`);
  };

  const onSetDropPosition = (data) => {
    setDropPosition(data.position);
    setDropAddress(data.address);
    setDropLocationPositionValue(`${data.position.lat},${data.position.lng}`);
  };

  const onSetPickupPosition = (data) => {
    setPickupPosition(data.position);
    setPickupAddress(data.address);
    setPickupLocationPositionValue(`${data.position.lat},${data.position.lng}`);
  };

  useEffect(() => {
    if (props.show == true) {
      // if (props.data) dispatch(getTask(props.data))
      if (props.task) {
        setTask(props.task);
        setJobDesctiption(props.task.description || "");
      }
      dispatch(getTaskCheckSettings());
      // dispatch(getTaskDriverGroups());
      dispatch(getTaskDrivers());
      dispatch(getTaskAttributes());
    }
  }, [props.show]);

  const loadTaskSettings = async () => {
    dispatch(getTaskSettings());
  };

  useEffect(() => {
    loadTaskSettings();
  }, []);

  useEffect(() => {
    if (!_taskSettings) return;
    setTaskSettings(_taskSettings);
    if (_taskSettings.allowFields) setAllowFields(_taskSettings.allowFields);
  }, [_taskSettings]);

  const _allow = (key) => {
    if (allowFields && (!allowFields[key] || allowFields[key] == undefined)) {
      return null;
    } else if (allowFields && allowFields[key] && allowFields[key] == 0) {
      return null;
    } else {
      return true;
    }
  };

  useEffect(() => {
    if (!task || !props.show) return;

    // set Driver
    if (task && task.driver && task.base == "Driver") {
      setSelectedDriver(
        typeof task.driver == "string" ? { _id: task.driver } : task.driver
      );
    }

    // select Time
    if (task.fromTime) setFromTime(moment(task.fromTime, "HH:mm"));
    if (task.toTime) setToTime(moment(task.toTime, "HH:mm"));

    // set Address
    if (task.startAddress) setStartAddress(task.startAddress);
    if (task.startLocation)
      setStartPosition({
        lat: parseFloat(task.startLocation.latitude),
        lng: parseFloat(task.startLocation.longitude),
      });

    if (task.endAddress) setEndAddress(task.endAddress);
    if (task.endLocation)
      setEndPosition({
        lat: parseFloat(task.endLocation.latitude),
        lng: parseFloat(task.endLocation.longitude),
      });

    if (task.dropLocation) {
      setDropAddress(task.dropLocation?.name);
      setDropPosition(task.dropLocation?.coordinates);
    }

    if (task.pickupLocation) {
      setPickupAddress(task.pickupLocation?.name);
      setPickupPosition(task.pickupLocation?.coordinates);
    }
    if (task.taskDate) setDate1(new Date(task.taskDate));
    if (task.taskStatus) {
      setStatus(task.taskStatus);
    }

    setStaff(task.base);

    if (task.coordinates) {
      setPosition({
        lat: parseFloat(task.coordinates.latitude),
        lng: parseFloat(task.coordinates.longitude),
      });
    }

    // find customer
    if (!customers || !task.customer) return;
    const index = customers.findIndex((c) => c._id == task.customer._id);

    if (index !== -1) {
      setSelectedCustomer(customers[index]);
    }
  }, [task, props.show, customers]);

  const onSelectCustomer = (option) => {
    setSelectedCustomer(option);
    setSelectedCustomer(option);
    if (option.location)
      setPosition({
        lat: parseFloat(option.location.latitude),
        lng: parseFloat(option.location.longitude),
      });
  };

  const onSelectDriver = (event) => {
    if (event.target.value == "") setStatus("Not Assigned");
    setSelectedDriver(false);
    setSelectedDriverGroup(false);
    const index = taskDrivers.findIndex((c) => c._id == event.target.value);
    if (index !== -1) {
      setSelectedDriver(taskDrivers[index]);
      setStatus("Assigned");
    }
  };

  const onSelectDriverGroup = (event) => {
    setSelectedDriver(false);
    setSelectedDriverGroup(false);
    const index = taskDriverGroups.findIndex(
      (c) => c._id == event.target.value
    );
    if (index !== -1) setSelectedDriverGroup(taskDriverGroups[index]);
  };

  const makeArray = (value) => {
    const cloneValue = value.toString().replaceAll("[", "");
    const parts = cloneValue.toString().split(",");
    return parts;
  };

  const onMapStartAddressChange = (ad) => {
    setStartAddress(ad.address);
    setStartPosition(ad.position);
  };

  const onMapEndAddressChange = (ad) => {
    setEndAddress(ad.address);
    setEndPosition(ad.position);
  };

  const handleSubmit = async (event) => {
    if (isLoading) return;
    let data = { ...task };
    try {
      setLoading(true);

      const formData = new FormData(event.target);
      event.preventDefault();

      for (let [key, value] of formData.entries()) {
        data[key] = value;
      }

      if (!selectedCustomer) {
        throw new Error(`Please Select Location`);
      }

      // if (taskCheckSetting && taskCheckSetting.check_time == 1) {
      //   data[`fromTime`] = moment(fromTime).format("HH:mm");
      //   data[`toTime`] = moment(toTime).format("HH:mm");
      // } else {
      //   data[`fromTime`] = moment(date1).format("HH:mm");
      //   data[`toTime`] = moment(date1).format("HH:mm");
      // }

      if (_allow("time")) {
        if (!isValidMomentDate(fromTime)) {
          throw new Error(`Please enter valid from time`);
        }
        if (!isValidMomentDate(toTime)) {
          throw new Error(`Please enter valid to time`);
        }
        data[`fromTime`] = moment(fromTime).format("HH:mm");
        data[`toTime`] = moment(toTime).format("HH:mm");
      }

      data["taskDate"] = `${moment(date1).format("yyyy-MM-DD 00:00:00")}`;
      data.deliveryAddress = data.endAddress;

      // MANAGE END ADDRESS

      if (_allow("endAddress")) {
        data.endLocation = {
          latitude: endPosition.lat,
          longitude: endPosition.lng,
        };
      } else {
        data.endLocation = { latitude: position.lat, longitude: position.lng };
      }

      if (_allow("startAddress")) {
        data.startLocation = {
          latitude: startPosition.lat,
          longitude: startPosition.lng,
        };
      } else {
        data.startLocation = {
          latitude: position.lat,
          longitude: position.lng,
        };
      }

      if (_allow("dropAndPickup")) {
        data.dropLocation = { name: dropAddress, coordinates: dropPosition };
        data.pickupLocation = {
          name: pickupAddress,
          coordinates: pickupPosition,
        };
      }

      // data.endLocation = { latitude: endPosition.lat, longitude: endPosition.lng };
      // data.startLocation = { latitude: startPosition.lat, longitude: startPosition.lng };

      data.coordinates = { latitude: position.lat, longitude: position.lng };
      data.customer = selectedCustomer._id;

      data["task"] = data._id;

      if (!data.group) data.group = "";
      if (data.taskStatus == "Not Assigned") data.driver = null;
      if (data.base == "Group") delete data.driver;
      if (data.base == "Driver") delete data.group;

      var attributes = task.attributes ? task.attributes : {};

      taskAttributes.map((attribute) => {
        if (attribute.type.toLowerCase() == "checkbox") {
          const _data = makeArray(attribute.value);
          _data.map((value, i) => {
            if (data[`attr_${attribute.key}_${value.trim()}`] !== undefined) {
              attributes[`${attribute.key}_${value.trim()}`] =
                data[`attr_${attribute.key}_${value.trim()}`];
            } else {
              attributes[`${attribute.key}_${value.trim()}`] = "off";
            }
            delete data[`attr_${attribute.key}_${value.trim()}`];
          });
        } else {
          if (
            data[`attr_${attribute.key}`] !== "" &&
            data[`attr_${attribute.key}`] !== undefined
          ) {
            attributes[attribute.key] = data[`attr_${attribute.key}`];
            delete data[`attr_${attribute.key}`];
          }
        }
      });

      data.attributes = attributes;
      data.EstimatedFromTime = "";
      data.EstimatedToTime = "";

      if (
        data.taskStatus == "Not Assigned" &&
        (selectedDriver || data.group != "")
      ) {
        throw new Error(
          `You have selected driver or group, but status is 'Not Assigned'. Please select status`
        );
      }
      if (
        data.taskStatus != "Not Assigned" &&
        !selectedDriver &&
        data.group == ""
      ) {
        throw new Error(
          `You have selected status '${data.taskStatus}' but driver or group is Not selected. Please select driver or group`
        );
      }
      // var _location = await _getCurrentLocation(false, true);
      // if (_location) data.location = _location;

      const user = await getStorage("__user__", "object");
      if (user) {
        data.authUserName = user.name;
      }

      // API call here

      const response = await TaskManagerRequest(
        "/update/driver/task",
        data,
        false,
        "updateTaskButton"
      );
      var _drivers = activeDriver ? [activeDriver._id] : selectedDrivers;

      dispatch(
        getTasks({
          startDate: `${moment(time.from).format("yyyy-MM-DD")} 00:00:00`,
          endDate: `${moment(time.to).format("yyyy-MM-DD")} 23:59:59`,
          driver: taskHeaderSwitchOn ? _drivers : null,
          group: taskHeaderSwitchOn ? selectedGroups : null,
          taskStatus:
            time.taskStatus == "All" || !taskHeaderSwitchOn
              ? false
              : time.taskStatus,
        })
      );
      dispatch(
        getDriverTaskStatusCount({
          startDate: `${moment(time.from).format("yyyy-MM-DD")} 00:00:00`,
          endDate: `${moment(time.to).format("yyyy-MM-DD")} 23:59:59`,
        })
      );
      ShowNotification(
        "success",
        props.isFromTimeline
          ? "Task edited successfully, Kindly optimize the tasks again to refresh the Timeline view"
          : "Task edited successfully"
      );
      if (props.onEditTask) props.onEditTask();
      props.toggleModal();
    } catch (error) {
      ShowNotification("error", error);
    } finally {
      setLoading(false);
    }
    props.toggleModal();
  };

  const handleGoogleSearch = (value, type = "position") => {
    var lat = value.geometry.location.lat();
    var lng = value.geometry.location.lng();

    switch (type) {
      case "position":
        setSearch(`${lat}, ${lng}`);
        break;
      case "drop":
        setDropLocationPositionValue(`${lat}, ${lng}`);
        break;
      case "pickup":
        setPickupLocationPositionValue(`${lat}, ${lng}`);
        break;
    }
  };

  const onSearchPosition = (type = "position") => {
    var parts = search.split(",");
    if (type == "drop") parts = dropLocationPositionValue.split(",");
    if (type == "pickup") parts = pickupLocationPositionValue.split(",");
    if (parts.length >= 2) {
      switch (type) {
        case "position":
          setPosition({
            lat: parseFloat(parts[0].trim()),
            lng: parseFloat(parts[1].trim()),
          });
          break;
        case "drop":
          setDropPosition({
            lat: parseFloat(parts[0].trim()),
            lng: parseFloat(parts[1].trim()),
          });
          break;
        case "pickup":
          setPickupPosition({
            lat: parseFloat(parts[0].trim()),
            lng: parseFloat(parts[1].trim()),
          });
          break;
      }
    }
  };

  // const onDurationChange = (event) => {
  //   setToTime(moment(fromTime).add(event.target.value, "minutes"));
  // };

  const onStatusChange = (e) => {
    setStatus(e.target.value);
    if (e.target.value == "Not Assigned") setSelectedDriver(false);
  };

  return (
    <>
      <AddCustomerModal
        show={isModal}
        toggleModal={(res) => toggleModal(res)}
      />

      <ChooseAddressFromMapModel
        onMapAddressChange={onMapStartAddressChange}
        position={startPosition}
        show={isStartAddressModel}
        toggleModal={toggleStartAddressModel}
      />

      <ChooseAddressFromMapModel
        onMapAddressChange={onMapEndAddressChange}
        position={endPosition}
        show={isEndAddressModel}
        toggleModal={toggleEndAddressModel}
      />

      <Modal
        show={props.show}
        dialogClassName="modal-60w"
        onHide={props.toggleModal}
        aria-labelledby="EditTaskModal"
      >
        <Modal.Header closeButton>Edit Task</Modal.Header>

        <form onSubmit={handleSubmit}>
          <Modal.Body>
            <Container
              style={{ height: "65vh", overflow: "auto" }}
              className="visible-scroll-hover"
            >
              <div className="row">
                <div className="col-12">
                  <fieldset>
                    <legend>
                      <span>Job Description</span>
                    </legend>
                    <textarea
                      name="description"
                      required={true}
                      rows="2"
                      value={jobDescription}
                      onChange={(event) =>
                        setJobDesctiption(event.target.value)
                      }
                      className="textarea"
                    ></textarea>
                  </fieldset>
                </div>

                {_allow("uniqueId") && (
                  <div className="col-12">
                    <Input
                      type="number"
                      name="uniqueId"
                      label="Unique Id"
                      value={task && task.uniqueId ? task.uniqueId : ""}
                    />
                  </div>
                )}

                {/* {_allow("jobId") && (
                  <div className="col-md-6">
                    <Input
                      type="number"
                      name="jobId"
                      label="Job Id"
                      value={task && task.jobId ? task.jobId : ""}
                    />
                  </div>
                )} */}

                <React.Fragment>
                  <div
                    className={!_allow("jobWaitingTime") ? "col-12" : "col-6"}
                  >
                    <div className="d-flex">
                      <div style={{ width: "70%" }}>
                        <CustomerSelect
                          className="mt-3 mb-2"
                          value={selectedCustomer}
                          onChange={onSelectCustomer}
                          name="customer"
                          placeholder="Select Location"
                          closeMenuOnSelect={true}
                        />
                      </div>

                      <div className="ms-auto">
                        <IconButton
                          label="Add Location"
                          onClick={() => toggleModal(false)}
                          className="pb-2 mt-3 mb-2 date-button"
                        />
                      </div>
                    </div>
                  </div>

                  {_allow("jobWaitingTime") && (
                    <div className="col-6">
                      <Input
                        label="Job Duration [Min]"
                        placeholder="In Minutes Ex : 5"
                        name="jobWaitingTime"
                        value={
                          task
                            ? task.jobWaitingTime
                            : taskSettings && taskSettings.defaultValues
                            ? taskSettings.defaultValues.jobsDefaultDuration
                            : ""
                        }
                        type="number"
                      />
                    </div>
                  )}

                  {selectedCustomer && (
                    <React.Fragment>
                      <div className="col-6">
                        <div className="task-outer">
                          <div>
                            <div className="d-flex">
                              <span className="task_label">Name</span>
                              <span className="task_text">
                                {selectedCustomer.name}
                              </span>
                            </div>
                            <div className="d-flex">
                              <span className="task_label">Email</span>
                              <span className="task_text">
                                {selectedCustomer.email}
                              </span>
                            </div>
                            <div className="d-flex">
                              <span className="task_label">Phone</span>
                              <span className="task_text">
                                {selectedCustomer.phoneNumber}
                              </span>
                            </div>
                            <div className="d-flex">
                              <span className="task_label">Address</span>
                              <span className="task_text">
                                {selectedCustomer.address}
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="d-flex mt-2">
                          {isAddress ? (
                            <>
                              <fieldset className="pb-0">
                                <legend>
                                  <span>Address</span>
                                </legend>
                                <Autocomplete
                                  apiKey={GOOGLE_MAPS_API_KEY.MAIN_KEY}
                                  onPlaceSelected={(place) =>
                                    handleGoogleSearch(place)
                                  }
                                  className="form-control p-0 font-12 box-shadow-none"
                                  placeholder="Search here..."
                                  options={{
                                    componentRestrictions: { country: "AE" },
                                    fields: [
                                      "address_components",
                                      "geometry",
                                      "icon",
                                      "name",
                                    ],
                                    strictBounds: false,
                                    types: ["establishment"],
                                  }}
                                  defaultValue=""
                                  onFocus={(event) => {
                                    event.target.setAttribute(
                                      "autocomplete",
                                      "off"
                                    );
                                  }}
                                />
                              </fieldset>
                            </>
                          ) : (
                            <Input
                              label="Coordinates"
                              placeholder="25.2048,55.2708"
                              value={search}
                              onChange={(value) => setSearch(value)}
                            />
                          )}

                          <button
                            type="button"
                            className="btn btn-search mb-0  mt-3 ml-2"
                            onClick={() => onSearchPosition()}
                          >
                            <i className="fa fa-search font-14"></i>
                          </button>

                          <button
                            type="button"
                            className="btn btn-theme mb-0  mt-3 ml-2"
                            onClick={() => setSearch("")}
                          >
                            <i className="fa fa-refresh font-13"></i>
                          </button>

                          <span
                            onClick={toggleType}
                            style={styles.toggleButton}
                            data-tip={
                              isAddress
                                ? "Search Co-ordinates"
                                : "Search Address"
                            }
                          >
                            {isAddress ? (
                              <FaMapMarkerAlt size={15} />
                            ) : (
                              <FaRegKeyboard size={15} />
                            )}
                          </span>
                        </div>
                      </div>

                      <div className="col-6">
                        <div
                          className="mt-2"
                          style={{ height: "220px", width: "100%" }}
                        >
                          <DragableMap
                            onDragChange={onSetPosition}
                            position={position}
                            allowUpdateOnChange={true}
                          />
                        </div>
                      </div>
                    </React.Fragment>
                  )}
                </React.Fragment>

                {_allow("phoneNumber") && (
                  <div className="col-6">
                    <div className="d-flex">
                      <div className="w-40 mr-3">
                        <Input
                          name="countryCode"
                          label="Country Code"
                          value={task ? task.countryCode : ""}
                        />
                      </div>

                      <div className="w-40 mr-3">
                        <Input
                          value={task ? task.phoneNumber : ""}
                          name="phoneNumber"
                          label="Phone (971xxxxxxxxx)"
                        />
                      </div>

                      <div className="ms-auto">
                        <button
                          type="button"
                          className="btn btn-search mb-0"
                          style={{ marginTop: 15, height: 40 }}
                        >
                          <i className="fa fa-search font-14"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                )}

                {_allow("taskDate") && (
                  <div className="col-6">
                    <div className="row">
                      <div className="col-md-7">
                        <CalendarField
                          label="Date"
                          selected={date1}
                          onChange={(date) => setDate1(date)}
                        />
                      </div>
                      <div className="col-md-5 d-flex p-1 pt-3 pb-2 float-right">
                        <button
                          type="button"
                          className="btn btn-theme date-button font-10 mb-0 mr-2"
                          onClick={() => setDate1(Date.now())}
                        >
                          Today
                        </button>
                        <button
                          type="button"
                          className="btn btn-theme font-10 mb-0"
                          onClick={() => setDate1(tomorrow)}
                        >
                          Tomorrow
                        </button>
                      </div>
                    </div>
                  </div>
                )}

                {_allow("time") && (
                  <div className="col-6">
                    <div className="row">
                      <div className="col-md-6 pr-0">
                        <TimePicker
                          value={fromTime}
                          label="From Time"
                          onChange={(date) => setFromTime(date)}
                        />
                      </div>
                      {/* <div className="col-md-4 pr-0" style={{ height: 45 }}>
                        <Select
                          title="Duration"
                          name="duration"
                          value={task ? task.duration : 15}
                          onChange={onDurationChange}
                        >
                          <option value="0">0 Minutes</option>
                          <option value="15">15 Minutes</option>
                          <option value="30">30 Minutes</option>
                          <option value="45">45 Minutes</option>
                          <option value="60">1 Hour</option>
                        </Select>
                      </div> */}
                      <div className="col-md-6">
                        <TimePicker
                          value={toTime}
                          label="To Time"
                          onChange={(date) => setToTime(date)}
                        />
                      </div>
                    </div>
                  </div>
                )}

                {_allow("priority") && (
                  <div className="col-6">
                    <Select
                      title="Priority"
                      name="priority"
                      value={task ? task.priority : ""}
                    >
                      <option value="normal">Normal</option>
                      <option value="critical">Urgent</option>
                    </Select>
                  </div>
                )}

                <div className="col-6">
                  <Select
                    title="Status"
                    name="taskStatus"
                    value={status}
                    onChange={onStatusChange}
                  >
                    <TaskStatusList
                      statusList={
                        taskSettings && taskSettings.taskStatus
                          ? taskSettings.taskStatus
                          : []
                      }
                    />
                  </Select>
                </div>

                {/* {_allow("group") && ( */}
                <div className="col-6">
                  <div className="mt-2 text-left">
                    <input
                      type="radio"
                      className="radio w-10 ml-2"
                      name="base"
                      value="Driver"
                      checked={staff == "Driver"}
                      onChange={() => {
                        setSelectedDriverGroup(false);
                        if (task && task.driver) {
                          setSelectedDriver(
                            typeof task.driver == "string"
                              ? { _id: task.driver }
                              : task.driver
                          );
                        }
                        setStaff("Driver");
                      }}
                    />
                    <span className="mt-1 ml-2 font-14">Field Staff</span>
                    {_allow("group") && (
                      <>
                        <input
                          type="radio"
                          className="radio w-10 ml-2"
                          name="base"
                          value="Group"
                          checked={staff == "Group"}
                          onChange={() => {
                            setSelectedDriver(false);
                            setStaff("Group");
                          }}
                        />
                        <span className="mt-1 ml-2 font-14">
                          Field Staff Group
                        </span>
                      </>
                    )}
                  </div>

                  {staff == "Driver" && (
                    <div className="row">
                      <div className="col-md-12">
                        <Select
                          title="Field Staff"
                          name="driver"
                          // value={task && task.driver ? task.driver._id : ''}
                          value={selectedDriver ? selectedDriver._id : ""}
                          onChange={onSelectDriver}
                        >
                          <option value=""></option>
                          {taskDrivers.map((driver, i) => (
                            <option key={i} value={driver._id}>
                              {driver.name}
                            </option>
                          ))}
                        </Select>
                      </div>
                    </div>
                  )}

                  {staff == "Group" && (
                    <Select
                      title="Field Staff Group"
                      name="group"
                      onChange={onSelectDriverGroup}
                    >
                      <option value=""></option>
                      {taskDriverGroups.map((group, i) => (
                        <option key={i} value={group._id}>
                          {group.name}
                        </option>
                      ))}
                    </Select>
                  )}
                </div>
                {/* )} */}

                {_allow("startAddress") && (
                  <div className="col-6">
                    {/* <AddressField
                      name="startAddress"
                      label="From Address"
                      value={startAddress}
                      position={startPosition}
                      setPosition={setStartPosition}
                      wantMap={true}
                      placeholder="Enter a Location"
                    /> */}
                    <div className="pointer" onClick={toggleStartAddressModel}>
                      <Input
                        disabled
                        label="From Address"
                        name="startAddress"
                        type="text"
                        value={startAddress}
                      />
                    </div>
                    <StaticMap
                      lat={startPosition.lat}
                      lng={startPosition.lng}
                      style={{
                        height: 200,
                        borderRadius: 5,
                        top: 10,
                        marginBottom: 10,
                      }}
                    />
                  </div>
                )}

                {_allow("endAddress") && (
                  <div className="col-6">
                    {/* <AddressField
                      name="endAddress"
                      label="To Address"
                      placeholder="Enter a Location"
                      value={endAddress}
                      position={endPosition}
                      setPosition={setEndPosition}
                      wantMap={true}
                    /> */}
                    <div className="pointer" onClick={toggleEndAddressModel}>
                      <Input
                        disabled
                        label="End Address"
                        name="endAddress"
                        type="text"
                        value={endAddress}
                      />
                    </div>
                    <StaticMap
                      lat={endPosition.lat}
                      lng={endPosition.lng}
                      style={{
                        height: 200,
                        borderRadius: 5,
                        top: 10,
                        marginBottom: 10,
                      }}
                    />
                  </div>
                )}

                {/* DROP & PICKUP */}

                {_allow("dropAndPickup") && (
                  <div className="row m-0">
                    <div className="col-6">
                      <div
                        className="mt-3"
                        style={{ height: "250px", width: "100%" }}
                      >
                        <p className="text-left"> Pickup Location </p>
                        <DragableMap
                          onDragChange={onSetPickupPosition}
                          position={pickupPosition}
                          allowUpdateOnChange={true}
                        />
                      </div>

                      <div className="d-flex mt-5">
                        {/* <Input
                          label="Coordinates"
                          placeholder="25.2048,55.2708"
                          value={search}
                          onChange={(value) => setSearch(value)}
                        /> */}
                        {isAddress ? (
                          <>
                            <fieldset className="pb-0">
                              <legend>
                                <span>Address</span>
                              </legend>
                              <Autocomplete
                                apiKey={GOOGLE_MAPS_API_KEY.MAIN_KEY}
                                onPlaceSelected={(place) =>
                                  handleGoogleSearch(place, "pickup")
                                }
                                className="form-control p-0 font-12 box-shadow-none"
                                placeholder="Search here..."
                                options={{
                                  componentRestrictions: { country: "AE" },
                                  fields: [
                                    "address_components",
                                    "geometry",
                                    "icon",
                                    "name",
                                  ],
                                  strictBounds: false,
                                  types: ["establishment"],
                                }}
                                defaultValue=""
                                onFocus={(event) => {
                                  event.target.setAttribute(
                                    "autocomplete",
                                    "off"
                                  );
                                }}
                              />
                            </fieldset>
                          </>
                        ) : (
                          <Input
                            label="Coordinates"
                            placeholder="25.2048,55.2708"
                            value={pickupLocationPositionValue}
                            onChange={(value) =>
                              setPickupLocationPositionValue(value)
                            }
                          />
                        )}
                        <button
                          type="button"
                          className="btn btn-search mb-0  mt-3 ml-2"
                          onClick={() => onSearchPosition("pickup")}
                        >
                          <i className="fa fa-search font-14"></i>
                        </button>
                        <button
                          type="button"
                          className="btn btn-theme mb-0  mt-3 ml-2"
                          onClick={() => {
                            setPickupLocationPositionValue("");
                            setIsAddress("");
                          }}
                        >
                          <i className="fa fa-refresh font-13"></i>
                        </button>
                        <span
                          onClick={toggleType}
                          style={styles.toggleButton}
                          data-tip={
                            isAddress ? "Search Co-ordinates" : "Search Address"
                          }
                        >
                          {isAddress ? (
                            <FaMapMarkerAlt size={15} />
                          ) : (
                            <FaRegKeyboard size={15} />
                          )}
                        </span>
                      </div>
                    </div>

                    <div className="col-6">
                      <div
                        className="mt-3"
                        style={{ height: "250px", width: "100%" }}
                      >
                        <p className="text-left"> Drop Location </p>
                        <DragableMap
                          onDragChange={onSetDropPosition}
                          position={dropPosition}
                          allowUpdateOnChange={true}
                        />
                      </div>

                      <div className="d-flex mt-5">
                        {isAddress ? (
                          <>
                            <fieldset className="pb-0">
                              <legend>
                                <span>Address</span>
                              </legend>
                              <Autocomplete
                                apiKey={GOOGLE_MAPS_API_KEY.MAIN_KEY}
                                onPlaceSelected={(place) =>
                                  handleGoogleSearch(place, "drop")
                                }
                                className="form-control p-0 font-12 box-shadow-none"
                                placeholder="Search here..."
                                options={{
                                  componentRestrictions: { country: "AE" },
                                  fields: [
                                    "address_components",
                                    "geometry",
                                    "icon",
                                    "name",
                                  ],
                                  strictBounds: false,
                                  types: ["establishment"],
                                }}
                                defaultValue=""
                                onFocus={(event) => {
                                  event.target.setAttribute(
                                    "autocomplete",
                                    "off"
                                  );
                                }}
                              />
                            </fieldset>
                          </>
                        ) : (
                          <Input
                            label="Coordinates"
                            placeholder="25.2048,55.2708"
                            value={dropLocationPositionValue}
                            onChange={(value) =>
                              setDropLocationPositionValue(value)
                            }
                          />
                        )}
                        <button
                          type="button"
                          className="btn btn-search mb-0  mt-3 ml-2"
                          onClick={() => onSearchPosition("drop")}
                        >
                          <i className="fa fa-search font-14"></i>
                        </button>
                        <button
                          type="button"
                          className="btn btn-theme mb-0  mt-3 ml-2"
                          onClick={() => {
                            setDropLocationPositionValue("");
                            setIsAddress("");
                          }}
                        >
                          <i className="fa fa-refresh font-13"></i>
                        </button>
                        <span
                          onClick={toggleType}
                          style={styles.toggleButton}
                          data-tip={
                            isAddress ? "Search Co-ordinates" : "Search Address"
                          }
                        >
                          {isAddress ? (
                            <FaMapMarkerAlt size={15} />
                          ) : (
                            <FaRegKeyboard size={15} />
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                )}

                {taskAttributes &&
                  taskAttributes.map((attribute, i) => {
                    return (
                      <div key={i} className="col-6">
                        <MakeAttribute
                          key={i}
                          attribute={attribute}
                          attributes={task.attributes}
                        />
                      </div>
                    );
                  })}
              </div>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              style={{ fontSize: "12px" }}
              className="btn btn-small"
              onClick={props.toggleModal}
            >
              Cancel
            </button>
            <button
              id="updateTaskButton"
              style={{
                fontSize: "12px",
                backgroundColor: "#007bff",
                color: "#ffffff",
              }}
              className="btn btn-small"
              disabled={isLoading}
            >
              Submit Task
            </button>
          </Modal.Footer>
        </form>

        {/* <form onSubmit={handleSubmit} >
                    <Modal.Body>
                        <Container style={{height: '65vh', overflow: 'auto'}}>

                            <div className="row">

                                <div className="col-md-6">

                                    {_allow('uniqueId') &&
                                        <div className="row">
                                            <div className="col-md-12">
                                                <Input name="uniqueId" value={task && task.uniqueId ? task.uniqueId : ''} label="Unique Id" />
                                            </div>
                                        </div>
                                    }

                                    {_allow('jobId') &&
                                        <div className="row">
                                            <div className="col-md-12">
                                                <Input name="jobId" value={task && task.jobId ? task.jobId : ''} label="Job Id" />
                                            </div>
                                        </div>
                                    }

                                    {_allow('customer') &&
                                        <div className="row">
                                            <div className="col-md-12">

                                                {taskCheckSetting && taskCheckSetting.check_customer == 1 &&
                                                    <div>
                                                        <div className="float-left" style={{ width: '70%' }}>
                                                            <Select title="Location" name="customer" value={task && task.customer ? task.customer._id : ''} onChange={onSelectCustomer} >
                                                                <option value=""></option>
                                                                {customersList &&
                                                                    customersList.map((customer) => <option key={customer._id} value={customer._id}>{customer.name}</option>)
                                                                }
                                                            </Select>
                                                        </div>
                                                        <div className="float-right">
                                                            <IconButton type="button" label="Add Location" onClick={toggleModal} className="pb-2 mt-3 date-button" />
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    }

                                    {_allow('phoneNumber') &&
                                        <div className="row">
                                            <div className="col-md-5 pr-0" >
                                                <Input name="countryCode" value={task ? task.countryCode : ''} label="Country Code" />
                                            </div>
                                            <div className="col-md-5 pr-0" >
                                                <Input name="phoneNumber" value={task ? task.phoneNumber : ''} label="Phone (971xxxxxxxxx)" />
                                            </div>
                                            <div className="col-md-2" >
                                                <button type="button" className="btn btn-search mb-0" style={{ marginTop: 15, height: 40 }}><i className="fa fa-search font-14"></i></button>
                                            </div>
                                        </div>
                                    }


                                    {_allow('startAddress') &&
                                        <div className="row">
                                            <div className="col-md-12">
                                                <AddressField name="startAddress" value={task ? task.startAddress : ''} label="From Address" placeholder="Enter a Location" />
                                            </div>
                                        </div>
                                    }

                                    {_allow('endAddress') &&
                                        <div className="row">
                                            <div className="col-md-12">
                                                <AddressField name="endAddress" label="To Address" placeholder="Enter a Location" value={address} />
                                            </div>
                                        </div>
                                    }

                                    {selectedCustomer &&
                                        <div className="row task-outer">
                                            <div className="col-md-12">
                                                <div className="d-flex">
                                                    <span className="task_label">Name</span>
                                                    <span className="task_text">{selectedCustomer.name}</span>
                                                </div>
                                                <div className="d-flex">
                                                    <span className="task_label">Email</span>
                                                    <span className="task_text">{selectedCustomer.email}</span>
                                                </div>
                                                <div className="d-flex">
                                                    <span className="task_label">Phone</span>
                                                    <span className="task_text">{selectedCustomer.phoneNumber}</span>
                                                </div>
                                                <div className="d-flex">
                                                    <span className="task_label">Address</span>
                                                    <span className="task_text">{selectedCustomer.address}</span>
                                                </div>
                                            </div>
                                        </div>

                                    }

                                </div>

                                <div className="col-md-6">

                                    <div className="row">
                                        <div className="col-md-12">
                                            <fieldset>
                                                <legend><span>Job</span></legend>
                                                <textarea name="description" defaultValue={task ? task.description : ''} rows="2" className="textarea"></textarea>
                                            </fieldset>
                                        </div>
                                    </div>


                                    {_allow('taskDate') &&
                                        <div className="row">
                                            <div className="col-md-7">
                                                <CalendarField label="date" selected={date1} onChange={(date) => setDate1(date)} />
                                            </div>
                                            <div className="col-md-5 d-flex p-1 pt-3 pb-2 float-right">
                                                <button type="button" className="btn btn-theme date-button font-10 mb-0 mr-2" onClick={() => setDate1(Date.now())} >Today</button>
                                                <button type="button" className="btn btn-theme font-10 mb-0" onClick={() => setDate1(tomorrow)} >Tomorrow</button>
                                            </div>
                                        </div>
                                    }

                                    {_allow('fromTime') &&
                                        <div className="row">
                                            <div className="col-md-4 pr-0">
                                                <TimePicker value={fromTime} label="From Time" onChange={(date) => setFromTime(date)} />
                                            </div>
                                            <div className="col-md-4 pr-0" style={{ height: 45 }}>
                                                <Select title="Duration" name="duration" value={task && task.duration} onChange={onDurationChange}>
                                                    <option value="0">0 Minutes</option>
                                                    <option value="15">15 Minutes</option>
                                                    <option value="30">30 Minutes</option>
                                                    <option value="45">45 Minutes</option>
                                                    <option value="60">1 Hour</option>
                                                </Select>
                                            </div>
                                            <div className="col-md-4">
                                                <TimePicker value={toTime} label="To Time" onChange={(date) => setToTime(date)} />
                                            </div>
                                        </div>
                                    }

                                    {_allow('priority') &&
                                        <div className="row">
                                            <div className="col-md-12">
                                                <Select title="Priority" name="priority" value={task ? task.priority : ''}>
                                                    <option value="normal">Normal</option>
                                                    <option value="critical">Urgent</option>
                                                </Select>
                                            </div>
                                        </div>
                                    }

                                    <div className="row">
                                        <div className="col-md-12">
                                            <Select title="Status" name="taskStatus" value={task ? task.taskStatus : ''}>
                                                {!selectedDriver && <option value="Not Assigned">Not Assigned</option>}
                                                <option value="Assigned">Assigned</option>
                                                <option value="Accepted">Accepted</option>
                                                <option value="Partially Completed">Partially Completed</option>
                                                <option value="Completed">Completed</option>
                                                <option value="Rejected">Rejected</option>
                                            </Select>
                                        </div>
                                    </div>

                                    {(_allow('driver') || _allow('driver')) &&
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="text-left">
                                                    <input type="radio" className="radio w-10 ml-2" name='base' value='Driver' checked={staff == 'Driver'} onChange={() => setStaff('Driver')} />
                                                    <span className="mt-1 ml-2 font-14">Field Staff</span>
                                                    <input type="radio" className="radio w-10 ml-2" name='base' value='Group' checked={staff == 'Group'} onChange={() => setStaff('Group')} />
                                                    <span className="mt-1 ml-2 font-14">Field Staff Group</span>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    {_allow('driver') && staff == 'Driver' &&
                                        <div className="row">
                                            <div className="col-md-12">
                                                <Select title="Field Staff" name="driver" value={task && task.driver ? task.driver._id : ''} onChange={onSelectDriver} >
                                                    <option value=""></option>
                                                    {
                                                        taskDrivers.map((driver) => <option key={driver._id} value={driver._id}>{driver.name}</option>)
                                                    }
                                                </Select>
                                            </div>
                                        </div>
                                    }

                                    {_allow('group') && staff == 'Group' &&
                                        <div className="row">
                                            <div className="col-md-12">
                                                <Select title="Field Staff Group" name="group" value={task ? task.group : ''} onChange={onSelectDriverGroup}>
                                                    <option value=""></option>
                                                    {
                                                        taskDriverGroups.map((group, i) => <option key={i} value={group._id}>{group.name}</option>)
                                                    }
                                                </Select>
                                            </div>
                                        </div>
                                    }


                                </div>

                            </div>

                            <div className="mt-3" style={{ height: '200px', width: '100%' }}>
                                <DragableMap onDragChange={onSetPosition} position={position} />
                            </div>

                            <div className="d-flex mt-2">
                                <Input label="Coordinates" placeholder="25.2048,55.2708" value={search} onChange={(value) => setSearch(value)} />
                                <button type="button" className="btn btn-search mb-0  mt-3 ml-2" onClick={() => onSearchPosition()}><i className="fa fa-search font-14"></i></button>
                                <button type="button" className="btn btn-theme mb-0  mt-3 ml-2" onClick={() => setSearch('')}><i className="fa fa-refresh font-13"></i></button>
                            </div>

                            <div className="row text-left">
                                {taskAttributes &&
                                    taskAttributes.map((attribute) => {
                                        return (
                                            <div className="col-md-6 mb-2">
                                                <MakeAttribute attribute={attribute} attributes={task.attributes} />
                                            </div>
                                        )
                                    })
                                }
                            </div>

                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" style={{ fontSize: '12px' }} className="btn btn-small" onClick={props.toggleModal}>Cancel</button>
                        <button style={{ fontSize: '12px', backgroundColor: '#007bff', color: '#ffffff' }} className="btn btn-small" >Submit Task</button>
                    </Modal.Footer>
                </form> */}
      </Modal>

      <ReactTooltip className="tooltip" />
    </>
  );
};

export default EditTaskModal;

const styles = {
  toggleButton: {
    marginBlock: "auto",
    marginTop: "1rem",
    backgroundColor: "#f2f2f2",
    borderRadius: 3,
    cursor: "pointer",
    padding: "8px 12px",
    marginLeft: 5,
  },
};
