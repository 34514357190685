import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch } from "react-redux";
import { getOrganizationVehicles } from "../../../../store/actions/setting.action";
import { ShowNotification } from "../../../../services/Notifications";
import TaskManagerRequest from "../../../../services/TaskManagerRequest";
import Input from "../../../UI/Input";

const AddEditVehicle = (props) => {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    const formData = new FormData(event.target);
    event.preventDefault();

    let data = {};

    for (let [key, value] of formData.entries()) {
      data[key] = value;
    }

    data.vehicleID = props.vehicle.vehicleID;

    setLoading(true);

    try {
      const res = await TaskManagerRequest("/admin/vehicle/edit", data);
      ShowNotification("success", "Changes saved successfully.");
      dispatch(getOrganizationVehicles());
      props.toggleModal();
    } catch (error) {
      ShowNotification("error", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      show={props.show}
      dialogClassName="modal-40w"
      onHide={props.toggleModal}
      aria-labelledby="AssignTaskModal"
    >
      <Modal.Header closeButton>Add/Edit {props.vehicle.name}</Modal.Header>
      <form onSubmit={handleSubmit}>
        <Modal.Body className="show-grid pb-4 pt-4">
          <div className="row">
            <div className="col-md-12 text-start mb-3">
              <p>Max Speed </p>
              <Input
                defaultValue={props.vehicle?.maxSpeed}
                type="number"
                options={{ min: 0, max: 200, step: 0.01 }}
                name="maxSpeed"
                className="mt-1"
              />
            </div>

            <div className="col-md-12 text-start mb-3">
              <p>Optimized Average Speed </p>
              <Input
                defaultValue={props.vehicle?.optimizedAverageSpeed}
                type="number"
                options={{ min: 0.1, max: 1, step: 0.01 }}
                name="optimizedAverageSpeed"
                className="mt-1"
              />
            </div>

            <div className="col-md-12 text-start mb-3">
              <p>Daily Allowed KM </p>
              <Input
                defaultValue={props.vehicle?.dailyAllowedKM}
                type="number"
                options={{ min: 0, step: 0.01 }}
                name="dailyAllowedKM"
                className="mt-1"
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            style={{ fontSize: "12px" }}
            className="btn btn-small"
            onClick={props.toggleModal}
          >
            Cancel
          </button>
          <button
            style={{
              fontSize: "12px",
              backgroundColor: "#007bff",
              color: "#ffffff",
            }}
            className="btn btn-small"
          >
            Save Changes
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default AddEditVehicle;
