
export const fileValidation = (name,type) => {

    var ext = name.split('.');
    ext = ext[ext.length - 1].toLowerCase();


    switch(type){

        case 'image':
            return (['png','jpg','jpeg','svg','gif'].indexOf(ext) == -1) ? ext : true
        default:
            return true;
    }


    return url.match('http') == null ? `${baseURL}${url}` : url;
}