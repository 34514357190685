import React from 'react'

const TaskStatusList = (props) => {

    return(
       <React.Fragment>
            {props.showAllLabel && <option value="All">All</option>}
            {props.statusList && props.statusList.map((status,i) => <option key={i} value={status.label}>{status.label}</option>)}
       </React.Fragment>
    )
}


export default TaskStatusList
