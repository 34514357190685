import React, { useState, useEffect, useRef } from "react";
import { Dropdown } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import {
  getStorage,
  logout,
  setStorage,
} from "../services/localStorageService";
import moment from "moment";
import { useDispatch } from "react-redux";
import { toggleSidebar } from "../store/actions/group.action";
import { setShowMap } from "../store/actions/report.action";
import { ASSETS_URL, TASK_ASSETS_URL } from "../environments/environments";
import EditProfileModal from "./modals/settings/EditProfileModal";
import TaskManagerRequest from "../services/TaskManagerRequest";
import "./header.css";
import { FaChevronDown } from "react-icons/fa";
import { ShowNotification } from "../services/Notifications";
import { useSelector } from "react-redux";
import Timer from "./Timer";
import Reminders from "./modals/fleet-manager/Reminders";
import {
  getOrganizationStorage,
  getOrganizationVehicles,
} from "../store/actions/setting.action";
import { formatBytes } from "../configs/common";
import { setUserForAnalytics } from "../configs/analytics";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

function useOutsideAlerter(ref, callback) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

const Header = () => {
  const dispatch = useDispatch();

  const organizationStorage = useSelector(
    (state) => state.settingReducer.organizationStorage
  );
  const _newNotificationCount = useSelector(
    (state) => state.groupReducer.newNotificationCount
  );

  const [remindersModal, setRemindersModal] = useState(false);

  const toggleRemindersModal = () => {
    setRemindersModal((prevState) => !prevState);
  };

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, () => setNotificationPopup(false));

  const history = useHistory();
  const [isNotificationPopup, setNotificationPopup] = useState(false);
  const [notifications, setNotifications] = useState([]);

  const toggleNotificationPopup = () =>
    setNotificationPopup(!isNotificationPopup);

  // change background
  const user = getStorage("__user__", "object");

  const onDeleteNotification = async (id) => {
    try {
      const res = await TaskManagerRequest("/event-notification/delete", {
        _id: id,
      });
      var clone = [...notifications];
      var exist = clone.findIndex((n) => n._id == id);
      if (exist != -1) clone.splice(exist, 1);
      setNotifications(clone);
    } catch (error) {
      ShowNotification("error", error);
    }
  };

  const loadEventNotifications = async (isLoadMore = false) => {
    try {
      const res = await TaskManagerRequest("/event-notifications", {
        skip: isLoadMore ? notifications.length : 0,
      });
      setNotifications(isLoadMore ? [...notifications, ...res] : res);
    } catch (error) {
      console.log("Error...", error);
    }
  };

  useEffect(() => {
    // Check Auth
    if (!user) {
      logout();
      history.push("/login");
    }
    setUserForAnalytics(user.user_email);
    dispatch(getOrganizationVehicles());
    dispatch(getOrganizationStorage());


  }, []);

  // Toggle Sidebar

  const toggle = () => {
    dispatch(toggleSidebar());
  };

  // Profile Modal

  const [isProfileModal, setIsProfileModal] = useState(false);

  const toggleProfileModal = () => setIsProfileModal(!isProfileModal);

  // Logout

  const handleLogout = () => {
    logout();
    history.push("/login");
  };

  const switchToOldVersion = () => {
    window.location.href = "https://mylocatorplus.com";
  };

  useEffect(() => {
    if (isNotificationPopup) loadEventNotifications();
  }, [isNotificationPopup]);

  // Handle Setting

  const settingHandler = () => {
    setStorage("setting-tab", "profile");
    history.push("/settings/profile");
  };

  const removeReportMapStyle = () => {
    dispatch(setShowMap(false));
  };

  const getImage = (type) => {
    switch (type) {
      case "Speed":
        return require("../assets/img/settings/not-speed.svg").default;
      case "Geozone":
        return require("../assets/img/settings/not-geozone.svg").default;
      case "Ignition":
        return require("../assets/img/settings/not-ignition-on.svg").default;
      default:
        return require("../assets/img/settings/not-idling.png").default;
    }
  };

  const getUsedStoragePer = () => {
    var usedStorage = 0;
    if (
      organizationStorage &&
      organizationStorage.totalSpace &&
      organizationStorage.usedSpace
    ) {
      usedStorage =
        (organizationStorage.usedSpace * 100) / organizationStorage.totalSpace;
    }
    return usedStorage.toFixed(2);
  };

  return (
    <React.Fragment>
      {user && (
        <EditProfileModal
          show={isProfileModal}
          toggleModal={toggleProfileModal}
        />
      )}

      <Reminders
        show={remindersModal}
        toggleModal={toggleRemindersModal}
        type="service"
      />

      <nav
        onClick={removeReportMapStyle}
        className="navbar navbar-main navbar-expand-lg py-0 pl-0"
        id="navbarBlur"
        navbar-scroll="true"
        style={{ height: "6vh" }}
      >
        <div className="sidenav-header mt-2">
          <i
            className="fas fa-times p-3 cursor-pointer text-secondary opacity-5 position-absolute right-0 top-0 d-none d-xl-none"
            aria-hidden="true"
            id="iconSidenav"
          />
          <a className="navbar-brand p-0">
            <span className="ms-1 font-weight-bold text-white logo-image">
              <img
                src={require("../assets/images/logo.svg").default}
                height="40px"
                width="auto"
              />
            </span>
          </a>
        </div>
        <div className="container-fluid  px-3">
          <div
            className="collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4"
            id="navbar"
          >
            <div className="d-flex mb-2" style={{ alignItems: "center" }}>
              <a id="toggle" className="menu-card" onClick={toggle}>
                <i className="fa fa-bars text-white"></i>
              </a>

              <Link className="header-menu-icon ml-5 " to="/tutorials">
                <img
                  src={
                    require("../assets/img/header_icons/youtube.png").default
                  }
                  width="20px"
                />
              </Link>
              <a className="header-menu-icon" onClick={toggleRemindersModal}>
                <img
                  src={require("../assets/img/header_icons/watch.png").default}
                  width="19px"
                />
              </a>
              <a className="header-menu-icon">
                {_newNotificationCount != 0 && (
                  <p className="notification"> {_newNotificationCount} </p>
                )}
                <img
                  onClick={toggleNotificationPopup}
                  src={require("../assets/img/header_icons/sound.png").default}
                  width="19px"
                />
                {isNotificationPopup && (
                  <div
                    ref={wrapperRef}
                    id="tri"
                    className={`notifications-container visible-scroll-hover ${notifications.length > 5 ? "pr-1" : ""
                      }`}
                  >
                    <div
                      className={`e-notifications-cards visible-scroll-hover `}
                    >
                      {notifications.map((item, i) => (
                        <div key={i} className="e-notification-card d-flex">
                          <img
                            src={getImage(item.type)}
                            className="mr-2 ml-1 my-auto"
                            height={
                              ["Idling", "Seat Belt"].includes(item.type)
                                ? 25
                                : 20
                            }
                          />
                          <div className="flex-1">
                            <div className="d-flex justify-content-between">
                              <p className="e-notification-title flex-1">
                                {item.title}
                              </p>
                              <p className="e-notification-date">
                                {moment(item.createdAt).format(
                                  "DD/MM/yyyy HH:mm"
                                )}
                              </p>
                            </div>
                            <div className="d-flex justify-content-between">
                              <p className="e-notification-body flex-1">
                                {item.body}
                              </p>
                              <div
                                className="delete-container pointer ml-2 mr-1 my-auto"
                                onClick={() => onDeleteNotification(item._id)}
                              ></div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="e-notification-downward mx-auto mt-2">
                      <FaChevronDown
                        onClick={() => loadEventNotifications(true)}
                        color="#fff"
                        size={14}
                      />
                    </div>
                  </div>
                )}
              </a>

              <Timer />
            </div>

            <div className="ms-md-auto pe-md-3 d-flex align-items-center">
              {" "}
            </div>

            <ul className="navbar-nav  justify-content-end mt-3">
              <li className="nav-item d-xl-none ps-3 d-flex align-items-center">
                <a className="nav-link p-0" id="iconNavbarSidenav">
                  <div className="sidenav-toggler-inner">
                    <i className="sidenav-toggler-line bg-dark" />
                    <i className="sidenav-toggler-line bg-dark" />
                    <i className="sidenav-toggler-line bg-dark" />
                  </div>
                </a>
              </li>

              {/* <li className="nav-item px-3 d-flex align-items-center mb-1">

                                <Dropdown>
                                    <Dropdown.Toggle id="dropdown-setting" style={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
                                        <i className="fa fa-cog fixed-plugin-button-nav cursor-pointer" aria-hidden="true" />
                                    </Dropdown.Toggle>

                                    
                                    
                                            <div className="d-flex mb-2" >
                                                <img src={require('../assets/img/header_icons/bg1.jpg').default} onClick={() => setBackground('url(https://mylocatorplus.com/web/assets/img/bg.jpg)')} className="theme-icon" />
                                                <img src={require('../assets/img/header_icons/bg2.jpg').default} onClick={() => setBackground('url(https://mylocatorplus.com/web/assets/img/bg2.jpg)')} className="theme-icon ml-2" />
                                                <img src={require('../assets/img/header_icons/bg3.png').default} onClick={() => setBackground('url(https://mylocatorplus.com/web/assets/img/bg3.png)')} className="theme-icon ml-2" />
                                                <img src={require('../assets/img/header_icons/bg4.png').default} onClick={() => setBackground('url(https://mylocatorplus.com/web/assets/img/giftly.png)')} className="theme-icon ml-2" />
                                                <img src={require('../assets/img/header_icons/bg5.jpg').default} onClick={() => setBackground('url(https://mylocatorplus.com/web/assets/img/bg5.jpg)')} className="theme-icon ml-2" />
                                                <img src={require('../assets/img/header_icons/bg6.jpg').default} onClick={() => setBackground('url(https://mylocatorplus.com/web/assets/img/bg.jpg)')} className="theme-icon ml-2" />
                                            </div>
                                            <div className="d-flex mb-2">
                                                <img src={require('../assets/img/header_icons/bg7.jpg').default} onClick={() => setBackground('url(https://mylocatorplus.com/web/assets/img/bg9.jpg)')} className="theme-icon" />
                                                <img src={require('../assets/img/header_icons/bg8.jpg').default} onClick={() => setBackground('url(https://mylocatorplus.com/web/assets/img/bg8.jpg)')} className="theme-icon ml-2" />
                                                <img src={require('../assets/img/header_icons/bg9.jpg').default} onClick={() => setBackground('url(https://mylocatorplus.com/web/assets/img/bg10.jpg)')} className="theme-icon ml-2" />
                                                <img src={require('../assets/img/header_icons/bg10.jpg').default} onClick={() => setBackground('url(https://mylocatorplus.com/web/assets/img/bg11.jpg)')} className="theme-icon ml-2" />
                                                <img src={require('../assets/img/header_icons/bg11.jpg').default} onClick={() => setBackground('url(https://mylocatorplus.com/web/assets/img/bg12.jpg)')} className="theme-icon ml-2" />
                                                <img src={require('../assets/img/header_icons/bg12.jpg').default} onClick={() => setBackground('url(https://mylocatorplus.com/web/assets/img/bg13.jpg)')} className="theme-icon ml-2" />
                                            </div>
                                            <div className="d-flex" >
                                                <img src={require('../assets/themes/BlueAnimation.svg').default} onClick={() => setBackground(`url(${require('../assets/themes/BlueAnimation.svg').default})`)} className="theme-icon" />
                                                <img src={require('../assets/themes/LeafeAimation.svg').default} onClick={() => setBackground(`url(${require('../assets/themes/LeafeAimation.svg').default})`)} className="theme-icon ml-2" />
                                                <img src={require('../assets/themes/YellowAnimation.svg').default} onClick={() => setBackground(`url(${require('../assets/themes/YellowAnimation.svg').default})`)} className="theme-icon ml-2" />
                                            </div>
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </li> */}

              <li className="nav-item px-3 d-flex align-items-center mb-2">
                <Dropdown>
                  <Dropdown.Toggle
                    id="dropdown-basic"
                    style={{
                      backgroundColor: "transparent",
                      boxShadow: "none",
                      textTransform: "none",
                    }}
                  >
                    English
                  </Dropdown.Toggle>

                  <Dropdown.Menu id="tri">
                    <Dropdown.Item>English</Dropdown.Item>
                    <Dropdown.Item>Arabic</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </li>

              {user && (
                <li className="nav-item d-flex align-items-center mb-2">
                  <Dropdown>
                    <Dropdown.Toggle
                      id="dropdown-setting"
                      style={{
                        backgroundColor: "transparent",
                        boxShadow: "none",
                      }}
                    >
                      <a
                        className="nav-link font-weight-bold px-0 after-none"
                        onClick={() => dispatch(getOrganizationStorage())}
                      >
                        <div>
                          <span className="d-sm-inline d-none text-normal">
                            {user ? user.name : ""}
                          </span>
                          <img
                            className="border-radius-lg shadow"
                            style={{
                              width: 45,
                              height: 45,
                              marginLeft: 5,
                              borderRadius: "50%",
                            }}
                            src={
                              user.photo !== null
                                ? `${TASK_ASSETS_URL}/images/${user.photo}`
                                : require("../assets/images/avatar-men.png")
                                  .default
                            }
                          ></img>
                        </div>
                      </a>
                    </Dropdown.Toggle>

                    <Dropdown.Menu
                      id="tri-profile"
                      style={{
                        borderRadius: "15px",
                        inset: "26px auto auto 0px",
                        zIndex: 9999,
                      }}
                    >
                      <Dropdown.Item className="dropdown-item-profile">
                        {user.photo == null && (
                          <img
                            className="border-radius-lg shadow"
                            style={{
                              width: 80,
                              height: 80,
                              marginLeft: 5,
                              borderRadius: "50%",
                              border: "2px solid rgba(255, 255, 255, 0.8)",
                            }}
                            src={
                              require("../assets/images/avatar-men.png").default
                            }
                          ></img>
                        )}
                        {user.photo !== null && (
                          <img
                            className="border-radius-lg shadow"
                            style={{
                              width: 80,
                              height: 80,
                              marginLeft: 5,
                              borderRadius: "50%",
                              border: "2px solid rgba(255, 255, 255, 0.8)",
                            }}
                            src={`${TASK_ASSETS_URL}/images/${user.photo}`}
                          ></img>
                        )}
                        <br />
                        <p className="text-black mt-3">
                          {user ? user.name : ""}
                        </p>
                      </Dropdown.Item>
                      <Dropdown.Item
                        className="profile-link border-bottom"
                        onClick={toggleProfileModal}
                      >
                        My Profile
                      </Dropdown.Item>
                      <Dropdown.Item
                        className="profile-link border-bottom"
                        onClick={switchToOldVersion}
                      >
                        Switch to Old Version
                      </Dropdown.Item>
                      {/*  */}
                      {user?.administrator == true ||
                        user?.administrator == 1 ||
                        user?.readonly == false ? (
                        <Dropdown.Item
                          className="profile-link border-bottom"
                          onClick={settingHandler}
                        >
                          Settings
                        </Dropdown.Item>
                      ) : (
                        <></>
                      )}

                      <Dropdown.Item
                        className="profile-link border-bottom"
                        onClick={() => {
                          setStorage("setting-tab", "storage-summary");
                          history.push("/settings/storage-summary");
                        }}
                      >
                        Storage{" "}
                        <span style={{ fontSize: 10 }}>
                          (
                          <span
                            className={`${getUsedStoragePer() > 80
                                ? "text-danger"
                                : "text-success"
                              }`}
                          >
                            {formatBytes(
                              organizationStorage.usedSpace * 1000 || 0
                            )}
                          </span>{" "}
                          used of{" "}
                          {formatBytes(
                            organizationStorage.totalSpace * 1000 || 0
                          )}{" "}
                          ({getUsedStoragePer()}%))
                        </span>
                      </Dropdown.Item>
                      <Dropdown.Item
                        className="profile-link"
                        onClick={handleLogout}
                      >
                        Logout
                      </Dropdown.Item>
                      <Dropdown.Item className="mt-5 profile-link text-black">
                        Customer Care
                      </Dropdown.Item>
                      <Dropdown.Item className="text-center mb-2">
                        <a
                          href="mailto:info@locator.ae"
                          title="info@locator.ae"
                        >
                          <img
                            className="border-radius-lg shadow"
                            style={{
                              width: 30,
                              height: 30,
                              borderRadius: "50%",
                              border: "2px solid rgba(255, 255, 255, 0.8)",
                            }}
                            src={
                              require("../assets/img/header_icons/email.svg")
                                .default
                            }
                          ></img>
                        </a>
                        <img
                          onClick={() =>
                            window.open(
                              `https://api.whatsapp.com/send?phone=971502250286`
                            )
                          }
                          className="border-radius-lg shadow"
                          style={{
                            width: 30,
                            height: 30,
                            borderRadius: "50%",
                            border: "2px solid rgba(255, 255, 255, 0.8)",
                          }}
                          src={
                            require("../assets/img/header_icons/whatsapp.svg")
                              .default
                          }
                        ></img>
                      </Dropdown.Item>
                      {/* <Dropdown variant="primary" drop="end" autoClose="outside">
                                                <Dropdown.Toggle>Test</Dropdown.Toggle> <Dropdown.Menu>
                                                    <Dropdown.Item>Item 3.1</Dropdown.Item>
                                                    <Dropdown.Item>Item 3.2</Dropdown.Item>
                                                    <Dropdown.Item>Item 3.3</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown> */}
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
              )}
            </ul>
          </div>
        </div>
      </nav>
    </React.Fragment>
  );
};

export default React.memo(Header);
