import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const TooltipBox = (props) => {
  const { placement = "top", tip = "", id = Math.random(999999999) } = props;

  return (
    <OverlayTrigger
      placement={placement}
      overlay={<Tooltip id={`tooltip-${id}`}>{tip}</Tooltip>}
    >
      {props.children}
    </OverlayTrigger>
  );
};

export default React.memo(TooltipBox);
