import { sortByDaysRemaining } from "../../components/Inspection/util";
import {
  GET_INSPECTION_RECORDS,
  SET_ACTIVE_VEHICLE,
  SET_VEHICLES,
  TOGGLE_LOADING,
  SET_RECORD_INDEX,
  TOGGLE_RECORDS_LOADING,
  FILTER_VEHICLES,
  ADD_INSPECTION_RECORD,
  UPDATE_INSPECTION_DETAILS,
  SORT_VEHICLES_BY_DAYS_REMAINING,
  TOGGLE_SHOW_SORTED_VEHICLES,
} from "../actions/inspection.action";

const initialState = {
  activeVehicle: null,
  isLoading: false,
  isLoadingRecords: false,
  inspectionRecords: {},
  inspectionVehicles: [],
  sortedVehicles: [],
  showSortedVehicles: false,
  recordIndex: null,
  activeFilter: "all",
};

const InspectionReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_LOADING:
      return { ...state, isLoading: action.value };
    // return { ...state, isLoading: action.value };
    case GET_INSPECTION_RECORDS:
      return {
        ...state,
        inspectionRecords: {
          ...state.inspectionRecords,
          [action.vehicle]: action.value,
        },
      };
    case SET_ACTIVE_VEHICLE:
      return {
        ...state,
        activeVehicle: action.value,
      };
    case SET_VEHICLES:
      return {
        ...state,
        inspectionVehicles: action.value,
      };
    case FILTER_VEHICLES:
      return { ...state, activeFilter: action.value };

    case TOGGLE_RECORDS_LOADING:
      return { ...state, isLoadingRecords: action.value };
    case UPDATE_INSPECTION_DETAILS:
      let activeVehicle;
      let inspectionVehiclesClone = state.inspectionVehicles.map((group) => ({
        ...group,
        vehicles: group.vehicles.map((vehicle) => {
          if (vehicle.id === action.vehicleId) {
            activeVehicle = {
              ...vehicle,
              inspectionDetails: action.inspectionDetails,
            };
            return activeVehicle;
          } else return vehicle;
        }),
      }));
      return {
        ...state,
        activeVehicle: activeVehicle,
        inspectionVehicles: inspectionVehiclesClone,
      };
    case SET_RECORD_INDEX:
      return {
        ...state,
        recordIndex: action.value,
      };
    case ADD_INSPECTION_RECORD:
      let records = [];
      records = [...state.inspectionRecords[action.vehicleId]];
      return {
        ...state,
        inspectionRecords: {
          ...state.inspectionRecords,
          [action.vehicleId]: records,
        },
      };
    case TOGGLE_SHOW_SORTED_VEHICLES:
      return {
        ...state,
        showSortedVehicles: action.value,
      };
    case SORT_VEHICLES_BY_DAYS_REMAINING:
      let sortedArrays = state.inspectionVehicles.map((group) => ({
        ...group,
        vehicles: [...group.vehicles].sort(sortByDaysRemaining),
      }));
      return {
        ...state,
        sortedVehicles: sortedArrays,
      };
    default:
      return state;
  }
};

export default InspectionReducer;
