import React, { useCallback, useEffect, useRef, useState } from 'react';
import { GOOGLE_MAPS_API_KEY } from '../../configs/map.key';
import { dubaiCoordinates } from '../../configs/common';


const PickupAndDropMap = (props) => {


    const ref = useRef();
    const [map, setMap] = useState(null);


    const loadScript = useCallback(async () => {

        const onLoad = () => {
            var p = dubaiCoordinates
            if (props.pickup && props.pickup.coordinates) p = props.pickup.coordinates;
            var m = new window.google.maps.Map(ref.current, {
                center: p,
                zoom: 10,
                keyboardShortcuts: false,
                mapTypeControl: false
            });

            setMap(m);


        }

        if (!window.google) {
            const script = document.createElement(`script`)
            script.src =
                `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY.MAIN_KEY}`
            document.head.append(script)
            script.addEventListener(`load`, onLoad)
            return () => script.removeEventListener(`load`, onLoad)
        } else onLoad();


    }, [props.pickup, props.drop])


    useEffect(() => {
        loadScript();
    }, [props.pickup, props.drop]);


    useEffect(() => {

        if (!map) return


        if (props.pickup && props.pickup.coordinates) {
            var pickupIcon = {
                url: require('../../assets/img/new/marker-blue.png').default,
                scaledSize: new window.google.maps.Size(30, 40),
                origin: new window.google.maps.Point(0, 0),
            };

            var pickupMarker = new window.google.maps.Marker(
                {
                    position: props.pickup.coordinates,
                    icon: pickupIcon
                }
            );
            pickupMarker.setMap(map);
        }


        if (props.drop && props.drop.coordinates) {
            var dropIcon = {
                url: require('../../assets/img/new/marker.png').default,
                scaledSize: new window.google.maps.Size(40, 40),
                origin: new window.google.maps.Point(0, 0),
            };

            var dropMarker = new window.google.maps.Marker(
                {
                    position: props.drop.coordinates,
                    icon: dropIcon
                }
            );
            dropMarker.setMap(map);
        }


    }, [map])

    return (
        <div
            ref={ref}
            style={{ height: `100%`, borderTopRightRadius: '5px', borderBottomRightRadius: '5px' }}
        />
    )
}

export default PickupAndDropMap