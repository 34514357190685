import React from "react";
import Modal from "react-bootstrap/Modal";
import Select from "../../UI/Select";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { editTask, getTasks } from "../../../store/actions/taskManager.action";
import { ShowNotification } from "../../../services/Notifications";
import TaskManagerRequest from "../../../services/TaskManagerRequest";
import moment from "moment";
import _getCurrentLocation from "../../../configs/current-location";
import { getStorage } from "../../../services/localStorageService";

const ChangeStatusModal = (props) => {
  const dispatch = useDispatch();
  const activeDriver = useSelector((state) => state.taskReducer.activeDriver);
  const selectedDrivers = useSelector(
    (state) => state.taskReducer.selectedDrivers
  );
  const selectedGroups = useSelector(
    (state) => state.taskReducer.selectedGroups
  );
  const taskHeaderSwitchOn = useSelector(
    (state) => state.taskReducer.taskHeaderSwitchOn
  );
  const time = useSelector((state) => state.taskReducer.time);

  const handleSubmit = async (event) => {
    const formData = new FormData(event.target);
    event.preventDefault();

    let data = { ...props.task };
    data.task = data._id;

    for (let [key, value] of formData.entries()) {
      data[key] = value;
    }

    // API call here
    try {
      var _location = await _getCurrentLocation(false, true);
      if (_location) data.location = _location;

      const user = await getStorage("__user__", "object");
      if (user) {
        data.authUserName = user.name;
      }

      const response = await TaskManagerRequest("/update/driver/task", data);
      var _drivers = activeDriver ? [activeDriver._id] : selectedDrivers;

      dispatch(
        getTasks({
          startDate: `${moment(time.from).format("yyyy-MM-DD")} 00:00:00`,
          endDate: `${moment(time.to).format("yyyy-MM-DD")} 23:59:59`,
          driver: taskHeaderSwitchOn ? _drivers : null,
          group: taskHeaderSwitchOn ? selectedGroups : null,
          taskStatus:
            time.taskStatus == "All" || !taskHeaderSwitchOn
              ? false
              : time.taskStatus,
        })
      );
      ShowNotification("success", "Status changed successfully");
    } catch (error) {
      ShowNotification("error", error);
    }

    props.toggleModal();
  };

  return (
    <>
      <Modal
        show={props.show}
        dialogClassName="modal-40w"
        onHide={props.toggleModal}
        aria-labelledby="ChangeStatusModal"
      >
        <Modal.Header closeButton>Change Status</Modal.Header>
        <form onSubmit={handleSubmit}>
          <Modal.Body className="show-grid pb-4 pt-4">
            <Container>
              <Select
                title="Status"
                name="taskStatus"
                value={props.task && props.task.task_status}
              >
                <option value="Assigned">Assigned</option>
                <option value="Accepted">Accepted</option>
                <option value="Partially Completed">Partially Completed</option>
                <option value="Completed">Completed</option>
                <option value="Rejected">Rejected</option>
              </Select>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              style={{ fontSize: "12px" }}
              className="btn btn-small"
              onClick={props.toggleModal}
            >
              Cancel
            </button>
            <button
              type="submit"
              style={{
                fontSize: "12px",
                backgroundColor: "#007bff",
                color: "#ffffff",
              }}
              className="btn btn-small"
            >
              Update
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default ChangeStatusModal;
