import React from "react";
import Modal from "react-bootstrap/Modal";
import Select from "../../UI/Select";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  getTasks,
  assignTasksToObject,
  getDriverTaskStatusCount,
} from "../../../store/actions/taskManager.action";
import { ShowNotification } from "../../../services/Notifications";
import moment from "moment";
import _getCurrentLocation from "../../../configs/current-location";

const AssignTaskModal = (props) => {
  const dispatch = useDispatch();
  const taskDrivers = useSelector((state) => state.taskReducer.taskDrivers);
  const time = useSelector((state) => state.taskReducer.time);
  const activeDriver = useSelector((state) => state.taskReducer.activeDriver);
  const selectedDrivers = useSelector(
    (state) => state.taskReducer.selectedDrivers
  );
  const selectedGroups = useSelector(
    (state) => state.taskReducer.selectedGroups
  );
  const taskHeaderSwitchOn = useSelector(
    (state) => state.taskReducer.taskHeaderSwitchOn
  );

  const handleSubmit = async (event) => {
    try {
      const formData = new FormData(event.target);
      event.preventDefault();

      let data = {};

      for (let [key, value] of formData.entries()) {
        data[key] = value;
      }

      if (data["driver"] === "") return;

      const _data = {
        driver: data["driver"],
        task: props.task,
      };

      var _location = await _getCurrentLocation(false, true);
      if (_location) data.location = _location;

      // API call here
      await dispatch(assignTasksToObject(_data));
      dispatch(
        getDriverTaskStatusCount({
          startDate: `${moment(time.from).format("yyyy-MM-DD")} 00:00:00`,
          endDate: `${moment(time.to).format("yyyy-MM-DD")} 23:59:59`,
        })
      );
      ShowNotification("success", "Task assigned successfully");

      var _drivers = activeDriver ? [activeDriver._id] : selectedDrivers;

      dispatch(
        getTasks({
          startDate: `${moment(time.from).format("yyyy-MM-DD")} 00:00:00`,
          endDate: `${moment(time.to).format("yyyy-MM-DD")} 23:59:59`,
          driver: taskHeaderSwitchOn ? _drivers : null,
          group: taskHeaderSwitchOn ? selectedGroups : null,
          taskStatus:
            time.taskStatus == "All" || !taskHeaderSwitchOn
              ? false
              : time.taskStatus,
        })
      );

      props.toggleModal();
    } catch (error) {
      ShowNotification("error", error);
    }
  };

  return (
    <>
      <Modal
        show={props.show}
        dialogClassName="modal-40w"
        onHide={props.toggleModal}
        aria-labelledby="AssignTaskModal"
      >
        <Modal.Header closeButton>Assign Task</Modal.Header>
        <form onSubmit={handleSubmit}>
          <Modal.Body className="show-grid pb-4 pt-4">
            <Container>
              <Select title="Field Staff" name="driver">
                <option value="">Select Field Staff</option>
                {taskDrivers.map((driver, i) => (
                  <option key={i} value={driver._id}>
                    {driver.name}
                  </option>
                ))}
              </Select>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              style={{ fontSize: "12px" }}
              className="btn btn-small"
              onClick={props.toggleModal}
            >
              Cancel
            </button>
            <button
              style={{
                fontSize: "12px",
                backgroundColor: "#007bff",
                color: "#ffffff",
              }}
              className="btn btn-small"
            >
              Assign Task
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default AssignTaskModal;
