import React, { useState, useEffect, useRef } from "react";
import Dropzone from "react-dropzone";
import Thumb from "./Thumb";
import { ShowNotification } from "../../services/Notifications";
import { getFileFromUrl } from "../../configs/common";

const dropzoneStyle = {
  width: "97%",
  minHeight: "90%",
  height: "auto",
  borderWidth: 2,
  borderColor: "rgb(204, 204, 204)",
  borderStyle: "dashed",
};

const DropZoneInput = (props) => {
  const [selected, setSelected] = useState([]);
  const dropZoneRef = useRef();

  const { multiple = false, maxFiles = 5 } = props;

  const handleOnRemoveFile = (index) => {
    var clone = [...selected];
    clone.splice(index, 1);
    if (!props.onRemoveSingleFile && !props.onRemove) setSelected(clone);
    if (multiple && props.onRemoveSingleFile) {
      props.onRemoveSingleFile(index);
    }
    if (props.onRemove) props.onRemove();
  };

  const loadFileFromUrl = async () => {
    try {
      if (props.file) {
        const files = multiple ? props.file : [props.file];
        var filesForDropZone = [];
        for (var i = 0; i < files.length; i++) {
          const f = files[i];
          if (f.name) {
            filesForDropZone.push(f);
          } else {
            const fl = await getFileFromUrl(f, f);
            filesForDropZone.push(fl);
          }
        }
        setSelected(filesForDropZone);
      }
    } catch (error) {}
  };

  useEffect(() => {
    loadFileFromUrl();
  }, [props.file]);

  return (
    <div
      style={{ ...dropzoneStyle, ...props.style }}
      onClick={() => dropZoneRef.current?.click()}
      className="d-flex justify-content-center align-items-center mt-2"
    >
      <Dropzone
        multiple={multiple}
        maxFiles={maxFiles}
        onDrop={(acceptedFiles) => {
          if (acceptedFiles.length === 0) {
            return;
          }
          var totalFiles = multiple
            ? [...selected, ...acceptedFiles]
            : [acceptedFiles[0]];

          if (multiple && totalFiles.length > 5) {
            ShowNotification("Error", "You can select maximum 5 files");
            return;
          }
          setSelected(totalFiles);
          props.onChange(multiple ? totalFiles : acceptedFiles[0]);
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <section>
            <div {...getRootProps()}>
              <input
                {...getInputProps()}
                accept={`${props.accept ? props.accept : null}`}
                ref={dropZoneRef}
              />
              {selected.length == 0 && (
                <p className="text-muted">CHOOSE FILES OR DROP HERE</p>
              )}
              <div className="d-flex justify-content-center">
                {selected.map((f, i) => {
                  return (
                    <div className="mx-2" key={i}>
                      <Thumb
                        onRemove={() => handleOnRemoveFile(i)}
                        file={selected[i]}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </section>
        )}
      </Dropzone>
    </div>
    // <fieldset style={{height: 'auto', ...props.style}}>
    //     <legend><span>{props.label}</span></legend>

    // </fieldset>
  );
};

export default DropZoneInput;
