import React from "react";
import Lottie from "react-lottie";
import { lottieDefaultSettings, MAIN_LOADER } from "../../configs/lottie";

const LottieLoader = () => {
  return (
    <Lottie
      options={{
        ...lottieDefaultSettings,
        animationData: MAIN_LOADER,
        loop: true,
        autoplay: true,
      }}
      width={"150px"}
      height={"150px"}
    />
  );
};

export default LottieLoader;
