import { LOAD_CHAT_MESSAGES } from "../actions/chat.action"


const initialState = {
    chats : []
}

const ChatReducer = (state = initialState, action) => {

    switch (action.type) {

        case LOAD_CHAT_MESSAGES:
            return { ...state, chats: action.value}

        default:
            return state
    }


}


export default ChatReducer;