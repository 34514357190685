import TaskManagerRequest from "../../services/TaskManagerRequest";


export const LOAD_CHAT_MESSAGES = 'LOAD_CHAT_MESSAGES'



export const loadChatMessages = ( data ) => {

    try {

        return async dispatch => {

            const messages = await TaskManagerRequest('/admin/chats',data);
            console.log('messages',messages)
            dispatch({
                type: LOAD_CHAT_MESSAGES,
                value: messages
            })

        };

    } catch (error) {
        console.log('Error : ', error)
        return false;
    }


   
}
