const IconButton = (props) => {
  return (
    <button
      {...props}
      className={props.className}
      style={{ ...styles.buttonContainer, ...props.buttonstyle }}
      onClick={props.onClick ? props.onClick : () => {}}
      disabled={props.isLoading == true}
      type={props.type || "button"}
    >
      {props.isLoading == true && (
        <i className="fa fa-spinner fa-spin mr-2"></i>
      )}
      {props.icon && props.isLoading != true && (
        <img src={props.icon} style={{ ...styles.icon, ...props.iconStyle }} />
      )}
      {props.label}
    </button>
  );
};

const styles = {
  buttonContainer: {
    border: "1px solid #dbe8fe",
    borderRadius: 5,
    color: "#326bf0",
    fontSize: "12px",
    padding: "3px 5px",
    cursor: "pointer",
  },
  icon: {
    paddingRight: "5px",
    height: 15,
    with: 15,
    resizeMode: "contain",
  },
};

export default IconButton;
