import {
  SET_GROUPS,
  SET_ACTIVE_ALERT,
  SELECT_ALL_GROUPS,
  GET_ZONES,
  GET_VEHICLE_DRIVERS,
  GET_SINGLE_OBJECT_DETAILS,
  GET_DRIVER_INFO,
  GET_ALL_SHARED_LOCATIONS,
  SET_POSITIONS,
  FILTER_VEHICLES,
  UPDATE_POSITION,
  TOGGLE_SIDEBAR,
  GET_DRIVERS,
  TOGGLE_IN_MAP,
  CHANGE_MAP_CENTER_ZOOM,
  SEARCH_VEHICLE,
  REFRESH_GROUPS,
  MAP_LOADED,
  CURRUNTLY_DISPLAYED_VEHICLES,
  TOGGLE_GROUP_MARKER,
  GET_DRIVER_GROUPS,
  GET_POI,
  IS_SOCKET_CONNECTED,
  GET_ALERTS,
  GET_CURRENT_TRACK,
  SORT_GROUPS,
} from "../actions/group.action";

import _ from "underscore";
import { VehicleService } from "../../services/vehicle.service";
import moment from "moment";
import { dubaiCoordinates } from "../../configs/common";

const initialState = {
  groups: [],
  cloneGroups: [],
  markers: [],
  positions: [],
  vehicles: [],
  vehicleToEdit: false,
  driverGroups: [],
  drivers: [],
  vehicleDrivers: {},
  driverInfo: false,
  updatedVehicles: [],
  curruntlyDisplaedVehicles: [],
  isMapRead: false,
  isSocketConnected: false,
  activeFilter: "all",
  counts: {
    moving: 0,
    parking: 0,
    idling: 0,
    undefined: 0,
    total: 0,
  },
  showSidebar: true,
  mapZoomAndCenter: {
    zoom: 3,
    center: dubaiCoordinates,
  },
  mapLiveZoomAndCenter: {
    zoom: 13,
    center: {
      lat: 53.338741,
      lng: -6.261563,
    },
  },
  pois: [],
  zones: [],
  alerts: [],
  activeAlert: false,
  sharedLocations: [],
  currentTrack: [],
  newNotificationCount: 0,
  vehicleTypes: {
    Car: 12,
    Van: 8,
    Bike: 36,
    "Small Pickup": 9,
    "Big Pickup": 7,
    Truck: 3,
    SUV: 6,
    "Small Bus": 7,
    "Big Bus": 3,
  },
};

const countVehicleState = (vhls) => {
  var moving = 0;
  var parking = 0;
  var idling = 0;
  var offline = 0;

  for (var i = 0; i < vhls.length; i++) {
    try {
      var e = vhls[i];

      if (!e.position) {
        offline++;
        continue;
      }

      let position = e.position;

      //set status of vehicle whose protocol is not 'osmand'
      if (position.protocol !== "osmand") {
        if (position.livestatus !== "Disconnected") {
          // For Online Vehicles
          if (
            (position.attributes.di1 == 1 &&
              position.attributes.motion == true &&
              position.attributes.ignition == true) ||
            position.attributes.motion == true ||
            (position.speed > 0 &&
              position.attributes.di1 != 0 &&
              position.attributes.motion != false)
          ) {
            moving++;
          } else if (
            position.attributes.di1 == 1 &&
            position.attributes.motion == false
          ) {
            idling++; //idling count
          } else if (
            position.attributes.di1 == 0 &&
            position.attributes.motion == false
          ) {
            parking++; //stopped count
          } else if (
            position.attributes.di1 === undefined &&
            position.attributes.motion == false &&
            position.attributes.ignition === undefined
          ) {
            parking++; //stopped count
          }
        } else {
          offline++;
        }
      }

      if (position.protocol == "osmand") {
        //'osmand' protocol vehicle

        try {
          var duration = moment.duration(
            moment().diff(moment(position.deviceTime))
          );
          var hours = parseInt(duration.asHours());

          if (hours !== 0) {
            moving--; //moving count
            offline++;
          }
        } catch (error) {
          moving++;
        }
      }
    } catch (error) {}
  }

  return {
    moving,
    parking: parking,
    idling,
    offline: offline,
    total: vhls.length,
  };
};

const getStatus = (position) => {
  var status = "offline";

  if (position.protocol !== "osmand") {
    if (position.livestatus !== "Disconnected") {
      if (
        (position.attributes.di1 === 1 &&
          position.attributes.motion === true &&
          position.attributes.ignition === true) ||
        position.attributes.motion === true ||
        (position.speed > 0 &&
          position.attributes.di1 !== 0 &&
          position.attributes.motion !== false)
      ) {
        status = "moving";
      } else if (
        (position.attributes.di1 === 1 ||
          (position.attributes.ignition &&
            position.attributes.ignition === true)) &&
        position.attributes.motion === false
      ) {
        status = "idling";
      } else if (
        (position.attributes.di1 === 0 ||
          (position.attributes.ignition &&
            position.attributes.ignition === false)) &&
        position.attributes.motion === false
      ) {
        status = "parking";
      } else if (
        !position.attributes.di1 &&
        position.attributes.motion === false &&
        !position.attributes.ignition
      ) {
        status = "parking";
      }
    } else {
      status = "offline";
    }
  }

  return status;
};

const __position_filtering = (position) => {
  if (!position.attributes) {
    position.attributes = {};
  }
  if (position.attributes.di1 === undefined) {
    position.attributes.di1 = 0;
    if (
      position.attributes.ignition !== undefined &&
      position.attributes.ignition == "true"
    ) {
      position.attributes.di1 = 1;
    }
  }

  var duration = moment.duration(moment().diff(moment(position.deviceTime)));
  var hours = parseInt(duration.asHours());
  var minutes = parseInt(duration.asMinutes()); //I dodn't know the conversion is correct

  if (hours > 10) {
    position.livestatus = "Disconnected";
  }

  if (position.attributes.status) {
    if (position.attributes.status == "0800") {
      position.attributes.di1 = 1;
      position.attributes.ignition = true;
    } else {
      position.attributes.ignition == false;
      position.attributes.di1 = 0;
    }
  }

  if (position.protocol == "gt06") {
    if (position.attributes.ignition == true) {
      position.attributes.di1 = 1;
      position.attributes.ignition = true;
    } else {
      position.attributes.ignition == false;
      position.attributes.di1 = 0;
    }
  }

  if (position.protocol == "ruptela") {
    if (position.attributes.ignition == true) {
      position.attributes.di1 = 1;
      position.attributes.ignition = true;
    } else {
      position.attributes.ignition == false;
      position.attributes.di1 = 0;
    }
  }

  if (position.protocol == "eelink") {
    if (position.attributes.ignition == true) {
      position.attributes.di1 = 1;
      position.attributes.ignition = true;
    } else {
      position.attributes.ignition == false;
      position.attributes.di1 = 0;
    }
  }

  position.attributes.state = getStatus(position);

  return position;
};

const sortGroupsByName = (groups, type) => {
  groups.map((group) => {
    if (type == "name") {
      group.vehicles = _.sortBy(group.vehicles, "name");
    } else {
      group.vehicles = _.sortBy(group.vehicles, "id");
    }

    var _v = {};
    group.vehicles.map((v) => {
      if (type == "name") {
        _v[v.id.toString()] = group.__vehicles__[v.id];
      } else {
        _v[v.id] = group.__vehicles__[v.id];
      }
    });
    group.__vehicles__ = _v;
  });

  return groups;
};

const GroupReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_GROUPS:
      var _v = [];

      var cloneAction = action.value;
      if (cloneAction) {
        // cloneAction = sortGroupsByName(cloneAction, action.sortBy);
        cloneAction.map((group) => _v.push(...group.vehicles));
      }

      return {
        ...state,
        groups: action.value,
        cloneGroups: action.value,
        // vehicles: _v
      };

    case SORT_GROUPS:
      var groupClone = [...state.groups];
      const sortedGroups = sortGroupsByName(groupClone, action.value);
      return {
        ...state,
        groups: sortedGroups,
      };

    case REFRESH_GROUPS:
      var gcn = [...state.cloneGroups];
      return { ...state, groups: gcn };

    case GET_DRIVERS:
      return { ...state, drivers: action.value };

    case GET_VEHICLE_DRIVERS:
      return { ...state, vehicleDrivers: action.value };

    case GET_DRIVER_GROUPS:
      return { ...state, driverGroups: action.value };

    case GET_DRIVER_INFO:
      return { ...state, driverInfo: action.value };

    case FILTER_VEHICLES:
      return { ...state, activeFilter: action.value };

    case TOGGLE_SIDEBAR:
      const value = !state.showSidebar;
      return { ...state, showSidebar: value };

    case IS_SOCKET_CONNECTED:
      return { ...state, isSocketConnected: action.value };

    case SET_POSITIONS:
      var groupClone = [...state.groups];
      var v = [];
      var cnt = [];

      for (
        let positionIndex = 0;
        positionIndex < action.value.length;
        positionIndex++
      ) {
        let position = action.value[positionIndex];

        position = __position_filtering(position);

        // Extract Groups
        for (let groupIndex = 0; groupIndex < groupClone.length; groupIndex++) {
          const group = groupClone[groupIndex];

          if (group.__vehicles__[position.deviceId]) {
            group.__vehicles__[position.deviceId].position = position;
            v.push(group.__vehicles__[position.deviceId]);
          }
        }
      }

      // COUNT
      for (let groupIndex = 0; groupIndex < groupClone.length; groupIndex++) {
        const group = groupClone[groupIndex];
        cnt = cnt.concat(Object.values(group.__vehicles__));
      }

      var counts = countVehicleState(cnt);

      return {
        ...state,
        markers: action.value,
        groups: groupClone,
        cloneGroups: groupClone,
        vehicles: v,
        counts: counts,
      };

    case UPDATE_POSITION:
      // if (!state.isMapRead) return state;

      const _groupClone = [...state.groups];
      var vhl = [];

      for (let index = 0; index < action.value.length; index++) {
        let position = action.value[index];

        position = __position_filtering(position);

        // Check in Groups
        for (let gIndex = 0; gIndex < _groupClone.length; gIndex++) {
          const gp = _groupClone[gIndex];

          if (gp.__vehicles__[position.deviceId]) {
            gp.__vehicles__[position.deviceId].position = position;
          }
        }
      }

      // COUNT
      for (let groupIndex = 0; groupIndex < _groupClone.length; groupIndex++) {
        const group = _groupClone[groupIndex];
        vhl = vhl.concat(Object.values(group.__vehicles__));
      }

      var counts = countVehicleState(vhl);

      // return state;

      return {
        ...state,
        updatedVehicles: action.value,
        counts: counts,
        groups: _groupClone,
        // vehicles : vhl
      };
    // return {...state,
    //     groups : _groupClone,
    //     cloneGroups : [..._groupClone],
    //     updatedVehicles : action.value,
    //     counts : counts,
    //     vehicles : vhl
    // }

    case TOGGLE_IN_MAP:
      const groupCloneForMap = JSON.parse(JSON.stringify([...state.groups]));
      var v = [];

      for (let index = 0; index < groupCloneForMap.length; index++) {
        const group = groupCloneForMap[index];
        var i = group.vehicleIds.indexOf(action.value.id);
        if (i !== -1) {
          group.vehicles[i].showInMap = !group.vehicles[i].showInMap;
          break;
        }
      }

      return {
        ...state,
        groups: groupCloneForMap,
      };

    case TOGGLE_GROUP_MARKER:
      const groupCloneForMapMarker = JSON.parse(
        JSON.stringify([...state.groups])
      );
      const groupIndex = groupCloneForMapMarker.findIndex(
        (g) => g.id === action.value.id
      );

      var vcls = Object.keys(groupCloneForMapMarker[groupIndex].__vehicles__);
      groupCloneForMapMarker[groupIndex].selected = action.value.status;

      var ids = [];

      for (let vIndex = 0; vIndex < vcls.length; vIndex++) {
        const vehicleKey = vcls[vIndex];
        groupCloneForMapMarker[groupIndex].__vehicles__[vehicleKey].showInMap =
          action.value.status;
        ids.push(groupCloneForMapMarker[groupIndex].__vehicles__[vehicleKey]);
      }

      VehicleService.updateToggleMarker(ids);

      return {
        ...state,
        groups: groupCloneForMapMarker,
      };

    case SELECT_ALL_GROUPS:
      const _groupCloneForMapMarker = JSON.parse(
        JSON.stringify([...state.groups])
      );

      var ids = [];

      for (let gIndex = 0; gIndex < _groupCloneForMapMarker.length; gIndex++) {
        var vcls = Object.keys(_groupCloneForMapMarker[gIndex].__vehicles__);
        _groupCloneForMapMarker[gIndex].selected = action.value;

        for (let vIndex = 0; vIndex < vcls.length; vIndex++) {
          const vehicleKey = vcls[vIndex];
          _groupCloneForMapMarker[gIndex].__vehicles__[vehicleKey].showInMap =
            action.value;
          ids.push(_groupCloneForMapMarker[gIndex].__vehicles__[vehicleKey]);
        }
      }

      VehicleService.updateToggleMarker(ids);

      return {
        ...state,
        groups: _groupCloneForMapMarker,
      };

    case CHANGE_MAP_CENTER_ZOOM:
      const cloneVehicles = [...state.vehicles];
      const centerIndex = cloneVehicles.findIndex(
        (p) => p.id === action.value.vehicleID
      );

      if (
        centerIndex !== -1 &&
        cloneVehicles[centerIndex] &&
        cloneVehicles[centerIndex].position
      ) {
        return {
          ...state,
          mapZoomAndCenter: {
            zoom: action.value.zoom,
            center: {
              lat: cloneVehicles[centerIndex].position.latitude,
              lng: cloneVehicles[centerIndex].position.longitude,
            },
          },
        };
      }

      return state;

    case CURRUNTLY_DISPLAYED_VEHICLES:
      return { ...state, curruntlyDisplaedVehicles: action.value };

    case MAP_LOADED:
      return { ...state, isMapRead: true };

    case GET_POI:
      return { ...state, pois: action.value };

    case GET_ZONES:
      return { ...state, zones: action.value };

    case GET_ALERTS:
      return { ...state, alerts: action.value };

    case SET_ACTIVE_ALERT:
      return { ...state, activeAlert: action.value };

    case GET_ALL_SHARED_LOCATIONS:
      return { ...state, sharedLocations: action.value };

    case GET_CURRENT_TRACK:
      return { ...state, currentTrack: action.value };

    case GET_SINGLE_OBJECT_DETAILS:
      return { ...state, vehicleToEdit: action.value };

    case SEARCH_VEHICLE:
      const __group = [...state.groups];

      __group.map((group) => {
        group.vehicles = group.vehicles.filter((v) => {
          if (v.name.match(action.value) !== null) {
            return v;
          }
        });
      });

      return {
        ...state,
        groups: __group,
      };

    default:
      return state;
  }
};

export default GroupReducer;
