import React, { useEffect, useCallback, useState, useRef } from "react";
import GoogleMapReact from "google-map-react";
import { GOOGLE_MAPS_API_KEY } from "../../configs/map.key";

const StaticMap = (props) => {
  if (!props.lat || !props.lng || props.lat == "" || props.lng == "") {
    return null;
  }

  const ref = useRef();
  const [map, setMap] = useState(null);

  const [activeMarker, setActiveMarker] = useState(null);

  const loadScript = useCallback(async () => {
    const onLoad = () => {
      var m = new window.google.maps.Map(ref.current, {
        center: {
          lat: +props.lat.toString().trim(),
          lng: +props.lng.toString().trim(),
        },
        zoom: 13,
        keyboardShortcuts: false,
      });

      setMap(m);
    };

    if (!window.google) {
      const script = document.createElement(`script`);
      script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY.MAIN_KEY}`;
      document.head.append(script);
      script.addEventListener(`load`, onLoad);
      return () => script.removeEventListener(`load`, onLoad);
    } else onLoad();
  }, []);

  useEffect(() => {
    loadScript();
  }, [props.lat, props.lng]);

  useEffect(() => {
    if (!map) return false;

    if (props.lat && props.lng) {
      var icon = {
        url: require("../../assets/img/new/marker.png").default,
        scaledSize: new window.google.maps.Size(40, 40), // scaled size
        origin: new window.google.maps.Point(0, 0), // origin
        // anchor: new window.google.maps.Point(50, 50), // anchor
      };

      var marker = new window.google.maps.Marker({
        position: {
          lat: +props.lat.toString().trim(),
          lng: +props.lng.toString().trim(),
        },
        icon: icon,
      });

      if (activeMarker) {
        activeMarker.setMap(null);
      }

      marker.setMap(map);
      map.setCenter({
        lat: +props.lat.toString().trim(),
        lng: +props.lng.toString().trim(),
      });
      setActiveMarker(marker);
    }
  }, [map, props.lat, props.lng]);

  return (
    <div
      ref={ref}
      style={{
        height: `100%`,
        width: `100%`,
        borderTopRightRadius: "5px",
        borderBottomRightRadius: "5px",
        ...props.style,
      }}
    />
  );
};

export default StaticMap;
