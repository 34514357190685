import React from "react";
import { getImageURL } from "../../configs/common";

const DriverCard = (props) => {
  return (
    <>
      {!props.driver && (
        <div className="d-flex">
          <img
            src={
              require("../../assets/img/task_manager_icons/user-group.svg")
                .default
            }
          />
          <p
            className="font-14 text-black my-auto ml-3"
            style={{ whiteSpace: "nowrap" }}
          >
            {props.placeholder}
          </p>
        </div>
      )}

      {props.driver && (
        <div className="row driver-info-card m-0">
          <div className="col-3 p-0 my-auto">
            {(!props.driver.profileImage ||
              props.driver.profileImage === null) && (
              <img
                src={
                  require("../../assets/img/task_manager_icons/taskdriver.svg")
                    .default
                }
                height="45"
              />
            )}
            {props.driver.profileImage &&
              props.driver.profileImage !== null && (
                <img
                  src={`${getImageURL(props.driver.profileImage, "/images/")}`}
                  width="45"
                  height="45"
                  // className="mt-1"
                  style={{ borderRadius: "50%", resize: "contain" }}
                />
              )}
          </div>

          <div className="col-9 pl-1 my-auto">
            <span className="font-weight-bold font-13 my-auto">
              {props.driver && props.driver.name}
            </span>

            {props.driver.email && (
              <p className="font-12 pt-1 mb-0 text-left">
                {props.driver.email}
              </p>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default DriverCard;
