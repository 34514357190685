import axios from "axios";
import { getStorage } from "./localStorageService";

import { API_URLS } from "../configs/api.urls";

const API_URL = API_URLS.API_REPORT_URL;

const ReportRequest = async (path, data = "") => {
  const token = getStorage("__token__");
  const headers = {
    Xtoken: token,
    "X-XSRF-TOKEN": token,
    "Content-Type": "application/json",
  };

  return new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}${path}`, data, { headers: headers })
      .then(function (response) {
        try {
          if (response.data && response.status == 200) {
            resolve(response.data);
            return;
          }

          reject("Oops! Something went wrong!");
        } catch (error) {
          throw new Error(error.message);
        }
      })
      .catch(function (error) {
        reject(error.message);
      });
  });
};

export default ReportRequest;
