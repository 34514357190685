import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { getStorage } from "../../../services/localStorageService";
import { getDriverStatusColor, getImageURL } from "../../../configs/common";
import Card from "../../UI/Card";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import {
  getDriver,
  removeActiveDriver,
  removeSelectedDrivers,
  setSelectedDrivers,
} from "../../../store/actions/taskManager.action";
import Checkbox from "../../UI/CheckBox";

const Driver = (props) => {
  const dispatch = useDispatch();
  const vehicles = getStorage("__vehicles__", "object");

  const activeDriver = useSelector((state) => state.taskReducer.activeDriver);
  const organizationStorage = useSelector(
    (state) => state.settingReducer.organizationStorage
  );

  const selectedDrivers = useSelector(
    (state) => state.taskReducer.selectedDrivers
  );

  const onClickHandler = async () => {
    try {
      props.setLoading(true);
      await dispatch(removeSelectedDrivers());
      await dispatch(getDriver(props.driver._id));
    } catch (error) {
    } finally {
      props.setLoading(false);
    }
  };

  const getIsActive = () => {
    return (
      selectedDrivers.includes(props.driver._id) ||
      activeDriver._id === props.driver._id
    );
  };

  const getUsedStorage = () => {
    var usedStorage = 0;
    if (
      props.storageUsed &&
      props.storageUsed[props.driver._id] &&
      organizationStorage &&
      organizationStorage.totalSpace
    ) {
      usedStorage =
        (props.storageUsed[props.driver._id]["totalDriverUsedSpace"] * 100) /
        organizationStorage.totalSpace;
    }
    return usedStorage;
  };

  const onToggleDriverSelection = async (event) => {
    dispatch(removeActiveDriver());
    dispatch(
      setSelectedDrivers(
        event.target.checked,
        [props.driver._id],
        [props.driver.group]
      )
    );
  };

  return (
    <Card
      onClick={() => onClickHandler()}
      style={styles.card}
      className={`hover-outline-task v-center ${
        getIsActive() && !props.disabled ? "active-card" : null
      } `}
    >
      <div className="row m-0">
        <div className="col-12 d-flex justify-content-between p-0">
          <div className="pr-0 lex-grow-1 d-flex" style={{ paddingTop: "2px" }}>
            <Checkbox
              checked={getIsActive()}
              id={props.driver._id}
              onChange={onToggleDriverSelection}
              className="chk-white mr-2 ml-1"
              labelClass="mb-0 mt-1"
            />
            <div className="my-auto">
              {/* IMAGE */}

              {(!props.driver.profileImage ||
                props.driver.profileImage === null) && (
                <img
                  src={
                    require("../../../assets/img/task_manager_icons/taskdriver.svg")
                      .default
                  }
                  height="32"
                />
              )}
              {props.driver.profileImage &&
                props.driver.profileImage !== null && (
                  <img
                    src={`${getImageURL(
                      props.driver.profileImage,
                      "/images/"
                    )}`}
                    width="32"
                    height="32"
                    style={{ borderRadius: "50%", resize: "contain" }}
                  />
                )}

              {/* ONLINE */}
              {["Online", "Busy", "Offline"].includes(props.driver.status) && (
                <div
                  style={{
                    ...styles.isOnline,
                    backgroundColor: getDriverStatusColor(props.driver.status),
                  }}
                ></div>
              )}
            </div>

            <div className="flex-1">
              <span
                className="font-weight-500 ellipsis-250 font-14 ml-2"
                style={{ color: "#428bca" }}
              >
                {props.driver.name}
              </span>
              <p
                className="ellipsis-250 font-12 ml-2 mb-0"
                style={{ color: "#333333", width: 180 }}
              >
                {vehicles && vehicles[props.driver?.vehicle]
                  ? `[${vehicles[props.driver?.vehicle].name}]`
                  : "--"}
              </p>
            </div>
          </div>
          <div className="my-auto" style={{ height: 40, width: 40 }}>
            <CircularProgressbar
              value={getUsedStorage()}
              text={`${getUsedStorage().toFixed(2)}%`}
            />
          </div>
        </div>
      </div>
    </Card>
  );
};

const styles = {
  card: {
    marginBottom: "5px",
    boxShadow: "none",
    cursor: "pointer",
    padding: "5px",
    // height: 43
  },
  isOnline: {
    position: "absolute",
    height: 8,
    width: 8,
    backgroundColor: "#36bf5d",
    borderRadius: 5,
    top: 10,
    left: 60,
  },
};

export default Driver;
