import moment from "moment";
import _ from "underscore";
import { TASK_ASSETS_URL } from "../environments/environments";
import { getStorage } from "../services/localStorageService";
import { MAIN_DOMAIN_URL } from "./api.urls";
import GetRequest from "../services/GetRequest";
import { settingsBasedVehicleSpeed } from "./speed";
import { TramRounded, TrendingUpRounded } from "@material-ui/icons";
import { ImageCompressor } from "image-compressor";
import { ShowNotification } from "../services/Notifications";

export const dubaiCoordinates = { lat: 25.204849, lng: 55.270782 };

export const defaultFuelRates = {
  Petrol: 10,
  Diesel: 7,
  Gas: 3,
  Other: 5,
};

export const graphicalReportsGroup = {
  report_group_name: "Graphical Reports",
  report_group_id: -1,
};

export const defaultDashboardCharts = [
  { label: "Pie Chart", type: "piechart", key: "piechart", order: 1 },
  {
    label: "Weekly Distance Time",
    type: "weeklydistance",
    key: "weeklydistance",
    order: 2,
  },
  { label: "Map", type: "map", key: "map", order: 3 },
  {
    label: "Weekly Moving Time",
    type: "weeklymoving",
    key: "weeklymoving",
    order: 4,
  },
  {
    label: "Weekly Idling Time",
    type: "weeklyidling",
    key: "weeklyidling",
    order: 5,
  },
  {
    label: "Attendance Report",
    type: "attendanceReport",
    key: "attendanceReport",
    order: 6,
  },
  {
    label: "Driver Behaviour",
    type: "driverbehaviour",
    key: "driverbehaviour",
    order: 7,
  },
  {
    label: "Speed Violation Report",
    type: "speedViolationReport",
    key: "speedViolationReport",
    order: 8,
  },
  {
    label: "Weekly Excess Kilometer Usage",
    type: "excessKilometerUsage",
    key: "excessKilometerUsage",
    order: 9,
  },
  {
    label: "Weekly Fuel Cost",
    type: "weeklyFuelCost",
    key: "weeklyFuelCost",
    order: 10,
  },
  {
    label: "Weekly Salik Usage",
    type: "weeklySalikUsage",
    key: "salikUsage",
    order: 11,
  },
  {
    label: "Weekly DARB Usage",
    type: "weeklyDarbUsage",
    key: "DarbUsage",
    order: 12,
  },
];

export const generate4DigitRandomNumber = () => {
  return Math.floor(1000 + Math.random() * 9000);
};

export const getColor = (key) => {
  switch (key) {
    case "Paid":
    case "Completed":
      return "#28a745";

    case "Not Paid":
    case "Work Not Started":
      return "#bd4142";

    case "Approved":
      return "#28a745";

    case "Verified":
      return "orange";

    case "Rejected":
      return "#bd4142";

    default:
      return "#3166ee";
  }
};

export const taskCSVFields = [
  "taskDate",
  "taskStatus",
  "countryCode",
  "location_address",
  "location_address_coordinates",
  "location_email",
  "location_name",
  "location_phone",
  "location_contact_name",
  "description",
  "driver_email",
  "driver_group_name",
  "driver_name",
  "driver_phone",
  "endAddress",
  "endLocation",
  "fromTime",
  // "jobId",
  "jobDuration",
  "priority",
  "startAddress",
  "startLocation",
  "toTime",
  "uniqueId",
];

export const taskCSVFieldValues = [
  "dd/mm/yyyy",
  "Assigned",
  "+971-ae",
  "14th Street Dubai-Supervisor Dubai United Arab Emirates",
  "Latitude-Longitude",
  "customer@example.com",
  "Customer Name",
  "000-000-0000",
  "Contact Name",
  "value",
  "driver@example.com",
  "Default",
  "Driver Name",
  "000-000-0000",
  "14th Street Dubai-Supervisor Dubai United Arab Emirates",
  "Latitude-Longitude",
  "hh:mm",
  // "1",
  "15",
  "normal/critical",
  "14th Street Dubai-Supervisor Dubai United Arab Emirates",
  "Latitude-Longitude",
  "hh:mm",
  "1",
];

export const countryCodes = [
  { label: "Afghanistan (+93)", value: "+93-af" },
  { label: "Albania (+355)", value: "+355-al" },
  { label: "Algeria (+213)", value: "+213-dz" },
  { label: "American Samoa (+1)", value: "+1-as" },
  { label: "Andorra (+376)", value: "+376-ad" },
  { label: "Angola (+244)", value: "+244-ao" },
  { label: "Anguilla (+1)", value: "+1-ai" },
  { label: "Antigua and Barbuda (+1)", value: "+1-ag" },
  { label: "Argentina (+54)", value: "+54-ar" },
  { label: "Armenia (+374)", value: "+374-am" },
  { label: "Aruba (+297)", value: "+297-aw" },
  { label: "Australia (+61)", value: "+61-au" },
  { label: "Austria (+43)", value: "+43-at" },
  { label: "Azerbaijan (+994)", value: "+994-az" },
  { label: "Bahamas (+1)", value: "+1-bs" },
  { label: "Bahrain (+973)", value: "+973-bh" },
  { label: "Bangladesh (+880)", value: "+880-bd" },
  { label: "Barbados (+1)", value: "+1-bb" },
  { label: "Belarus (+375)", value: "+375-by" },
  { label: "Belgium (+32)", value: "+32-be" },
  { label: "Belize (+501)", value: "+501-bz" },
  { label: "Benin (+229)", value: "+229-bj" },
  { label: "Bermuda (+1)", value: "+1-bm" },
  { label: "Bhutan (+975)", value: "+975-bt" },
  { label: "Bolivia (+591)", value: "+591-bo" },
  { label: "Bosnia and Herzegovina (+387)", value: "+387-ba" },
  { label: "Botswana (+267)", value: "+267-bw" },
  { label: "Brazil (+55)", value: "+55-br" },
  { label: "British Indian Ocean Territory (+246)", value: "+246-io" },
  { label: "Brunei (+673)", value: "+673-bn" },
  { label: "Bulgaria (+359)", value: "+359-bg" },
  { label: "Burkina Faso (+226)", value: "+226-bf" },
  { label: "Burundi (+257)", value: "+257-bi" },
  { label: "Cambodia (+855)", value: "+855-kh" },
  { label: "Cameroon (+237)", value: "+237-cm" },
  { label: "Canada (+1)", value: "+1-ca" },
  { label: "Cape Verde (+238)", value: "+238-cv" },
  { label: "Caribbean Netherlands (+1)", value: "+1-bq" },
  { label: "Cayman Islands (+1)", value: "+1-ky" },
  { label: "Central African Republic (+236)", value: "+236-cf" },
  { label: "Chad (+235)", value: "+235-td" },
  { label: "Chile (+56)", value: "+56-cl" },
  { label: "China (+86)", value: "+86-cn" },
  { label: "Christmas Island (+61)", value: "+61-cx" },
  { label: "Cocos (Keeling) Islands (+61)", value: "+61-cc" },
  { label: "Colombia (+57)", value: "+57-co" },
  { label: "Comoros (+269)", value: "+269-km" },
  { label: "Congo (DRC) (+243)", value: "+243-cd" },
  { label: "Congo (Republic) (+242)", value: "+242-cg" },
  { label: "Cook Islands (+682)", value: "+682-ck" },
  { label: "Costa Rica (+506)", value: "+506-cr" },
  { label: "Côte d’Ivoire (+225)", value: "+225-ci" },
  { label: "Croatia (+385)", value: "+385-hr" },
  { label: "Cuba (+53)", value: "+53-cu" },
  { label: "Curaçao (+599)", value: "+599-cw" },
  { label: "Cyprus (+357)", value: "+357-cy" },
  { label: "Czech Republic (+420)", value: "+420-cz" },
  { label: "Denmark (+45)", value: "+45-dk" },
  { label: "Djibouti (+253)", value: "+253-dj" },
  { label: "Dominica (+1)", value: "+1-dm" },
  { label: "Dominican Republic (+1)", value: "+1-do" },
  { label: "Ecuador (+593)", value: "+593-ec" },
  { label: "Egypt (+20)", value: "+20-eg" },
  { label: "El Salvador (+503)", value: "+503-sv" },
  { label: "Equatorial Guinea (+240)", value: "+240-gq" },
  { label: "Eritrea (+291)", value: "+291-er" },
  { label: "Estonia (+372)", value: "+372-ee" },
  { label: "Ethiopia (+251)", value: "+251-et" },
  { label: "Falkland Islands (+500)", value: "+500-fk" },
  { label: "Faroe Islands (+298)", value: "+298-fo" },
  { label: "Fiji (+679)", value: "+679-fj" },
  { label: "Finland (+358)", value: "+358-fi" },
  { label: "France (+33)", value: "+33-fr" },
  { label: "French Guiana (+594)", value: "+594-gf" },
  { label: "French Polynesia (+689)", value: "+689-pf" },
  { label: "Gabon (+241)", value: "+241-ga" },
  { label: "Gambia (+220)", value: "+220-gm" },
  { label: "Georgia (+995)", value: "+995-ge" },
  { label: "Germany (+49)", value: "+49-de" },
  { label: "Ghana (+233)", value: "+233-gh" },
  { label: "Gibraltar (+350)", value: "+350-gi" },
  { label: "Greece (+30)", value: "+30-gr" },
  { label: "Greenland (+299)", value: "+299-gl" },
  { label: "Grenada (+1)", value: "+1-gd" },
  { label: "Guadeloupe (+590)", value: "+590-gp" },
  { label: "Guam (+1)", value: "+1-gu" },
  { label: "Guatemala (+502)", value: "+502-gt" },
  { label: "Guernsey (+44)", value: "+44-gg" },
  { label: "Guinea (+224)", value: "+224-gn" },
  { label: "Guinea-Bissau (+245)", value: "+245-gw" },
  { label: "Guyana (+592)", value: "+592-gy" },
  { label: "Haiti (+509)", value: "+509-ht" },
  { label: "Honduras (+504)", value: "+504-hn" },
  { label: "Hong Kong (+852)", value: "+852-hk" },
  { label: "Hungary (+36)", value: "+36-hu" },
  { label: "Iceland (+354)", value: "+354-is" },
  { label: "India (+91)", value: "+91-in" },
  { label: "Indonesia (+62)", value: "+62-id" },
  { label: "Iran (+98)", value: "+98-ir" },
  { label: "Iraq (+964)", value: "+964-iq" },
  { label: "Ireland (+353)", value: "+353-ie" },
  { label: "Isle of Man (+44)", value: "+44-im" },
  { label: "Israel (+972)", value: "+972-il" },
  { label: "Italy (+39)", value: "+39-it" },
  { label: "Jamaica (+1)", value: "+1-jm" },
  { label: "Japan (+81)", value: "+81-jp" },
  { label: "Jersey (+44)", value: "+44-je" },
  { label: "Jordan (+962)", value: "+962-jo" },
  { label: "Kazakhstan (+7)", value: "+7-kz" },
  { label: "Kenya (+254)", value: "+254-ke" },
  { label: "Kiribati (+686)", value: "+686-ki" },
  { label: "Kosovo (+383)", value: "+383-xk" },
  { label: "Kuwait (+965)", value: "+965-kw" },
  { label: "Kyrgyzstan (+996)", value: "+996-kg" },
  { label: "Laos (+856)", value: "+856-la" },
  { label: "Latvia (+371)", value: "+371-lv" },
  { label: "Lebanon (+961)", value: "+961-lb" },
  { label: "Lesotho (+266)", value: "+266-ls" },
  { label: "Liberia (+231)", value: "+231-lr" },
  { label: "Libya (+218)", value: "+218-ly" },
  { label: "Liechtenstein (+423)", value: "+423-li" },
  { label: "Lithuania (+370)", value: "+370-lt" },
  { label: "Luxembourg (+352)", value: "+352-lu" },
  { label: "Macau (+853)", value: "+853-mo" },
  { label: "Madagascar (+261)", value: "+261-mg" },
  { label: "Malawi (+265)", value: "+265-mw" },
  { label: "Malaysia (+60)", value: "+60-my" },
  { label: "Maldives (+960)", value: "+960-mv" },
  { label: "Mali (+223)", value: "+223-ml" },
  { label: "Malta (+356)", value: "+356-mt" },
  { label: "Marshall Islands (+692)", value: "+692-mh" },
  { label: "Martinique (+596)", value: "+596-mq" },
  { label: "Mauritania (+222)", value: "+222-mr" },
  { label: "Mauritius (+230)", value: "+230-mu" },
  { label: "Mayotte (+262)", value: "+262-yt" },
  { label: "Mexico (+52)", value: "+52-mx" },
  { label: "Micronesia (+691)", value: "+691-fm" },
  { label: "Moldova (+373)", value: "+373-md" },
  { label: "Monaco (+377)", value: "+377-mc" },
  { label: "Mongolia (+976)", value: "+976-mn" },
  { label: "Montenegro (+382)", value: "+382-me" },
  { label: "Montserrat (+1)", value: "+1-ms" },
  { label: "Morocco (+212)", value: "+212-ma" },
  { label: "Mozambique (+258)", value: "+258-mz" },
  { label: "Myanmar (Burma) (+95)", value: "+95-mm" },
  { label: "Namibia (+264)", value: "+264-na" },
  { label: "Nauru (+674)", value: "+674-nr" },
  { label: "Nepal (+977)", value: "+977-np" },
  { label: "Netherlands (+31)", value: "+31-nl" },
  { label: "New Caledonia (+687)", value: "+687-nc" },
  { label: "New Zealand (+64)", value: "+64-nz" },
  { label: "Nicaragua (+505)", value: "+505-ni" },
  { label: "Niger (+227)", value: "+227-ne" },
  { label: "Nigeria (+234)", value: "+234-ng" },
  { label: "Niue (+683)", value: "+683-nu" },
  { label: "Norfolk Island (+672)", value: "+672-nf" },
  { label: "North Korea (+850)", value: "+850-kp" },
  { label: "North Macedonia (+389)", value: "+389-mk" },
  { label: "Northern Mariana Islands (+1)", value: "+1-mp" },
  { label: "Norway (+47)", value: "+47-no" },
  { label: "Oman (+968)", value: "+968-om" },
  { label: "Pakistan (+92)", value: "+92-pk" },
  { label: "Palau (+680)", value: "+680-pw" },
  { label: "Palestine (+970)", value: "+970-ps" },
  { label: "Panama (+507)", value: "+507-pa" },
  { label: "Papua New Guinea (+675)", value: "+675-pg" },
  { label: "Paraguay (+595)", value: "+595-py" },
  { label: "Peru (+51)", value: "+51-pe" },
  { label: "Philippines (+63)", value: "+63-ph" },
  { label: "Poland (+48)", value: "+48-pl" },
  { label: "Portugal (+351)", value: "+351-pt" },
  { label: "Puerto Rico (+1)", value: "+1-pr" },
  { label: "Qatar (+974)", value: "+974-qa" },
  { label: "Réunion (+262)", value: "+262-re" },
  { label: "Romania (+40)", value: "+40-ro" },
  { label: "Russia (+7)", value: "+7-ru" },
  { label: "Rwanda (+250)", value: "+250-rw" },
  { label: "Saint Barthélemy (+590)", value: "+590-bl" },
  { label: "Saint Helena (+290)", value: "+290-sh" },
  { label: "Saint Kitts and Nevis (+1)", value: "+1-kn" },
  { label: "Saint Lucia (+1)", value: "+1-lc" },
  { label: "Saint Martin (+590)", value: "+590-mf" },
  { label: "Saint Pierre and Miquelon (+508)", value: "+508-pm" },
  { label: "Saint Vincent and the Grenadines (+1)", value: "+1-vc" },
  { label: "Samoa (+685)", value: "+685-ws" },
  { label: "San Marino (+378)", value: "+378-sm" },
  { label: "São Tomé and Príncipe (+239)", value: "+239-st" },
  { label: "Saudi Arabia (+966)", value: "+966-sa" },
  { label: "Senegal (+221)", value: "+221-sn" },
  { label: "Serbia (+381)", value: "+381-rs" },
  { label: "Seychelles (+248)", value: "+248-sc" },
  { label: "Sierra Leone (+232)", value: "+232-sl" },
  { label: "Singapore (+65)", value: "+65-sg" },
  { label: "Sint Maarten (+1)", value: "+1-sx" },
  { label: "Slovakia (+421)", value: "+421-sk" },
  { label: "Slovenia (+386)", value: "+386-si" },
  { label: "Solomon Islands (+677)", value: "+677-sb" },
  { label: "Somalia (+252)", value: "+252-so" },
  { label: "South Africa (+27)", value: "+27-za" },
  { label: "South Korea (+82)", value: "+82-kr" },
  { label: "South Sudan (+211)", value: "+211-ss" },
  { label: "Spain (+34)", value: "+34-es" },
  { label: "Sri Lanka (+94)", value: "+94-lk" },
  { label: "Sudan (+249)", value: "+249-sd" },
  { label: "Suriname (+597)", value: "+597-sr" },
  { label: "Svalbard and Jan Mayen (+47)", value: "+47-sj" },
  { label: "Sweden (+46)", value: "+46-se" },
  { label: "Switzerland (+41)", value: "+41-ch" },
  { label: "Syria (+963)", value: "+963-sy" },
  { label: "Taiwan (+886)", value: "+886-tw" },
  { label: "Tajikistan (+992)", value: "+992-tj" },
  { label: "Tanzania (+255)", value: "+255-tz" },
  { label: "Thailand (+66)", value: "+66-th" },
  { label: "Timor-Leste (+670)", value: "+670-tl" },
  { label: "Togo (+228)", value: "+228-tg" },
  { label: "Tokelau (+690)", value: "+690-tk" },
  { label: "Tonga (+676)", value: "+676-to" },
  { label: "Trinidad and Tobago (+1)", value: "+1-tt" },
  { label: "Tunisia (+216)", value: "+216-tn" },
  { label: "Turkey (+90)", value: "+90-tr" },
  { label: "Turkmenistan (+993)", value: "+993-tm" },
  { label: "Turks and Caicos Islands (+1)", value: "+1-tc" },
  { label: "Tuvalu (+688)", value: "+688-tv" },
  { label: "U.S. Virgin Islands (+1)", value: "+1-vi" },
  { label: "Uganda (+256)", value: "+256-ug" },
  { label: "Ukraine (+380)", value: "+380-ua" },
  { label: "United Arab Emirates (+971)", value: "+971-ae" },
  { label: "United Kingdom (+44)", value: "+44-gb" },
  { label: "United States (+1)", value: "+1-us" },
  { label: "Uruguay (+598)", value: "+598-uy" },
  { label: "Uzbekistan (+998)", value: "+998-uz" },
  { label: "Vanuatu (+678)", value: "+678-vu" },
  { label: "Vatican City (+39)", value: "+39-va" },
  { label: "Venezuela (+58)", value: "+58-ve" },
  { label: "Vietnam (+84)", value: "+84-vn" },
  { label: "Wallis and Futuna (+681)", value: "+681-wf" },
  { label: "Yemen (+967)", value: "+967-ye" },
  { label: "Zambia (+260)", value: "+260-zm" },
  { label: "Zimbabwe (+263)", value: "+263-zw" },
];

export const getDriverStatusColor = (key) => {
  switch (key) {
    case "Online":
      return "#36bf5d";

    case "Busy":
      return "#ac0e63";

    default:
      return "grey";
  }
};

export const getRouteOptimizerSVG = (count, fill) => {
  return `
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 188.38 188.38"><defs><style>.cls-1,.cls-3{fill:${fill};}.cls-1{opacity:0.35;}.cls-1,.cls-2{isolation:isolate;}.cls-2{fill:#f2f3f4;opacity:0.9;}.cls-4{font-size:70px;fill:#fff;font-family:Arial-BoldMT, Arial;font-weight:700;}</style></defs><title>stop cluster</title><path class="cls-1" d="M97.18,3a94.19,94.19,0,1,0,94.18,94.2h0A94.2,94.2,0,0,0,97.18,3Zm0,144.21a50,50,0,1,1,50-50h0a50,50,0,0,1-50,50Z" transform="translate(-2.98 -2.98)"/><path class="cls-2" d="M97.18,24.62a72.55,72.55,0,1,0,72.54,72.56h0A72.56,72.56,0,0,0,97.18,24.62Zm0,125.19a52.64,52.64,0,1,1,52.63-52.65h0a52.65,52.65,0,0,1-52.63,52.65Z" transform="translate(-2.98 -2.98)"/><circle class="cls-3" cx="94.19" cy="94.19" r="64.78"/><text class="cls-4" x="20%" text-anchor="middle" transform="translate(55.16 121.54)">${count}</text></svg>`;
};

export const defaultModulePermissions = {
  dashboard: { allowAllModules: TramRounded, modules: [], label: "Dashboard" },
  live: { allowAllModules: TramRounded, modules: [], label: "Live" },
  trips: { allowAllModules: TramRounded, modules: [], label: "Trips" },
  reports: {
    allowAllModules: TramRounded,
    modules: [],
    label: "Reports",
    graphicalReports: [],
  },
  fleetManager: {
    allowAllModules: TrendingUpRounded,
    modules: [],
    label: "Fleet Manager",
  },
  expanseManager: {
    allowAllModules: TrendingUpRounded,
    modules: [],
    label: "Expense Manager",
  },
  attendance: { allowAllModules: false, modules: [], label: "Attendance" },
  tolls: { allowAllModules: false, modules: [], label: "Tolls" },
  zoneAndPoi: { allowAllModules: true, modules: [], label: "Zone & POIs" },
  taskManager: { allowAllModules: true, modules: [], label: "Task Manager" },
  taskManagerDriverApp: {
    allowAllModules: false,
    modules: [],
    label: "Task Manager Driver App",
  },
  assetsTracking: {
    allowAllModules: false,
    modules: [],
    label: "Assets Tracking",
  },
};

export const defaultExpenseData = {
  summary: { canView: false },
  detailed: {
    canView: false,
    canMakePayment: false,
    canUpdate: false,
    canDelete: false,
    approval: false,
    verification: false,
  },
  pcf: {
    canView: false,
    canMakePayment: false,
    canUpdate: false,
    canDelete: false,
  },
};

export const pad = (d) => {
  return d < 10 ? "0" + d.toString() : d.toString();
};

export const getMonthName = (days = 0) => {
  var d = new Date();

  switch (days) {
    case 30:
      d = new Date(new Date().getTime() - 1 * 24 * 60 * 60 * 1000 * 30);
      return moment(d).format("MMMM");

    case 60:
      d = new Date(new Date().getTime() - 1 * 24 * 60 * 60 * 1000 * 30);
      var d1 = new Date(new Date().getTime() - 1 * 24 * 60 * 60 * 1000 * 60);

      return `${moment(d1).format("MMMM")}-${moment(d).format("MMMM")}`;

    default:
      return moment(d).format("MMMM");
  }
};

export const base64ToFile = (base64, filename) => {
  const arr = base64.split(",");
  const mime = arr[0]?.match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};

export const getCompressedDriverImage = (file) => {
  return new Promise((resolve, reject) => {
    try {
      const imageCompressor = new ImageCompressor();
      compressorSettings = {
        toWidth: 96,
        toHeight: 96,
        mimeType: file.type,
        mode: "strict",
        quality: 0.6,
        speed: "low",
      };
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        if (reader.result && reader.result !== null) {
          imageCompressor.run(
            reader.result,
            compressorSettings,
            (compressedSrc) => {
              const f = base64ToFile(compressedSrc, file.name);
              resolve(f);
            }
          );
        }
      };
    } catch (error) {
      resolve(file);
    }
  });
};

export const getImageURL = (url, folder = "") => {
  if (url.includes("http") || url.includes("https")) return url;
  return `${TASK_ASSETS_URL}${folder}${url}`;
};

export const getFieldTotal = (data, fieldName) => {
  if (!data) return 0;

  var total = 0;

  data.map((item) => {
    if (item[fieldName]) total += parseFloat(item[fieldName]);
  });

  return total.toFixed(2);
};

export const getExpensePDFReportData = (data, type) => {
  var rows = [];
  var columns = [];

  switch (type) {
    case "summary":
      columns = [
        { label: "Date", width: "20%" },
        { label: "Employee Name", width: "20%" },
        { label: "Category", width: "20%" },
        { label: "Description", width: "20%" },
        { label: "Amount", width: "20%" },
      ];

      data.map((item) => {
        rows.push([
          item.createdAt
            ? moment(item.createdAt).format("DD/MM/yyyy HH:mm")
            : "-",
          item.driver ? item.driver.name : "-",
          item.category ? item.category : "-",
          item.description ? item.description : "-",
          item.amount ? `${item.amount.toFixed(2)} AED` : "-",
        ]);
      });

      break;

    case "detailed":
      columns = [
        { label: "Date", width: "15%" },
        { label: "Employee Name", width: "15%" },
        { label: "Category", width: "15%" },
        { label: "Description", width: "10%" },
        { label: "Amount", width: "10%" },
        { label: "Status", width: "10%" },
        { label: "Payment Status", width: "15%" },
        { label: "Verified", width: "10%" },
      ];

      data.map((item) => {
        rows.push([
          item.createdAt
            ? moment(item.createdAt).format("DD/MM/yyyy HH:mm")
            : "-",
          item.driver ? item.driver.name : "-",
          item.category ? item.category : "-",
          item.description ? item.description : "-",
          item.amount ? `${item.amount.toFixed(2)} AED` : "-",
          item.status ? item.status : "-",
          item.paymentStatus ? item.paymentStatus : "-",
          item.verifiedBy == "" ? "No" : "Yes",
        ]);
      });

      break;

    case "pcf":
      columns = [
        { label: "Date", width: "15%" },
        { label: "Employee Name", width: "15%" },
        { label: "Category", width: "15%" },
        { label: "Description", width: "15%" },
        { label: "Bill Amount", width: "10%" },
        { label: "Paid Amount", width: "15%" },
        { label: "Balance", width: "15%" },
      ];

      var clone = [...data];
      clone = clone.reverse();
      var total = 0;

      clone.map((e) => {
        if (e.type == "Expense") {
          total -= e.amount;
        } else {
          total += e.amount;
        }
        e.balance = total;
      });

      clone.map((item) => {
        rows.push([
          item.createdAt
            ? moment(item.createdAt).format("DD/MM/yyyy HH:mm")
            : "-",
          item.driver ? item.driver.name : "-",
          item.category ? item.category : "-",
          item.description ? item.description : "-",
          item.type == "Expense" ? `${item.amount.toFixed(2)} AED` : "-",
          item.type == "Payment" ? `${item.amount.toFixed(2)} AED` : "-",
          `${item.balance ? item.balance.toFixed(2) : "0"} AED`,
        ]);
      });

      rows = rows.reverse();

      break;

    default:
      rows = [];
      columns = [];
  }

  return {
    rows,
    columns,
  };
};

export const getExpenseExelReportData = (data, type) => {
  var rows = [];
  var columns = [];

  switch (type) {
    case "summary":
      columns = [
        { title: "Date", width: { wpx: 120 } },
        { title: "Employee Name", width: { wpx: 120 } },
        { title: "Category", width: { wpx: 100 } },
        { title: "Description", width: { wpx: 180 } },
        { title: "Amount", width: { wpx: 100 } },
      ];

      data.map((item) => {
        rows.push([
          {
            value: item.createdAt
              ? moment(item.createdAt).format("DD/MM/yyyy HH:mm")
              : "-",
          },
          { value: item.driver ? item.driver.name : "-" },
          { value: item.category ? item.category : "-" },
          { value: item.description ? item.description : "-" },
          { value: item.amount ? `${item.amount.toFixed(2)} AED` : "-" },
        ]);
      });

      break;

    case "detailed":
      columns = [
        { title: "Date", width: { wpx: 120 } },
        { title: "Employee Name", width: { wpx: 120 } },
        { title: "Category", width: { wpx: 100 } },
        { title: "Description", width: { wpx: 180 } },
        { title: "Amount", width: { wpx: 100 } },
        { title: "Status", width: { wpx: 100 } },
        { title: "Payment Status", width: { wpx: 100 } },
        { title: "Verified", width: { wpx: 100 } },
      ];

      data.map((item) => {
        rows.push([
          {
            value: item.createdAt
              ? moment(item.createdAt).format("DD/MM/yyyy HH:mm")
              : "-",
          },
          { value: item.driver ? item.driver.name : "-" },
          { value: item.category ? item.category : "-" },
          { value: item.description ? item.description : "-" },
          { value: item.amount ? `${item.amount.toFixed(2)} AED` : "-" },
          { value: item.status ? item.status : "-" },
          { value: item.paymentStatus ? item.paymentStatus : "-" },
          { value: item.verifiedBy == "" ? "No" : "Yes" },
        ]);
      });

      break;

    case "pcf":
      columns = [
        { title: "Date", width: { wpx: 120 } },
        { title: "Employee Name", width: { wpx: 120 } },
        { title: "Category", width: { wpx: 100 } },
        { title: "Description", width: { wpx: 180 } },
        { title: "Bill Amount", width: { wpx: 100 } },
        { title: "Paid Amount", width: { wpx: 100 } },
        { title: "Balance", width: { wpx: 100 } },
      ];

      var clone = [...data];
      clone = clone.reverse();

      var total = 0;

      clone.map((e) => {
        if (e.type == "Expense") {
          total -= e.amount;
        } else {
          total += e.amount;
        }
        e.balance = total;
      });

      clone.map((item) => {
        rows.push([
          {
            value: item.createdAt
              ? moment(item.createdAt).format("DD/MM/yyyy HH:mm")
              : "-",
          },
          { value: item.driver ? item.driver.name : "-" },
          { value: item.category ? item.category : "-" },
          { value: item.description ? item.description : "-" },
          {
            value:
              item.type == "Expense" ? `${item.amount.toFixed(2)} AED` : "-",
          },
          {
            value:
              item.type == "Payment" ? `${item.amount.toFixed(2)} AED` : "-",
          },
          { value: `${item.balance ? item.balance.toFixed(2) : "0"} AED` },
        ]);
      });

      rows = rows.reverse();

      break;

    default:
      rows = [];
      columns = [];
  }

  return {
    rows,
    columns,
  };
};

export const _getRowFields = (cardSettings, rowNumber) => {
  if (!cardSettings) return [];

  return cardSettings.filter((cs) =>
    rowNumber == 2 ? cs.row == 2 : !cs.row || cs.row == "1"
  );
};

export const matchWithField = (field) => {
  if (field.type == "job" && field.label == "Job Name")
    field.type = "description";
  if (field.type == "s_time") field.type = "fromTime";
  if (field.type == "e_time") field.type = "toTime";
  if (field.type == "s_address") field.type = "startAddress";
  if (field.type == "e_address") field.type = "endAddress";
  if (field.type == "map") field.type = "endAddress";
  if (field.type == "contact") field.type = "phoneNumber";
  if (field.type == "endAddress") field.type = "address";

  return field;
};

export const _getFieldDataValue = (task, field, statusList = []) => {
  field = matchWithField(field);

  const statusTypeList = statusList.map((s) => s.type);
  const statusKeyValue = {};
  const statusValueKey = {};
  statusList.map((s) => {
    statusKeyValue[s.type] = s.label;
  });

  statusList.map((s) => {
    statusValueKey[s.label] = s.type;
  });

  if (statusTypeList.includes(field.key)) {
    if (task.statusActivities && _.isArray(task.statusActivities)) {
      var reversedArray = [...task.statusActivities].reverse();
      const exist = reversedArray.findIndex(
        (t) => t.status == statusKeyValue[field.key]
      );
      return exist >= 0
        ? moment(reversedArray[exist].date).format("DD/MM/yy HH:mm")
        : "-";
    } else {
      return "-";
    }
  }

  const statusLocationsList = statusTypeList.map((s) => `${s}_location`);
  if (statusLocationsList.includes(field.key)) {
    if (task.statusActivities && _.isArray(task.statusActivities)) {
      var reversedArray = [...task.statusActivities].reverse();
      const exist = reversedArray.findIndex(
        (t) => `${statusValueKey[t.status]}_location` == field.key
      );
      return exist >= 0 && reversedArray[exist].location
        ? reversedArray[exist].location?.address || "-"
        : "-";
    } else {
      return "-";
    }
  }

  const statusChangedByList = statusTypeList.map((s) => `${s}_by`);
  if (statusChangedByList.includes(field.key)) {
    if (task.statusActivities && _.isArray(task.statusActivities)) {
      var reversedArray = [...task.statusActivities].reverse();
      const exist = reversedArray.findIndex(
        (t) => `${statusValueKey[t.status]}_by` == field.key
      );
      return exist >= 0 && reversedArray[exist].user
        ? reversedArray[exist].user
        : "-";
    } else {
      return "-";
    }
  }

  // FROM TASK
  if (task[field.key] && field.type !== "populate") return task[field.key];

  // FROM CUSTOMER
  if (field.key == "customer" && field.type == "populate" && task.customer)
    return `${task.customer.name} (${task.customer.phoneNumber})`;

  // FROM ATTRIBUTES
  if (task.attributes && task.attributes[field.key]) {
    if (["Image", "image"].indexOf(field.type) !== -1) {
      return `${field.type}---${TASK_ASSETS_URL}/${task.attributes[field.key]}`;
    }
    if (["Sigature", "signature"].indexOf(field.type) !== -1) {
      return `${field.type}---${task.attributes[field.key]}`;
    }

    return task.attributes[field.key];
  }

  return "-";
};

export const getTaskPDFReportData = (downloaddetails, tasks, status) => {
  var rows = [];
  var columns = [{ label: "Task Date" }, { label: "Status" }];

  downloaddetails.map((item) => {
    columns.push({ label: item.label });
  });

  tasks.map((task) => {
    var d = [moment(task.taskDate).format("DD-MM-YYYY"), task.taskStatus];

    downloaddetails.map((item) => {
      d.push(_getFieldDataValue(task, item, status));
    });

    rows.push(d);
  });

  return {
    rows,
    columns,
  };
};

export const getTaskExcelReportData = (downloaddetails, tasks, status) => {
  var rows = [];
  var columns = [
    { title: "Task Date", width: { wpx: 120 } },
    { title: "Status", width: { wpx: 120 } },
  ];

  if (downloaddetails) {
    downloaddetails.map((item) => {
      if (["image", "signature"].includes(item.type.toLowerCase())) {
        return;
      }
      columns.push({ title: item.label, width: { wpx: 120 } });
    });
  }

  tasks.map((task) => {
    var d = [
      { value: moment(task.taskDate).format("DD-MM-YYYY") },
      { value: task.taskStatus },
    ];

    if (downloaddetails) {
      downloaddetails.map((item) => {
        if (["image", "signature"].includes(item.type.toLowerCase())) {
          return;
        }
        d.push({
          value: _getFieldDataValue(task, item, status),
          style: { alignment: { wrapText: true } },
        });
      });
    }

    rows.push(d);
  });

  return {
    rows,
    columns,
  };
};

export const getSalikExcelReportData = (data) => {
  var rows = [];

  var columns = [
    { title: "Date", width: { wpx: 120 } },
    { title: "Time", width: { wpx: 120 } },
    { title: "Plate", width: { wpx: 120 } },
    { title: "Toll Gate", width: { wpx: 120 } },
    { title: "Direction", width: { wpx: 120 } },
    { title: "Amount", width: { wpx: 120 } },
  ];

  data.map((_item) => {
    rows.push([
      { value: _item.vehicle.name },
      { value: "" },
      { value: "" },
      { value: "" },
      { value: "" },
      { value: "" },
    ]);
    _item.trips.map((item) => {
      rows.push([
        {
          value: item.tripDate
            ? moment(item.tripDate).format("DD/MM/yyyy HH:mm")
            : "-",
        },
        { value: item.tripTime ? item.tripTime : "-" },
        { value: item.platNumber ? item.platNumber : "-" },
        { value: item.tollGate ? item.tollGate : "-" },
        { value: item.direction ? item.direction : "-" },
        { value: item.amount ? `${item.amount.toFixed(2)} AED` : "-" },
      ]);
    });
  });

  return {
    rows,
    columns,
  };
};

export const getDARBExcelReportData = (data) => {
  var rows = [];

  var columns = [
    { title: "Date", width: { wpx: 120 } },
    { title: "Time", width: { wpx: 120 } },
    // { title: 'Plate', width: { wpx: 120 } },
    { title: "Toll Gate", width: { wpx: 120 } },
    // { title: 'Direction', width: { wpx: 120 } },
    { title: "Amount", width: { wpx: 120 } },
  ];

  data.map((_item) => {
    rows.push([
      { value: _item.vehicle.name },
      { value: "" },
      { value: "" },
      { value: "" },
    ]);
    _item.trips.map((item) => {
      rows.push([
        {
          value: item.tripDate
            ? moment(item.tripDate).format("DD/MM/yyyy HH:mm")
            : "-",
        },
        { value: item.tripTime ? item.tripTime : "-" },
        // { value: item.platNumber ? item.platNumber : '-' },
        { value: item.tollGate ? item.tollGate : "-" },
        // { value: item.direction ? item.direction : '-' },
        { value: item.amount ? `${item.amount.toFixed(2)} AED` : "-" },
      ]);
    });
  });

  return {
    rows,
    columns,
  };
};

export const getRoutePDFReportData = (data) => {
  var rows = [];
  var columns = [];

  columns = [
    { label: "Location Name", width: "20%" },
    { label: "Address", width: "20%" },
    { label: "Duration (Minutes)", width: "15%" },
    { label: "Quantity", width: "10%" },
    { label: "Estimated Time", width: "15%" },
    { label: "Note", width: "20%" },
  ];

  data.map((item) => {
    rows.push([
      item.locationName ? item.locationName : "-",
      item.address ? item.address.name : "-",
      item.duration ? item.duration : "-",
      item.quantity ? item.quantity : "-",
      item.estimatedTime ? item.estimatedTime : "-",
      item.note ? item.note : "-",
    ]);
  });

  return {
    rows,
    columns,
  };
};

export const getApproxCost = (vehicleId) => {
  const vehicles = getStorage("__vehicles__", "object");

  if (!vehicles[vehicleId]) return "0";

  const vehicle = vehicles[vehicleId];
  console.log("vehicle", vehicle);

  return "1";
};

export const getTaskDuration = (
  duration,
  hasDefaultDurationSetting = false
) => {
  try {
    duration = Number(duration);
    if (isNaN(duration) || duration == 0) {
      // GET DEFAULT DURATION SETTING FROM SETTING DATA
      if (hasDefaultDurationSetting) {
        duration = hasDefaultDurationSetting?.jobsDefaultDuration;
      }
    }

    // duration = Number(duration);
    duration = duration * 60;
    return duration;
  } catch (e) {
    return 10;
  }
};

export const getTaskColor = (list, key) => {
  if (list.length == 0) return null;

  var taskColor = list.find((k) => k.label == key);
  if (taskColor) return taskColor.color;
  return null;
};

export const makeRouteOptimizerDataFromTasksManager = async (options) => {
  const {
    taskData,
    taskSettings,
    activeDriver,
    hasSelectedDriverList,
    selectedDrivers = [],
    _driverGroups = [],
    vehicleSpeedSettings = {},
  } = options;

  try {
    var jobs = [];

    var tasks = taskData.tasks;
    var end = false;
    var start = false;

    for (let index = 0; index < tasks.length; index++) {
      const job = tasks[index];
      if (!job.isSharedWithGroup || job.isSharedJobStartedByDriver != null) {
        end = job;

        const jobTime =
          job.fromTime && job.toTime && job.fromTime !== "" && job.toTime !== ""
            ? { startTime: `${job.fromTime}:00`, endTime: `${job.toTime}:59` }
            : { startTime: "00:00:00", endTime: "23:59:59" };

        try {
          var location = [
            +job.startLocation?.latitude,
            +job.startLocation?.longitude,
          ];
          if (
            job.endLocation &&
            job.endLocation?.latitude != "" &&
            job.endLocation?.longitude != ""
          ) {
            location = [
              +job.endLocation?.latitude,
              +job.endLocation?.longitude,
            ];
          }

          jobs.push({
            id: index + 1,
            taskID: job._id,
            location: location,
            time_window: jobTime,
            priority: "normal",
            weight: 15,
            quantity: 1,
            duration: getTaskDuration(
              job.jobWaitingTime,
              taskSettings?.defaultValues
            ),
            data: {
              showMarkerDetails: {
                title: job.customer ? job.customer.name : "",
                markerColor: getTaskColor(
                  taskSettings.taskStatus,
                  job.taskStatus
                ),
                body: [
                  {
                    label: "Address",
                    value: job.customer ? job.customer.address : "",
                  },
                  {
                    label: "Time Window",
                    value: `${job.fromTime ? job.fromTime : ""} - ${
                      job.toTime ? job.toTime : ""
                    }`,
                  },
                  {
                    label: "Estimated Time",
                    type: "EstimateTime",
                  },
                  {
                    label: "Waiting Time",
                    type: "waitingTime",
                  },
                ],
                footer: job.description,
              },
              details: job,
            },
          });
        } catch (error) {
          console.log("Error", error);
        }
      }
    }

    if (!end) {
      end = {
        endLocation: {
          latitude: activeDriver.taskTartingPosition.lat,
          longitude: activeDriver.taskTartingPosition.lng,
        },
      };
    }

    if (!activeDriver.taskTartingPosition) {
      start = [jobs[0]?.location[0], jobs[0]?.location[1]];
    }

    var allDrivers = [];

    if (!hasSelectedDriverList) {
      // PREPARE LIST IF WE DON'T HAVE DRIVERS

      var selectedDriversClone = [...selectedDrivers];
      if (selectedDriversClone.length == 0) {
        allDrivers = [activeDriver];
      } else {
        _driverGroups.map((driverGroup) => {
          var filtered = driverGroup.Drivers?.filter((d) => {
            if (selectedDriversClone.indexOf(d._id) !== -1) {
              return d;
            } else {
              return false;
            }
          });
          allDrivers = allDrivers.concat(filtered);
        });
      }
    } else {
      allDrivers = hasSelectedDriverList;
    }

    var vehicleList = [];

    allDrivers.map((driver, driverIndex) => {
      if (!driver) return;
      const time_window =
        driver.fromTime &&
        driver.toTime &&
        driver.fromTime !== "" &&
        driver.toTime !== ""
          ? {
              startTime: driver.fromTime + ":00",
              endTime: driver.toTime + ":59",
            }
          : { startTime: "00:00:00", endTime: "23:59:59" };

      var vehicle = {
        id: driverIndex + 1,
        profile: "driving-car",
        capacity: 999999999,
        time_window: time_window,
        vehicleType: "Car",
        driverType: "senior",
        vehicleSpeed: settingsBasedVehicleSpeed(
          driver?.vehicle,
          vehicleSpeedSettings,
          driver
        ),
        speed: "maximum",
        data: {
          routeName: driver.name,
          driverID: driver._id,
          startTime: driver?.fromTime || "00:00",
        },
      };

      if (driver.taskTartingPosition) {
        vehicle.start = [
          driver.taskTartingPosition.lat,
          driver.taskTartingPosition.lng,
        ];
      } else {
        vehicle.start = [jobs[0]?.location[0], jobs[0]?.location[1]];
      }

      if (driver.taskEndingPosition) {
        vehicle.end = [
          driver.taskEndingPosition.lat,
          driver.taskEndingPosition.lng,
        ];
      }

      vehicleList.push(vehicle);
      // end: [+end.endLocation.latitude, +end.endLocation.longitude],
    });

    var tripCoast = false;

    if (activeDriver.vehicle && activeDriver.vehicle !== "") {
      try {
        const vehicleDetails = await GetRequest(
          `/user/getSingleObjectDetails/${activeDriver.vehicle}`,
          {}
        );

        if (vehicleDetails) {
          const average = Number(
            vehicleDetails[0].obj_fuel_consumption_running
          );
          const fuelRatePrice = Number(vehicleDetails[0].obj_fuel_rate);
          tripCoast = fuelRatePrice / average;

          if (isNaN(tripCoast)) {
            tripCoast = false;
          }
        }
      } catch (error) {
        console.log("Unable to calculate trip cost");
      }
    }

    return { jobs, vehicleList, tripCoast };
  } catch (error) {
    console.log("Error in makeRouteOptimizerDataFromTasksManager", error);

    return false;
  }
};

export const _pushNotification = (title = "title", body = "test") => {
  if (Notification.permission !== "granted") {
    try {
      Notification.requestPermission().then(function (permission) {
        if (permission === "granted") {
          _pushNotification(body, title);
        }
      });
    } catch (error) {
      console.log("error _pushNotification", error);
    }
  } else {
    try {
      var notification = new Notification(title, {
        icon: `${MAIN_DOMAIN_URL}/logo512.png`,
        body: body,
        renotify: true,
        tag: body,
      });
    } catch (error) {
      console.log("error _pushNotification 2", error);
    }

    // Ensure audio playback only after user interaction
    var audio = new Audio("/notification/notification.mp3");
    audio.play().catch(function (error) {
      console.log("Audio play failed:", error);
      // Show Toaster
      ShowNotification("push", body);
    });

    notification.onclick = function () {
      if (link !== "") {
        notification.close();
        window.open(link);
      }
    };
  }
};

export const getTemperature = (vehicle) => {
  if (
    vehicle?.position?.attributes.temp1 &&
    vehicle?.position?.attributes.temp1 <= 60
  ) {
    return `${(vehicle?.position?.attributes.temp1).toFixed(1)}°C`;
  } else if (
    vehicle?.position?.attributes.temp1 &&
    vehicle?.position?.attributes.temp1 > 60
  ) {
    return `22°C`;
  } else if (vehicle?.position?.attributes.io25) {
    return `${vehicle?.position?.attributes.io25 / 10}°C`;
  } else {
    return "-";
  }
};

export const getStatusCount = (key, status, list) => {
  if (status == "totalTask") {
    const curruntDriverStatus = list[key];

    if (!curruntDriverStatus) return 0;

    var t = 0;
    Object.keys(curruntDriverStatus).map(
      (k) => (t = t + curruntDriverStatus[k])
    );
    return t;
  }

  if (list[key] && list[key][status]) {
    return list[key][status];
  }

  return "0";
};

export const setDriversLastEstimatedTimeData = (
  driver,
  task,
  estimatedTime
) => {
  try {
    var clone = JSON.parse(
      localStorage.getItem("driversLastEstimatedTimeData")
    );
    if (!clone) clone = {};
    if (!clone[driver]) clone[driver] = {};
    clone[driver][task] = estimatedTime;
    localStorage.setItem("driversLastEstimatedTimeData", JSON.stringify(clone));
  } catch (error) {}
};

export const unSetDriversLastEstimatedTimeData = (driver) => {
  try {
    var clone = JSON.parse(
      localStorage.getItem("driversLastEstimatedTimeData")
    );
    if (!clone) clone = {};
    clone[driver] = {};
    localStorage.setItem("driversLastEstimatedTimeData", JSON.stringify(clone));
  } catch (error) {}
};

export const isValidMomentDate = (value) => {
  // Check if the value has less than 5 characters
  if (typeof value === "string" && value.length < 5) {
    return false;
  }

  // Check if the value is a Moment.js object
  if (moment.isMoment(value)) {
    return true;
  }

  // Check if the value is a valid date string
  if (moment(value, moment.ISO_8601, true).isValid()) {
    return true;
  }

  // Return false if the value is neither a Moment.js object nor a valid date string
  return false;
};

export const isValidPhone = (phone) => {
  // const pattern = /^[1-9][0-9]*$/;
  const pattern = /^[0-9]+$/;
  return pattern.test(phone);
};

export const makeGroupByReports = (reports) => {
  const groupedData = _.groupBy(reports, "groupId");

  // Format the output
  const formattedData = _.map(groupedData, (group, groupId) => ({
    groupId: parseInt(groupId, 10), // Convert groupId to number if needed
    groupName: group[0].groupName,
    reports: group,
  }));

  return formattedData;
};

export const getFileFromUrl = async (url, name, defaultType = "image/jpeg") => {
  try {
    var isError = !url || url?.match("null") || url?.match("No Image");
    if (isError) {
      return false;
    } else {
      const response = await fetch(url);
      const data = await response.blob();
      return new File([data], name, {
        type: response.headers.get("content-type") || defaultType,
      });
    }
  } catch (error) {
    console.log("Error fetching file...", error);
    return false;
  }
};

export const fixCoordinate = (coordinate) => {
  return parseFloat(`${coordinate}`).toFixed(4);
};

export const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return "0 Bytes";

  const k = 1000;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const loadTypesIcon = (category) => {
  if (category) {
    try {
      return require(`../assets/images/type icons/new-types/type${category}.svg`)
        .default;
    } catch (error) {
      return require(`../assets/images/type icons/new-types/typecar.svg`)
        .default;
    }
  } else {
    return require(`../assets/images/type icons/new-types/typecar.svg`).default;
    // return require('../../../assets/images/type icons/new-types/typePickup.svg').default;
  }
};

{
  /* <svg id="Capa_1" data-name="Capa 1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 750 900">
    <defs>
        <style>.cls-1{fill:none;}.cls-2{fill:#fb2b3a;}.cls-3{fill:#2d2d2d;opacity:0.1;}.cls-4{fill:#ebebeb;}.cls-5{fill:#d3d1d1;}.cls-6{clip-path:url(#clip-path);}.cls-7{fill:#606060;}.cls-8{fill:#fff;filter:url(#filter);}.cls-9{font-size:70px;font-family:Arial-BoldMT, Arial;font-weight:700;}</style>
        <clipPath id="clip-path">
            <circle class="cls-1" cx="300" cy="300" r="150.68"/>
        </clipPath>
        <filter id="filter" x="0" y="640" width="720" height="210" filterUnits="userSpaceOnUse">
            <feOffset input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="9" result="blur"/>
            <feFlood/>
            <feComposite operator="in" in2="blur"/>
            <feComposite in="SourceGraphic"/>
        </filter>
    </defs>
    <path class="cls-2" d="M525.82,300c0-115.26-92.5-208.91-207.31-210.79C201.93,87.3,104.87,182.11,104.17,298.7A210.74,210.74,0,0,0,213.05,484.55a91.54,91.54,0,0,1,32.38,30.81l47.38,73.71a26.37,26.37,0,0,0,44.36,0l47.39-73.72A91.72,91.72,0,0,1,417,484.5a210.73,210.73,0,0,0,108.8-184.5Z"/>
    <path class="cls-3" d="M525.82,300A210.75,210.75,0,0,1,417,484.51a91.63,91.63,0,0,0-32.46,30.85l-47.39,73.71a26.36,26.36,0,0,1-44.35,0l-.75-1.16a26.15,26.15,0,0,0,16-11.39L355.46,502.84a91.71,91.71,0,0,1,32.46-30.85,210.88,210.88,0,0,0-4.28-371.36c82.74,28.49,142.18,107,142.18,199.4Z"/>
    <circle class="cls-4" cx="300" cy="300" r="150.68"/>
    <path class="cls-5" d="M450.68,300a150.78,150.78,0,0,1-199,142.78A150.71,150.71,0,0,0,323.24,151.1,150.72,150.72,0,0,1,450.68,300Z"/>
    <g class="cls-6">
        <path class="cls-7" d="M300,212a56.07,56.07,0,1,0,56.07,56.07A56,56,0,0,0,300,212Z"/>
        <path class="cls-7" d="M321.83,330.62H278.15a79.73,79.73,0,0,0-79.68,79.69v32.46a8.69,8.69,0,0,0,8.85,8.85H392.66a8.69,8.69,0,0,0,8.85-8.85V410.31A79.73,79.73,0,0,0,321.83,330.62Z"/>
    </g>
    <rect class="cls-8" x="10" y="670" width="700" height="120" rx="12"/>
    <text class="cls-9" transform="translate(120 755)">TEST DRIVER</text>
</svg> */
}

export const getModuleNameByURL = (path) => {
  if (["/live"].includes(path)) {
    return "LIVE";
  }

  if (["/dashboard"].includes(path)) {
    return "DASHBOARD";
  }

  if (["/trips"].includes(path)) {
    return "TRIPS";
  }

  if (["/reports"].includes(path)) {
    return "REPORTS";
  }

  if (["/fleet-manager"].includes(path)) {
    return "FLEET MANAGER";
  }

  if (["/expense-manager"].includes(path)) {
    return "EXPANSE MANAGER";
  }

  if (["/tolls"].includes(path)) {
    return "SALIK & DARB";
  }

  if (["/zone-poi"].includes(path)) {
    return "ZONE & POI";
  }

  if (["/task_manager"].includes(path)) {
    return "TASK MANAGER";
  }

  if (["/settings/"].includes(path)) {
    return "SETTINGS";
  }

  if (["/tutorials"].includes(path)) {
    return "TUTORIALS";
  }

  return "OTHER";
};
