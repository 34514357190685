import React from 'react'

export const _driverFields = {
    email : 'email',
    contact : 'phoneNumber'
};

const DriverFields = ( props ) => {
    return <p className="mb-0 font-12 text-black">{ props.driver.name} <small>({props.driver.email})</small> </p>
}

export default DriverFields
