// google-chrome --user-data-dir="/var/tmp/Chrome" --disable-web-security
import React, { useEffect, Suspense } from "react";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import ReactGA from "react-ga4";

import { ToastContainer } from "react-toastify";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import "./assets/css/soft-ui-dashboard.css?v=1.0.2";
import "react-datepicker/dist/react-datepicker.css";
import "react-datetime/css/react-datetime.css";
import "./assets/css/buttons.css";
import "./assets/css//bootstrap.min.css";
import "./App.css";
import "./KD.css";

export const IOT_DEVICE = false;
export const IOT_USER = "TCSIKJI1";
export const IOT_PASSWORD = "6kKB7B7nGbREWum8";

import { createStore, combineReducers, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import ReduxThunk from "redux-thunk";
import GroupReducer from "./store/reducers/group.reducer";
import { getStorage, setStorage } from "./services/localStorageService";
import TripReducer from "./store/reducers/trip.reducer";
import SettingReducer from "./store/reducers/setting.reducer";
import ReportReducer from "./store/reducers/report.reducer";
import FleetManagerReducer from "./store/reducers/fleetManager.reducer";
import DashboardReducer from "./store/reducers/dashboard.reducer";
import GeoZone from "./store/reducers/geozone.reducer";
import AssetReducer from "./store/reducers/asset.reducer";
import TaskReducer from "./store/reducers/taskManager.reducer";
// import LeafletMap_App from "./pages/LeafletMap/LeafletMap";
import { socketEmit, startSocketIO } from "./sockets/SocketIO";
import ChatReducer from "./store/reducers/chat.reducer";
import TollReducer from "./store/reducers/toll.reducer";
import ExpenseReducer from "./store/reducers/expenseManager.reducer";
import ImmobilizerReducer from "./store/reducers/immobilizer.reducer";
import InspectionReducer from "./store/reducers/inspection.reducer";

// import ReportsPDFGenerator from './components/reports/PDF/ReportsPDFGenerator';

// import { useJsApiLoader } from '@react-google-maps/api';
// import Loader from './components/UI/Loader';

// import LeafletMap from "./components/live/LeafletMap";
import MainLoader from "./components/UI/MainLoader";
import OptimizationReducer from "./store/reducers/optimizer.reducer";
import RouteReducer from "./store/reducers/route.reducer";
import ServiceCenterDashboard from "./pages/ServiceCenter/ServiceCenterDashboard";
import OtherSettings from "./pages/settings/OtherSettings";
// import { requestForToken, onMessageListener } from './components/notifications/firebaseInit';
import TimelineView from "./components/taskManager/task/timeline/TimelineView";

import "./firebase";
import NotFound from "./pages/others/NotFound";
import ServiceCenterProfile from "./pages/ServiceCenter/ServiceCenterProfile";
import Maintenance from "./pages/others/Maintenance";
import StorageSummary from "./pages/settings/StorageSummary";
import SubscriptionExpired from "./pages/others/SubscriptionExpired";
import SiteBroken from "./pages/others/SiteBroken";
import RatingsAndReview from "./pages/RatingsAndReview";
import { trackActivePage } from "./configs/analytics";

const Login = React.lazy(() => import("./pages/Login"));
const SharedLocation = React.lazy(() => import("./pages/SharedLocation"));
const Tolls = React.lazy(() => import("./pages/tolls/Tolls"));
const Live = React.lazy(() => import("./pages/Live"));
const ShareRoute = React.lazy(() => import("./pages/ShareRoute/ShareRoute"));

const TollSetting = React.lazy(() => import("./pages/settings/TollSetting"));
const ThemesSetting = React.lazy(() =>
  import("./pages/settings/ThemesSetting")
);
const ExpanseManager = React.lazy(() =>
  import("./pages/ExpanseManager/ExpanseManager")
);
// const OpenStreetMap = React.lazy(() => import('./components/live/OpenStreetMap'));
const ExpenceManager = React.lazy(() =>
  import("./pages/settings/ExpanceManager")
);
const Attendance = React.lazy(() => import("./pages/Attendance/Attendance"));

const Dashboard = React.lazy(() => import("./pages/Dashboard"));
const Trips = React.lazy(() => import("./pages/Trips"));
const Reports = React.lazy(() => import("./pages/Reports"));
const FleetManager = React.lazy(() => import("./pages/FleetManager"));
const ZonePOI = React.lazy(() => import("./pages/ZonePOI"));
const TaskManager = React.lazy(() => import("./pages/TaskManager"));
const AssetsManager = React.lazy(() => import("./pages/AssetsManager"));
// const Rental = React.lazy(() => import("./pages/Rental"));
const VehicleInspection = React.lazy(() => import("./pages/VehicleInspection"));
const Account = React.lazy(() => import("./pages/settings/Account"));
const Profile = React.lazy(() => import("./pages/settings/Profile"));
const LiveSetting = React.lazy(() => import("./pages/settings/LiveSetting"));
const DashboardSetting = React.lazy(() =>
  import("./pages/settings/DashboardSetting")
);
const TaskManagerSetting = React.lazy(() =>
  import("./pages/settings/TaskManagerSetting")
);
const InspectionSettings = React.lazy(() =>
  import("./pages/settings/InspectionSettings")
);
const NotificationSetting = React.lazy(() =>
  import("./pages/settings/NotificationSetting")
);
const Report = React.lazy(() => import("./pages/settings/Report"));
const FleetManagerSettings = React.lazy(() =>
  import("./pages/settings/FleetManager")
);
const ServiceCenterLogin = React.lazy(() =>
  import("./pages/ServiceCenter/ServiceCenterLogin")
);

const Tutorials = React.lazy(() => import("./pages/Tutorials/Tutorials"));
const Immobilizer = React.lazy(() => import("./pages/Immobilizer/Immobilizer"));
const Cam = React.lazy(() => import("./pages/dashCam/Cam"));

const rootReducer = combineReducers({
  groupReducer: GroupReducer,
  tripReducer: TripReducer,
  settingReducer: SettingReducer,
  reportReducer: ReportReducer,
  fleetManagerReducer: FleetManagerReducer,
  dashboardReducer: DashboardReducer,
  geoZoneReducer: GeoZone,
  assetReducer: AssetReducer,
  taskReducer: TaskReducer,
  chatReducer: ChatReducer,
  tollReducer: TollReducer,
  expenseReducer: ExpenseReducer,
  optimizationReducer: OptimizationReducer,
  routeReducer: RouteReducer,
  immobilizerReducer: ImmobilizerReducer,
  inspectionReducer: InspectionReducer,
});

const store = createStore(rootReducer, applyMiddleware(ReduxThunk));

function App() {
  // requestForToken();

  // onMessageListener()
  //   .then((payload) => {
  //     console.log('payload', payload)
  //     // setNotification({title: payload?.notification?.title, body: payload?.notification?.body});
  //   })

  // eslint-disable-next-line

  // const { isLoaded, loadError } = useJsApiLoader({
  //   googleMapsApiKey: "AIzaSyDiu91FonB6BiQDxaIUAgbr5usMu9D0Fyk" ,
  //   libraries: ["places"]
  // });

  if (getStorage("background") == null) {
    setStorage(
      "background",
      `url(${require("./assets/images/themes/1.png").default})`
    );
  }

  document.body.style.backgroundImage = getStorage("background");

  // Socket
  useEffect(() => {
    startSocketIO(store);
    if (navigator.appVersion.indexOf("Win") !== -1) {
      document.documentElement.style.setProperty("--scrollbar-width", "thin");
    }

    trackActivePage(window.location.pathname + window.location.search);
  }, []);

  const ModulesPermissions = getStorage("ModulesPermissions", "object");
  const user = getStorage("__user__", "object");
  const isModuleGuardActivated = getStorage("isModuleGuardActivated");
  // if(!isLoaded) return <Loader />
  // http://localhost:3000/share-route/65fe98374cd99fb0ca0dd95f
  if (
    !ModulesPermissions &&
    !isModuleGuardActivated &&
    !window.location.pathname.includes("/share-route")
  ) {
    setStorage("isModuleGuardActivated", true);
    window.location.href = "/login";
  }

  useEffect(() => {
    ReactGA.initialize("G-6B3TXTSM2Z", {
      gaOptions: user
        ? {
            userId: user.id,
            name: `${user.name}_${user.organisation.name}_${user.id}`,
          }
        : {},
    });
  }, [user]);

  return (
    <Provider store={store}>
      <Suspense fallback={<MainLoader />}>
        <Router>
          <Switch>
            <Redirect exact from="/" to="/login" />

            <Route path="/login">
              <Login />
            </Route>

            <Route path="/site-broken">
              <SiteBroken />
            </Route>

            <Route path="/subscription-expired">
              <SubscriptionExpired />
            </Route>

            <Route path="/share/:minutes/:type/:id">
              <SharedLocation />
            </Route>

            <Route path="/ratings-review/:task">
              <RatingsAndReview />
            </Route>

            <Route path="/tutorials">
              <Tutorials />
            </Route>

            {ModulesPermissions?.dashboard?.allowAllModules && (
              <Route path="/dashboard">
                <Dashboard />
              </Route>
            )}

            {/* <Route path="/OpenStreetMap">
              <OpenStreetMap />
            </Route> */}
            {/* <Route path="/live">
              <Live />
            </Route> */}
            {ModulesPermissions?.live?.allowAllModules && (
              <Route path="/live">
                <Live />
              </Route>
            )}

            {/* <Route path="/map-pas">
              <LeafletMap showSidebar={false} />
            </Route>

            <Route path="/pdf">
              <ReportsPDFGenerator />
            </Route> */}

            {ModulesPermissions?.trips?.allowAllModules && (
              <Route path="/trips">
                <Trips />
              </Route>
            )}

            {ModulesPermissions?.reports?.allowAllModules && (
              <Route path="/reports">
                <Reports />
              </Route>
            )}

            {ModulesPermissions?.fleetManager?.allowAllModules && (
              <Route path="/fleet-manager">
                <FleetManager />
              </Route>
            )}

            {ModulesPermissions?.expanseManager?.allowAllModules && (
              <Route path="/expense-manager">
                <ExpanseManager />
              </Route>
            )}

            {ModulesPermissions?.attendance?.allowAllModules && (
              <Route path="/attendance">
                <Attendance />
              </Route>
            )}

            {ModulesPermissions?.tolls?.allowAllModules && (
              <Route path="/tolls">
                <Tolls />
              </Route>
            )}

            {ModulesPermissions?.zoneAndPoi?.allowAllModules && (
              <Route path="/zone-poi">
                <ZonePOI />
              </Route>
            )}

            {ModulesPermissions?.taskManager?.allowAllModules && (
              <Route path="/task_manager">
                <TaskManager />
              </Route>
            )}

            {ModulesPermissions?.immobilizer?.allowAllModules && (
              <Route path="/immobilizer">
                <Immobilizer />
              </Route>
            )}
            {ModulesPermissions?.dashCam?.allowAllModules && (
              <Route path="/cam">
                <Cam />
              </Route>
            )}

            <Route path="/tasks/timeline">
              <TimelineView />
            </Route>

            {ModulesPermissions?.assetsTracking?.allowAllModules && (
              <Route path="/assets-manager">
                <AssetsManager />
              </Route>
            )}

            {/* <Route path="/rental">
              <Rental />
            </Route> */}

            <Route path="/vehicle-inspection">
              <VehicleInspection />
            </Route>

            <Route path="/maintenance">
              <Maintenance />
            </Route>

            {/* Service Center */}

            <Route path="/service-center/login">
              <ServiceCenterLogin />
            </Route>

            <Route path="/service-center/dashboard">
              <ServiceCenterDashboard />
            </Route>
            <Route path="/service-center/profile">
              <ServiceCenterProfile />
            </Route>

            {/* FOR PUBLIC ONLY */}
            <Route path="/share-route/:id">
              <ShareRoute />
            </Route>

            <Route path="/settings/storage-summary">
              <StorageSummary />
            </Route>

            {user?.administrator == true ||
            user?.administrator == 1 ||
            user?.readonly == false ? (
              <>
                <Route path="/settings/profile">
                  <Profile />
                </Route>

                <Route path="/settings/account">
                  <Account />
                </Route>

                <Route path="/settings/live">
                  <LiveSetting />
                </Route>

                <Route path="/settings/dashboard">
                  <DashboardSetting />
                </Route>

                <Route path="/settings/task-manager">
                  <TaskManagerSetting />
                </Route>

                <Route path="/settings/inspection">
                  <InspectionSettings />
                </Route>

                <Route path="/settings/notification">
                  <NotificationSetting />
                </Route>

                <Route path="/settings/report">
                  <Report />
                </Route>

                <Route path="/settings/fleet-manager">
                  <FleetManagerSettings />
                </Route>

                <Route path="/settings/toll">
                  <TollSetting />
                </Route>

                <Route path="/settings/themes">
                  <ThemesSetting />
                </Route>

                <Route path="/settings/expenceManager">
                  <ExpenceManager />
                </Route>

                <Route path="/settings/other-settings">
                  <OtherSettings />
                </Route>
              </>
            ) : (
              <></>
            )}

            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
          <ToastContainer autoClose={4000} />
        </Router>
      </Suspense>
    </Provider>
  );
}

export default App;
