import { useState, useEffect } from "react";

const Checkbox = (props) => {
  const { labelClass = "", labelStyle = {} } = props;

  const classes = "mb-0 text-sm " + props.className ? props.className : "";
  const [checked, setChecked] = useState(props.checked);

  const checkedChange = (event) => {
    setChecked(event.target.checked);
    if (props.onChange) props.onChange(event, props.id);
  };

  useEffect(() => {
    setChecked(props.checked);
  }, [props.checked]);

  return (
    <div className={classes} onClick={(e) => e.stopPropagation()}>
      <input
        defaultChecked={props.defaultChecked}
        onChange={checkedChange}
        type="checkbox"
        id={props.id}
        className="vh"
        checked={checked}
        name={props.name ? props.name : ""}
      />
      <label
        className={`check--box-lab ${labelClass}`}
        data-tip={props["data-tip"] ? props["data-tip"] : ""}
        style={labelStyle}
        htmlFor={props.id}
      ></label>
    </div>
  );
};

export default Checkbox;
