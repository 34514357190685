import { Subject } from 'rxjs';

const markerSubject = new Subject();
const markerZoomSubject = new Subject();
const clearAlertSubject = new Subject();

export const VehicleService = {
    updateToggleMarker: vehicle => markerSubject.next(vehicle),
    getToggleMarker: () => markerSubject.asObservable(),
    flyOnMarker: latlong => markerZoomSubject.next(latlong),
    subscribeCurruntMarker: () => markerZoomSubject.asObservable(),
    clearAlert: isClear => clearAlertSubject.next(isClear),
    subscribeClearAlert: () => clearAlertSubject.asObservable()
};
