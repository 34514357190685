import moment from "moment";
import React, { useState } from "react";
import { useEffect } from "react";
import { FaEdit } from "react-icons/fa";
import MapContentView from "../../components/layouts/MapContentView/MapContentView";
import EditServiceExpense from "../../components/serviceCenter/EditServiceExpense";
import Header from "../../components/serviceCenter/Header";
import SubHeader from "../../components/serviceCenter/SubHeader";
import Loader from "../../components/UI/Loader";
import { getColor } from "../../configs/common";
import { ShowNotification } from "../../services/Notifications";
import ServiceCenterRequest from "../../services/ServiceCenterRequest";
import _ from "underscore";
import "../layout.css";
import "./serviceCenter.css";

const ServiceCenterDashboard = () => {
  const [expensesResponse, setExpensesResponse] = useState([]);
  const [expenses, setExpenses] = useState([]);
  const [isLoading, setLoading] = useState(true);

  const [status, setStatus] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");
  const [tabFilter, setTabFilter] = useState("");
  const [search, setSearch] = useState("");

  const [interval, setInterval] = useState("Current Month");
  const [startDate, setStartDate] = useState(
    new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1))
  );
  const [endDate, setEndDate] = useState(new Date());

  const [active, setActive] = useState(false);
  const [isEditModal, setEditModal] = useState(false);

  const toggleEditModal = (data = false) => {
    setActive(data);
    setEditModal(!isEditModal);
  };

  const loadExpenses = async (data = {}, isLoadMore = false) => {
    var _data = {
      startDate: startDate,
      endDate: endDate,
      status,
      paymentStatus,
      ...data,
    };

    if (tabFilter == "0") {
      _data.type = ["Expense"];
      _data.notVerified = true;
    }

    if (tabFilter == "1") {
      _data.type = ["Expense"];
      _data.isVerified = true;
    }

    if (tabFilter == "2") {
      _data.status = "Approved";
      _data.isApproved = true;
    }

    try {
      setLoading(true);
      const response = await ServiceCenterRequest(
        "/service-center/expenses",
        _data
      );
      setExpensesResponse(response);
    } catch (error) {
      ShowNotification("error", error);
    } finally {
      setLoading(false);
    }
  };

  const _filter = () => {
    const clone = [...expensesResponse];
    var newData = [];

    if (search !== "") {
      newData = _.filter(
        clone,
        (expense) =>
          expense.driver?.name?.match(new RegExp(search, "gi")) ||
          expense.serviceCenter?.centerName?.match(new RegExp(search, "gi")) ||
          expense.amount?.toString().match(new RegExp(search, "gi")) ||
          expense.status?.match(new RegExp(search, "gi")) ||
          expense.paymentStatus?.match(new RegExp(search, "gi")) ||
          "Shams Sales L74324".match(new RegExp(search, "gi")) ||
          expense.description?.match(new RegExp(search, "gi"))
      );
    } else {
      newData = clone;
    }

    return newData;
  };

  useEffect(() => {
    setExpenses(_filter());
  }, [expensesResponse, search]);

  useEffect(() => {
    loadExpenses();
  }, []);

  useEffect(() => {
    loadExpenses();
  }, [status, paymentStatus, tabFilter, startDate, endDate]);

  return (
    <div className="as-main-container">
      <div className="as-header pb-2">
        <Header />
      </div>

      <div className={`as-body-conten as-body-content-no-sidebar`}>
        <div className="as-body-data w-100">
          <MapContentView style={{ borderLeft: "none" }}>
            <EditServiceExpense
              show={isEditModal}
              toggleModal={toggleEditModal}
              data={active}
              loadExpenses={loadExpenses}
            />

            <SubHeader
              setInterval={setInterval}
              interval={interval}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              startDate={startDate}
              endDate={endDate}
              loadExpenses={loadExpenses}
              status={status}
              setStatus={setStatus}
              paymentStatus={paymentStatus}
              setPaymentStatus={setPaymentStatus}
              tabFilter={tabFilter}
              setTabFilter={setTabFilter}
              search={search}
              setSearch={setSearch}
              total={expenses.length}
              isLoading={isLoading}
            />

            <div
              style={{ overflow: "auto", height: "72vh" }}
              className="visible-scroll-hover"
            >
              {isLoading ? (
                <Loader style={{ left: "45%" }} />
              ) : expenses.length <= 0 ? (
                <div
                  className="placeholder-content-view text-center"
                  style={{ marginTop: 250 }}
                >
                  <span>There are no any data available yet!</span>
                </div>
              ) : (
                <>
                  <div className="e-card-th">
                    <div className="row">
                      <div className="col-md-1 e-th">Date</div>
                      <div className="col-md-2 e-th">Vehicle</div>
                      <div className="col-md-1 e-th">Employee Name</div>
                      <div className="col-md-1 e-th">Service Name</div>
                      <div className="col-md-2 e-th">Note</div>
                      <div className="col-md-1 e-th">Amount</div>
                      <div className="col-md-1 e-th">Status</div>
                      <div className="col-md-1 e-th">Job Status</div>
                      <div className="col-md-1 e-th">Payment Status</div>
                      <div className="col-md-1 e-th">Action</div>
                    </div>
                  </div>

                  {expenses.map((item, i) => (
                    <div className={`service-card pointer `} key={i}>
                      <div className="row">
                        <div className="col-md-1 e-td">
                          {moment(item.createdAt).format("DD/MM/yyyy HH:mm")}
                        </div>
                        <div className="col-md-2 e-td">Shams Sales L74324</div>
                        <div className="col-md-1 e-td">
                          {item.driver?.name || "--"}
                        </div>
                        <div className="col-md-1 e-td">
                          {item.serviceCenter?.centerName || "--"}
                        </div>
                        <div className="col-md-2 e-td-description">
                          {item.description}
                        </div>
                        <div className="col-md-1 e-td">
                          {item.amount || 0} AED
                        </div>
                        <div
                          className="col-md-1 e-td"
                          style={{ color: getColor(item.status) }}
                        >
                          {item.type == "Payment" ? "--" : item.status}
                        </div>
                        <div
                          className="col-md-1 e-td"
                          style={{
                            color: getColor(
                              item.type == "Payment"
                                ? "Completed"
                                : item.jobStatus || "Work Not Started"
                            ),
                          }}
                        >
                          {item.type == "Payment"
                            ? "--"
                            : item.jobStatus || "Work Not Started"}
                        </div>
                        <div
                          className="col-md-1 e-td"
                          style={{ color: getColor(item.paymentStatus) }}
                        >
                          {item.paymentStatus}
                        </div>
                        <div className="col-md-1 e-td">
                          {item.status == "Pending" ? (
                            <FaEdit
                              onClick={() => toggleEditModal(item)}
                              color="#3166ee"
                              size={20}
                            />
                          ) : (
                            "-"
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
          </MapContentView>
        </div>
      </div>
    </div>
  );
};

export default ServiceCenterDashboard;
