import React, { useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Container } from "react-bootstrap";
import Select from "../../UI/Select";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import ReportRequest from "../../../services/ReportRequest";
import Loader from "../../UI/Loader";
import "../../../components/reports/ReportLayout/ReportLayout.css";
import PostRequest from "../../../services/PostRequest";
import { ShowNotification } from "../../../services/Notifications";
import ReactToPrint from "react-to-print";

const Reminders = (props) => {
  const printRef = useRef();

  const _vehicles = useSelector((state) => state.settingReducer.vehicles);

  const [intervalType, setIntervalType] = useState("Expired");
  const [reminderType, setReminderType] = useState("Document Reminder");

  const [reminders, setReminders] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const [isRenewModal, setRenewModal] = useState(false);
  const [reminderToRenew, setReminderToRenew] = useState(false);
  const [renewType, setRenewType] = useState("month");

  const toggleRenewModal = (reminder) => {
    if (reminder) {
      if (!window.confirm("Are you sure you want to update expiry date?"))
        return;
    }

    setReminderToRenew(reminder);
    setRenewModal(!isRenewModal);
  };

  const onChangeReminderType = (e) => {
    var _date = new Date().setHours(0, 0, 0, 0);
    var val = e.target.value;

    setReminderType(val);
    setIntervalType(val == "Document Reminder" ? "Expired" : "Due");

    loadData({
      fromDate: moment(_date).format("DD-MM-yyyy HH:mm:00"),
      toDate: moment(
        new Date(_date).setDate(
          val == "Document Reminder"
            ? new Date().getDate() + 1
            : new Date().getDate() - 1
        )
      ).format("DD-MM-yyyy HH:mm:00"),
      today: val == "Document Reminder" ? 1 : "due",
      reportID: val == "Document Reminder" ? 8 : 9,
      vehIDs: _vehicles?.map((vehicle) => [
        vehicle.name,
        vehicle.uniqueId,
        parseInt(vehicle.vehicleID.toString()),
      ]),
    });
  };

  const makeDataFromInterval = (val) => {
    var _date = new Date().setHours(0, 0, 0, 0);

    var data = {
      fromDate: moment(_date).format("DD-MM-yyyy HH:mm:00"),
      toDate: moment(new Date(_date).setDate(new Date().getDate() + 1)).format(
        "DD-MM-yyyy HH:mm:00"
      ),
      today: 1,
      reportID: reminderType == "Document Reminder" ? 8 : 9,
      vehIDs: _vehicles?.map((vehicle) => [
        vehicle.name,
        vehicle.uniqueId,
        parseInt(vehicle.vehicleID.toString()),
      ]),
    };

    switch (val) {
      case "In 7 Days":
        data.today = 0;
        data.toDate = moment(
          new Date(_date).setDate(new Date().getDate() + 7)
        ).format("DD-MM-yyyy HH:mm:00");
        break;
      case "In 30 Days":
        data.today = 0;
        data.toDate = moment(
          new Date(_date).setDate(new Date().getDate() + 30)
        ).format("DD-MM-yyyy HH:mm:00");
        break;
      case "Due":
        data.toDate = moment(
          new Date(_date).setDate(new Date().getDate() - 1)
        ).format("DD-MM-yyyy HH:mm:00");
        data.today = "due";
        break;
      case "0-500 KM":
        data.toDate = moment(
          new Date(_date).setDate(new Date().getDate() - 1)
        ).format("DD-MM-yyyy HH:mm:00");
        data.today = "500";
        break;
      case "500-1000 KM":
        data.toDate = moment(
          new Date(_date).setDate(new Date().getDate() - 1)
        ).format("DD-MM-yyyy HH:mm:00");
        data.today = "1000";
        break;
    }

    return data;
  };

  const onChangeIntervalType = (e) => {
    var val = e.target.value;
    setIntervalType(val);
    loadData(makeDataFromInterval(val));
  };

  const loadData = async (data) => {
    try {
      setLoading(true);
      const response = await ReportRequest(
        "/ReportCreator",
        JSON.stringify(data)
      );
      setReminders(response);
    } catch (error) {
      setReminders([]);
    } finally {
      setLoading(false);
    }
  };

  const onRenewReminder = async () => {
    try {
      setLoading(true);
      var d = {
        deviceid: reminderToRenew.deviceid,
        id:
          reminderType == "Document Reminder"
            ? reminderToRenew.document_id
            : reminderToRenew.remainder_id,
        reportid: reminderType == "Document Reminder" ? 8 : 9,
        interval: renewType,
      };
      const response = await PostRequest(
        "/user/updateremainderduedate",
        d,
        "renewReminder"
      );
      ShowNotification("success", "Remainder Expiry updated Successfully");
      toggleRenewModal(false);
      loadData(makeDataFromInterval(intervalType));
    } catch (error) {
      ShowNotification("error", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (props.show) {
      setIntervalType("Expired");
      setReminderType("Document Reminder");
      var _date = new Date().setHours(0, 0, 0, 0);
      loadData({
        fromDate: moment(_date).format("DD-MM-yyyy HH:mm:00"),
        toDate: moment(
          new Date(_date).setDate(new Date().getDate() + 1)
        ).format("DD-MM-yyyy HH:mm:00"),
        today: 1,
        reportID: 8,
        vehIDs: _vehicles?.map((vehicle) => [
          vehicle.name,
          vehicle.uniqueId,
          parseInt(vehicle.vehicleID.toString()),
        ]),
      });
    }
  }, [props.show]);

  const getIntervalLabel = (interval) => {
    if (interval % 7 == 0)
      return `${interval / 7} Week${interval / 7 == 1 ? "" : "s"}`;
    if (interval % 30 == 0)
      return `${interval / 30} Month${interval / 30 == 1 ? "" : "s"}`;
    return `${interval} Day${interval == 1 ? "" : "s"}`;
  };

  const printDiv = () => {
    window.print();
  };

  return (
    <>
      <Modal
        show={props.show}
        dialogClassName="modal-70w"
        onHide={props.toggleModal}
        aria-labelledby="Reminders"
      >
        <Modal.Header closeButton>Reminders</Modal.Header>
        <Modal.Body className="show-grid">
          <div className="row">
            <div className="col-md-6 my-3">
              <Select
                title="Reminder Type"
                name="reminderType"
                value={reminderType}
                onChange={onChangeReminderType}
              >
                <option value=""></option>
                <option value="Document Reminder">Document Reminder</option>
                <option value="Service Reminder">Service Reminder</option>
              </Select>
            </div>
            <div className="col-md-5 my-3">
              <Select
                title="Interval"
                name="intervalType"
                value={intervalType}
                onChange={onChangeIntervalType}
              >
                {reminderType == "Document Reminder" && (
                  <>
                    <option value="Expired">Expired</option>
                    <option value="In 7 Days">In 7 Days</option>
                    <option value="In 30 Days">In 30 Days</option>
                  </>
                )}

                {reminderType == "Service Reminder" && (
                  <>
                    <option value="Due">Due</option>
                    <option value="0-500 KM">0-500 KM</option>
                    <option value="500-1000 KM">500-1000 KM</option>
                  </>
                )}
              </Select>
            </div>
            <div className="col-md-1 my-auto">
              {/* <button onClick={printDiv} style={{ fontSize: '12px', backgroundColor: '#007bff', color: '#ffffff', height: 40, marginTop: 12 }} className="btn btn-small mb-0 w-100" >Print</button> */}
              <ReactToPrint
                trigger={() => {
                  return (
                    <button
                      onClick={printDiv}
                      style={{
                        fontSize: "12px",
                        backgroundColor: "#007bff",
                        color: "#ffffff",
                        height: 40,
                        marginTop: 12,
                      }}
                      className="btn btn-small mb-0 w-100"
                    >
                      Print
                    </button>
                  );
                }}
                content={() => printRef.current}
                onBeforeGetContent={() => {
                  return new Promise((resolve, reject) => {
                    // setExtraContent(true)
                    resolve();
                  });
                }}
                // onAfterPrint={() => setExtraContent(false)}
                // onPrintError={() => setExtraContent(false)}
              />
            </div>
          </div>

          <div
            ref={printRef}
            className="row mt-3 mx-0"
            style={{ maxHeight: "60vh", overflowY: "auto" }}
          >
            <div className="col-md-12">
              <div className="print-only">
                <div className="d-flex justify-content-between mt-3 ">
                  <img
                    src={
                      require("../../../assets/images/login-logo.png").default
                    }
                    height={40}
                    alt=""
                  />
                  <div className="text-end">
                    <div className="mb-1  font-14">{reminderType}</div>
                    <div className="text-theme font-12">
                      {intervalType}{" "}
                      {["Due", "Expired"].includes(intervalType) ? "List" : ""}
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-center mb-3 text-theme font-14 print-hidden">
                {intervalType}{" "}
                {["Due", "Expired"].includes(intervalType) ? "List" : ""}
              </div>
              {isLoading && <Loader style={{ left: "45%", top: "40%" }} />}
              {reminderType == "Document Reminder" && (
                <>
                  {reminders.map((documentReminder) => {
                    if (documentReminder.vehicles?.length == 0) return;
                    return (
                      <div
                        key={documentReminder.deviceid}
                        className="row distance-report-view"
                      >
                        <div className="col-md-12">
                          <div className="distance-report-header text-start">
                            {documentReminder.name}
                          </div>
                        </div>

                        <div className="col-md-12">
                          <table className="table table-striped table-bordered">
                            <thead>
                              <tr>
                                <th>Sl No </th>
                                <th>Document type</th>
                                <th>Expiry Date</th>
                                <th>Reminder Interval</th>
                                <th>Comments</th>
                                <th style={{ width: 100 }}></th>
                              </tr>
                            </thead>
                            <tbody>
                              {documentReminder.vehicles?.map(
                                (vehicle, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>{index + 1}</td>
                                      <td>{vehicle.document_type}</td>
                                      <td>{vehicle.document_expiry_date}</td>
                                      <td>
                                        {getIntervalLabel(
                                          parseInt(
                                            vehicle.document_remainder_interval?.toString()
                                          )
                                        )}
                                      </td>
                                      <td
                                        style={{ whiteSpace: "normal" }}
                                        className="text-start"
                                      >
                                        {vehicle.document_comments}
                                      </td>
                                      <td>
                                        <button
                                          onClick={() =>
                                            toggleRenewModal({
                                              ...vehicle,
                                              deviceid:
                                                documentReminder.deviceid,
                                            })
                                          }
                                          style={{
                                            fontSize: "12px",
                                            backgroundColor: "#007bff",
                                            color: "#ffffff",
                                          }}
                                          className="btn btn-small mb-0 print-hidden"
                                        >
                                          Renew
                                        </button>
                                      </td>
                                    </tr>
                                  );
                                }
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    );
                  })}
                </>
              )}
              {reminderType == "Service Reminder" && (
                <>
                  {reminders &&
                    reminders.map((serviceReminder) => {
                      if (serviceReminder.vehicles?.length == 0) return;
                      return (
                        <div
                          key={serviceReminder.deviceid}
                          className="row distance-report-view"
                        >
                          <div className="col-md-12">
                            <div className="distance-report-header text-start">
                              {serviceReminder.name}
                            </div>
                          </div>

                          <div className="col-md-12">
                            <table className="table table-striped table-bordered">
                              <thead>
                                <tr>
                                  <th>Sl No </th>
                                  <th>Name</th>
                                  <th>Previous Odometer</th>
                                  <th>Periodic Odometer</th>
                                  <th>Due Date</th>
                                  <th>Due Interval</th>
                                  <th>Comments</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                {serviceReminder.vehicles?.map(
                                  (vehicle, index) => {
                                    return (
                                      <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>
                                          {vehicle.remainder_service_name}
                                        </td>
                                        <td>
                                          {vehicle.remainder_previous_odometer}
                                        </td>
                                        <td>
                                          {vehicle.remainder_periodic_odometer}{" "}
                                          KM
                                        </td>
                                        <td>{vehicle.remainder_due_date}</td>
                                        <td>
                                          {vehicle.remainder_due_interval}{" "}
                                          Months
                                        </td>
                                        <td className="td-w-10 text-start">
                                          <div>
                                            {vehicle.remainder_comments}
                                          </div>
                                        </td>
                                        <td>
                                          <button
                                            onClick={() =>
                                              toggleRenewModal({
                                                ...vehicle,
                                                deviceid:
                                                  serviceReminder.deviceid,
                                              })
                                            }
                                            style={{
                                              fontSize: "12px",
                                              backgroundColor: "#007bff",
                                              color: "#ffffff",
                                            }}
                                            className="btn btn-small mb-0 print-hidden"
                                          >
                                            Renew
                                          </button>
                                        </td>
                                      </tr>
                                    );
                                  }
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      );
                    })}
                </>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={isRenewModal}
        dialogClassName="modal-30w"
        onHide={() => toggleRenewModal(false)}
        aria-labelledby="Reminders"
      >
        <Modal.Header closeButton>Renew Reminder</Modal.Header>
        <Modal.Body className="show-grid">
          <div className="row">
            <div className="col-md-12 ">
              <Select
                title="Interval"
                name="renewType"
                value={renewType}
                onChange={(e) => setRenewType(e.target.value)}
              >
                <option value="month">1 Month</option>
                <option value="year">1 Year</option>
              </Select>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            style={{ fontSize: "12px" }}
            className="btn btn-small"
            onClick={() => toggleRenewModal(false)}
          >
            Cancel
          </button>
          <button
            onClick={onRenewReminder}
            style={{
              fontSize: "12px",
              backgroundColor: "#007bff",
              color: "#ffffff",
            }}
            className="btn btn-small"
            id="renewReminder"
          >
            Update
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Reminders;
