import axios from "axios";
import { getStorage } from "./localStorageService";

import https from "https";
import { API_URLS } from "../configs/api.urls";

const API_URL = API_URLS.API_GET_URL;

axios.create({
  baseURL: API_URL,
  httpsAgent: new https.Agent({
    rejectUnauthorized: true,
  }),
});

const GetRequest = async (path, data = {}, api_url = API_URL) => {
  const token = getStorage("__token__");

  const headers = {
    Xtoken: token,
    "X-XSRF-TOKEN": token,
    "X-CSRF-TOKEN": token,
    "Access-Control-Allow-Origin": "*",
  };

  return new Promise((resolve, reject) => {
    axios
      .get(`${api_url}${path}`, { headers: headers })
      .then(function (response) {
        try {
          if (response.data.responseCode == 401) {
            window.location.href = "/login";
            throw new Error("Unauthorized");
          }
          if (response.status !== 200) {
            throw new Error(response.data.response);
          }
          resolve(response.data);
        } catch (error) {
          throw new Error(error.message);
        }
      })
      .catch(function (error) {
        console.log("API CAll Error,", error);
        // reject(error.message);
      });
  });
};

export default GetRequest;
