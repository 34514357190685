import React, { useState, useEffect } from "react";

const Select = (props) => {
  const [value, setValue] = useState(props.value);

  const onChangeHandler = (event) => {
    setValue(event.target.value);
    if (props.onChange) props.onChange(event);
  };

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  return (
    <fieldset className={props.className}>
      <legend>
        <span>{props.title}</span>
      </legend>
      <select
        className={`settings_select_field ${
          props.selectClass ? props.selectClass : ""
        }`}
        value={value}
        name={props.name}
        onChange={(event) => onChangeHandler(event)}
      >
        {props.children}
      </select>
    </fieldset>
  );
};

export default Select;
