

const ReverseAddress  = ({lat,lng}) => {

    return new Promise(async (resolve) =>{
        
        try {
            const response = await fetch(`https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${lat}&lon=${lng}&accept-language=en`);
            var address = await response.json()
            resolve(address.display_name)
            
        } catch (error) {
            resolve(false)
        }
        

    })

}


export default ReverseAddress;