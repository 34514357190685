import ReactGA from 'react-ga4';
import { getStorage } from '../services/localStorageService';


export const trackActivePage = (path) => {
    setUserForAnalytics();
    ReactGA.send({ hitType: 'pageview', page: path });
};


export const trackLoginTime = () => {

    setUserForAnalytics();

    const loginTime = new Date().getTime();
    ReactGA.event({
      category: 'User',
      action: 'Login Successful',
      label: 'Login Time',
      value: loginTime,
    });
};

export const setUserForAnalytics = () => {
    const user = getStorage("__user__", "object");

    if(user){
     ReactGA.set({ userId : user.id });
    }
};