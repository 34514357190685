import React, { useEffect, useState } from "react";
import DriverCard from "../UI/DriverCard";
import { Dropdown } from "react-bootstrap";
import SelectDateModal from "../modals/task-manager/SelectDateModal";
import { getStorage } from "../../services/localStorageService";
import ServiceCenterRequest from "../../services/ServiceCenterRequest";
import SearchInput from "../SearchInput";

const SubHeader = (props) => {
  const [selectDate, setSelectDate] = useState(false);
  const [serviceCenter, setServiceCenter] = useState(
    getStorage("__serviceCenter__", "object")
  );

  const loadServiceCenter = async () => {
    try {
      const response = await ServiceCenterRequest("/service-center/profile");
      setServiceCenter(response);
    } catch (error) {
      setServiceCenter(getStorage("__serviceCenter__", "object"));
    }
  };

  useEffect(() => {
    loadServiceCenter();
  }, []);

  const onChangeTime = async (_value) => {
    var _from, _to;

    switch (_value) {
      case "Today":
        _from = _to = new Date();
        break;

      case "Yesterday":
        _from = _to = new Date(new Date().getTime() - 1 * 24 * 60 * 60 * 1000);
        break;

      case "Last Week":
        _from = new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000);
        _to = new Date();
        break;

      case "Current Month":
        _from = new Date(
          new Date(new Date().getFullYear(), new Date().getMonth(), 1)
        );
        _to = new Date();
        break;
    }

    await props.setInterval(_value);
    props.setStartDate(_from);
    props.setEndDate(_to);
  };

  const toggleSelectDateModal = () => {
    props.setInterval("Custom Date");
    setSelectDate(!selectDate);
  };

  const onSelectAnyTime = async (_from, _to) => {
    setSelectDate(!selectDate);
    props.setStartDate(_from);
    props.setEndDate(_to);
  };

  return (
    <div className="d-flex justify-content-between px-4 py-2 border-bottom">
      <SelectDateModal
        show={selectDate}
        onSet={onSelectAnyTime}
        toggleModal={toggleSelectDateModal}
      />

      <DriverCard
        driver={false}
        placeholder={`Service History ${
          (props.tabFilter == "" &&
            props.status == "" &&
            props.paymentStatus == "" &&
            props.search == "") ||
          props.isLoading
            ? ""
            : `[${props.total}]`
        }`}
      />

      <div className="d-flex my-auto">
        <SearchInput
          placeholder="Search..."
          value={props.search}
          text={props.search}
          onSearch={(e) => props.setSearch(e.target.value)}
          onClear={() => props.setSearch("")}
          iconStyle={{ position: "relative", right: "8%", marginTop: 0 }}
          containerClass="align-item-center"
        />
        <p className="font-weight-bold my-auto font-11 mr-3">
          Total Balance :{" "}
          {serviceCenter &&
          serviceCenter.balances &&
          serviceCenter.balances.availableBalance
            ? serviceCenter.balances.availableBalance.toFixed(2)
            : 0}{" "}
          AED
        </p>

        <select
          className="font-11 mr-3 my-auto bg-white outline-none border-theme r-5"
          style={{ height: 34, paddingBottom: 3.3 }}
          value={props.status}
          onChange={(e) => props.setStatus(e.target.value)}
        >
          <option value="">Filter By Status</option>
          <option value="Pending">Pending</option>
          <option value="Approved">Approved</option>
          <option value="Rejected">Rejected</option>
        </select>

        <select
          className="font-11 mr-3 my-auto bg-white outline-none border-theme r-5"
          style={{ height: 34, paddingBottom: 3.3 }}
          value={props.paymentStatus}
          onChange={(e) => props.setPaymentStatus(e.target.value)}
        >
          <option value="">Filter By Payment Status</option>
          <option value="Paid">Paid</option>
          <option value="Not Paid">Not Paid</option>
        </select>

        <select
          className="font-11 mr-3 my-auto bg-white outline-none border-theme r-5"
          style={{ height: 34, paddingBottom: 3.3 }}
          value={props.tabFilter}
          onChange={(e) => props.setTabFilter(e.target.value)}
        >
          <option value="">All Bills</option>
          <option value="0">New Bills</option>
          <option value="1">Verified Bills</option>
          <option value="2">Payments</option>
        </select>

        <div className="d-flex" style={{ position: "relative", top: 8 }}>
          <span style={styles.calendarLabel}>{props.interval}</span>

          <Dropdown className="mr-3 ">
            <Dropdown.Toggle
              id="dropdown-basic"
              className="btn-white after-none shadow-none pt-1 pb-1"
            >
              <i className="fa fa-caret-down"></i>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item>
                <div className="row">
                  <div
                    className="col-md-12 pl-0"
                    onClick={() => onChangeTime("Today")}
                  >
                    <i className="fa fa-calendar  mt-2_5 text-success ml-2 font-12"></i>
                    <span className="font-12 text-black ml-2 mt-2 ml-1">
                      Today
                    </span>
                  </div>
                  <div
                    className="col-md-12 pl-0"
                    onClick={() => onChangeTime("Yesterday")}
                  >
                    <i className="fa fa-calendar  mt-2_5 text-success ml-2 font-12"></i>
                    <span className="font-12 text-black ml-2 mt-2 ml-1">
                      Yesterday
                    </span>
                  </div>

                  <div
                    className="col-md-12 pl-0"
                    onClick={() => onChangeTime("Last Week")}
                  >
                    <i className="fa fa-calendar  mt-2_5 text-success ml-2 font-12"></i>
                    <span className="font-12 text-black ml-2 mt-2 ml-1">
                      Last Week
                    </span>
                  </div>

                  <div
                    className="col-md-12 pl-0"
                    onClick={() => onChangeTime("Current Month")}
                  >
                    <i className="fa fa-calendar  mt-2_5 text-success ml-2 font-12"></i>
                    <span className="font-12 text-black ml-2 mt-2 ml-1">
                      Current Month
                    </span>
                  </div>

                  <div
                    className="col-md-12 pl-0"
                    onClick={toggleSelectDateModal}
                  >
                    <i className="fa fa-calendar  mt-2_5 text-success ml-2 font-12"></i>
                    <span className="font-12 text-black ml-2 mt-2 ml-1">
                      Custom Date
                    </span>
                  </div>
                </div>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export default SubHeader;

const styles = {
  calendarLabel: {
    border: "1px solid rgb(204, 204, 204)",
    padding: "6px 7px",
    borderRadius: 5,
    fontSize: "12px",
    margin: " 0px 10px",
    height: 34.73,
  },
};
