import moment from "moment";
import React from "react";
import PickupAndDropMap from "../../UI/PickupAndDropMap";
import StaticMap from "../../UI/StaticMap";

const TaskFields = ({ field, task }) => {
  const _fields = {
    description: "description",
    map: "map",
    s_address: "startAddress",
    e_address: "endAddress",
    s_time: "fromTime",
    e_time: "toTime",
    // jobid: "jobId",
    "Job Name": "description",
  };

  if (field.key == "startAddress" || field.key == "endAddress") {
    const loc =
      field.key == "startAddress" ? task.startLocation : task.endLocation;
    return (
      <React.Fragment>
        <p className="mb-0 font-12 text-black">{task[field.key]}</p>
        <div
          className="mb-3"
          style={{ height: "200px", width: "105%", marginTop: 10 }}
        >
          <StaticMap lat={loc.latitude} lng={loc.longitude} />
        </div>
      </React.Fragment>
    );
  }

  if (field.key == "dropAndPickup") {
    return (
      <React.Fragment>
        <p className="mb-0 font-12 text-black">{task[field.key]}</p>
        <div
          className="mb-3"
          style={{ height: "200px", width: "105%", marginTop: 10 }}
        >
          <PickupAndDropMap
            pickup={task.pickupLocation}
            drop={task.dropLocation}
          />
        </div>
      </React.Fragment>
    );
  }

  if (field.type == "date" && task[field.key]) {
    return (
      <p className="mb-0 font-12 text-black">
        {moment(task[field.key]).format("DD-MM-YYYY")}
      </p>
    );
  }

  return (
    <p className="mb-0 font-12 text-black">
      {task[field.key] ? task[field.key] : "-"}
    </p>
  );
};

export default TaskFields;
