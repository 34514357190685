import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Container } from "react-bootstrap";
import { ShowNotification } from "../../../services/Notifications";
import TaskManagerRequest from "../../../services/TaskManagerRequest";

const TaskManagerOTPModal = (props) => {
  const [otp, setOtp] = useState("");

  const onChangeOtp = (event) => {
    setOtp(event.target.value);
  };

  const handleVerifyOTP = async (event) => {
    try {
      event.preventDefault();

      if(otp == '') {
        throw Error("Please Enter OTP");
      }
      // Verify OTP
      await TaskManagerRequest("/task/settings/verifyotp", { otp: otp }, false, 'verify-otp');
      await props.handleConfirmOTP(otp);
      props.toggleModal();
    } catch (error) {
      ShowNotification('error', error);
    }
  };

  useEffect(() => {
    if (props.show) {
      setOtp("");
    }
  }, [props.show]);

  return (
    <>
      <Modal
        show={props.show}
        dialogClassName="modal-30w"
        onHide={props.toggleModal}
        aria-labelledby="TaskManagerOTPModal"
      >
        <Modal.Header closeButton>Task Settings OTP</Modal.Header>
        <Modal.Body className="show-grid text-start">
          <Container>
            <div>
              <p>Enter OTP</p>
              <input
                className="form-control"
                placeholder="Enter OTP"
                value={otp}
                onChange={onChangeOtp}
                type="password"
              />
            </div>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            style={{ fontSize: "12px" }}
            className="btn btn-small btn-theme"
            onClick={handleVerifyOTP}
            id="verify-otp"
          >
            Verify
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TaskManagerOTPModal;
