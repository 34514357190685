import React, { useState } from "react";
import Card from "../UI/Card";
import { Button, ButtonGroup } from "react-bootstrap";
import { TASK_ASSETS_URL } from "../../environments/environments";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  getDriver,
  getTaskCustomerGroups,
  getTasks,
  removeSelectedDrivers,
  toggleHeaderSwitch,
} from "../../store/actions/taskManager.action";
import ChatCount from "./task/ChatCount/ChatCount";
import ReactTooltip from "react-tooltip";
import { getStorage } from "../../services/localStorageService";
import {
  getDriverStatusColor,
  getImageURL,
  getStatusCount,
} from "../../configs/common";
import TaskStatusCount from "./TaskStatusCount";
import { setOptimizeRequestData } from "../../store/actions/optimizer.action";

const Task = (props) => {
  const dispatch = useDispatch();
  const vehicles = getStorage("__vehicles__", "object");

  const activeDriver = useSelector((state) => state.taskReducer.activeDriver);
  const _taskSettings = useSelector(
    (state) => state.settingReducer.taskSettings
  );
  const time = useSelector((state) => state.taskReducer.time);
  const selectedDrivers = useSelector(
    (state) => state.taskReducer.selectedDrivers
  );
  const _driverTaskStatusCount = useSelector(
    (state) => state.taskReducer.driverTaskStatusCount
  );

  const statusList =
    _taskSettings && _taskSettings.taskStatus ? _taskSettings.taskStatus : [];

  const onClickHandler = async () => {
    try {
      dispatch(setOptimizeRequestData({}));
      if (props.onClick) props.onClick();
      if (props.disabled || props.isMapList) return;

      const element = document.querySelector(".resizable");
      if (element) element.style.width = 10 + "px";

      props.setLoading(true);

      await dispatch(removeSelectedDrivers());
      await dispatch(getDriver(props.task._id));
      dispatch(getTaskCustomerGroups());
      await dispatch(toggleHeaderSwitch(true));
      await dispatch(
        getTasks({
          driver: [props.task._id],
          startDate: `${moment(time.from).format("yyyy-MM-DD")} 00:00:00`,
          endDate: `${moment(time.to).format("yyyy-MM-DD")} 23:59:59`,
          taskStatus: time.taskStatus == "All" ? false : time.taskStatus,
          group: [props.task.group],
        })
      );
    } catch (error) {
    } finally {
      props.setLoading(false);
    }
  };

  const getIsActive = () => {
    return (
      selectedDrivers.includes(props.task._id) ||
      activeDriver._id === props.task._id
    );
    // if (activeDriver) {
    //     return activeDriver._id === props.task._id
    // } else {
    //     return selectedDrivers.includes(props.task._id)
    // }
  };

  return (
    <Card
      onClick={() => props.task.driverStatus == 'Active' ? onClickHandler() : alert('This filed staff account seems not activated as the user limit exceeded, contact the admin for more details.')}
      style={styles.card}
      className={ `${props.task.driverStatus == 'Active' ? `hover-outline-task v-center ${
        getIsActive() && !props.disabled ? "active-card" : null
      }` : 'disable-driver-card'}`}
    >
      <div className="row m-0">
        <div className="col-12 d-flex justify-content-between p-0">
          <div className="pr-0 lex-grow-1 d-flex" style={{ paddingTop: "2px" }}>
            <div className="my-auto">
              {/* IMAGE */}

              {(!props.task.profileImage ||
                props.task.profileImage === null) && (
                <img
                  src={
                    require("../../assets/img/task_manager_icons/taskdriver.svg")
                      .default
                  }
                  height="32"
                />
              )}
              {props.task.profileImage && props.task.profileImage !== null && (
                <img
                  src={`${getImageURL(props.task.profileImage, "/images/")}`}
                  width="32"
                  height="32"
                  style={{ borderRadius: "50%", resize: "contain" }}
                />
              )}

              {/* ONLINE */}
              {["Online", "Busy", "Offline"].includes(props.task.status) && (
                <div
                  style={{
                    ...styles.isOnline,
                    backgroundColor: getDriverStatusColor(props.task.status),
                  }}
                ></div>
              )}
            </div>

            <div className="flex-1">
              <span
                className="font-weight-500 ellipsis-250 font-14 ml-2"
                style={{ color: "#428bca" }}
              >
                {props.task.name} {props.task.driverStatus}
              </span>
              <p
                className="ellipsis-250 font-12 ml-2 mb-0"
                style={{ color: "#333333", width: 180 }}
              >
                {vehicles && vehicles[props.task?.vehicle]
                  ? `[${vehicles[props.task?.vehicle].name}]`
                  : "--"}
              </p>
            </div>
          </div>

          {props.task.unreadMessage > 0 && (
            <div className="align-self-end">
              {" "}
              <ChatCount count={props.task.unreadMessage} />{" "}
            </div>
          )}

          {statusList.length <= 5 && (
            <div className={`p-0 text-right my-auto`}>
              <TaskStatusCount
                driver={props.task}
                driverTaskStatusCount={_driverTaskStatusCount}
                statusList={statusList}
              />
            </div>
          )}
        </div>

        {statusList.length > 5 && (
          <div className={`col-12 text-right p-0`}>
            <TaskStatusCount
              driver={props.task}
              driverTaskStatusCount={_driverTaskStatusCount}
              statusList={statusList}
            />
          </div>
        )}
      </div>
    </Card>
  );
};

const styles = {
  card: {
    marginBottom: "5px",
    boxShadow: "none",
    cursor: "pointer",
    padding: "5px",
    // height: 43
  },
  isOnline: {
    position: "absolute",
    height: 8,
    width: 8,
    backgroundColor: "#36bf5d",
    borderRadius: 5,
    top: 10,
    left: 24,
  },
};

export default Task;
