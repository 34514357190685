import moment from "moment";
import { pad } from "./common";

export const _duration = (d) => {
  return moment.utc(d).format("HH:mm:ss");
};

export const _formatUTCDateLLL = (date) => {
  return moment.utc(date).format("MMM DD, yyyy hh:mm:ss A");
};

export const _formatDateLLL = (date) => {
  return moment(date).format("MMM DD, yyyy hh:mm:ss A");
};

export const _getDuration = (start, end) => {
  var now = moment(end);
  var then = moment(start);
  var duration = moment
    .utc(
      moment(now, "DD/MM/YYYY HH:mm:ss").diff(
        moment(then, "DD/MM/YYYY HH:mm:ss")
      )
    )
    .format("HH:mm:ss");
  return duration;
};

export const _getTimeDuration = (start, end) => {
  var startTime = moment(start, "HH:mm");
  var endTime = moment(end, "HH:mm:");

  // calculate total duration
  var duration = moment.duration(endTime.diff(startTime));

  // duration in hours
  var hours = parseInt(duration.asHours());

  // duration in minutes
  var minutes = parseInt(duration.asMinutes()) % 60;
  return pad(hours) + " : " + pad(minutes) + " Hr";
};

export const hhmmss = (secs) => {
  var minutes = Math.floor(secs / 60);
  secs = secs % 60;
  var hours = Math.floor(minutes / 60);
  minutes = minutes % 60;
  return `${pad(hours)}:${pad(minutes)}:${pad(secs)}`;
};

export const _getDurationInUTC = (start, end) => {
  var now = moment(end);
  var then = moment(start);
  var duration = moment(now, "DD/MM/YYYY HH:mm:ss").diff(
    moment(then, "DD/MM/YYYY HH:mm:ss"),
    "seconds"
  );
  return duration;
};

export const _minutesToHHmm = (diffInMinutes) => {
  const hours = Math.floor(diffInMinutes / 60);
  const minutes = diffInMinutes % 60;
  return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
    2,
    "0"
  )}`;
};

export const getDateFromInterval = (data, wantLabel = false) => {
  switch (data.interval) {
    case "Today":
    case "Tomorrow":
    case "Yesterday":
    case "Day After Tomorrow":
      return wantLabel ? data.interval : moment(data.from).format("DD/MM/yy");
    case "Last Week":
    case "Next Week":
    case "Custom Date":
      return `${moment(data.from).format("DD/MM/yy")} - ${moment(
        data.to
      ).format("DD/MM/yy")}`;
    default:
      return "";
  }
};

export const isNextDay = (startTime, durationInSeconds) => {
  // Parse start time into moment object
  const startTimeMoment = moment(startTime, "HH:mm");

  // Add duration to the start time
  const endTimeMoment = startTimeMoment
    .clone()
    .add(durationInSeconds, "seconds");

  // Compare if the end time is on the next day
  return endTimeMoment.isAfter(startTimeMoment.endOf("day"));
};

export const getstartAndEndDateOfMonth = (date) => {
  const year = date.getFullYear();
  const month = date.getMonth();

  // Create a new date object for the first day of the next month
  const firstDayOfNextMonth = new Date(year, month + 1, 1);

  // Subtract one day from the first day of the next month to get the last day of the current month
  return {
    startDate: new Date(year, month, 1),
    endDate: new Date(firstDayOfNextMonth.getTime() - 1),
  };
};
