import React, { useState } from "react";
import Rating from "react-rating";
import { FaStar } from "react-icons/fa";
import { ShowNotification } from "../services/Notifications";
import TaskManagerRequest from "../services/TaskManagerRequest";
import { useParams } from "react-router-dom";

const RatingsAndReview = () => {
  const { task } = useParams();

  const [name, setName] = useState("");
  const [ratings, setRatings] = useState(0);
  const [review, setReview] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const data = {
        name,
        ratings,
        review,
        task,
      };
      await TaskManagerRequest("/task/add/ratings", data, false, "save-review");
      ShowNotification("success", "Ratings and Review added successfully");
    } catch (error) {
      ShowNotification("error", error);
    } finally {
      setName("");
      setRatings(0);
      setReview("");
    }
  };

  return (
    <>
      <div
        style={{ height: "9vh" }}
        className="bg-theme share-location-logo py-2"
      >
        <img src={require("../assets/images/logo.png").default} alt="" />
      </div>
      <div
        style={{
          height: `91vh`,
          width: "100%",
          borderRadius: "0 3px 3px 0",
          background: "white",
        }}
        className="text-center pt-5"
      >
        <div
          className="login-card d-flex justify-content-center flex-column mx-auto"
          style={{ height: 400 }}
        >
          <h5 className="text-left mb-4">Ratings & Review</h5>
          <form role="form text-left" onSubmit={handleSubmit}>
            <input
              className="form-control my-3 font-14"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required={true}
              type="text"
            />
            <p className="font-16 mb-1 text-left">Ratings</p>
            <div className="text-left">
              <Rating
                emptySymbol={<FaStar color="lightgray" size={24} />}
                fullSymbol={<FaStar color="#3166ee" size={24} />}
                style={{ textAlign: "left" }}
                initialRating={ratings}
                onChange={(r) => setRatings(r)}
              />
            </div>
            <textarea
              className="form-control my-4 font-14"
              placeholder="Review"
              value={review}
              onChange={(e) => setReview(e.target.value)}
            ></textarea>
            <button
              id="save-review"
              type="submit"
              className="btn bg-theme w-100 mt-4 text-white font-13 mb-0"
            >
              Save Review
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default RatingsAndReview;
