import GetRequest from "../../services/GetRequest";
import { getStorage } from "../../services/localStorageService";
import PostRequest from "../../services/PostRequest";
import TaskManagerRequest from "../../services/TaskManagerRequest";

export const GET_TRIPS = 'GET_TRIPS'
export const GET_ROUTE = 'GET_ROUTE'
export const GET_ROUTE_ZEOZONES = 'GET_ROUTE_ZEOZONES'
export const GET_ROUTE_SALIKS = 'GET_ROUTE_SALIKS'
export const TOGGLE_SELECTED_VEHICLE = 'TOGGLE_SELECTED_VEHICLE'
export const TOGGLE_SELECTED_VEHICLE_GROUP = 'TOGGLE_SELECTED_VEHICLE_GROUP'
export const SET_GROUPS = 'SET_GROUPS'
export const SET_ACTIVE_TRIP = 'SET_ACTIVE_TRIP'
export const SET_TRIP_ZONE = 'SET_TRIP_ZONE'
export const SET_ACTIVE_INTERVAL='SET_ACTIVE_INTERVAL'
export const TRIP_MAP_LOADER='TRIP_MAP_LOADER'

export const setGroups = () => {

    const groups = getStorage('__groups__', 'object');

    return dispatch => {
        dispatch({
            type: SET_GROUPS,
            value: groups
        })
    };
}

export const setActiveTrip = (trip) => {

    return dispatch => {
        dispatch({
            type: SET_ACTIVE_TRIP,
            value: trip
        })
    };
}



export const toggleSelectedVehicleGroup = (data) => {

    return dispatch => {
        dispatch({
            type: TOGGLE_SELECTED_VEHICLE_GROUP,
            value: data
        })
    };
}


export const toggleSelectedVehicle = (vehicle) => {

    return dispatch => {
        dispatch({
            type: TOGGLE_SELECTED_VEHICLE,
            value: vehicle
        })
    };

}

export const getTrips = (data = {}) => {

    try {
        return async dispatch => {

            let response = await PostRequest('/reports/trips', data);

            dispatch({
                type: GET_TRIPS,
                value: response.trips
            })

        };

    } catch (error) {
        console.log('Error : ', error)
        return false;
    }

}



export const getRoute = (data = {}) => {

    try {
        return async dispatch => {

            let response = await PostRequest('/reports/getroute', { tripsdata: data });
            dispatch({
                type: GET_ROUTE,
                value: response
            })

        };

    } catch (error) {
        console.log('Error : ', error)
        return false;
    }

}

export const removeTripRoutes = () => {

    try {
        return async dispatch => {

            dispatch({
                type: GET_ROUTE,
                value: null
            })

        };

    } catch (error) {
        console.log('Error : ', error)
        return false;
    }

}

export const getRouteGeozones = (path = '') => {

    try {
        return async dispatch => {

            let response = await GetRequest('/user/getRouteGeozones' + path, {});

            // let response = [
            //     {gz_name : 'Al Maktoum Gate', trip_geozone_enter_time: '11:03:04 12 Jul 2021', trip_geozone_exit_time: '11:03:04 12 Jul 2021'},
            //     {gz_name : 'Jebel Ali Gate', trip_geozone_enter_time: '11:03:04 12 Jul 2021', trip_geozone_exit_time: '11:03:04 12 Jul 2021'},
            // ]

            dispatch({
                type: GET_ROUTE_ZEOZONES,
                value: response
            })

        };

    } catch (error) {
        console.log('Error : ', error)
        return false;
    }

}

export const getRouteSaliks = (data = {}) => {

    try {
        return async dispatch => {
            // let response = await GetRequest('/user/getRouteSaliks' + path, {});
            // const response = await TaskManagerRequest('/get/vehicle/saliks', data)
            const response = await TaskManagerRequest(`/get/vehicle/trips`, data)
            
            dispatch({
                type: GET_ROUTE_SALIKS,
                value: response
            })

        };

    } catch (error) {
        console.log('Error : ', error)
        return false;
    }

}


export const setTripZone = (zone) => {

    return async dispatch => {

        dispatch({
            type: SET_TRIP_ZONE,
            value: zone
        })

    };

}
export const setActiveInterval = (value) => {

    return async dispatch => {

        dispatch({
            type: SET_ACTIVE_INTERVAL,
            value: value
        })

    };

}



export const setLoaderForTripMap = (status) => {

    try {
        return async dispatch => {
            dispatch({
                type: TRIP_MAP_LOADER,
                value: status
            })

        };

    } catch (error) {
        console.log('Error : ', error)
        return false;
    }

}