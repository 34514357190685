import React from 'react';

import './TabContentBody.css'

const TabContentBody = (props) => {
    return (
        <div className="h-100 tab-content-height" >
            <div className="as-tab-content-body" style={{...props.bodyStyle}}>
                {props.content}
            </div>

            <div className="as-tab-content-footer">
                {props.footer}
            </div>
        </div>
    );
}

export default TabContentBody;