import _ from "underscore";

import {
  GET_TASK_DRIVER_GROUPS,
  GET_TASKMANAGER_DRIVERS,
  GET_CUSTOMERS,
  GET_TASK_DRIVER,
  GET_CUSTOMER_GROUPS,
  GET_TASKS,
  GET_PENDING_TASKS,
  FILTER_TASKS,
  GET_TASK,
  GET_POI,
  GET_CUSTOMER_LIST,
  GET_CUSTOMER,
  SET_TIME,
  GET_TASK_ATTRIBUTES,
  GET_TASK_CHECK_SETTINGS,
  SET_SELECTED_DRIVERS,
  GET_DRIVER_TASK_STATUS_COUNT,
  REMOVE_SELECTED_DRIVERS,
  SELECTED_TASK_FIELDS,
  FILTER_DRIVERS,
  UPDATE_DRIVER_COUNTS,
  GET_TASKMANAGER_MODAL_DRIVERS,
  SET_DRIVERS_BALANCE,
  ORDER_POSITION_UPDATE,
  TOGGLE_HEADER_SWITCH,
  SELECT_ALL_TASK_DRIVER_GROUPS,
  SORT_TASK_DRIVER_GROUPS,
  SET_TIMELINE_VIEW,
  SET_OFFICE_TIME,
} from "../actions/taskManager.action";
import moment from "moment";

const initialState = {
  taskDriverGroups: [],
  taskDrivers: [],
  taskModalDrivers: [],
  activeDriver: false,
  selectedDrivers: [],
  selectedGroups: [],
  customerGroups: [],
  customers: [],
  customerList: [],
  activeCustomer: false,
  cloneTasks: false,
  tasks: false,
  pendingTasks: [],
  activeTask: false,
  poi: [],
  time: {
    from: moment(new Date()),
    to: moment(new Date()),
    taskStatus: "All",
    interval: "Today",
  },
  taskAttributes: [],
  taskCheckSetting: false,
  driverTaskStatusCount: {},
  selectedTaskFields: [],
  driverFilter: "all",
  driverCounts: {
    available: 0,
    notAvailable: 0,
    onTask: 0,
    total: 0,
  },
  hasOrderChanged: false,
  taskHeaderSwitchOn: true,
  isTimeLineView: false,
  officeTime: {
    officeStartTime: "11:00",
    officeEndTime: "18:00",
  },
  settings: false,
};

const getDriverCounts = (groups) => {
  var total = 0;
  var available = 0;
  var notAvailable = 0;
  var onTask = 0;

  for (var i = 0; i < groups.length; i++) {
    groups[i].Drivers?.map((driver) => {
      total += 1;
      if (driver.status == "Online") available += 1;
      if (driver.status == "Busy") onTask += 1;
      if (driver.status == "Offline" || !driver.status) notAvailable += 1;
    });
  }

  return {
    total,
    available,
    notAvailable,
    onTask,
  };
};

const sortDriverGroup = (groups, order = "name") => {
  // groups.map(group => {
  //     if (order == 'name') {
  //         group.Drivers = _.sortBy(group.Drivers, 'name')
  //     } else {
  //         group.Drivers = _.sortBy(group.Drivers, 'id')
  //     }
  // })

  return groups;
};

const TaskReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TASK_DRIVER_GROUPS:
      var counts = getDriverCounts(action.value);
      var sortedGroups = sortDriverGroup(action.value, action.sortBy);

      return { ...state, taskDriverGroups: sortedGroups, driverCounts: counts };

    case SORT_TASK_DRIVER_GROUPS:
      var cloneDriverGroupsForSort = [...state.taskDriverGroups];
      cloneDriverGroupsForSort = sortDriverGroup(
        cloneDriverGroupsForSort,
        action.value
      );

      return { ...state, taskDriverGroups: cloneDriverGroupsForSort };

    case SET_DRIVERS_BALANCE:
      var cloneDriverGroups = [...state.taskDriverGroups];
      var cloneActiveDriver = state.activeDriver
        ? { ...state.activeDriver }
        : false;

      Object.keys(action.value).map((key) => {
        cloneDriverGroups.map((group) => {
          if (group.Drivers) {
            var exist = group.Drivers?.findIndex((d) => d._id == key);
            if (exist !== -1) {
              group.Drivers[exist].balances = action.value[key];
              if (cloneActiveDriver && key == cloneActiveDriver._id)
                cloneActiveDriver.balances = group.Drivers[exist].balances;
            }
          }
        });
      });

      return {
        ...state,
        taskDriverGroups: cloneDriverGroups,
        activeDriver: cloneActiveDriver,
      };

    case GET_TASK_DRIVER:
      var _cloneDriverGroups = [...state.taskDriverGroups];

      _cloneDriverGroups.map((group) => {
        if (group.Drivers) {
          var exist = group.Drivers?.findIndex(
            (d) => d._id == action.value._id
          );
          if (exist !== -1)
            group.Drivers[exist].balances = action.value.balances;
        }
      });

      var returnObj = {
        ...state,
        activeDriver: action.value,
        selectedGroups: action.value ? [action.value.group._id] : [],
      };
      if (action.isUpdateBalance)
        returnObj.taskDriverGroups = _cloneDriverGroups;

      return returnObj;

    case UPDATE_DRIVER_COUNTS:
      return { ...state, driverCounts: action.value };

    case TOGGLE_HEADER_SWITCH:
      return { ...state, taskHeaderSwitchOn: action.value };

    case GET_TASKMANAGER_DRIVERS:
      return { ...state, taskDrivers: action.value };

    case GET_TASKMANAGER_MODAL_DRIVERS:
      return { ...state, taskModalDrivers: action.value };

    case FILTER_DRIVERS:
      return { ...state, driverFilter: action.value };

    case SET_SELECTED_DRIVERS:
      var cloneSelectedDrivers = [...state.selectedDrivers];
      var cloneSelectedGroups = [...state.selectedGroups];

      // // Add/Remove Drivers
      action.drivers.map((d) => {
        const exist = cloneSelectedDrivers.findIndex((_d) => _d == d);
        if (action.value == true && exist == -1) cloneSelectedDrivers.push(d);
        if (action.value == false && exist !== -1)
          cloneSelectedDrivers.splice(exist, 1);
      });

      // Add/Remove Groups
      action.groups.map((d) => {
        const exist = cloneSelectedGroups.findIndex((_d) => _d == d);
        if (action.value == true && exist == -1) cloneSelectedGroups.push(d);
        if (action.value == false && exist !== -1)
          cloneSelectedGroups.splice(exist, 1);
      });

      // console.log("cloneSelectedGroups....", cloneSelectedGroups);

      return {
        ...state,
        selectedDrivers: cloneSelectedDrivers,
        selectedGroups: cloneSelectedGroups,
      };

    case SELECT_ALL_TASK_DRIVER_GROUPS:
      var _selectedDrivers = [];
      var _selectedGroups = [];
      var cloneDriverGroups = [...state.taskDriverGroups];

      cloneDriverGroups.map((g) => {
        if (g.Drivers) {
          _selectedDrivers = [
            ..._selectedDrivers,
            ...g.Drivers?.map((d) => d._id),
          ];
        }
        _selectedGroups.push(g._id);
      });

      return {
        ...state,
        selectedDrivers: action.value ? _selectedDrivers : [],
        selectedGroups: action.value ? _selectedGroups : [],
        activeDriver: false,
      };

    case REMOVE_SELECTED_DRIVERS:
      return { ...state, selectedDrivers: [], selectedGroups: [] };

    case GET_CUSTOMERS:
      return { ...state, customers: action.value };

    case GET_CUSTOMER_GROUPS:
      return { ...state, customerGroups: action.value };

    case SET_TIMELINE_VIEW:
      return { ...state, isTimeLineView: action.value };

    case GET_TASKS:
      return { ...state, tasks: action.value, cloneTasks: action.value };

    case GET_TASK_CHECK_SETTINGS:
      return { ...state, taskCheckSetting: action.value };

    case GET_TASK:
      return { ...state, activeTask: action.value };

    case GET_PENDING_TASKS:
      return { ...state, pendingTasks: action.value };

    case FILTER_TASKS:
      var cloneTaskToFilter = [...state.cloneTasks];

      if (action.value !== "All" && cloneTaskToFilter.data) {
        cloneTaskToFilter.data = cloneTaskToFilter.data.filter(
          (item) => item.taskStatus == action.value
        );
      }

      return { ...state, tasks: cloneTaskToFilter };

    // Location

    case GET_POI:
      return { ...state, poi: action.value };

    case GET_CUSTOMER_LIST:
      return { ...state, customerList: action.value };

    case GET_CUSTOMER:
      return { ...state, activeCustomer: action.value };

    case SET_TIME:
      return { ...state, time: action.value };

    case GET_TASK_ATTRIBUTES:
      return { ...state, taskAttributes: action.value };

    case GET_DRIVER_TASK_STATUS_COUNT:
      return { ...state, driverTaskStatusCount: action.value };

    case ORDER_POSITION_UPDATE:
      return { ...state, hasOrderChanged: action.value };

    case SET_OFFICE_TIME:
      var cloneOfficeTime = { ...state.officeTime };
      if (
        action.value &&
        action.value.timelineSettings &&
        action.value.timelineSettings.officeTime
      ) {
        cloneOfficeTime = action.value.timelineSettings.officeTime;
      }
      return { ...state, officeTime: cloneOfficeTime, settings: action.value };

    case SELECTED_TASK_FIELDS:
      const list = action.value.filter((f) => f.checked == true);
      return { ...state, selectedTaskFields: list };

    default:
      return state;
  }
};

export default TaskReducer;
