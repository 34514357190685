import React, { useEffect, useState } from "react";
import Select from "react-select";
import TaskManagerRequest from "../../services/TaskManagerRequest";

const CustomerSelect = ({
  name = "",
  isMulti = false,
  value = null,
  onChange = () => {},
  className = "",
  placeholder = "Select a customer",
  closeMenuOnSelect = false,
}) => {
  const [customersList, setCustomersList] = useState([]);

  const handleInputChange = (value) => {
    try {
      TaskManagerRequest("/get/customers", {
        limit: 10,
        search: value.trim(),
      })
        .then((response) => {
          setCustomersList(response);
        })
        .catch((e) => {
          console.error(e);
        });
    } catch (error) {
      console.error("Error", error);
    }
  };

  const loadInitialData = async () => {
    try {
      const response = await TaskManagerRequest("/get/customers", {
        limit: 10,
      });
      setCustomersList(response);
    } catch (error) {
      setCustomersList([]);
    }
  };

  useEffect(() => {
    loadInitialData();
  }, []);

  return (
    <Select
      onInputChange={handleInputChange}
      closeMenuOnSelect={closeMenuOnSelect}
      isMulti={isMulti}
      name={name}
      getOptionLabel={(option) => `${option.name}, ${option.phoneNumber || ""}`}
      getOptionValue={(option) => option._id}
      options={customersList}
      value={value || null}
      onChange={onChange}
      className={`font-12 text-start ${className}`}
      placeholder={placeholder}
    />
  );
};

export default CustomerSelect;
