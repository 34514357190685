import React, { useCallback, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Container } from "react-bootstrap";
import Input from "../UI/Input";
import { ShowNotification } from "../../services/Notifications";
import ServiceCenterRequest from "../../services/ServiceCenterRequest";
import DropZoneInput from "../UI/DropZoneInput";
import Thumb from "../UI/Thumb";
import { getFileFromUrl, getImageURL } from "../../configs/common";
import Select from "../UI/Select";

const EditServiceExpense = (props) => {
  const [files, setFiles] = useState([]);
  const [attachmentsLabels, setAttachmentsLabels] = useState([]);
  const handleSubmit = async (event) => {
    const formData = new FormData(event.target);
    event.preventDefault();

    formData.append("expanses", props.data?._id);
    files.map((file) => {
      formData.append("serviceCenterAttachments", file);
    });
    formData.append("attachmentsLabels", JSON.stringify(attachmentsLabels));

    try {
      const response = await ServiceCenterRequest(
        "/service-center/expense/edit",
        formData,
        true,
        "editServiceExpense"
      );
      ShowNotification(
        "success",
        "Service center expense updated successfully"
      );
      props.loadExpenses();
      props.toggleModal();
    } catch (error) {
      ShowNotification("error", error);
    }
  };

  const getFile = async (path) => {
    var file = await getFileFromUrl(getImageURL(path, "/"));
    return file;
  };

  const setData = async () => {
    try {
      if (props.data.serviceCenterAttachments) {
        // setFiles(
        //   props.data.serviceCenterAttachments.map((attachment) =>
        //     getFile(attachment.filePath)
        //   )
        // );
        var attachments = [];
        for (
          let index = 0;
          index < props.data.serviceCenterAttachments.length;
          index++
        ) {
          const attachment = props.data.serviceCenterAttachments[index];
          attachments.push(
            await getFileFromUrl(
              getImageURL(attachment.filePath, "/"),
              attachment.filePath
            )
          );
        }
        setFiles(attachments);
        setAttachmentsLabels(
          props.data.serviceCenterAttachments.map(
            (attachment) => attachment.label
          )
        );
      } else {
        setFiles([]);
        setAttachmentsLabels([]);
      }
    } catch (error) {}
  };

  const getThumb = useCallback(() => {}, []);

  useEffect(() => {
    if (props.show && props.data) {
      setData();
    }
  }, [props.show, props.data]);

  return (
    <>
      <Modal
        show={props.show}
        dialogClassName="modal-40w"
        onHide={props.toggleModal}
        aria-labelledby="editServiceExpense"
      >
        <Modal.Header closeButton>Edit Service Expense</Modal.Header>
        <form onSubmit={handleSubmit}>
          <Modal.Body className="show-grid">
            <Container>
              <div className="row">
                <div className="col-md-12 mb-3">
                  <fieldset className="pb-0">
                    <legend>
                      <span>Note</span>
                    </legend>
                    <textarea
                      name="description"
                      required={true}
                      rows="2"
                      className="textarea resize-vertically"
                      placeholder="Note..."
                      defaultValue={props.data?.description || ""}
                    ></textarea>
                  </fieldset>
                </div>

                <div className="col-md-12 mb-3">
                  <Input
                    required={true}
                    name="amount"
                    type="number"
                    defaultValue={props.data?.amount || ""}
                    label="Amount"
                    placeholder="Amount"
                  />
                </div>
                <div className="col-md-12 mb-3">
                  <Select
                    title="Job Status"
                    name="jobStatus"
                    value={props.data?.jobStatus}
                  >
                    <option value="Work Not Started">Work Not Started</option>
                    <option value="In Progress">In Progress</option>
                    <option value="Completed">Completed</option>
                  </Select>
                </div>
                <div className="col-md-12 mb-3">
                  <fieldset>
                    <legend>
                      <span>Service Center Attachments</span>
                    </legend>
                    <DropZoneInput
                      file={files}
                      multiple={true}
                      label="Service Center Attachments"
                      style={{ minHeight: 80 }}
                      onChange={(file) => {
                        setFiles(file);
                        var clone = [];
                        file.map((f, i) => {
                          clone[i] = attachmentsLabels[i] || "";
                        });
                        setAttachmentsLabels(clone);
                      }}
                      onRemoveSingleFile={(index) => {
                        // Remove file at index
                        var cloneFiles = [...files];
                        cloneFiles.splice(index, 1);
                        setFiles(cloneFiles);

                        // Remove label at index
                        var cloneAttachmentsLabels = [...attachmentsLabels];
                        cloneAttachmentsLabels.splice(index, 1);
                        setAttachmentsLabels(cloneAttachmentsLabels);
                      }}
                    />
                  </fieldset>
                </div>
                <div className="">
                  {files.map((f, i) => {
                    return (
                      <div key={i} className="d-flex align-item-ceneter mb-2">
                        <Thumb isRemovable={false} file={f} key={i} />
                        <Input
                          placeholder="Attachment Label"
                          className="ml-3 mb-2"
                          value={attachmentsLabels[i] || ""}
                          onChange={(value) => {
                            var clone = [...attachmentsLabels];
                            clone[i] = value;
                            setAttachmentsLabels(clone);
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              style={{ fontSize: "12px" }}
              className="btn btn-small"
              onClick={props.toggleModal}
            >
              Close
            </button>
            <button
              id="editServiceExpense"
              style={{
                fontSize: "12px",
                backgroundColor: "#007bff",
                color: "#ffffff",
              }}
              className="btn btn-small"
            >
              Save
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default EditServiceExpense;
