import moment from "moment";
import React, { useState, useEffect } from "react";

const Timer = () => {
  // Header timer controller
  const [time, setTime] = useState(Date.now());

  useEffect(() => {
    const interval = setInterval(() => setTime(Date.now()), 1000);
    return () => {
      if (interval) clearInterval(interval);
    };
  }, []);

  return (
    <span className="header-date">{moment(time).format('ddd, DD MMMM YYYY HH:mm:ss')}</span>
  );
};

export default React.memo(Timer);
