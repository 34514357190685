import React, { useState, useCallback, useRef, useEffect } from "react";
import "./chats.css";
import { Media } from "react-bootstrap";
import { socketEmit } from "../../../../sockets/SocketIO";
import { getStorage } from "../../../../services/localStorageService";
import { useDispatch, useSelector } from "react-redux";
import { loadChatMessages } from "../../../../store/actions/chat.action";
import moment from "moment";
import { socketService } from "../../../../services/socket.service";
import $ from "jquery";
import { MAIN_DOMAIN_URL } from "../../../../configs/api.urls";
import Loader from "../../../UI/Loader";

const AlwaysScrollToBottom = () => {
  const elementRef = useRef();
  useEffect(() => elementRef.current.scrollIntoView());
  return <div ref={elementRef} />;
};

const ChatWindow = (props) => {
  const dispatch = useDispatch();
  const fileRef = useRef();

  const activeDriver = useSelector((state) => state.taskReducer.activeDriver);
  var _chts = useSelector((state) => state.chatReducer.chats);
  const [message, setMessage] = useState("");
  const [activeUser, setActiveUser] = useState(
    getStorage("__user__", "object")
  );

  const [chatMessages, setChatMessages] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const loadData = async () => {
    try {
      setLoading(true);

      await dispatch(
        loadChatMessages({
          admin: activeUser.id,
          driver: activeDriver._id,
          task: props.task !== -1 ? props.task : null,
        })
      );
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(async () => {
    loadData();
  }, [activeDriver]);

  useEffect(() => {
    setChatMessages(_chts);

    var t = setTimeout(() => {
      $("#scroll-to-bottom").scrollTop(
        $("#scroll-to-bottom")[0].scrollHeight + 500
      );
    }, 200);

    return () => {
      if (t) clearTimeout(t);
    };
  }, [_chts]);

  useEffect(() => {
    const messageListner = socketService
      .getMessage()
      .subscribe((inCommingMessage) => {
        var isUserChat = props.task == -1;

        if (
          (isUserChat && inCommingMessage.task) ||
          (!isUserChat && !inCommingMessage.task)
        )
          return;

        if (inCommingMessage.from == activeDriver._id.toString()) {
          const c = chatMessages ? [...chatMessages] : [];

          c.push({
            message: inCommingMessage.message,
            created_at: inCommingMessage.created_at,
            from: inCommingMessage._id,
            to: inCommingMessage.to,
            messageType: inCommingMessage.messageType
              ? inCommingMessage.messageType
              : "text",
          });

          setChatMessages(c);
        }
      });

    return () => {
      messageListner.unsubscribe();
    };
  }, [chatMessages]);

  const onKeyUpHandler = (e) => {
    if (e.key === "Enter" && e.keyCode === 13 && !e.shiftKey) {
      handleSendMessage(message);
    }
  };

  const handleSendMessage = useCallback(
    async (msg, type = "text") => {
      if (msg.trim() == "" && message.trim() == "") return false;
      if (!activeUser) return false;

      const messageData = {
        from: activeUser.id,
        to: activeDriver._id,
        message: msg,
        messageType: type,
        task: props.task !== -1 ? props.task : null,
        senderInfo: {
          id: activeUser.id,
          name: activeUser.name,
          image: `${MAIN_DOMAIN_URL}/web/assets/admin/${activeUser.photo}`,
        },
      };

      socketEmit("CHAT_MESSAGE_FROM_ADMIN_TO_DRIVER", messageData);
      setMessage("");

      const c = chatMessages ? [...chatMessages] : [];
      c.push({
        message: msg,
        created_at: moment(),
        from: activeUser.id,
        to: activeDriver._id,
        messageType: type,
        senderInfo: {
          id: activeUser.id,
          name: activeUser.name,
          image: `${MAIN_DOMAIN_URL}/web/assets/admin/${activeUser.photo}`,
        },
      });

      setChatMessages(c);

      setTimeout(() => {
        $("#scroll-to-bottom").scrollTop(
          $("#scroll-to-bottom")[0].scrollHeight + 500
        );
      }, 200);
    },
    [message, chatMessages]
  );

  const onSelectImage = (event) => {
    if (!activeUser) return false;

    if (event.target.files.length == 0) return false;
    const file = event.target.files[0];

    var type = file.type == "application/pdf" ? "pdf" : "image";

    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      if (reader.result && reader.result !== null) {
        handleSendMessage(reader.result, type);
      }
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  return (
    <>
      {isLoading && <Loader style={{ left: "40%", top: "40%" }} />}

      <div className="chat-window-container">
        <div id="scroll-to-bottom" className="chat-messages-container">
          <img
            src={require("./cancel.png").default}
            className="close-icon pointer"
            onClick={props.toggleChatWindow}
          />
          {chatMessages &&
            chatMessages.map((messageObject, i) => (
              <Messages
                key={messageObject.i}
                message={messageObject}
                activeUser={activeUser}
                toggleChatWindow={props.toggleChatWindow}
              />
            ))}
          {/* <AlwaysScrollToBottom/> */}
        </div>

        <div className="text-center" onClick={loadData}>
          <button className="btn reload-chat-btn">Refresh Chat</button>
        </div>

        <div className="input-container d-flex justify-content-between">
          <div className="d-flex">
            <img
              src={require("./chat-pin.png").default}
              className="pin-icon"
              onClick={() => fileRef.current.click()}
            />
            <input
              type="file"
              ref={fileRef}
              className="hidden"
              onChange={onSelectImage}
            />
            <textarea
              value={message}
              onKeyUp={onKeyUpHandler}
              multiple={true}
              onChange={(e) => setMessage(e.target.value)}
              rows={1}
              className="_input"
              cols={22}
              placeholder="Type here..."
            ></textarea>
          </div>
          <button
            onClick={() => handleSendMessage(message)}
            className="send-icon-container"
          >
            <img
              src={require("./play-button.png").default}
              className="send-icon"
            />
          </button>
        </div>
      </div>
    </>
  );
};

export default ChatWindow;

const Messages = ({ message, activeUser, toggleChatWindow }) => {
  let image = "";

  if (activeUser.id != message.from) {
    image = (
      <img
        className="user-img"
        src={require("../../../../assets/images/avatar-men.png").default}
        alt={"name"}
      />
    );
  }

  let msgClass = [];
  if (activeUser.id != message.from) {
    msgClass = [...msgClass, "chat-menu-content"];
  } else {
    msgClass = [...msgClass, "chat-menu-reply text-muted"];
  }

  let downloadClass = ["chat-download"];
  if (activeUser.id != message.from) {
    downloadClass = [...downloadClass, "chat-download-right"];
  } else {
    downloadClass = [...downloadClass, "chat-download-left"];
  }

  return (
    <React.Fragment>
      {/* <img src={require('./cancel.png').default} className='close-icon pointer' onClick={toggleChatWindow} /> */}
      <Media className="media chat-messages">
        {image}
        <Media.Body className={msgClass.join(" ")}>
          <div className="">
            {message.messageType == "text" && (
              <p className="chat-cont">{message.message}</p>
            )}
            {message.messageType == "image" && (
              <p className="chat-cont">
                <img src={message.message} height="100" />
                <a
                  className={downloadClass.join(" ")}
                  download
                  href={message.message}
                >
                  <img src={require("./download.png").default} height="16" />
                </a>
              </p>
            )}
            {message.messageType == "pdf" && (
              <p className="chat-cont">
                <a href={message.message} download="MylocatorPlus">
                  <img
                    className="pointer"
                    src={require("./file.png").default}
                    height="50"
                  />
                  <a
                    className={downloadClass.join(" ")}
                    download
                    href={message.message}
                  >
                    <img src={require("./download.png").default} height="16" />
                  </a>
                </a>
              </p>
            )}
          </div>
          <p className="chat-time">
            {moment(message.created_at).format("hh:mm A")}
          </p>
        </Media.Body>
      </Media>
    </React.Fragment>
  );
};
