import React from "react";

import "./not-found-style.css";
import { getStorage } from "../../services/localStorageService";

const Maintenance = () => {
  const data = getStorage("maintenanceData", "object");
  return (
    <section className="page_503">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 text-center">
            <div className="five_zero_three_bg"></div>
            <div className="contant_box_404">
              <p class="maintenance-title">WE ARE COMING SOON</p>
              <h3 className="maintenance-title-2">
                {data?.title || "The website under maintenance!"}
              </h3>
              <p className="maintenance-message">{data?.message || ""}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Maintenance;
