import React, { useState } from "react";
import Card from "../../UI/Card";
import IconButton from "../../UI/IconButton";
import { useSelector } from "react-redux";
import { formatBytes } from "../../../configs/common";
import TaskManagerRequest from "../../../services/TaskManagerRequest";
import { ShowNotification } from "../../../services/Notifications";
import { TASK_ASSETS_URL } from "../../../environments/environments";
import FileSaver from "file-saver";
import { getStorage } from "../../../services/localStorageService";
import TaskManagerOTPModal from "../task-manager/TaskManagerOTPModal";

const StorageTable = (props) => {
  const activeDriver = useSelector((state) => state.taskReducer.activeDriver);
  const selectedDrivers = useSelector(
    (state) => state.taskReducer.selectedDrivers
  );

  var activeStyle = {
    color: "#fff",
    marginRight: "5px",
    paddingInline: 20,
    paddingBlock: 5,
  };

  const [isLoading, setIsLoading] = useState(false);
  const [isOTPModal, setOTPModal] = useState(false);

  const toggleOTPModal = () => setOTPModal(!isOTPModal);

  const getUsedStorage = () => {
    var usedStorage = 0;
    if (activeDriver) {
      if (props.storageUsed && props.storageUsed[activeDriver._id]) {
        usedStorage =
          props.storageUsed[activeDriver._id]["totalDriverUsedSpace"];
      }
    } else {
      selectedDrivers.map((driver) => {
        if (props.storageUsed && props.storageUsed[driver]) {
          usedStorage += props.storageUsed[driver]["totalDriverUsedSpace"];
        }
      });
    }
    return usedStorage;
  };

  const handleDeletePermanently = async () => {
    if (
      !window.confirm(
        "Are you sure you want to delete all selected drivers files permanently?"
      )
    ) {
      return;
    }
    try {
      setIsLoading(true);

      // check email availability
      const user = getStorage("__user__", "object");

      if (!user?.user_email || user?.user_email == "") {
        throw Error(
          "Please update your profile. Currently you donn't have an email address to send mail for OTP."
        );
      }

      // Send OTP
      await TaskManagerRequest("/task/settings/sendotp", {
        email: user?.user_email,
      });

      toggleOTPModal();
    } catch (error) {
      ShowNotification("error", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleConfirmOTP = async () => {
    try {
      toggleOTPModal();
      setIsLoading(true);

      var drivers = [];

      if (activeDriver) {
        drivers.push(activeDriver._id);
      } else {
        drivers = selectedDrivers;
      }

      if (drivers.length <= 0) return;

      var data = { drivers };
      if (props.dates && props.dates.length >= 2) {
        data.startDate = props.dates.startDate;
        data.endDate = props.dates.endDate;
      }

      await TaskManagerRequest(
        "/storage/delete-files",
        data,
        false,
        "delete-files-permanently"
      );
      ShowNotification("success", "Deleted files permanently");
      var data2 = {};
      if (props.dates && props.dates.length >= 2) {
        data2.startDate = props.dates.startDate;
        data2.endDate = props.dates.endDate;
      }

      if (props.loadData) {
        props.loadData(data2);
      }
    } catch (error) {
      ShowNotification("error", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownloadFiles = async () => {
    try {
      var drivers = [];

      if (activeDriver) {
        drivers.push(activeDriver._id);
      } else {
        drivers = selectedDrivers;
      }
      if (drivers.length <= 0) return;

      var data = { drivers };
      if (props.dates && props.dates.length >= 2) {
        data.startDate = props.dates.startDate;
        data.endDate = props.dates.endDate;
      }

      const response = await TaskManagerRequest(
        "/storage/download-files",
        data,
        false,
        "download-files-localy"
      );
      if (response.zipPath) {
        const zipUrl = `${TASK_ASSETS_URL}/${response.zipPath}`;
        const parts = zipUrl.split(".");
        const ext = parts[parts.length - 1];
        FileSaver.saveAs(zipUrl, "files-storage." + ext);

        // await TaskManagerRequest("/storage/unlink-zip", response);
      }
    } catch (error) {
      ShowNotification("error", error);
    }
  };

  return (
    <>
      <TaskManagerOTPModal
        show={isOTPModal}
        toggleModal={toggleOTPModal}
        handleConfirmOTP={handleConfirmOTP}
      />
      <Card
        style={{
          boxShadow: "none",
          height: "100%",
          textAlign: "center",
          paddingInline: "15%",
          paddingTop: "15%",
        }}
      >
        <img
          src={require("../../../assets/img/settings/broom.png").default}
          height={150}
        />
        <h5 className="mt-4">Free Up {formatBytes(getUsedStorage() * 1000)}</h5>
        <p style={{ color: "grey" }}>
          [ {activeDriver ? "1" : selectedDrivers.length} Field Staff
          {activeDriver || selectedDrivers.length == 1 ? "" : "s"} Selected ]
        </p>
        <p className="mt-2 mb-4 text-secondary">
          "Delete Files Permanently" ensures that selected files are completely
          removed from your device without any possibility of recovery,
          enhancing privacy and security. Meanwhile, "Download Files Before
          Deletion" offers a safeguard by allowing you to retrieve and store
          important files locally before executing the deletion process,
          ensuring no loss of crucial data.
        </p>
        {getUsedStorage() != 0 ? (
          <div className="d-flex justify-content-center">
            <IconButton
              label="Download Localy"
              buttonstyle={{ ...activeStyle, backgroundColor: "#3160ee" }}
              // onClick={() => {}}
              onClick={handleDownloadFiles}
              className="ml-2"
              id="download-files-localy"
            />
            <IconButton
              label="Delete Files Permanently"
              buttonstyle={activeStyle}
              onClick={handleDeletePermanently}
              className="ml-2 btn-danger"
              id="delete-files-permanently"
            />
          </div>
        ) : (
          <></>
        )}
      </Card>
    </>
  );
};

export default StorageTable;
