import {
  GET_SALIKS,
  GET_SALIK_ACCOUNT,
  GET_VEHICLE_TAGS,
  SET_SELECTED_VEHICLES,
  GET_TOLL_TRIPS,
  GET_DARBS,
  GET_WEEKLY_SUMMARY,
  GET_SUMMARY_BY_GATES,
  RESET_SELECTED_VEHICLE,
} from "../actions/toll.action";

const initialState = {
  saliks: [],
  DARBs: [],
  vehicleTags: [],
  salikAccount: false,
  tollTrips: [],
  weeklySummary: [],
  summaryByGates: [],
  selectedVehicles: [],
};

const TollReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SALIKS:
      return { ...state, saliks: action.value };

    case GET_DARBS:
      return { ...state, DARBs: action.value };

    case GET_VEHICLE_TAGS:
      return { ...state, vehicleTags: action.value };

    case GET_SALIK_ACCOUNT:
      return { ...state, salikAccount: action.value };

    case GET_TOLL_TRIPS:
      return { ...state, tollTrips: action.value };

    case GET_WEEKLY_SUMMARY:
      return { ...state, weeklySummary: action.value };

    case GET_SUMMARY_BY_GATES:
      return { ...state, summaryByGates: action.value };

    case SET_SELECTED_VEHICLES:
      var cloneSelectedVehicles = [...state.selectedVehicles];

      action.vehicles.map((vehicle) => {
        const exist = cloneSelectedVehicles.findIndex((_d) => _d == vehicle);

        if (action.value == true && exist == -1)
          cloneSelectedVehicles.push(vehicle);
        if (action.value == false && exist !== -1)
          cloneSelectedVehicles.splice(exist, 1);
      });

      return { ...state, selectedVehicles: cloneSelectedVehicles };

    default:
      return state;
  }
};

export default TollReducer;
