
export const SET_OPTIMIZE_REQUEST_DATA = 'SET_OPTIMIZE_REQUEST_DATA'; 


export const setOptimizeRequestData = (requestData) => {

    return dispatch => {
        dispatch({
            type: SET_OPTIMIZE_REQUEST_DATA,
            value: requestData
        })
    };
}