import React, { useRef } from "react";
import DatePicker from "react-datepicker";
const CalendarField = (props) => {
  const newClasses = props.className ? props.className : "";
  const classes = "font-13 mt-1 " + newClasses;

  const calender = useRef();

  const openCalander = () => {
    calender.current.setOpen(true);
  };

  return (
    <fieldset>
      <legend>
        <span>{props.label}</span>
      </legend>
      <div className="d-flex">
        <DatePicker
          ref={calender}
          selected={props.selected ? props.selected : null}
          onChange={props.onChange ? props.onChange : null}
          dateFormat="dd-MM-yyyy"
          className={classes}
          minDate={props.minDate ? props.minDate : false}
        />
        <div style={styles.icon}>
          <i
            className="fa fa-calendar  mt-2_5 text-success ml-2 font-12 pointer"
            onClick={openCalander}
          ></i>
        </div>
      </div>
    </fieldset>
  );
};

const styles = {
  icon: {
    width: "100%",
    textAlign: "right",
    marginRight: "20px",
  },
};

export default CalendarField;
