import { REMOVE_ACTIVE_IMMOBILIZE_VEHICLE, SET_ACTIVE_IMMOBILIZE_VEHICLE, SET_LOADING, UPDATE_ACTIVE_IMMOBILIZE_VEHICLE, SET_VSS_TOKEN } from "../actions/immobilizer.action";


const vssDevices = {
    52448: "STL030924",
    53872: "STL230924",
    54186: "STL330924",
}
const initialState = {
    activeVehicle: null,
    vssToken: "",
    isLoading: false,
}

const ImmobilizerReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ACTIVE_IMMOBILIZE_VEHICLE:
            if (state.isLoading) return state;
            if (vssDevices[action?.value?.id]) action.value.vssID = vssDevices[action?.value?.id];
            return { ...state, activeVehicle: action.value }

        case REMOVE_ACTIVE_IMMOBILIZE_VEHICLE:
            return { ...state, activeVehicle: null }

        case SET_LOADING:
            return { ...state, isLoading: action.value }

        case UPDATE_ACTIVE_IMMOBILIZE_VEHICLE:
            return { ...state, activeVehicle: action.value }

        case SET_VSS_TOKEN:
            return { ...state, vssToken: action.value }

        default: return state;
    }
}

export default ImmobilizerReducer;