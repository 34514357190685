import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  getAllPoi,
  getCustomerList,
  getTaskCustomerGroups,
} from "../../../store/actions/taskManager.action";
import Header from "./Header";
import Locations from "./Locations";
import Loader from "../../UI/Loader";

const LocationPage = (props) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({
    sortBy: "date",
    groupId: 0,
    page: 1,
    search: "",
  });

  const loadCustomers = async () => {
    try {
      setIsLoading(true);
      await dispatch(getCustomerList(data));
      setIsLoading(false);
    } catch (error) {}
  };

  useEffect(() => {
    dispatch(getAllPoi());
    dispatch(getTaskCustomerGroups());
    loadCustomers();
  }, []);

  useEffect(() => {
    loadCustomers();
  }, [data]);

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <Header
        setData={setData}
        data={data}
        toggleLocation={props.toggleLocation}
      />
      <Locations setData={setData} data={data} />
    </React.Fragment>
  );
};

export default LocationPage;
