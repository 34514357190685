import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import CalendarField from '../../UI/CalendarField';
import { Container } from "react-bootstrap";

const SelectDateModal = (props) => {

    const [from, setFrom] = useState(new Date())
    const [to, setTo] = useState(new Date())

    const handleSubmit = (event) => {

        // const formData = new FormData(event.target);
        event.preventDefault();

        //APIs call here...
        if(props.onSet) props.onSet(from, to)

    }

    return (
        <>
            <Modal
                show={props.show}
                dialogClassName="modal-40w"
                onHide={props.toggleModal}
                aria-labelledby="SelectDateModal"
            >
                <Modal.Header closeButton >
                    Select Date
                </Modal.Header>
                <form onSubmit={handleSubmit} >
                    <Modal.Body className="show-grid pb-4 pt-4">
                        <Container>

                            <div className="row">
                                <div className="col-md-6">
                                    <CalendarField label="From" selected={from} onChange={(date) => setFrom(date)} />
                                </div>
                                <div className="col-md-6">
                                    <CalendarField label="To" selected={to} onChange={(date) => setTo(date)} />
                                </div>
                            </div>

                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <button style={{ fontSize: '12px', backgroundColor: '#007bff', color: '#ffffff' }} className="btn btn-small" >Go</button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default SelectDateModal