// import React from "react";
// export default class Thumb extends React.Component {
//   state = {
//     loading: false,
//     thumb: undefined,
//   };

//   componentWillReceiveProps(nextProps) {
//     if (!nextProps.file) {
//       return;
//     }

//     this.setState({ loading: true }, () => {
//       let reader = new FileReader();

//       reader.onloadend = () => {
//         this.setState({ loading: false, thumb: reader.result });
//       };

//       reader.readAsDataURL(nextProps.file);
//     });
//   }

//   render() {
//     const { file, onRemove, isRemovable = true, imgClasses = "" } = this.props;
//     const { loading, thumb } = this.state;

//     if (!file) {
//       return null;
//     }

//     if (loading) {
//       return <p>loading...</p>;
//     }

//     const closeStyle = {
//       position: "absolute",
//       marginLeft: "90px",
//     };

//     const removeFileHandler = (event) => {
//       onRemove();
//       event.stopPropagation();
//     };

//     const loadImage = () => {
//       var ext = file.name?.toLowerCase();

//       if (
//         ext?.match("jpg") ||
//         ext?.match("jpeg") ||
//         ext?.match("png") ||
//         ext?.match("gif")
//       ) {
//         return (
//           <img
//             src={thumb}
//             alt="Image Not Found"
//             className={`img-thumbnail mt-2 ${imgClasses}`}
//             width={100}
//           />
//         );
//       } else if (ext?.match("pdf")) {
//         return (
//           <img
//             src={require("../../assets/img/pdf.png").default}
//             alt=""
//             className={`img-thumbnail mt-2 ${imgClasses}`}
//             width={100}
//           />
//         );
//       } else {
//         return (
//           <img
//             src={require("../../assets/img/file.png").default}
//             alt=""
//             className={`img-thumbnail mt-2 ${imgClasses}`}
//             width={100}
//           />
//         );
//       }
//     };

//     return (
//       <div>
//         {isRemovable ? (
//           <i
//             className="fa fa-times-circle pointer"
//             onClick={(event) => removeFileHandler(event)}
//             style={closeStyle}
//           ></i>
//         ) : (
//           <></>
//         )}

//         {loadImage()}
//       </div>
//     );
//   }
// }

import React, { useState, useEffect } from "react";

const Thumb = (props) => {
  const [loading, setLoading] = useState(false);
  const [thumb, setThumb] = useState(undefined);

  useEffect(() => {
    if (!props.file) {
      return;
    }

    setLoading(true);

    let reader = new FileReader();

    reader.onloadend = () => {
      setLoading(false);
      setThumb(reader.result);
    };

    reader.readAsDataURL(props.file);
  }, [props.file]);

  const { file, onRemove, isRemovable = true, imgClasses = "" } = props;

  if (!file) {
    return null;
  }

  if (loading) {
    return <p>loading...</p>;
  }

  const closeStyle = {
    position: "absolute",
    marginLeft: "90px",
  };

  const removeFileHandler = (event) => {
    onRemove();
    event.stopPropagation();
  };

  const loadImage = () => {
    var ext = file.name?.toLowerCase();

    if (
      ext?.match("jpg") ||
      ext?.match("jpeg") ||
      ext?.match("png") ||
      ext?.match("gif")
    ) {
      return (
        <img
          src={thumb}
          alt="Image Not Found"
          className={`img-thumbnail mt-2 ${imgClasses}`}
          width={100}
        />
      );
    } else if (ext?.match("pdf")) {
      return (
        <img
          src={require("../../assets/img/pdf.png").default}
          alt=""
          className={`img-thumbnail mt-2 ${imgClasses}`}
          width={100}
        />
      );
    } else {
      return (
        <img
          src={require("../../assets/img/file.png").default}
          alt=""
          className={`img-thumbnail mt-2 ${imgClasses}`}
          width={100}
        />
      );
    }
  };

  return (
    <div>
      {isRemovable ? (
        <i
          className="fa fa-times-circle pointer"
          onClick={(event) => removeFileHandler(event)}
          style={closeStyle}
        ></i>
      ) : (
        <></>
      )}

      {loadImage()}
    </div>
  );
};

export default Thumb;
