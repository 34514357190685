import moment from "moment";

export const durationToMinutes = (timeInMilli) => {
  var seconds = timeInMilli / 1000;
  var minutes = seconds / 60;
  return Math.floor(minutes);
};

export const getTotalHours = (value, format = "hh:mm:ss") => {
  var sec_num = parseInt(value, 10); // don't forget the second param
  var hours = Math.floor(sec_num / 3600);
  var minutes = Math.floor((sec_num - hours * 3600) / 60);
  var seconds = sec_num - hours * 3600 - minutes * 60;

  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }

  switch (format) {
    case "hh:mm":
      return hours + ":" + minutes;

    default:
      return hours + ":" + minutes + ":" + seconds;
  }
};

export const toHHMMSS = (totalDuration, format = "HH:mm:ss") => {
  return moment
    .utc(moment.duration(totalDuration, "milliseconds").asMilliseconds())
    .format(format);
};

export const toHHmmFromSeconds = (totalDuration, format = "HH:mm") => {
  return moment
    .utc(moment.duration(totalDuration * 1000, "milliseconds").asMilliseconds())
    .format(format);
};

export const toHHmmFromSecondsInWord = (totalDuration, format = "HH:mm") => {
  const duration = toHHmmFromSeconds(totalDuration, format);
  var words = "";
  var parts = duration.split(":");
  if (parts.length >= 2) {
    words = `${parts[0]} Hrs ${parts[1]} Mins`;
  }
  return words;
};

export const filterTripsForReports = (trip) => {
  if (trip.type === undefined) {
    trip.type = trip.distance == 0 ? "Parking" : "Trips";
  }

  try {
    if (trip.type == "Speed Violation") return false;

    // Check Trips Distance
    if (trip.type == "Trips" && trip.distance <= 100) return false;

    // Duration lessthen 1 Minute
    if (trip.type == "Parking" && durationToMinutes(trip.duration) < 1)
      return false;

    return true;
  } catch (error) {
    return true;
  }
};

export const tripCalculation = (trips = []) => {
  const tripsCount = {
    total: 0,
    duration: 0,
    totalDistance: 0,
  };

  const parkingsCount = {
    total: 0,
    duration: 0,
  };

  const idlingsCount = {
    total: 0,
    duration: 0,
  };

  try {
    for (let i = 0; i < trips.length; i++) {
      const trip = trips[i];

      if (trip.type === undefined) {
        trip.type = trip.distance == 0 ? "Parking" : "Trips";
      }

      if (!filterTripsForReports(trip)) continue;

      switch (trip.type) {
        case "Trips":
          tripsCount.total = tripsCount.total + 1;
          tripsCount.duration = tripsCount.duration + trip.duration;
          tripsCount.totalDistance = tripsCount.totalDistance + trip.distance;
          break;

        case "Parking":
          parkingsCount.total = parkingsCount.total + 1;
          parkingsCount.duration = parkingsCount.duration + trip.duration;
          break;

        case "Idling":
          idlingsCount.total = idlingsCount.total + 1;
          idlingsCount.duration = idlingsCount.duration + trip.duration;
          break;

        default:
      }
    }
  } catch (error) {
    console.log(error);
  }

  return { tripsCount, parkingsCount, idlingsCount };
};
