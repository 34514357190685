import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Container } from "react-bootstrap";
import Select from "../../UI/Select";
import Input from "../../UI/Input";
import CalendarField from "../../UI/CalendarField";
import moment from "moment";


import './tasks.css'

const RepeatTaskModal = (props) => {


  const { startDate = Date.now() } = props

  const [repeatMode, setRepeatMode] = useState('norepeat');
  const [isModeSelected, setIsModeSelected] = useState(false);

  const [selectedDays, setSelectedDays] = useState([]);
  const [endDate, setEndDate] = useState(moment(startDate).add(1, 'months').toDate());
  const [selectedCustomMode, setSelectedCustomMode] = useState('days');
  const [repeatOn, setRepeatOn] = useState(1)

  const weekDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']


  const handleOnRepeatChange = (e) => {

    setRepeatMode(e.target.value);

    switch (e.target.value) {

      case 'custom':
        break;

      default:
        break;

    }

  }


  const handleCloseModal = () => {
    setRepeatMode('norepeat')
  }

  const handleOnDaySelect = (day) => {
    const i = selectedDays.indexOf(day)

    if (i == -1) {
      setSelectedDays(prev => [...prev, day])
    } else {
      const cln = [...selectedDays]
      cln.splice(i, 1)
      setSelectedDays(cln)
    }
  }

  const handleCustomRepeat = () => {

    var _message = ''

    switch (selectedCustomMode) {
      case 'weeks':

        var days = ''
        selectedDays.map((d) => days = `${days} ${d}`)

        _message = `Every ${repeatOn > 1 ? repeatOn : ''} Week on ${days}`;
        break;

      case 'months':
        _message = `Every ${repeatOn > 1 ? repeatOn : ''} months till ${moment(endDate).format('DD MMM, yyyy')}`;
        break;

      case 'years':
        _message = `Every ${repeatOn > 1 ? repeatOn : ''} years till ${moment(endDate).format('DD MMM, yyyy')}`;
        break;

      default:
        _message = repeatOn == 1 ? 'Every Day' : `Every ${repeatOn} Days`;

    }

    var _data = {
      message: _message,
      type: selectedCustomMode,
      days: selectedCustomMode == 'weeks' ? selectedDays.map((d) => d.slice(0, 3)) : [],
      endDate: endDate,
      interval: repeatOn,
      repeatType: repeatMode
    }

    setIsModeSelected(_data);
    setRepeatMode(JSON.stringify(_data))
  }


  return (
    <React.Fragment>
      <Modal
        show={repeatMode == 'custom' ? true : false}
        dialogClassName="modal-30w"
        onHide={handleCloseModal}
        aria-labelledby="RepeatTaskModal"
      >
        <Modal.Header closeButton>Custom Repeat</Modal.Header>
        <Modal.Body className="show-grid">
          <Container>
            <div className="row">
              <div className="col-4">
                <Input
                  value={repeatOn}
                  onChange={(val) => setRepeatOn(val)}
                  options={{
                    min: 1,
                  }}
                  type="number"
                  label="Repeat Every"
                />
              </div>
              <div className="col-8">
                <Select onChange={(e) => setSelectedCustomMode(e.target.value)} value={selectedCustomMode} title="Repeat On">
                  <option value="days"> Day </option>
                  <option value="weeks"> Week </option>
                  <option value="months"> Month </option>
                  {/* <option value="years"> Year </option> */}
                </Select>
              </div>

              {selectedCustomMode == 'weeks' && <div className="col-12 text-left font-12 mt-3 mb-1">
                <span>Repeat On Day</span>
                <div className="mt-2">
                  {weekDays.map(d => <label onClick={() => handleOnDaySelect(d)} className={`week-day ${selectedDays.indexOf(d) !== -1 ? 'week-day-active' : ''}`}>{d.slice(0, 3)}</label>)}
                </div>
              </div>
              }

              <div className="col-12">
                <CalendarField
                  label="Repeat End On"
                  selected={endDate}
                  minDate={moment().toDate()}
                  onChange={(date) => setEndDate(date)}
                />
              </div>
            </div>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            style={{ fontSize: "12px" }}
            className="btn btn-small"
            onClick={handleCloseModal}
          >
            Cancel
          </button>
          <button
            onClick={handleCustomRepeat}
            style={{
              fontSize: "12px",
              backgroundColor: "#007bff",
              color: "#ffffff",
            }}
            className="btn btn-small"
          >
            {" "}
            Done{" "}
          </button>
        </Modal.Footer>
      </Modal>

      <Select value={repeatMode} onChange={handleOnRepeatChange} title="Repeat This Job" name="repeat">
        <option value="norepeat">Does not Repeat</option>
        <option value="daily">Daily</option>
        {/* <option value="weekly">Weekly On {moment(startDate).format('dddd')}</option> */}
        {/* <option value="monthly">Monthly On the Third {moment(startDate).format('dddd')}</option> */}
        {/* <option value="annual">Annual on {moment(startDate).format('MMM DD')}</option> */}
        {isModeSelected && <option value={JSON.stringify(isModeSelected)}>{isModeSelected.message}</option>}
        <option value="custom">Custom</option>
      </Select>


    </React.Fragment>
  );
};

export default React.memo(RepeatTaskModal);
