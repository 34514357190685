import React, { useState, useEffect } from "react";
import GoogleMapReact, { Marker } from "google-map-react";
import { getAddress } from "../../services/localStorageService";
import { useCallback } from "react";
import { dubaiCoordinates } from "../../configs/common";

const DragableMap = (props) => {
  const [position, setPosition] = useState(dubaiCoordinates);

  const parsePosition = (pos) => {
    return { lat: parseFloat(`${pos.lat}`), lng: parseFloat(`${pos.lng}`) };
  };
  useEffect(() => {
    if (props.position) setPosition(parsePosition(props.position));
  }, []);

  useEffect(() => {
    if (props.position && props.allowUpdateOnChange) {
      setPosition(parsePosition(props.position));
    }
  }, [props.position]);

  const loadMap = useCallback((map, maps) => {});

  const onDragChange = async (e) => {
    const lat = e.lat.toFixed(4);
    const lng = e.lng.toFixed(4);
    setPosition({ lat: lat, lng: lng });
    if (props.onDragChange) {
      const address = await getAddress(lat, lng);
      props.onDragChange({
        position: { lat: lat, lng: lng },
        address: address,
      });
    }
  };

  return (
    <GoogleMapReact
      bootstrapURLKeys={{ key: "AIzaSyC-ahgqsPN7HgQz_2fjWFLlUYzQkI6FHKo" }}
      center={position}
      defaultZoom={15}
      options={{
        fullscreenControl: false,
      }}
      onClick={(e) => onDragChange(e)}
      yesIWantToUseGoogleMapApiInternals
      onGoogleApiLoaded={({ map, maps }) => loadMap(map, maps)}
    >
      <img
        lat={position.lat}
        lng={position.lng}
        draggable={true}
        className="marker"
        src={require(`../../assets/img/new/marker.png`).default}
        style={{ height: "40px", width: "40px" }}
      />
    </GoogleMapReact>
  );
};

export default DragableMap;
