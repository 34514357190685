import React from "react";

const SearchInput = (props) => {
  return (
    <div className={`d-flex ${props.containerClass}`}>
      <input
        type="text"
        onChange={props.onSearch}
        className={`searchInput ${props.className}`}
        placeholder={props.placeholder}
        value={props.value}
        style={props.style}
        onFocus={(e) => e.stopPropagation()}
        onClick={(e) => e.stopPropagation()}
      />
      {props.text ? (
        props.text.trim() == "" ? (
          <i
            className="fa fa-search iconSearch"
            style={props.iconStyle || {}}
          ></i>
        ) : (
          <i
            className="fa fa-close iconSearch"
            style={props.iconStyle || {}}
            onClick={props.onClear}
          ></i>
        )
      ) : (
        <i
          className="fa fa-search iconSearch"
          style={props.iconStyle || {}}
        ></i>
      )}
    </div>
  );
};

export default SearchInput;
