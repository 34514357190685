import LottieLoader from "./LottieLoader";

const MainLoader = (props) => {
  return (
    <div 
        className="d-flex justify-content-center align-items-center" 
        style={{ 
            backgroundColor : 'rgba(255, 255, 255, 0.85)', //0000005e
            height : '100vh',
            zIndex: 999999999999,
            width: '100%',
            position: 'absolute',
            // left: '8.5%'
        }}>
        
        <LottieLoader/>
    </div>
  );
};

export default MainLoader;
