import { EDIT_ROUTE, GET_ROUTES, SET_ACTIVE_ROUTE } from "../actions/route.action";

const initialState = {
    routes: [],
    activeRoute : false

};

const RouteReducer = (state = initialState, action) => {
    switch (action.type) {

        case GET_ROUTES:
            return { ...state, routes: action.value };

        case SET_ACTIVE_ROUTE:
            return { ...state, activeRoute: action.value };

        case EDIT_ROUTE:

            var cloneRoutes = [...state.routes]
            var exist = cloneRoutes.findIndex((route) => route._id == action.value._id)

            if (exist != -1) cloneRoutes[exist] = action.value

            return { ...state, routes: cloneRoutes };


        default:
            return state;
    }
};

export default RouteReducer;
