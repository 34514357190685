import React from "react";

const SiteBroken = () => {
  return (
    <section className="page_503">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 text-center">
            <div className="site_broken_bg"></div>
            <div className="contant_box_404">
              {/* <p class="maintenance-title">Subscription Expired!</p> */}
              <h3 className="maintenance-title-2">Something went wrong!</h3>
              <p className="maintenance-message">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SiteBroken;
