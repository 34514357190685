import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { getStorage, setStorage } from '../../services/localStorageService';
import { getModuleNameByURL } from '../../configs/common';
import moment from 'moment';
import { trackSession } from '../../analitics/track';

const useModuleTimer = () => {
  const [startTime, setStartTime] = useState(null);
  const [moduleTimes, setModuleTimes] = useState({});
  const location = useLocation();

  useEffect(() => {


    const lastActiveModule = getStorage('MDL');
    const moduleName = getModuleNameByURL(location.pathname);

    console.log(lastActiveModule,moduleName)

    if(lastActiveModule != moduleName){
        // CALL API FOR END SESSION
        const time = getStorage('MDL-START')
        console.log('SESSION START WAS ',time)

        try {
            
            const duration = moment().diff( moment(time), 'seconds');
            trackSession(lastActiveModule,duration)
            

        } catch (error) {
            console.log('Error in track',error)
        }

        setStorage('MDL',moduleName);
        setStorage('MDL-START',new Date());
    } else {
        // 
        console.log('lastActiveModule',lastActiveModule)
    }


  }, [location.pathname]); // Run the effect whenever the route changes

  return moduleTimes;
};

export default useModuleTimer;
