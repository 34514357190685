import { isArray } from "underscore";
import { defaultDashboardCharts } from "../../configs/common";
import GetRequest from "../../services/GetRequest";
import PostRequest from "../../services/PostRequest";

export const SHOW_VEHICLES = "SHOW_VEHICLES";
export const REMOVE_VEHICLE = "REMOVE_VEHICLE";
export const DISTANCE_DETAILS = "DISTANCE_DETAILS";
export const MOVING_DETAILS = "MOVING_DETAILS";
export const IDLING_DETAILS = "IDLING_DETAILS";
export const PARKING_DETAILS = "PARKING_DETAILS";
export const DASHBOARD_REPORTS = "DASHBOARD_REPORTS";

export const CHART_PARKING_REPORT = "CHART_PARKING_REPORT";
export const CHART_MOVING_REPORT = "CHART_MOVING_REPORT";
export const CHART_IDLING_REPORT = "CHART_IDLING_REPORT";
export const DASHBOARD_VEHICLE_DETAILS = "DASHBOARD_VEHICLE_DETAILS";
export const CHART_DRIVER_BEHAVIOUR_REPORT = "CHART_DRIVER_BEHAVIOUR_REPORT";

export const SET_INTERVAL = "SET_INTERVAL";

const removePreviousChartData = (dispatch, key) => {
  dispatch({
    type: key,
    value: null,
  });
};

export const showVehicles = (data, position = null) => {
  return async (dispatch) => {
    var response = await PostRequest("/dashboard/showVehicle", data);

    response.id = data.id;
    response.position = position;

    dispatch({
      type: SHOW_VEHICLES,
      value: response,
    });
  };
};

export const removeActiveVehicle = () => {
  return async (dispatch) => {
    dispatch({
      type: REMOVE_VEHICLE,
      value: null,
    });
  };
};

export const getWeeklyDistanceDetails = (id) => {
  return async (dispatch) => {
    try {
      removePreviousChartData(dispatch, DISTANCE_DETAILS);

      var response = await GetRequest(
        `/dashboard/getWeeklyDistanceDetails/${id}`,
        {}
      );

      dispatch({
        type: DISTANCE_DETAILS,
        value: response,
      });
    } catch (error) {
      console.log("Error", error);
    }
  };
};

// https://mylocatorplus.com/gateway/index.php/api-v1/dashboard/getWeeklyMovingDetails/20614
export const getWeeklyMovingDetails = (id) => {
  return async (dispatch) => {
    removePreviousChartData(dispatch, CHART_MOVING_REPORT);

    var response = await GetRequest(
      `/dashboard/getWeeklyMovingDetails/${id}`,
      {}
    );
    dispatch({
      type: CHART_MOVING_REPORT,
      value: response,
    });
  };
};

// https://mylocatorplus.com/gateway/index.php/api-v1/dashboard/getWeeklyIdlingDetails/20614
export const getWeeklyIdlingDetails = (id) => {
  return async (dispatch) => {
    removePreviousChartData(dispatch, CHART_IDLING_REPORT);

    var response = await GetRequest(
      `/dashboard/getWeeklyIdlingDetails/${id}`,
      {}
    );
    dispatch({
      type: CHART_IDLING_REPORT,
      value: response,
    });
  };
};

export const getDriverBehaviourReport = (id) => {
  return async (dispatch) => {
    removePreviousChartData(dispatch, CHART_DRIVER_BEHAVIOUR_REPORT);

    var response = await GetRequest(
      `/dashboard/getDriverBehaviourDetails/${id}`,
      {}
    );
    dispatch({
      type: CHART_DRIVER_BEHAVIOUR_REPORT,
      value: response,
    });
  };
};

// https://mylocatorplus.com/gateway/index.php/api-v1/dashboard/getWeeklyParkingDetails/20614
export const getWeeklyParkingDetails = (id) => {
  return async (dispatch) => {
    removePreviousChartData(dispatch, CHART_PARKING_REPORT);

    var response = await GetRequest(
      `/dashboard/getWeeklyParkingDetails/${id}`,
      {}
    );
    dispatch({
      type: CHART_PARKING_REPORT,
      value: response,
    });
  };
};

export const getWeeklyVehicleDetails = (id) => {
  return async (dispatch) => {
    var response = await GetRequest(
      `/dashboard/getDashboardVehDetails/${id}`,
      {}
    );
    dispatch({
      type: DASHBOARD_VEHICLE_DETAILS,
      value: response,
    });
  };
};

export const getDashboardSettings = () => {
  return async (dispatch) => {
    try {
      const response = await GetRequest("/user/getdashboardsettingslist");

      dispatch({
        type: DASHBOARD_REPORTS,
        value:
          isArray(response) && response.length > 0
            ? response
            : defaultDashboardCharts,
      });
    } catch (error) {
      dispatch({
        type: DASHBOARD_REPORTS,
        value: defaultDashboardCharts,
      });
    }
  };
};

export const setIntervalData = (data) => {
  return async (dispatch) => {
    dispatch({
      type: SET_INTERVAL,
      value: data,
    });
  };
};
