import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Container } from "react-bootstrap";
import DropZoneInput from "../../UI/DropZoneInput";
import { ShowNotification } from "../../../services/Notifications";
import TaskManagerRequest from "../../../services/TaskManagerRequest";
import LottieLoader from "../../UI/LottieLoader";
import { CUSTOMER_CSV_FIELDS } from "../../../configs/csv.config";

const UploadLocationCSVModal = (props) => {
  const [uploadedCSV, setUploadedCSV] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [uploadResponse, setUploadResponse] = useState({
    isDone: false,
    success: false,
    hasError: false,
    updated: false,
  });

  const handleUploadCSV = async () => {
    if (!uploadedCSV || isLoading) return false;

    try {
      const formData = new FormData();
      formData.append("csv", uploadedCSV);
      setIsLoading(true);
      const response = await TaskManagerRequest("/customers/import", formData);

      setUploadResponse({
        isDone: true,
        success: response.success,
        hasError: response.errors,
        updated: response.updated,
      });
    } catch (error) {
      ShowNotification("error", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Modal
        show={props.show}
        dialogClassName="modal-70w"
        onHide={props.toggleModal}
        aria-labelledby="attendanceMap"
      >
        <Modal.Header closeButton>Import Locations</Modal.Header>
        <Modal.Body className="show-grid">
          <Container>
            <DropZoneInput
              selected={uploadedCSV}
              style={{ height: 100 }}
              file={uploadedCSV}
              label="Select File"
              onChange={(file) => setUploadedCSV(file)}
              accept=".csv"
              onRemove={() => setUploadedCSV(false)}
            />

            {uploadedCSV && (
              <div className="text-center">
                <button
                  onClick={handleUploadCSV}
                  type="button"
                  className="btn btn-primary my-4"
                >
                  Import Tasks
                </button>
              </div>
            )}

            {isLoading && uploadedCSV && (
              <div className="text-center">
                <LottieLoader />
                <span className="text-muted font-12"> Please Wait... </span>
              </div>
            )}

            <div className="text-start pt-5">
              {uploadResponse.isDone && (
                <React.Fragment>
                  <div className="text-success">
                    <span>
                      <b>{uploadResponse.success}</b> Locations imported
                      successfully.
                    </span>
                  </div>
                  <div className="text-primary">
                    <span>
                      <b>{uploadResponse.updated}</b> Locations updated
                      successfully.
                    </span>
                  </div>
                  <div className="mt-2 text-danger">
                    <span>
                      <b>{uploadResponse.hasError.length}</b> Locations Failed
                      to import
                    </span>
                  </div>

                  {uploadResponse.hasError.length !== 0 && (
                    <div className="table-responsive">
                      <div className="mt-2 mb-2 font-12">
                        <span className="text-muted">
                          The following locations are failed to import, please
                          checkout the list.
                        </span>
                      </div>

                      <table
                        className="w-100 history-table"
                        style={{ border: "1px solid #eef3fe" }}
                      >
                        <tbody>
                          <tr
                            className="bg-white font-13 p-1"
                            style={{ color: "#1460ee" }}
                          >
                            <th
                              style={{ minWidth: 150, maxWidth: 200 }}
                              className="font-weight-bold p-2"
                            >
                              Error
                            </th>
                            {CUSTOMER_CSV_FIELDS.map((f, j) => (
                              <th
                                style={{ minWidth: 120, maxWidth: 150 }}
                                key={j}
                                className="font-weight-bold p-2"
                              >
                                {f}
                              </th>
                            ))}
                          </tr>

                          {uploadResponse.hasError.map((er, i) => (
                            <React.Fragment>
                              <tr
                                key={i}
                                style={{
                                  backgroundColor:
                                    i % 2 == 0 ? "#eef3fe" : "#fff",
                                }}
                                className="font-13 p-2"
                              >
                                <td
                                  className="p-2 text-danger font-12"
                                  style={{ minWidth: 150, maxWidth: 200 }}
                                >
                                  {er.error}
                                </td>
                                {CUSTOMER_CSV_FIELDS.map((f, j) => (
                                  <td
                                    style={{ minWidth: 120, maxWidth: 150 }}
                                    key={i + j}
                                    className="p-2 font-12"
                                  >
                                    {er.customer[f]}
                                  </td>
                                ))}
                              </tr>
                            </React.Fragment>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </React.Fragment>
              )}
            </div>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            style={{ fontSize: "12px" }}
            className="btn btn-small"
            onClick={props.toggleModal}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UploadLocationCSVModal;
