import moment from "moment";
import React, { useState, useRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { makeStyles } from "@material-ui/core/styles";

const Header = (props) => {
  const ref = useRef();
  const useStyles = makeStyles((theme) => ({
    removeElement: {
      display: "none !important",
    },
    header: {
      paddingBottom: "8px",
      background: "white !important",
    },
    month: {
      padding: "8px 0",
    },
    selectedMonth: {
      background: "gray !important",
      padding: "8px 0",
    },
    inputBox: {
      // background: 'white !important',
      border: "none",
    },
  }));
  const classes = useStyles();
  return (
    <div className="row mb-2">
      <div className="col-md-3">
        <p className="pt-3 text-black mb-2">Storage Summary</p>
      </div>
      <div className="col-md-9 pt-2 text-right">
        <div className="d-flex flex-1 float-right">
          <div className="d-flex">
            <DatePicker
              placeholderText="Select the Month"
              selected={props.dates ? props.dates.startDate : null}
              onChange={(date) => props.onChageMonth(date)}
              dateFormat="MMM y"
              maxDate={new Date()}
              isClearable={true}
              showMonthYearPicker
              ref={ref}
              className="form-control w-200px"
              onYearChange={() => {
                const selectedMonth = document.getElementsByClassName(
                  "react-datepicker__month-text--keyboard-selected"
                );
                selectedMonth[0].classList.add(classes.selectedMonth);
              }}
              onCalendarOpen={() => {
                const x = document.getElementsByClassName(
                  "react-datepicker-popper"
                );
                const y = document.getElementsByClassName(
                  "react-datepicker__triangle"
                );
                const z = document.getElementsByClassName(
                  "react-datepicker__header"
                );
                const months = document.getElementsByClassName(
                  "react-datepicker__month-text"
                );
                const selectedMonth = document.getElementsByClassName(
                  "react-datepicker__month-text--keyboard-selected"
                );
                x[0].classList.add(classes.root);
                y[0].classList.add(classes.removeElement);
                z[0].classList.add(classes.header);
                for (let i = 0; i < months.length; i++) {
                  months[i].classList.add(classes.month);
                }
                selectedMonth[0].classList.add(classes.selectedMonth);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  calendarLabel: {
    border: "1px solid rgb(204, 204, 204)",
    padding: "6px 7px",
    borderRadius: 5,
    fontSize: "12px",
    margin: " 0px 10px",
    height: 34.73,
  },
};

export default Header;
