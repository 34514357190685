import { GET_EXPENSES, LOAD_MORE_EXPENSES, LOAD_PERMISSIONS, GET_SERVICE_CENTER, GET_SERVICE_CENTERS, REMOVE_ACTIVE_SERVICE_CENTER, SET_SERVICE_CENTERS_BALANCE } from "../actions/ExpenseManager.action"

const initialState = {
    expenses: [],
    hasMoreExpanses : true,
    permissions: {
        summary: { canView: false },
        detailed: { canView: false, canMakePayment: false, canUpdate: false, canDelete: false, approval: false, verification: false },
        pcf: { canView: false, canMakePayment: false, canUpdate: false, canDelete: false },
    },
    serviceCenters: [],
    activeServiceCenter: false,
}

const ExpenseReducer = (state = initialState, action) => {

    switch (action.type) {

        case GET_EXPENSES:
            return { ...state, expenses: action.value }

        case GET_SERVICE_CENTERS:
            return { ...state, serviceCenters: action.value }

        case GET_SERVICE_CENTER:

            var _cloneServiceCenters = [...state.serviceCenters]

            var exist = _cloneServiceCenters.findIndex((center) => center._id == action.value._id)
            if (exist !== -1) _cloneServiceCenters[exist].balances = action.value.balances

            return { ...state, activeServiceCenter: action.value, serviceCenters: _cloneServiceCenters }

        case REMOVE_ACTIVE_SERVICE_CENTER:
            return { ...state, activeServiceCenter: action.value }

        case SET_SERVICE_CENTERS_BALANCE:

            var __cloneServiceCenters = [...state.serviceCenters]
            var cloneCenter = state.activeServiceCenter ? { ...state.activeServiceCenter } : false

            Object.keys(action.value).map((key) => {
                var exist = __cloneServiceCenters.findIndex((c) => c._id == key)
                if (exist !== -1) {
                    __cloneServiceCenters[exist].balances = action.value[key]
                    if (cloneCenter && key == cloneCenter._id) cloneCenter.balances = __cloneServiceCenters[exist].balances
                }
            })

            return { ...state, serviceCenters: __cloneServiceCenters, activeServiceCenter: cloneCenter }

        case LOAD_MORE_EXPENSES:
            return { ...state, expenses: state.expenses.concat(action.value), hasMoreExpanses : action.value.length == 0 ? false : true  }

        case LOAD_PERMISSIONS:
            return { ...state, permissions: action.value }

        default:
            return state

    }

}

export default ExpenseReducer