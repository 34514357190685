import React from "react";
import Modal from "react-bootstrap/Modal";

const ImageModal = (props) => {
  const downloadImage = () => {
    const imageUrl = props.src;
    fetch(imageUrl)
      .then((response) => response.blob())
      .then((blob) => {
        // Create a temporary anchor element
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;

        // Extract the filename from the URL
        const filename = imageUrl.substring(imageUrl.lastIndexOf("/") + 1);

        // Set the download attribute and filename
        link.setAttribute("download", filename);
        document.body.appendChild(link);

        // Simulate a click on the anchor element to start the download
        link.click();

        // Clean up the temporary anchor element
        link.parentNode.removeChild(link);

      })
      .catch((error) => {
        console.error("Error downloading image:", error);
      });
  };

  return (
    <>
      <Modal
        show={props.show}
        dialogClassName="modal-70w"
        onHide={props.toggleModal}
        aria-labelledby="attendanceMap"
      >
        <Modal.Header closeButton>{props.title}</Modal.Header>
        <Modal.Body className="show-grid">
          <img
            className="sign-image-view pointer"
            src={props.src}
            height={"auto"}
            width={"100%"}
          />
        </Modal.Body>
        <Modal.Footer>
          <button
            style={{
              fontSize: "12px",
              backgroundColor: "#007bff",
              color: "#ffffff",
            }}
            className="btn btn-small"
            onClick={downloadImage}
          >
            Download
          </button>
          <button
            type="button"
            style={{ fontSize: "12px" }}
            className="btn btn-small"
            onClick={props.toggleModal}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ImageModal;
