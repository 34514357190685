import React, { useEffect, useState } from "react";
import Settings from "./Settings";
import { useDispatch } from "react-redux";
import { getTaskDriverGroups } from "../../store/actions/taskManager.action";
import DriverGroup from "../../components/settings/storage-summary/driverGroups";
import Header from "../../components/settings/storage-summary/Header";
import StorageTable from "../../components/settings/storage-summary/StorageTable";
import TaskManagerRequest from "../../services/TaskManagerRequest";
import { ShowNotification } from "../../services/Notifications";
import Loader from "../../components/UI/Loader";
import { getstartAndEndDateOfMonth } from "../../configs/duration";

const StorageSummary = () => {
  const dispatch = useDispatch();
  const [storageUsed, setStorageUsed] = useState({});
  const [isLoading, setLoading] = useState(true);
  const [dates, setDates] = useState(false);

  const loadData = async (data = {}) => {
    try {
      setLoading(true);
      const response = await TaskManagerRequest("/storage/used", data);
      setStorageUsed(response);
    } catch (error) {
      ShowNotification("error", error);
    } finally {
      setLoading(false);
    }
  };

  const onChageMonth = (d) => {
    if (d == null) {
      setDates(false);
      loadData();
      return;
    }

    const datesObj = getstartAndEndDateOfMonth(d);
    setDates(datesObj);
    loadData(datesObj);
  };

  const loadInitialData = async () => {
    try {
      await dispatch(getTaskDriverGroups());
      const response = await TaskManagerRequest("/storage/oldest-date", {});
      var data = {};
      if (response) {
        data = getstartAndEndDateOfMonth(new Date(response));
        setDates(data);
        loadData(data);
      } else {
        loadData(data);
      }
    } catch (error) {
      console.log("Error...", error);
    }
  };

  useEffect(() => {
    loadInitialData();
  }, []);

  return (
    <Settings
      containerStyle={{ overflowY: "hidden" }}
      contentContainerStyle={{ overflowY: "hidden" }}
    >
      {isLoading ? <Loader style={{ left: "14%" }} /> : <></>}
      <Header onChageMonth={onChageMonth} dates={dates} />
      <div className="row">
        <div className="col-md-4" style={styles.container}>
          <DriverGroup
            storageUsed={storageUsed}
            isLoading={isLoading}
            setLoading={setLoading}
          />
        </div>
        <div
          className="col-md-8 pl-0 visible-scroll-hover"
          style={styles.container2}
        >
          <StorageTable
            storageUsed={storageUsed}
            loadData={loadData}
            dates={dates}
          />
        </div>
      </div>
    </Settings>
  );
};

const styles = {
  container: {
    height: "79vh",
    overflowY: "hidden",
    overflowX: "hidden",
  },
  container2: {
    height: "75vh",
    overflowY: "auto",
    overflowX: "hidden",
  },
};

export default StorageSummary;
