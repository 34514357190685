import { filter } from "underscore";
import moment from "moment";
import { CHART_DRIVER_BEHAVIOUR_REPORT, REMOVE_VEHICLE, SET_INTERVAL, CHART_IDLING_REPORT, CHART_MOVING_REPORT, CHART_PARKING_REPORT, DASHBOARD_REPORTS, DASHBOARD_VEHICLE_DETAILS, DISTANCE_DETAILS, SHOW_VEHICLES } from "../actions/dashboard.action";

const initialState = {
    vehicle: null,
    dashboardReports: [],
    weeklyDistanceReport: null,
    weeklyParkingReport: null,
    weeklyMovingReport: null,
    weeklyIdlingReport: null,
    weeklyDriverBehaviourReport: null,
    dashboardVehicleDetails: {
        manufacture_date: '',
        payload: '',
        engine_number: '',
        chassis_number: ''
    },
    intervalData: {
        from: moment(new Date().setHours(0, 0, 0, 0)).subtract(1, 'day').format('yyyy-MM-DD HH:mm:ss'),
        to: moment(new Date().setHours(0, 0, 0, 0)).format('yyyy-MM-DD HH:mm:ss'),
        deviceid: 0,
        option: 'yesterday'
    }
}

const DashboardReducer = (state = initialState, action) => {

    switch (action.type) {

        case SHOW_VEHICLES:
            return { ...state, vehicle: action.value }

        case REMOVE_VEHICLE:
            return { ...state, vehicle: action.value }


        case DASHBOARD_REPORTS:
            return { ...state, dashboardReports: action.value }


        case DISTANCE_DETAILS:
            return { ...state, weeklyDistanceReport: action.value }


        case CHART_PARKING_REPORT:
            return { ...state, weeklyParkingReport: action.value }

        case CHART_IDLING_REPORT:
            return { ...state, weeklyIdlingReport: action.value }


        case CHART_MOVING_REPORT:
            return { ...state, weeklyMovingReport: action.value }

        case DASHBOARD_VEHICLE_DETAILS:
            return { ...state, dashboardVehicleDetails: action.value }


        case CHART_DRIVER_BEHAVIOUR_REPORT:
            return { ...state, weeklyDriverBehaviourReport: action.value }

        case SET_INTERVAL:
            return { ...state, intervalData: action.value }

        default:
            return state

    }

}

export default DashboardReducer;