import React from "react";
import StaticMap from "../../UI/StaticMap";

export const _customerFields = {
  name: "name",
  email: "email",
  contact: "phoneNumber",
  // address : 'address',
};

const CustomerField = (props) => {
  if (!props.customer) return <p className="mb-0 font-12 text-black">-</p>;

  return (
    <React.Fragment>
      <div className="mb-0 font-12 text-black">
        {Object.values(_customerFields).map((f, i) => (
          <div key={i} className="d-flex justify-content-between m-1 ml-2">
            <div className="color: gray; font-size: 12px; margin-bottom: 0px;">
              {" "}
              {f}{" "}
            </div>
            <div>
              {" "}
              {props.customer[f] && props.customer[f] != ""
                ? props.customer[f]
                : "-"}{" "}
            </div>
          </div>
        ))}
      </div>

      {/* {props.isMap && props.customer.location?.latitude && props.customer.location?.longitude &&
                <div className="mb-3" style={{ height: '200px', width: '105%', marginTop: 10 }}>
                    <StaticMap lat={props.customer.location?.latitude} lng={props.customer.location?.longitude} />
                </div>
                } */}

      {props.isMap &&
        props.customer.location?.latitude &&
        props.customer.location?.longitude && (
          <div
            className="mb-3"
            style={{ height: "200px", width: "105%", marginTop: 10 }}
          >
            <StaticMap
              lat={parseFloat(`${props.customer.location?.latitude}`)}
              lng={parseFloat(`${props.customer.location?.longitude}`)}
            />
          </div>
        )}
    </React.Fragment>
  );
};

export default CustomerField;
